import { Component, OnInit, Input } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CommunityService } from 'src/app/core-services/community.service';
import { Observable } from 'rxjs';
import { QMI } from 'src/app/models/product/qmi.model';
import { QmiGalleryModel } from 'src/app/models/content/qmi/qmi-gallery.model';
import { GalleryModalModel } from 'src/app/models/content/media/gallery-modal.model';
import { BaseComponent } from '../../shared/base/base.component';

@Component({
  selector: 'app-qmi-gallery',
  templateUrl: './qmi-gallery.component.html',
  styleUrls: ['./qmi-gallery.component.scss']
})
export class QmiGalleryComponent extends BaseComponent implements OnInit {

  @Input() data: QmiGalleryModel;

  qmi$: Observable<QMI>;
  galleryModalModel: GalleryModalModel;

  constructor(private communityService: CommunityService,
              private route: ActivatedRoute) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      takeUntil(this.unsubscribe$)
    ).subscribe((inventoryHomeId: string) => {
      this.qmi$ = this.communityService.getQmiById(inventoryHomeId);
    });

    this.galleryModalModel = new GalleryModalModel();
    this.galleryModalModel.ModalTitle = this.data.Modal_Title;
    this.galleryModalModel.ExteriorLabel = this.data.Exterior_Label;
    this.galleryModalModel.InteriorLabel = this.data.Interior_Label;
    this.galleryModalModel.IsFilterGallery = true;
  }

}
