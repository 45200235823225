import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommunityPoiCategoriesModel } from 'src/app/models/content/community/community-poi-categories.model';
import { combineLatest } from 'rxjs';
import { CommunityService } from 'src/app/core-services/community.service';
import { CommunityPOICategoryModel } from 'src/app/models/product/community-poi-category';
import { takeUntil, take } from 'rxjs/operators';
import { GMapsOptionsModel } from 'src/app/models/maps/gmaps-options.model';
import { PointOfInterest } from 'src/app/models/product/point-of-interest.model';
import { Community } from 'src/app/models/product/community.model';
import { GMapsMarker } from 'src/app/models/maps/gmaps-marker.model';
import { getDistanceFromCommunity } from 'src/helper-functions/geolocation-helper';
import { GooglePlacesService } from 'src/app/core-services/google-places.service';
import { GMapsMarkerService } from 'src/app/core-services/gmaps-marker.service';
import { GooglePlacesSearchParams } from 'src/app/models/maps/google-places-search-params';
import { BaseComponent } from '../../shared/base/base.component';
import { PointOfInterestDetails } from 'src/app/models/product/point-of-interest-details.model';
import { GoogleMapsConfiguration } from 'src/app/models/maps/google-maps-configuration.model';
import { MapCommuteModalComponent } from '../../shared/modals/map-commute-modal/map-commute-modal.component';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { TravelTimeOption } from 'src/app/models/enums/google-maps-enum';
import { GmapsDirectionsService } from 'src/app/core-services/gmaps-directions.service';

@Component({
  selector: 'app-community-poi-categories',
  templateUrl: './community-poi-categories.component.html',
  styleUrls: ['./community-poi-categories.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class CommunityPoiCategoriesComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() data: CommunityPoiCategoriesModel;

  community: Community;
  communityCategories: CommunityPOICategoryModel[] = [];
  selectedCategory: CommunityPOICategoryModel;
  communityPOIs: PointOfInterest[] = [];
  categoryPOIs: PointOfInterest[] = [];
  selectedCategoryPOI: PointOfInterest;
  selectedPOIDetails: PointOfInterestDetails;
  isDirectionsMode: boolean = false;
  directionsRoute: google.maps.DirectionsRoute;
  leaveNow: TravelTimeOption = TravelTimeOption.LeaveNow;
  departAt: TravelTimeOption = TravelTimeOption.DepartAt;
  departureOption: TravelTimeOption = this.leaveNow;

  googleMapsConfiguration: GoogleMapsConfiguration;
  mapOptions: GMapsOptionsModel = new GMapsOptionsModel();
  mapMarkers: GMapsMarker[] = [];
  mapMarkersForService: GMapsMarker[] = [];
  filteredMarkers: GMapsMarker[] = [];
  selectedMarker: GMapsMarker;

  loading: boolean = false;
  radiusInMeters = 40233; // TODO: Make radius value (20 miles currently) sitecore configurable or a constant somewhere.
  radiusInMiles = 25;

  constructor(private communityService: CommunityService,
              private googlePlacesService: GooglePlacesService,
              private gmapsMarkerService: GMapsMarkerService,
              private gmapsDirectionsService: GmapsDirectionsService,
              private modalService: NgbModal) { super(); }

  ngOnInit() {
    const observables = [this.communityService.community,
                         this.communityService.communityPOICategories,
                         this.communityService.communityPOILocations,
                         this.gmapsMarkerService.mapConfiguration];
    combineLatest(observables)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([community, categories, pois, googleMapsConfiguration]: any[]) => {
        if (community) {
          // Set current community, used for map origin and Home Center map marker
          this.community = community;
          // Set initial Google Map location
          this.mapOptions.InitialLatitude = this.community.Latitude;
          this.mapOptions.InitialLongitude = this.community.Longitude;
        }
        if (categories && categories.length) {
          // Create Category model objects for display and filtering
          const communityCategories = categories.map((communityPoi, index) => {
            return new CommunityPOICategoryModel(communityPoi, index);
          });
          this.communityCategories = communityCategories;
          this.setSelectedCategory(0);
        }
        if (pois) {
          // Get initial Google Map Markers and filter by current Category (first one)
          this.communityPOIs = pois;
        }
        if (googleMapsConfiguration) {
          this.googleMapsConfiguration = googleMapsConfiguration;
        }
        this.setCategoryPOIsBySelectedCategory();
        this.setMapMarkersForDisplay();
      });

    this.googlePlacesService.places
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(places => {
        if (places && places.results && places.results.length) {
          const googlePOIs = [];
          for (const place of places.results) {
            const distance = getDistanceFromCommunity(this.community, place.geometry.location.lat, place.geometry.location.lng);
            const withinSearchRadius = distance <= this.radiusInMiles;
            if (place && this.selectedCategory && this.selectedCategory.item && withinSearchRadius) {
              const googlePOI = new PointOfInterest(googlePOIs.length.toString(), this.selectedCategory.item.PointOfInterestID);
              googlePOI.GooglePlaceID = place.place_id;
              googlePOI.POIName = place.name;
              googlePOI.POIAddress = place.formatted_address;
              googlePOI.POILatitude = place.geometry.location.lat;
              googlePOI.POILongitude = place.geometry.location.lng;
              googlePOI.Distance = distance;
              googlePOIs.push(googlePOI);
            }
          }
          this.loading = false;
          this.categoryPOIs = googlePOIs.sort((a, b) => parseFloat(a.Distance) - parseFloat(b.Distance));
          this.setMapMarkersForDisplay();
        }
      });

    this.googlePlacesService.details
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(details => {
        if (this.loading && details && details.result) {
          this.selectedPOIDetails = new PointOfInterestDetails();
          this.selectedPOIDetails.Name = details.result.name;
          this.selectedPOIDetails.Address = details.result.formatted_address;
          if (details.result.rating && details.result.rating > 0) {
            this.selectedPOIDetails.Rating = details.result.rating;
            this.selectedPOIDetails.RatingsCount = details.result.user_ratings_total;
          }
          if (details.result.price_level) {
            // Repeat '$' depending on price level given back from Google.
            this.selectedPOIDetails.Price = '$'.repeat(details.result.price_level);
          }
          if (details.result.reviews && details.result.reviews.length) {
            // Sort reviews by highest number rating and get text for highest-rated review.
            // TODO: Removed for now pending description design discussions.
            // this.selectedPOIDetails.Description = details.result.reviews.sort((a, b) => b.rating - a.rating)[0].text;
          }
          if (details.result.types && details.result.types.length) {
            // Concatenate, capitalize, and format all 'types' for selected Google Place returned back from Google.
            const subDescriptions = [];
            details.result.types.forEach(type => {
              if (!type.toLowerCase().includes('point_of_interest')) {
                subDescriptions.push(type.split('_').map(c => c.slice(0, 1).toUpperCase() + c.slice(1)).join(' '));
              }
            });
            if (subDescriptions.length) {
              this.selectedPOIDetails.SubDescription = subDescriptions.join(' - ');
              this.selectedPOIDetails.TypeDescriptor = subDescriptions[0];
            }
          } else if (this.selectedCategory) {
            // If no sub-descriptions/place types in response...just use the selected category name.
            this.selectedPOIDetails.TypeDescriptor = this.selectedCategory.item.PointOfInterestName;
          }
          this.loading = false;
        }
      });

    this.gmapsDirectionsService.directionsRoute$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(directionsRoute => {
        if (directionsRoute && directionsRoute.legs && directionsRoute.legs.length) {
          this.setDirectionsRouteData(directionsRoute);
        }
    });
  }

  ngOnDestroy() {
    this.setSelectedCategory(0);
    this.categoryPOIs = [];
    this.googlePlacesService.clearGooglePlacesData();
    super.ngOnDestroy();
  }

  setSelectedCategory(categoryIndex: number) {
    if (this.communityCategories && this.communityCategories.length) {
      if (this.communityCategories[categoryIndex]) {
        this.communityCategories[categoryIndex].isSelected = true;
        this.selectedCategory = this.communityCategories[categoryIndex];
      }
    }
  }

  // Set Selected Category then filter POIs based off of Category
  toggleCategory(categoryIndex: number): void {
    if (this.communityCategories && this.communityCategories.length) {
      this.deselectAllCategories();
      this.setSelectedCategory(categoryIndex);
      this.setCategoryPOIsBySelectedCategory();
    }
  }

  deselectAllCategories() {
    if (this.communityCategories && this.communityCategories.length) {
      this.communityCategories.map((item) => { item.isSelected = false; });
    }
  }

  setCategoryPOIsBySelectedCategory() {
    // Filter POIs for display in Local Area Map component
    this.selectedCategoryPOI = null;
    this.isDirectionsMode = false;
    this.clearDirectionsData();

    if (this.selectedCategory && this.selectedCategory.item) {
      if (this.selectedCategory.item.SourceType === 'G') {
        this.setCategoryPOIsFromGoogle();
      } else {
        this.setCategoryPOIsFromCommunity();
      }
    }
  }

  setMapMarkersForDisplay() {
    this.mapMarkersForService = [];
    if (this.community) {
      this.gmapsMarkerService.setCommunityMapMarkerForDisplay(this.community.BrandName, this.community.Latitude, this.community.Longitude,
        this.googleMapsConfiguration, this.mapMarkersForService);
    }
    this.createPOIMapMarkersForDisplay(this.categoryPOIs);

    this.mapMarkers = this.mapMarkersForService;
    this.gmapsMarkerService.updateGMapsMarkers(this.mapMarkersForService);
  }

  // Get initial Google Map Markers based off POIs
  createPOIMapMarkersForDisplay(pois: PointOfInterest[]) {
    if (this.community && pois && pois.length) {
      for (const poi of pois) {
        if (poi) {
          const marker = new GMapsMarker(poi.POILocationID, poi.POILatitude, poi.POILongitude, this.googleMapsConfiguration);
          marker.LabelText = (pois.findIndex(p => p === poi) + 1).toString();
          this.mapMarkersForService.push(marker);
        }
      }
    }
  }

  // Used for setting label of POI Icon
  getPOILocationIndexFromID(poiLocationID) {
    return this.mapMarkers.findIndex(m => m.MarkerID === poiLocationID);
  }

  setCategoryPOIsFromCommunity() {
    this.loading = false;
    this.categoryPOIs = [];
    if (this.selectedCategory && this.communityPOIs) {
      const poisFromCommunity = [];
      for (const poi of this.communityPOIs) {
        if (poi && poi.PointOfInterestID === this.selectedCategory.item.PointOfInterestID) {
          poisFromCommunity.push(poi);
        }
      }
      this.categoryPOIs = poisFromCommunity.sort((a, b) => parseFloat(a.Distance) - parseFloat(b.Distance));
      this.setMapMarkersForDisplay();
    }
  }

  setCategoryPOIsFromGoogle() {
    this.categoryPOIs = [];
    if (this.selectedCategory) {
      this.loading = true;
      const googlePlacesParams = new GooglePlacesSearchParams();
      googlePlacesParams.Query = this.selectedCategory.item.PointOfInterestName;
      googlePlacesParams.Latitude = this.community.Latitude;
      googlePlacesParams.Longitude = this.community.Longitude;
      googlePlacesParams.Radius = this.radiusInMeters;
      this.googlePlacesService.googlePlacesTextSearch(googlePlacesParams);
    }
  }

  setSelectedCategoryPOIByLocationID(poiLocationID) {
    if (this.categoryPOIs) {
      const newSelectedCategory = this.categoryPOIs.find(poi => {
        return poi.POILocationID === poiLocationID;
      });
      if (newSelectedCategory && newSelectedCategory !== this.selectedCategoryPOI) {
        this.selectedCategoryPOI = newSelectedCategory;
        this.loading = true;
        this.selectedPOIDetails = null;
        this.gmapsMarkerService.setSelectedMarker(this.selectedCategoryPOI.POILocationID, this.mapMarkersForService);
        this.googlePlacesService.getGooglePlacesDetailsById(this.selectedCategoryPOI.GooglePlaceID);
      }
    }
  }

  clearSelectedPOIData() {
    if (this.isDirectionsMode) {
      this.isDirectionsMode = false;
      this.clearDirectionsData();

      // check if Directions Destination POI is from a POI Category or not
      if (this.selectedCategoryPOI && this.selectedCategoryPOI.PointOfInterestID) {
        this.setMapMarkersForDisplay();
        this.gmapsMarkerService.setSelectedMarker(this.selectedCategoryPOI.POILocationID, this.mapMarkersForService);
        return;
      }

      if (this.selectedCategory) {
        this.toggleCategory(this.selectedCategory.index);
      }
    }

    this.selectedCategoryPOI = null;
    this.setMapMarkersForDisplay();
    this.gmapsMarkerService.deselectAllMarkers(this.mapMarkersForService);
  }

  setMarkersForDirections(destinationPOI: PointOfInterest) {
    if (destinationPOI && this.community) {
      this.loading = true;
      this.isDirectionsMode = true;
      this.gmapsDirectionsService.updateShowDirection(true);
      this.mapMarkersForService = [];

      // Origin/Community Marker
      this.gmapsMarkerService.setCommunityMapMarkerForDisplay(this.community.BrandName, this.community.Latitude, this.community.Longitude,
        this.googleMapsConfiguration, this.mapMarkersForService);

      // Destination/POI Marker
      const destinationMarker = new GMapsMarker(destinationPOI.POILocationID, destinationPOI.POILatitude, destinationPOI.POILongitude,
        this.googleMapsConfiguration, this.community.BrandName);
      destinationMarker.IsDestination = true;
      destinationMarker.IsSelected = true;
      destinationMarker.LabelText = destinationPOI.POIName;
      destinationMarker.setLabelAdjacentMarkerOptions();
      this.mapMarkersForService.push(destinationMarker);

      this.gmapsMarkerService.updateGMapsMarkers(this.mapMarkersForService);
    }
  }

  // Filter pois based off of currently selected marker
  markerClicked(marker: GMapsMarker) {
    if (marker) {
      if (marker.IsOrigin) {
        this.clearSelectedPOIData();
      } else {
        this.setSelectedCategoryPOIByLocationID(marker.MarkerID);
      }
    }
  }

  // If map clicked, reset filtered options on POIs
  mapClicked() {
    this.clearSelectedPOIData();
  }

  setDirectionsRouteData(route: google.maps.DirectionsRoute) {
    if (route && route.legs && route.legs.length) {
      this.directionsRoute = route;
      this.loading = false;
    } else {
      this.clearSelectedPOIData();
      this.openMapMyCommuteModal();
    }
  }

  openMapMyCommuteModal() {
    const modalRef: NgbModalRef = this.modalService.open(MapCommuteModalComponent,
      { windowClass: `${ModalClasses.Full} ${ModalClasses.Left} `, backdrop: true, backdropClass: ` ${ModalClasses.BackDrop}` });
    modalRef.componentInstance.data = this.data;

    // Subscribe for any submitted POI/Destinations submitted from the Modal
    modalRef.componentInstance.poiSubmitted
      .pipe(take(1))
      .subscribe((poi: PointOfInterest) => {
        if (poi) {
          this.directionsRoute = null;
          this.departureOption = this.leaveNow;
          this.deselectAllCategories();
          this.selectedCategoryPOI = poi;
          this.setMarkersForDirections(poi);
        }
      });
  }

  clearDirectionsData() {
    this.directionsRoute = null;
    this.departureOption = this.leaveNow;
    this.gmapsDirectionsService.updateDirectionsRoute(null);
    this.setTravelTime(null);
  }

  updateDepartureOptions(departureOption: TravelTimeOption) {
    this.departureOption = departureOption;

    if (departureOption === this.leaveNow) {
      this.setTravelTime(new Date());
    }
  }

  setTravelTime(selectedDate: Date) {
    this.gmapsDirectionsService.updateTravelTime(selectedDate);
  }
}
