import { ProductFilterDataModel } from '../content/community/product-filter-data.model';
import { Community } from '../product/community.model';
import { Plan } from '../product/plan.model';
import { QMI } from '../product/qmi.model';
import { AdobeLaunchEvent, AdobeLaunchFilterKey } from './adobe-launch-event-enum';

export class AdobeLaunchCommunity {
  communityID: string;
  communityName: string;
  brandName: string;

  constructor(community: Community) {
    if (community) {
      this.communityID = community.Id.toString();
      this.communityName = community.Name;
      this.brandName = community.BrandName;
    }
  }
}

export class AdobeLaunchPage {
  pageID: string;
  pageType: string;
  pageURL: string;
  community: AdobeLaunchCommunity;

  constructor(pageID, pageType, pageURL) {
    this.pageID = pageID;
    this.pageType = pageType;
    this.pageURL = pageURL;
  }
}

export class AdobeLaunchPageViewLoadedEvent {
  event: string;
  page: AdobeLaunchPage;

  constructor(page: AdobeLaunchPage) {
    this.event = AdobeLaunchEvent.PageViewLoaded;
    this.page = page;
  }
}

export class AdobeLaunchPlanInfo {
  planName: string;
  planID: string;
  qmiID: string;
  startingPrice: number;

  constructor(plan: Plan, startingPrice: number, qmiId: string = null) {
    if (plan) {
      this.planName = plan.PlanName;
      this.planID = plan.Id && plan.Id.toString();
    }
    if (qmiId) {
      this.qmiID = qmiId;
    }
    if (startingPrice) {
      this.startingPrice = startingPrice;
    }
  }
}

export class AdobeLaunchListing {
  community: AdobeLaunchCommunity;
  planInfo: AdobeLaunchPlanInfo;

  constructor(community: Community, plan: Plan, startingPrice: number, qmiId: string = null) {
    if (community) {
      this.community = new AdobeLaunchCommunity(community);
    }
    if (plan) {
      this.planInfo = new AdobeLaunchPlanInfo(plan, startingPrice, qmiId);
    }
  }
}

interface AdobeLaunchFiltersApplied extends Record<string, string> {
  filterKey: string;
  filterValue: string;
}

export class AdobeLaunchForm {
  formName: string;
  formType: string;

  constructor(formName: string, formType: string) {
    this.formName = formName;
    this.formType = formType;
  }
}

export class AdobeLaunchListingDisplayed {
  filtersApplied: AdobeLaunchFiltersApplied[];
  listing: AdobeLaunchListing[];

  constructor(listing: AdobeLaunchListing[], filterData?: ProductFilterDataModel) {
    if (listing) {
      this.listing = listing;
    }
    this.filtersApplied = this.createFiltersAppliedFromData(filterData);
  }

  private createFiltersAppliedFromData(filterData: ProductFilterDataModel): AdobeLaunchFiltersApplied[] {
    const filtersApplied: AdobeLaunchFiltersApplied[] = [];
    if (filterData) {
      if (filterData.minPrice || filterData.minPrice === 0) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MinPrice, filterValue: filterData.minPrice.toString() });
      }

      if (filterData.maxPrice || filterData.maxPrice === 0) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MaxPrice, filterValue: filterData.maxPrice.toString() });
      }

      if (filterData.minSquareFoot) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MinSqFt, filterValue: filterData.minSquareFoot.toString() });
      }

      if (filterData.maxSquareFoot) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MaxSqft, filterValue: filterData.maxSquareFoot.toString() });
      }

      if (filterData.minBedrooms || filterData.minBedrooms === 0) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MinBeds, filterValue: filterData.minBedrooms.toString() });
      }

      if (filterData.maxBedrooms || filterData.maxBedrooms === 0) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MaxBeds, filterValue: filterData.maxBedrooms.toString() });
      }

      if (filterData.minBathrooms || filterData.minBathrooms === 0) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MinBaths, filterValue: filterData.minBathrooms.toString() });
      }

      if (filterData.maxBathrooms || filterData.maxBathrooms === 0) {
        filtersApplied.push({ filterKey: AdobeLaunchFilterKey.MaxBaths, filterValue: filterData.maxBathrooms.toString() });
      }

      filtersApplied.push({ filterKey: AdobeLaunchFilterKey.ModelAvailable, filterValue: filterData.modelAvailableOnly.toString() });
      filtersApplied.push({ filterKey: AdobeLaunchFilterKey.NotReleased, filterValue: filterData.notReleased.toString() });
      filtersApplied.push({ filterKey: AdobeLaunchFilterKey.QmiAvailable, filterValue: filterData.qmiAvailableOnly.toString() });
      filtersApplied.push({ filterKey: AdobeLaunchFilterKey.Sold, filterValue: filterData.sold.toString() });
    }
    return filtersApplied;
  }
}

export class AdobeLaunchPlanListingDisplayedEvent {
  event: string;
  listingDisplayed: AdobeLaunchListingDisplayed;

  constructor(listing: AdobeLaunchListing[], filterData?: ProductFilterDataModel) {
    if (listing) {
      this.listingDisplayed = new AdobeLaunchListingDisplayed(listing, filterData);
    }
    this.event = AdobeLaunchEvent.PlanListingDisplayed;
  }
}

export class AdobeLaunchPlanListingViewedEvent {
  event: string;
  listingViewed: AdobeLaunchListing[];

  constructor(community: Community, plan: Plan, startingPrice: number, qmiId: string = null) {
    this.event = AdobeLaunchEvent.PlanListingViewed;
    if (community && plan) {
      this.listingViewed = [new AdobeLaunchListing(community, plan, startingPrice, qmiId)];
    }
  }
}

export class AdobeLaunchPlanListingClickedEvent {
  event: string;
  listingClicked: AdobeLaunchListing[];

  constructor(community: Community, plan: Plan, startingPrice: number, qmiId: string = null) {
    this.event = AdobeLaunchEvent.PlanListingClicked;
    if (community && plan) {
      this.listingClicked = [new AdobeLaunchListing(community, plan, startingPrice, qmiId)];
    }
  }
}

export class AdobeLaunchFormSubmittedEvent {
  event: string;
  form: AdobeLaunchForm;
  community: AdobeLaunchCommunity;
  plans: AdobeLaunchPlanInfo[];
  qmis: AdobeLaunchPlanInfo[];

  constructor(community: Community, formName: string, formType: string, plans: Plan[] = null, qmis: QMI[] = null) {
    this.event = AdobeLaunchEvent.FormSubmitted;
    if (community && formName && formType) {
      this.community = new AdobeLaunchCommunity(community);
      this.form = new AdobeLaunchForm(formName, formType);

      if (plans && plans.length) {
        this.plans = [];
        plans.map(plan => this.plans.push(new AdobeLaunchPlanInfo(plan, plan.Price)));
      }

      if (qmis && qmis.length) {
        this.qmis = [];
        qmis.map(qmi =>
          this.qmis.push(new AdobeLaunchPlanInfo(qmi.Plan, qmi.Price, qmi.InventoryHomeID && qmi.InventoryHomeID.toString())));
      }
    }
  }
}
