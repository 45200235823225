import { Component, OnInit, Input } from '@angular/core';
import { CommunityPromotion, Promotions } from 'src/app/models/product/community-promotions.model';
import { CommunityService } from 'src/app/core-services/community.service';
import { BaseComponent } from '../../shared/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { SpecialOffersCardModel } from 'src/app/models/content/editorial/card.model';
import { NavigationService } from 'src/app/core-services/navigation.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfImageModalComponent } from '../../shared/modals/pdf-image-modal/pdf-image-modal.component';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-special-offers-card',
  templateUrl: './special-offers-card.component.html',
  styleUrls: ['./special-offers-card.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class SpecialOffersCardComponent extends BaseComponent implements OnInit {

  @Input() data: SpecialOffersCardModel;
  communityPromotions: CommunityPromotion[] = [];
  noPromotions: boolean = false;
  constructor(private communityService: CommunityService,
              private navigationService: NavigationService,
              private modalService: NgbModal) { super(); }

  ngOnInit() {
    this.communityService.promotions.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((promotions: CommunityPromotion[]) => {
      if (promotions) {
        this.communityPromotions = promotions.filter((promotionItem) => {
          if (promotionItem.Category === Promotions.Offers && promotionItem.StartDate && promotionItem.EndDate) {
            const date = Date.parse(new Intl.DateTimeFormat('en-US').format(new Date()));
            const startDate = Date.parse(promotionItem.StartDate);
            const endDate = Date.parse(promotionItem.EndDate);
            if ((startDate < date) && (endDate + 1 > date)) {
              return promotionItem;
            }
          }
        });
      } else {
        this.noPromotions = true;
      }

      if (!this.communityPromotions.length) {
        this.noPromotions = true;
      }
    });
  }

  openSpecialOffers(promotion: CommunityPromotion): void {
    //The toggles in CMS (Link or Image/PDF) control DocumentFlag
    //If True (Image/PDF) show the modal If False Open Link in a new Tab
    if(promotion.DocumentFlag) {
      const modalRef: NgbModalRef = this.modalService.open(PdfImageModalComponent,
        { windowClass: `${ModalClasses.Full}`,  backdrop: false });
      modalRef.componentInstance.Url = promotion.CallToActionImage;
    } else if(!promotion.DocumentFlag) {
      if (this.navigationService.validateUrl) {
        promotion.URL = this.navigationService.prependExternalUrls(promotion.URL);
        window.open(promotion.URL);
      }
    }
  }
}
