import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { InteractiveToolsModel } from '../models/interactive-tools-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class InteractiveToolsService {
  InteractiveToolsItem = new ReplaySubject<InteractiveToolsModel>(1);

  updateInteractiveTools(interactiveTools: InteractiveToolsModel): void {
    this.InteractiveToolsItem.next(interactiveTools);
    this.InteractiveToolsItem.complete();
  }
}
