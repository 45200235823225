export class Image {
  Name: string;
  Path: string;
  AltText: string;
  TargetUrl: string;
  ImageRank: number;
  ModalRank: number;
  CategoryRank: number;
  EmbraceYourSpaceRank: number;
  Caption: string;
  ImageType: string;
  ImageTypeRank: number;
  RefID: number;
  KioskRank: number;
  KioskSleepStateRank: number;
  Description: string;
}
