export class GuestRegistrationContactModel {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Zip: string;

  constructor(firstName?: string, lastName?: string) {
    this.FirstName = firstName;
    this.LastName = lastName;
  }
}
