<div *ngIf="data" class="MapCommuteModal d-flex flex-column mx-4 pt-2 position-relative" [@fadeInOnEnter]>
  <div class="MapCommuteModal__header modal-header pb-0">
    <h3 class="modal-title"></h3>
    <span class="modal-action text-white" (click)="activeModal.close()"><app-close-button></app-close-button></span>
  </div>
  <div class="MapCommuteModal__body modal-body row pt-0 mx-1 text-center">
    <div class="w-100">
      <div class="MapCommuteModal__input-content d-flex flex-column">
        <span class="MapCommuteModal__title w-100">{{data.Commute_Modal_Title_Label}}</span>
        <span class="MapCommuteModal__subtitle w-100 px-2">{{data.Commute_Modal_Subtitle_Label}}</span>
        <input class="MapCommuteModal__input-text w-100 input rounded my-5"
               id="destinationAddressInput"
               #destinationAddressInput
               ngx-google-places-autocomplete
               placeholder={{data.Commute_Modal_Input_Placeholder_Text}}
               (change)="focusAddressInput()"
               (onAddressChange)="handleAddressChange($event)">
        <button class="MapCommuteModal__submit-button btn btn-outline-light"
             [disabled]="!allowSubmit"
             (click)="submitDestinationPOI()">
             {{data.Commute_Modal_Submit_CTA_Label}}
        </button>
      </div>
    </div>
  </div>
  <div class="w-100 position-absolute fixed-bottom pb-3">
    <app-form-keyboard (keyPress)="focusAddressInput()"></app-form-keyboard>
  </div>
  <div class="back-wrapper modal-footer"></div>
</div>
