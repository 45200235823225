import { Component, OnInit, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Plan } from 'src/app/models/product/plan.model';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil, concatMap } from 'rxjs/operators';
import { PlanToursModel } from 'src/app/models/content/plan/plan-tours.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanVirtualTourModalComponent } from '../../shared/modals/plan-virtual-tour-modal/plan-virtual-tour-modal.component';
import { VideoModalModel } from 'src/app/models/content/media/video-modal.model';
import { VideoModalComponent } from '../../shared/modals/video-modal/video-modal.component';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { PagesService } from 'src/app/core-services/pages.service';
import { BaseComponent } from '../../shared/base/base.component';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-plan-tours',
  templateUrl: './plan-tours.component.html',
  styleUrls: ['./plan-tours.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800, delay: 400 })
  ]
})
export class PlanToursComponent extends BaseComponent implements OnInit {

  @Input() data: PlanToursModel;

  plan: Plan;
  hasVideos: boolean;
  modalClass: string;
  hasSummary: boolean;

  constructor(private communityService: CommunityService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private pageService: PagesService) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      concatMap((planId: string) => {
        const observables = [ this.communityService.getPlanInfoById(planId),
                              this.pageService.hasSummary ];
        return forkJoin(observables);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(([plan, hasSummary]: any[]) => {
      this.plan = plan;
      this.hasSummary = hasSummary;
      this.modalClass = hasSummary ? ModalClasses.Summary : '';
      this.hasVideos = plan && plan.Videos && plan.Videos.length && plan.Videos[0].VideoPath ? true : false;
    });
  }

  openVirtualTourModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(PlanVirtualTourModalComponent, { backdrop: false,
      windowClass: `${ModalClasses.Full} ${this.modalClass}` });
    const videoModalModel: VideoModalModel = new VideoModalModel();
    videoModalModel.ModalHeader = this.data.Virtual_Tour_Modal_Header_Text;
    videoModalModel.VirtualTourUrl = this.plan.VirtualTour;
    modalRef.componentInstance.videoModalModel = videoModalModel;
  }

  openVideoTourModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(VideoModalComponent, { backdrop: false,
      windowClass: `${ModalClasses.Full} ${this.modalClass}` });
    const videoModalModel: VideoModalModel = new VideoModalModel();
    videoModalModel.ModalHeader = this.data.Video_Tour_Modal_Header_Text;
    videoModalModel.ModalDescription = this.plan.Videos[0].Caption;
    videoModalModel.ModalDescriptionPlacement = this.data.Video_Tour_Modal_Caption_Placement;
    videoModalModel.VideoId = this.plan.Videos[0].VideoPath;
    videoModalModel.HasSummary = this.hasSummary;
    modalRef.componentInstance.videoModalModel = videoModalModel;
  }

}
