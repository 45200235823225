import { Component, OnInit, Input } from '@angular/core';
import { NearbyCommunitiesModel } from 'src/app/models/content/community/nearby-communities.model';
import { Community } from 'src/app/models/product/community.model';
import { combineLatest } from 'rxjs';
import { CommunityService } from 'src/app/core-services/community.service';
import { takeUntil } from 'rxjs/operators';
import { Image } from 'src/app/models/product/image.model';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';
import { GMapsOptionsModel } from 'src/app/models/maps/gmaps-options.model';
import { GMapsMarker } from 'src/app/models/maps/gmaps-marker.model';
import { GMapsMarkerService } from 'src/app/core-services/gmaps-marker.service';
import { checkForImages, checkForSingleImage, sortImagesByKioskRank } from 'src/helper-functions/image-helper';
import { BaseComponent } from '../../shared/base/base.component';
import { GoogleMapsConfiguration } from 'src/app/models/maps/google-maps-configuration.model';
import { CrossBrandService } from 'src/app/core-services/cross-brand.service';
import { CrossBrandConfiguration } from 'src/app/models/cross-brand-configuration.model';
import { setBrandFieldFromSitecore } from 'src/helper-functions/brand-specific-configuration';
import { fadeInLeftOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-nearby-communities',
  templateUrl: './nearby-communities.component.html',
  styleUrls: ['./nearby-communities.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation({duration: 500})
  ]
})
export class NearbyCommunitiesComponent extends BaseComponent implements OnInit {

  @Input() data: NearbyCommunitiesModel;

  community: Community;
  nearbyCommunities: Community[];
  carouselOptions: CarouselOptionsModel;
  sortedImages: Image[];
  selectedCommunity: Community;
  showLegend: boolean = true;
  crossBrandConfig: CrossBrandConfiguration;

  googleMapsConfiguration: GoogleMapsConfiguration;
  mapOptions: GMapsOptionsModel = new GMapsOptionsModel();
  mapMarkers: GMapsMarker[] = [];
  filteredMarkers: GMapsMarker[] = [];
  legendMarkers: GMapsMarker[] = [];

  constructor(private communityService: CommunityService,
              private gmapsMarkerService: GMapsMarkerService,
              private crossBrandService: CrossBrandService) { super(); }

  ngOnInit() {
    const observables = [
      this.communityService.community,
      this.communityService.nearbyCommunities,
      this.gmapsMarkerService.mapConfiguration,
      this.crossBrandService.crossBrandConfig
    ];
    combineLatest(observables)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([community, nearbyCommunities, googleMapsConfiguration, crossBrandConfig]: any[]) => {
      if (community) {
        this.community = this.selectedCommunity = community;
        this.mapOptions.InitialLatitude = this.community.Latitude;
        this.mapOptions.InitialLongitude = this.community.Longitude;
      }

      if (googleMapsConfiguration) {
        this.googleMapsConfiguration = googleMapsConfiguration;
      }

      if (nearbyCommunities && nearbyCommunities.length) {
        this.nearbyCommunities = nearbyCommunities.filter(nearbyCommunity =>
          nearbyCommunity.Id !== this.community.Id && nearbyCommunity.Latitude && nearbyCommunity.Longitude);
        this.setMapMarkersForDisplay();
        this.setMapLegendMarkers();
      }

      if (crossBrandConfig) {
        this.crossBrandConfig = crossBrandConfig;
      }

      if (this.community && checkForImages(this.community.Images)) {
        this.sortedImages = sortImagesByKioskRank(this.community.Images);
      }
    });

    if (this.sortedImages && this.sortedImages.length) {
      this.carouselOptions = new CarouselOptionsModel(this.sortedImages);
      this.carouselOptions.ShowImageIndex = false;
      this.carouselOptions.ShowImageCaption = false;
      this.carouselOptions.UseSmallArrows = true;
    }
  }

  setMapMarkersForDisplay() {
    this.mapMarkers = [];
    if (this.community) {
      this.gmapsMarkerService.setCommunityMapMarkerForDisplay(
        this.community.BrandName, this.community.Latitude, this.community.Longitude, this.googleMapsConfiguration, this.mapMarkers);
    }
    this.createMapMarkersForDisplay(this.nearbyCommunities);
    this.gmapsMarkerService.updateGMapsMarkers(this.mapMarkers);
  }

  createMapMarkersForDisplay(nearbyCommunities: Community[]) {
    if (nearbyCommunities && nearbyCommunities.length) {
      nearbyCommunities.map(nearbyCommunity => {
        if (nearbyCommunity && nearbyCommunity.Latitude && nearbyCommunity.Longitude) {
          const marker = new GMapsMarker(
            nearbyCommunity.Id,
            nearbyCommunity.Latitude,
            nearbyCommunity.Longitude,
            this.googleMapsConfiguration,
            nearbyCommunity.BrandName);
          if (this.googleMapsConfiguration && this.googleMapsConfiguration.Use_Default_Pin_Label) {
            marker.LabelText = nearbyCommunity.BrandName[0];
          }
          this.mapMarkers.push(marker);
        }
      });
    }
  }

  setSelectedCommunityByID(selectedCommunityId: string | number) {
    if (this.nearbyCommunities) {
      this.selectedCommunity = this.nearbyCommunities.find(community => community.Id === selectedCommunityId);
      if (this.selectedCommunity) {
        this.setCarouselImagesForSelectedCommunity();
        this.gmapsMarkerService.setSelectedMarker(this.selectedCommunity.Id, this.mapMarkers);
      }
    }
  }

  clearSelectedCommunity() {
    this.selectedCommunity = null;
    this.gmapsMarkerService.deselectAllMarkers(this.mapMarkers);
    this.gmapsMarkerService.updateGMapsMarkers(this.mapMarkers);
  }

  markerClicked(marker: GMapsMarker) {
    if (marker) {
      if (marker.IsOrigin) {
        this.clearSelectedCommunity();
        this.selectedCommunity = this.community;
        this.setCarouselImagesForSelectedCommunity();
      } else {
        this.setSelectedCommunityByID(marker.MarkerID);
      }
    }
  }

  mapClicked() {
    this.clearSelectedCommunity();
    this.selectedCommunity = this.community;
    this.setCarouselImagesForSelectedCommunity();
  }

  setCarouselImagesForSelectedCommunity(): void {
    if (this.selectedCommunity) {
      this.carouselOptions.HasImages = checkForImages(this.selectedCommunity.Images);

      if (this.carouselOptions.HasImages) {
        this.carouselOptions.Images = sortImagesByKioskRank(this.selectedCommunity.Images);
        this.carouselOptions.HasSingleImage = checkForSingleImage(this.carouselOptions.Images);
        this.carouselOptions.CarouselBanner = this.setBannerImageByBrand(this.selectedCommunity.BrandName);
      }
    }
  }

  setMapLegendMarkers(): void {
    let currentBrands: string[] = [];
    if (this.nearbyCommunities) {
      this.nearbyCommunities.map(community => {
        if (!currentBrands.find(brand => brand === community.BrandName)) {
          currentBrands.push(community.BrandName);
        }});
    }
    if (currentBrands.length) {
      if (this.community) {
        currentBrands = currentBrands.filter(brandName => brandName !== this.community.BrandName);
        currentBrands.sort((a, b) => a.localeCompare(b));
        currentBrands.unshift(this.community.BrandName);
      }
      currentBrands.map(brandName => {
        const brandMarker = new GMapsMarker(
          this.setBrandLabelForLegendMarkers(brandName),
          0,
          0,
          this.googleMapsConfiguration,
          brandName
        );
        this.legendMarkers.push(brandMarker);
      });
    }
  }

  setBannerImageByBrand(brandName: string): any {
    let bannerImage;
    if (brandName && this.crossBrandConfig && this.community && this.community.BrandName !== brandName) {
      bannerImage = setBrandFieldFromSitecore(brandName,
        this.crossBrandConfig.Pulte_Cross_Brand_Banner_Image,
        this.crossBrandConfig.Centex_Cross_Brand_Banner_Image,
        this.crossBrandConfig.Del_Webb_Cross_Brand_Banner_Image,
        this.crossBrandConfig.DiVosta_Cross_Brand_Banner_Image,
        this.crossBrandConfig.John_Wieland_Cross_Brand_Banner_Image,
        this.crossBrandConfig.American_West_Cross_Brand_Banner_Image);
    }
    return bannerImage;
  }

  setBrandLabelForLegendMarkers(brandName: string): string {
    let brandLabel: string = '';
    if (this.data) {
      brandLabel = setBrandFieldFromSitecore(brandName,
        this.data.Pulte_Label,
        this.data.Centex_Label,
        this.data.Del_Webb_Label,
        this.data.DiVosta_Label,
        this.data.John_Wieland_Label,
        this.data.American_West_Label);
    }
    return brandLabel || brandName;
  }

  toggleLegend(): void {
    this.showLegend = !this.showLegend;
  }
}
