/**
 * Add brand specific link and meta tags based on the search param 'brand' or hostname in the url
 * Add brand specific class to body tag
 * Return brand name
 */

import { BrandName } from 'src/app/models/enums/brand-enum';

// default brand name to pulte
let brandName = 'pulte';

export function setBrandSpecificConfiguration() {
    // get the brand from the hostname and the search param 'brand'
    const hostname = window.location.hostname.toLowerCase();
    const url = new URL(window.location.href);
    const searchParamBrandName = url.searchParams.get('brand');

    // set brand name based on search params if they exist. otherwise, use hostname
    if (searchParamBrandName) {
        brandName = getBrandNameFromUrl(searchParamBrandName);
    } else {
        brandName = getBrandNameFromUrl(hostname);
    }

    // add the brand-{brand} class to the body element where {brand} is the brand name
    document.getElementsByTagName('body')[0].className += ' brand-' + brandName;

    // array of link tags
    const linkTags = [
        {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/assets/' + brandName + '/favicon/apple-touch-icon-180x180.png'
        },
        {
            rel: 'apple-touch-icon',
            sizes: '144x144',
            href: '/assets/' + brandName + '/favicon/apple-touch-icon-144x144.png'
        },
        {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/assets/' + brandName + '/favicon/favicon-32x32.png'
        },
        {
            rel: 'shortcut icon',
            href: '/assets/' + brandName + '/favicon/favicon.ico'
        },
        {
            rel: 'stylesheet'
        }
    ];

    // array of meta tags
    const metaTags = [
        {
            name: 'msapplication-TileImage',
            content: '/assets/' + brandName + '/favicon/mstile-144x144.png'
        }
    ];

    const head = document.getElementsByTagName('head')[0];

    // add link tags to head
    for (const linkTag of linkTags) {
        const link = document.createElement('link');

        if (linkTag.rel) {
            link.rel = linkTag.rel;
        }
        if (linkTag.type) {
            link.type = linkTag.type;
        }
        if (linkTag.sizes) {
            link.sizes.value = linkTag.sizes;
        }
        if (linkTag.href) {
            link.href = linkTag.href;
        }

        head.appendChild(link);
    }

    // add meta tags to head
    for (const metaTag of metaTags) {
        const meta = document.createElement('meta');

        if (metaTag.name) {
            meta.name = metaTag.name;
        }
        if (metaTag.content) {
            meta.content = metaTag.content;
        }

        head.appendChild(meta);
    }
}

export function getBrandName(): string {
  return brandName;
}

export function getBrandNameForApi(): string {
    let brandNameForApi = brandName;

    if (brandName === 'jw') {
        brandNameForApi = 'jwhomes';
    } else if (brandName === 'americanwest') {
        brandNameForApi = 'americanwesthomes';
    }

    return brandNameForApi;
}

export function setBrandFieldFromSitecore(targetBrand, pulteField?, centexField?, delWebbField?, divostaField?, jwField?, amWestField?) {
  let brandField;
  if (targetBrand) {
      switch (targetBrand) {
        case BrandName.Pulte:
          brandField = pulteField;
          break;
        case BrandName.DelWebb:
          brandField = delWebbField;
          break;
        case BrandName.Centex:
          brandField = centexField;
          break;
        case BrandName.DiVosta:
          brandField = divostaField;
          break;
        case BrandName.JohnWieland:
          brandField = jwField;
          break;
        case BrandName.AmericanWest:
          brandField = amWestField;
          break;
      }
    }
  return brandField;
}

function getBrandNameFromUrl(value: string): string {
    let brand: string;

    if (value === 'pulte' || value.includes('pulte')) {
        brand = 'pulte';
    } else if (value === 'delwebb' || value.includes('delwebb')) {
        brand = 'delwebb';
    } else if (value === 'centex' || value.includes('centex')) {
        brand = 'centex';
    } else if (value === 'divosta' || value.includes('divosta')) {
        brand = 'divosta';
    } else if (value === 'jw' || value.includes('jwhomes')) {
        brand = 'jw';
    } else if (value === 'americanwest' || value.includes('americanwesthomes')) {
        brand = 'americanwest';
    } else {
        brand = 'pulte';
    }

    return brand;
}
