import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pdf-image-modal',
  templateUrl: './pdf-image-modal.component.html',
  styleUrls: ['./pdf-image-modal.component.scss']
})
export class PdfImageModalComponent implements OnInit {

  @Input() Url: string;

  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
