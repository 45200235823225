import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// takes in a trusted resource URL after handling URL provided by users to bypass Angular's sanitation
// NOTE: THIS DOES NOT CHANGE OR VALIDATE THE VALUE PASSED IN
// Suggested for handling resource URLs:
// before using this pipe, only take the parameters from user input and append those parameters to a trusted URL
@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
