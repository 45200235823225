import { Component, OnInit, Input } from '@angular/core';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from 'src/app/models/product/image.model';
import { GalleryModalModel } from 'src/app/models/content/media/gallery-modal.model';

@Component({
  selector: 'app-filter-gallery-modal',
  templateUrl: './filter-gallery-modal.component.html',
  styleUrls: ['./filter-gallery-modal.component.scss']
})
export class FilterGalleryModalComponent implements OnInit {

  @Input() galleryModalModel: GalleryModalModel;
  @Input() allImages: Image[];

  exteriorCarouselOptions: CarouselOptionsModel;
  interiorCarouselOptions: CarouselOptionsModel;
  selectedImageType: string = 'Exterior';
  displayControls: boolean = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.allImages && this.allImages.length) {
      const exteriorImages = this.allImages.filter(image =>
        image.ImageType.toLowerCase().includes('exterior') || image.ImageType.toLowerCase().includes('elevation'));
      this.exteriorCarouselOptions = new CarouselOptionsModel(exteriorImages);

      const interiorImages = this.allImages.filter(image => image.ImageType.toLowerCase().includes('interior'));
      this.interiorCarouselOptions = new CarouselOptionsModel(interiorImages);

      this.setDisplayControls(exteriorImages, interiorImages);
    } else {
      this.displayControls = false;
    }
  }

  selectImageType(imageType: string): void {
    this.selectedImageType = imageType;
  }

  setDisplayControls(exteriorImages: Image[], interiorImages: Image[]): void {
    if (exteriorImages.length === 0) {
      this.displayControls = false;
      this.selectedImageType = 'Interior';
    } else if (interiorImages.length === 0) {
      this.displayControls = false;
    }
  }

}
