export interface AlphaVisionGeneralResponse {
  name: string;
  datatype: AlphaVisionDataType;
  eventtype: AlphaVisionEventType;
  data: AlphaVisionGeneralResponseData;
  siteplans?: AlphaVisionSitePlan[];
}

export interface AlphaVisionClickResponse {
  name: string;
  datatype: AlphaVisionDataType;
  eventtype: AlphaVisionEventType;
  data: AlphaVisionClickResponseData;
}

export interface AlphaVisionSelectMapResponse {
  data: AlphaVisionSitePlan;
  datatype: AlphaVisionDataType;
  eventtype: AlphaVisionEventType;
}

export interface AlphaVisionFilterLotsResponse {
  data: AlphaVisionFilterLotsResponseData;
  datatype: AlphaVisionDataType;
  eventtype: AlphaVisionEventType;
}

export interface AlphaVisionSelectLotResponse {
  data: AlphaVisionLot;
  datatype: AlphaVisionDataType;
  eventtype: AlphaVisionEventType;
}

export interface AlphaVisionPlan {
  id: string;
  name: string;
  number: string;
}

export interface AlphaVisionLot {
  lot: string;
  lotAttributes: [];
  lotGroupName: string;
  lotImages: AlphaVisionImage[];
  lotLabel: string;
  lotNumber: string;
  plans: AlphaVisionPlan[];
  status: string;
}

export interface AlphaVisionImage {
  name: string;
  url: string;
}

export interface AlphaVisionAmenity {
  zoneName: string;
  location: string;
  description: string;
  medias: AlphaVisionImage[];
}

export class AlphaVisionSitePlan {
  amenities: AlphaVisionAmenity[];
  availablePlans: AlphaVisionPlan[];
  availableStatus: string[];
  lots: AlphaVisionLot[];
  mapName: string;
  siteplans: AlphaVisionSitePlan[];
}

export class AlphaVisionLotCounts {
  HomesiteLotsCount: number;
  AvailableHomesiteLotsCount: number;
  QmiLotsCount: number;
}

// These are the exact values returned from Alpha Vision API
export enum AlphaVisionAttributeValues {
  LotNumber = 'Lot Number',
  LotLabel = 'LotLabel',
  Lot = 'lot',
  Address = 'address',
  EstMoveInDate = 'estmoveindate',
  Premium = 'premium',
  UniqueLot = 'uniquelot',
  Elevation = 'elevation',
  LotFacing = 'LOTFACING',
  LotType = 'LOTTYPE',
  HomeSiteAvailability = 'Home Site Availability'
}

export class AlphaVisionAttributes {
  lotLabel: string;
  lotNumber: string;
  lotFacing: string;
  lotType: string;
  lotPremium: string;
  address: string;
  elevation: string;
  estMoveInDate: string;
  homeSiteAvailability: string;
  uniqueLot: string;
  lot: string;

  // Setting Values from the array of objects/properties returned from AlphaVision
  constructor(attributes: any[]) {
    attributes.map(attribute => {
      const  key = Object.keys(attribute)[0];
      switch (key) {
        case AlphaVisionAttributeValues.LotNumber:
          this.lotNumber = attribute[AlphaVisionAttributeValues.LotNumber];
          break;
        case AlphaVisionAttributeValues.LotLabel:
          this.lotLabel = attribute[AlphaVisionAttributeValues.LotLabel];
          break;
        case AlphaVisionAttributeValues.Lot:
          this.lot = attribute[AlphaVisionAttributeValues.Lot];
          break;
        case AlphaVisionAttributeValues.Address:
          this.address = attribute[AlphaVisionAttributeValues.Address];
          break;
        case AlphaVisionAttributeValues.EstMoveInDate:
          this.estMoveInDate = attribute[AlphaVisionAttributeValues.EstMoveInDate];
          break;
        case AlphaVisionAttributeValues.Premium:
          this.lotPremium = attribute[AlphaVisionAttributeValues.Premium];
          break;
        case AlphaVisionAttributeValues.UniqueLot:
          this.uniqueLot = attribute[AlphaVisionAttributeValues.UniqueLot];
          break;
        case AlphaVisionAttributeValues.Elevation:
          this.elevation = attribute[AlphaVisionAttributeValues.Elevation];
          break;
        case AlphaVisionAttributeValues.LotFacing:
          this.lotFacing = attribute[AlphaVisionAttributeValues.LotFacing];
          break;
        case AlphaVisionAttributeValues.LotType:
          this.lotType = attribute[AlphaVisionAttributeValues.LotType];
          break;
        case AlphaVisionAttributeValues.HomeSiteAvailability:
          this.homeSiteAvailability = attribute[AlphaVisionAttributeValues.HomeSiteAvailability];
          break;
      }
    });
  }
}

export class AlphaVisionLotFilterAttributes {
  LotTypes: AlphaVisionLotType[];
  LotFacings: AlphaVisionLotFacing[];

  constructor(types: AlphaVisionLotType[], facings: AlphaVisionLotFacing[]) {
    this.LotTypes = types && types.length ? types : [];
    this.LotFacings = facings && facings.length ? facings : [];
  }
}

export enum AlphaVisionDataType {
  MasterMap = 'mastermap',
  SitePlan = 'siteplan',
  Error = 'error',
  Lot = 'lot',
  Amenity = 'amenity',
  Plan = 'plan',
  ZoomLevel = 'zoom_level',
  SVG = 'svg'
}

export enum AlphaVisionEventType {
  Init = 'init',
  Click = 'click',
  ShowLots = 'showLots()',
  ShowPlans = 'showPlans()',
  FilterLots = 'filterLots()',
  SelectLot = 'selectLot()',
  SelectMap = 'selectMap()',
  Reset = 'reset()',
  ExportStaticSVG = 'exportStaticSVG()'
}

export enum AlphaVisionLotStatusType {
  Available = 'Available',
  Model = 'Model',
  QuickMoveIn = 'Quick Move In',
  Sold = 'Sold',
  Unreleased = 'Unreleased'
}

export enum AlphVisionLotStatusClass {
  AvailableClass = 'available',
  ModelClass = 'model-available',
  QuickMoveInClass = 'qmi',
  SoldClass = 'sold',
  UnreleasedClass = 'not-released'
}

export enum AlphaVisionLotFacing {
  SW = 'South West',
  SE = 'South East',
  S  = 'South',
  NW = 'North West',
  NE = 'North East',
  N = 'North',
  E = 'East',
  W = 'West',
  NA = 'N/A'
}

export enum AlphaVisionLotType {
  OpenSpace = 'Open Space',
  OtherView = 'Other View',
  None = 'None',
  BaseView = 'Base View',
  Waterfront = 'Waterfront',
  NaturePreserve = 'Nature/Preserve',
  Standard = 'Standard',
  WaterView = 'Water View',
  CityView = 'City View',
  GolfCourse = 'Golf Course'
}

export interface AlphaVisionGeneralResponseData extends AlphaVisionSitePlan, AlphaVisionLot, AlphaVisionPlan { }

export interface AlphaVisionClickResponseData extends AlphaVisionLot, AlphaVisionAmenity, AlphaVisionSitePlan { }

export interface AlphaVisionFilterLotsResponseData extends AlphaVisionLot, AlphaVisionSitePlan { }
