import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunityService } from './community.service';
import { HttpClientModule } from '@angular/common/http';
import { NavigationService } from './navigation.service';
import { SleepStateService } from './sleep-state.service';
import { AppInsightsService } from './app-insights.service';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { InteractiveToolsService } from './interactive-tool.service';
import { HersScoreService } from './hers-score.service';
import { GooglePlacesService } from './google-places.service';
import { GMapsMarkerService } from './gmaps-marker.service';
import { LmpService } from './lmp.service';
import { GuestRegistrationService } from './guest-registration.service';
import { ModalService } from './modal.service';
import { FormValidationService } from './form-validation.service';
import { ScriptInjectorService } from './script-injector.service';
import { AdobeLaunchService } from './adobe-launch.service';
import { ProductOverviewService } from './product-overview.service';
import { ProductFilterService } from './product-filter.service';
import { SettingsService } from './settings.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    CommunityService,
    ProductOverviewService,
    NavigationService,
    InteractiveToolsService,
    HersScoreService,
    SleepStateService,
    AppInsightsService,
    GooglePlacesService,
    GMapsMarkerService,
    LmpService,
    FormValidationService,
    GuestRegistrationService,
    ModalService,
    ScriptInjectorService,
    AdobeLaunchService,
    ProductFilterService,
    SettingsService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ]
})
export class CoreServicesModule { }
