import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LotMapLegendFormModel, LotMapLegendModel, LegendItem } from 'src/app/models/content/community/community-lot-map.model';
import { Community } from 'src/app/models/product/community.model';
import { AlphaVisionMapService } from 'src/app/core-services/alphavision-map.service';
import { AlphaVisionLot, AlphaVisionLotStatusType, AlphaVisionAmenity,
         AlphaVisionLotCounts,
         AlphaVisionAttributes,
         AlphaVisionLotFacing,
         AlphaVisionLotType,
         AlphVisionLotStatusClass,
         AlphaVisionLotFilterAttributes} from 'src/app/models/maps/alphavision-map-data.model';
import { Plan } from 'src/app/models/product/plan.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-lot-map-legend',
  templateUrl: './lot-map-legend.component.html',
  styleUrls: ['./lot-map-legend.component.scss']
})
export class LotMapLegendComponent implements OnInit {

  @Input() legendData: LotMapLegendModel;
  @Input() community: Community;
  @Input() selectedLot: AlphaVisionLot;
  @Input() lotAttributes: AlphaVisionAttributes;
  @Input() selectedPlan: Plan;
  @Input() selectedAmenity: AlphaVisionAmenity;
  @Input() lotsCount: AlphaVisionLotCounts;
  @Input() initialLots: AlphaVisionLot[];
  @Input() showBackButton: boolean;

  @Output() backButtonClicked = new EventEmitter();
  @Output() showLots = new EventEmitter();

  private lotMapLegendForm: LotMapLegendFormModel = new LotMapLegendFormModel();

  lotFilterStatuses: LegendItem[] = [];
  lotFilterFacings: LegendItem[] = [];
  lotFilterTypes: LegendItem[] = [];
  lotDisplayTypes: string[] = [];
  lotDisplayFacings: string[] = [];

  isCollapsedStatus: boolean = true;
  isCollapsedFacing: boolean = true;
  isCollapsedType: boolean = true;

  get isEmptyFacingFilters(): boolean {
    return !this.lotFilterFacings || this.lotFilterFacings.length === 0 || (this.lotFilterFacings.length === 1
            && this.lotMapLegendForm.blackListedFacingValues.includes(this.lotFilterFacings[0].label));
    }

  get isEmptyTypeFilters(): boolean {
    return !this.lotFilterTypes || this.lotFilterTypes.length === 0 || (this.lotFilterTypes.length === 1
            && this.lotMapLegendForm.blackListedTypeValues.includes(this.lotFilterTypes[0].label));
    }

  constructor(private alphaVisionMapService: AlphaVisionMapService) { }

  ngOnInit() {
    this.alphaVisionMapService.statuses
      .pipe(take(1))
      .subscribe((statuses) => {
        if (statuses) {
          this.setValuesFromStatus(statuses);
        }
      });

    this.alphaVisionMapService.lotAttributes
      .pipe(take(1))
      .subscribe((lotAttributes) => {
        if (lotAttributes) {
          this.lotDisplayFacings = lotAttributes.LotFacings;
          this.setCheckboxValuesFromFacing(lotAttributes.LotFacings);

          this.lotDisplayTypes = lotAttributes.LotTypes;
          this.setCheckboxValuesFromType(lotAttributes.LotTypes);
        }
      });
    this.setCheckboxValuesFromStatus();
  }

  private setCheckboxValuesFromStatus() {
    if (this.legendData) {
      if (this.legendData.Available) {
        this.lotFilterStatuses.push(new LegendItem(this.legendData.Available,
                                      this.lotMapLegendForm.availableValue, AlphVisionLotStatusClass.AvailableClass));
      }

      if (this.legendData.Model_Available) {
        this.lotFilterStatuses.push(new LegendItem(this.legendData.Model_Available,
                                      this.lotMapLegendForm.modelAvailableValue, AlphVisionLotStatusClass.ModelClass));
      }

      if (this.legendData.Quick_Move_In) {
        this.lotFilterStatuses.push(new LegendItem(this.legendData.Quick_Move_In,
                                      this.lotMapLegendForm.qmiAvailableValue, AlphVisionLotStatusClass.QuickMoveInClass));
      }

      if (this.legendData.Sold) {
        this.lotFilterStatuses.push(new LegendItem(this.legendData.Sold,
                                      this.lotMapLegendForm.soldValue, AlphVisionLotStatusClass.SoldClass));
      }

      if (this.legendData.Not_Released) {
        this.lotFilterStatuses.push( new LegendItem(this.legendData.Not_Released,
                                      this.lotMapLegendForm.notReleasedValue, AlphVisionLotStatusClass.UnreleasedClass));
      }
    }
  }

  private setValuesFromStatus(statuses: AlphaVisionLotStatusType[]) {
    if (statuses) {
      this.lotMapLegendForm.availableValue.value = statuses.includes(AlphaVisionLotStatusType.Available);
      this.lotMapLegendForm.modelAvailableValue.value = statuses.includes(AlphaVisionLotStatusType.Model);
      this.lotMapLegendForm.qmiAvailableValue.value = statuses.includes(AlphaVisionLotStatusType.QuickMoveIn);
      this.lotMapLegendForm.soldValue.value = statuses.includes(AlphaVisionLotStatusType.Sold);
      this.lotMapLegendForm.notReleasedValue.value = statuses.includes(AlphaVisionLotStatusType.Unreleased);
    }
  }

  private setCheckboxValuesFromFacing(facings: AlphaVisionLotFacing[]) {
    facings.forEach(facing => {
      if (!this.lotMapLegendForm.blackListedFacingValues.includes(facing)) {
        this.lotFilterFacings.push(new LegendItem(facing, facing));
      }
    });
  }

  private setCheckboxValuesFromType(types: AlphaVisionLotType[]) {
    types.forEach(type => {
      if (!this.lotMapLegendForm.blackListedTypeValues.includes(type)) {
        this.lotFilterTypes.push(new LegendItem(type, type));
      }
    });
  }

  updateMapStatus() {
    const statusList = [];
    if (this.lotMapLegendForm.modelAvailableValue.value) {
      statusList.push(AlphaVisionLotStatusType.Model);
    }
    if (this.lotMapLegendForm.qmiAvailableValue.value) {
      statusList.push(AlphaVisionLotStatusType.QuickMoveIn);
    }
    if (this.lotMapLegendForm.availableValue.value) {
      statusList.push(AlphaVisionLotStatusType.Available);
    }
    if (this.lotMapLegendForm.soldValue.value) {
      statusList.push(AlphaVisionLotStatusType.Sold);
    }
    if (this.lotMapLegendForm.notReleasedValue.value) {
      statusList.push(AlphaVisionLotStatusType.Unreleased);
    }
    this.alphaVisionMapService.updateStatuses(statusList);
  }

  updateMapLotAttributes() {
    // Get selected Types
    const filteredTypeLabels = [];
    if (this.lotFilterTypes && this.lotFilterTypes.length) {
      this.lotFilterTypes.forEach(type => {
        if (type.checked) {
          filteredTypeLabels.push(type.label);
        }
      });
      // ReAdding blacklisted type values for total filtering
      this.lotMapLegendForm.blackListedTypeValues.forEach(r => {
        if (this.lotDisplayTypes.includes(r)) {
          filteredTypeLabels.push(r);
        }
      });
    }

    // Get selected Facings
    const filteredFacingLabels = [];

    if (this.lotFilterFacings && this.lotFilterFacings.length) {
      this.lotFilterFacings.forEach(facing => {
        if (facing.checked) {
          filteredFacingLabels.push(facing.label);
        }
      });

      // ReAdding blacklisted facing values for total filtering
      this.lotMapLegendForm.blackListedFacingValues.forEach(r => {
        if (this.lotDisplayFacings.includes(r)) {
          filteredFacingLabels.push(r);
        }
      });
    }

    const lotFilterAttributes = new AlphaVisionLotFilterAttributes(filteredTypeLabels, filteredFacingLabels);
    this.alphaVisionMapService.updateLotAttributes(lotFilterAttributes.LotTypes, lotFilterAttributes.LotFacings);
  }

  backButton() {
    this.collapseAllLists();
    this.backButtonClicked.emit();
  }

  toggleStatus(): void {
    this.isCollapsedStatus = !this.isCollapsedStatus;
    this.isCollapsedType = true;
    this.isCollapsedFacing = true;
  }

  toggleFacing(): void {
    this.isCollapsedFacing = !this.isCollapsedFacing;
    this.isCollapsedStatus = true;
    this.isCollapsedType = true;
  }

  toggleType(): void {
    this.isCollapsedType = !this.isCollapsedType;
    this.isCollapsedStatus = true;
    this.isCollapsedFacing = true;
  }

  collapseAllLists(): void {
    this.isCollapsedType = true;
    this.isCollapsedFacing = true;
    this.isCollapsedStatus = true;
  }
}
