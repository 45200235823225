// this file contains functions used on plan components

import { Plan } from 'src/app/models/product/plan.model';

export function createStatusOverlayText(plan: Plan, data: any) {
  if (plan && data) {
    const overlayLabels = [];
    if (plan.InventoryCount > 0 && data.QMI_Availability) {
      overlayLabels.push(data.QMI_Availability);
    }
    if (plan.ModelExists && data.Model_Open) {
      overlayLabels.push(data.Model_Open);
    }
    if (plan.IsNewPlan && data.New_Plan && overlayLabels.length < 2) {
      overlayLabels.push(data.New_Plan);
    }
    if (plan.IsFutureRelease && data.Future_Release && overlayLabels.length < 2) {
      overlayLabels.push(data.Future_Release);
    }
    if (plan.QuickMoveInOnlyFlag && data.QMI_Only && overlayLabels.length < 2) {
      overlayLabels.push(data.QMI_Only);
    }
    if (plan.NewModelFlag && data.New_Model && overlayLabels.length < 2) {
      overlayLabels.push(data.New_Model);
    }
    if (plan.IsSoldOut && data.Sold_Out && overlayLabels.length < 2) {
      overlayLabels.push(data.Sold_Out);
    }
    if (plan.LastHomeDesignFlag && data.Last_Home_Design && overlayLabels.length < 2) {
      overlayLabels.push(data.Last_Home_Design);
    }
    if (plan.LastChanceFlag && data.Last_Chance && overlayLabels.length < 2) {
      overlayLabels.push(data.Last_Chance);
    }
    if (plan.ModelForSaleFlag && data.Model_For_Sale && overlayLabels.length < 2) {
      overlayLabels.push(data.Model_For_Sale);
    }
    if (plan.AlmostSoldOutFlag && data.Almost_Sold_Out && overlayLabels.length < 2) {
      overlayLabels.push(data.Almost_Sold_Out);
    }
    return overlayLabels.length > 0 ? overlayLabels : undefined;
  }
  return undefined;
}
