<div *ngIf="data" class="GuestRegistrationModal d-flex flex-column pt-2" [@fadeInOnEnter]>
  <div class="GuestRegistrationModal__header modal-header pb-0">
    <h3 class="modal-title"></h3>
    <span class="modal-action text-white" (click)="activeModal.close()"><app-close-button [isWhiteIcon]="true"></app-close-button></span>
  </div>

  <ng-container *ngIf="loading; then loadingSpinner else registrationContainer"></ng-container>
</div>

<ng-template #registrationContainer>
  <ng-container *ngIf="registrationResponse; then registrationFinished else registrationForm"></ng-container>
</ng-template>

<ng-template #registrationForm>
    <form *ngIf="guestRegistrationModel" class="GuestRegistrationModal__body modal-body row pt-2 mx-1 d-flex flex-column"
          #guestRegistrationForm="ngForm" id="guestRegistrationForm" name="guestRegistrationForm"
          (ngSubmit)="submitGuestRegistrationForm()">

    <!-- HEADER -->
    <h3 class="GuestRegistrationModal__title modal-title text-center text-white mx-auto font-weight-light pb-2">
      {{data.Title_Label}}
    </h3>
    <div class="GuestRegistrationModal__label text-white text-center mx-auto pb-4">
      {{data.Subtitle_Label}}
    </div>

    <div class="GuestRegistrationModal__form-body w-100 pt-1 px-4">

      <!-- Persons Contact Fields (iterative) -->
      <div *ngFor="let contact of guestRegistrationModel?.Contacts; let i = index;"
            class="d-flex flex-column mb-3"
            [@fadeInOnEnter]
            [@fadeOutOnLeave]="{value: '', params: {duration: 200}}">

        <div *ngIf="i > 0" class="GuestRegistrationModal__label text-white mb-2">
          <hr class="w-100 mt-2 mb-3" />
          <div class="d-flex flex-row align-items-center position-relative mb-2">
            <span>{{data.Additional_Contact_Label}} {{i}}</span>
            <button class="GuestRegistrationModal__remove-button font-weight-light position-absolute" type="button"
                    (click)="removeContactFromForm(i)"
                    onmousedown="event.preventDefault()"></button>
          </div>
        </div>

        <div class="form-row">
          <input #firstName="ngModel" id="firstName" name="firstName{{i}}" type="text" minlength="2" appNameValid
                class="form-input w-50 input rounded mr-3" [ngClass]="{'form-input-error': firstName.touched && firstName.invalid}"
                required placeholder="{{data.First_Name_Placeholder_Text}}" [(ngModel)]="contact.FirstName">
          <input #lastName="ngModel" id="lastName" name="lastName{{i}}" type="text" minlength="2" appNameValid
                class="form-input w-50 input rounded" [ngClass]="{'form-input-error': lastName.touched && lastName.invalid}"
                required placeholder="{{data.Last_Name_Placeholder_Text}}" [(ngModel)]="contact.LastName">
        </div>

        <div class="form-row">
          <app-form-control-error class="w-100 mr-3" [input]="firstName" [type]="'First Name'" [displayError]='formSubmitted'></app-form-control-error>
          <app-form-control-error class="w-100" [input]="lastName" [type]="'Last Name'" [displayError]='formSubmitted'></app-form-control-error>
        </div>

        <input #email="ngModel" id="email" name="email{{i}}" type="email" appEmailValid
              class="form-input w-100 input rounded mt-3" [ngClass]="{'form-input-error': email.touched && email.invalid}"
              [required]="!contact.Phone" placeholder="{{data.Email_Placeholder_Text}}" [(ngModel)]="contact.Email">

        <app-form-control-error class="w-100" [input]="email" [type]="'Email'" [displayError]='formSubmitted'></app-form-control-error>

        <div class="form-row mt-3">
          <input #phone="ngModel" id="phone" name="phone{{i}}" maxlength="21" appPhoneMask appPhoneValid
                class="form-input w-50 input rounded mr-3" [ngClass]="{'form-input-error': phone.touched && phone.invalid}"
                [required]="!contact.Email" placeholder="{{data.Phone_Placeholder_Text}}" [(ngModel)]="contact.Phone">
          <input #zip="ngModel" id="zip" name="zip{{i}}" minlength="5" maxlength="5" appDigitsOnly
                class="form-input w-25 input rounded" [ngClass]="{'form-input-error': zip.touched && zip.invalid}"
                required placeholder="{{data.Zip_Placeholder_Text}}" [(ngModel)]="contact.Zip">
        </div>

        <div class="form-row">
          <app-form-control-error class="w-100 mr-4" [input]="phone" [type]="'Phone Number'" [displayError]='formSubmitted'></app-form-control-error>
          <app-form-control-error class="w-100" [input]="zip" [type]="'Zip Code'" [displayError]='formSubmitted'></app-form-control-error>
        </div>
      </div>

      <!-- Add Additional Contact button -->
      <button *ngIf="!atMaxContactsLimit"class="GuestRegistrationModal__add-person-button btn btn-outline-light btn-sm"
        onmousedown="event.preventDefault()"
        [@fadeInOnEnter]
        (click)="addContactToForm()"
        type="button">
        {{data.Add_Additional_Contact_Label}}
    </button>

      <!-- Real Estate Agent Fields -->
      <div class="my-4">
        <div class="GuestRegistrationModal__label text-white mb-3">{{data.Real_Estate_Agent_Checkbox_Label}}</div>
        <div class="form-row form-group">
          <div class="d-inline-flex align-items-center mr-3">
            <div class="GuestRegistrationModal__input-radio form-radio-pill d-inline-flex">
              <input #hasAgent="ngModel" id="hasAgentYes" name="hasAgentYes" type="radio"
                class="form-radio-pill-button form-radio-button" required
                [value]="true" [(ngModel)]="guestRegistrationModel.HasAgent"
                onmousedown="event.preventDefault()"/>
            </div>
            <label class="GuestRegistrationModal__label form-radio-pill-label cursor-pointer mb-0"
              [ngClass]="{'form-radio-pill-label-checked': guestRegistrationModel.HasAgent}" for="hasAgentYes">{{data.Real_Estate_Agent_Yes}}</label>
          </div>
          <div class="d-inline-flex align-items-center">
            <div class="GuestRegistrationModal__input-radio form-radio-pill d-inline-flex">
              <input #hasAgent="ngModel" id="hasAgentNo" name="hasAgentNo" type="radio"
                class="form-radio-pill-button form-radio-button" required
                [value]="false" [(ngModel)]="guestRegistrationModel.HasAgent"
                onmousedown="event.preventDefault()"/>
            </div>
            <label class="GuestRegistrationModal__label form-radio-pill-label cursor-pointer mb-0"
              [ngClass]="{'form-radio-pill-label-checked': guestRegistrationModel.HasAgent === false}" for="hasAgentNo">{{data.Real_Estate_Agent_No}}</label>
          </div>
        </div>
        <app-form-control-error class="w-100" [input]="hasAgent" [displayError]='formSubmitted'></app-form-control-error>
      </div>

      <div [hidden]="!guestRegistrationModel.HasAgent" class="mt-3">
        <div [@fadeInOnEnter] [@fadeOutOnLeave]="{value: '', params: {duration: 200}}">
          <span class="GuestRegistrationModal__label text-white">{{data.Agent_Information_Label}}</span>

          <div class="form-row mt-3">
            <input #agentFirstName="ngModel" id="agentFirstName" name="agentFirstName" type="text" minlength="2" appNameValid
                  class="form-input w-50 input rounded mr-3" [ngClass]="{'form-input-error': agentFirstName.touched && agentFirstName.invalid}"
                  [required]="guestRegistrationModel.HasAgent" placeholder="{{data.Agent_First_Name_Placeholder_Text}}" [(ngModel)]="guestRegistrationModel.AgentFirstName">
            <input #agentLastName="ngModel" id="agentLastName" name="agentLastName" type="text" minlength="2" appNameValid
                  class="form-input w-50 input rounded" [ngClass]="{'form-input-error': agentLastName.touched && agentLastName.invalid}"
                  [required]="guestRegistrationModel.HasAgent" placeholder="{{data.Agent_Last_Name_Placeholder_Text}}" [(ngModel)]="guestRegistrationModel.AgentLastName">
          </div>

          <div class="form-row">
            <app-form-control-error class="w-100 mr-3" [input]="agentFirstName" [type]="'First Name'" [displayError]='formSubmitted'></app-form-control-error>
            <app-form-control-error class="w-100" [input]="agentLastName" [type]="'Last Name'" [displayError]='formSubmitted'></app-form-control-error>
          </div>

          <input #agentEmail="ngModel" id="agentEmail" name="agentEmail" type="email" appEmailValid
                class="form-input w-100 input rounded mt-3" [ngClass]="{'form-input-error': agentEmail.touched && agentEmail.invalid}"
                [required]="guestRegistrationModel.HasAgent && !guestRegistrationModel.AgentPhone" placeholder="{{data.Agent_Email_Placeholder_Text}}" [(ngModel)]="guestRegistrationModel.AgentEmail">

          <app-form-control-error class="w-100" [input]="agentEmail" [type]="'Email'" [displayError]='formSubmitted'></app-form-control-error>

          <div class="form-row mt-3 mb-1">
            <input #agentPhone="ngModel" id="agentPhone" name="Agent Phone" maxlength="21" appPhoneMask appPhoneValid
                  class="form-input w-50 input rounded mr-3" [ngClass]="{'form-input-error': agentPhone.touched && agentPhone.invalid}"
                  [required]="guestRegistrationModel.HasAgent && !guestRegistrationModel.AgentEmail" placeholder="{{data.Agent_Phone_Placeholder_Text}}" [(ngModel)]="guestRegistrationModel.AgentPhone">
            <input #agentZip="ngModel" id="agentZip" name="agentZip" minlength="5" maxlength="5" appDigitsOnly
                  class="form-input w-25 input rounded" [ngClass]="{'form-input-error': agentZip.touched && agentZip.invalid}"
                  [required]="guestRegistrationModel.HasAgent" placeholder="{{data.Agent_Zip_Placeholder_Text}}" [(ngModel)]="guestRegistrationModel.AgentZip">
          </div>

          <div class="form-row">
            <app-form-control-error class="w-100 mr-4" [input]="agentPhone" [type]="'Phone Number'" [displayError]='formSubmitted'></app-form-control-error>
            <app-form-control-error class="w-100" [input]="agentZip" [type]="'Zip Code'" [displayError]='formSubmitted'></app-form-control-error>
          </div>
        </div>
      </div>

      <div *ngIf="data.Enable_Additional_Comments_Field" class="form-row my-4">
        <textarea #additionalComments="ngModel" id="additionalComments" name="Additional Comments" rows="3" class="form-input form-text-area mini-scrollbar h-auto w-100 input rounded"
          placeholder="{{data.Additional_Comments_Placeholder_Text}}" [(ngModel)]="guestRegistrationModel.AdditionalComments"></textarea>
      </div>

      <span *ngIf="data.Contact_Disclaimer_Label" class="GuestRegistrationModal__label text-white mb-3">
        {{data.Contact_Disclaimer_Label}}
      </span>
      <br/>
      <span *ngIf="data.Communication_Disclaimer_Label" class="GuestRegistrationModal__label--communication-disclaimer text-white my-3 d-flex  text-italic">
        {{data.Communication_Disclaimer_Label}}
      </span>

    </div>

    <!-- Submit Button and Keyboard -->
    <button
      class="GuestRegistrationModal__register-button btn btn-outline-light"
      [ngClass]="{'GuestRegistrationModal__register-button--disabled': guestRegistrationForm.invalid}"
      type="submit">
      {{data.Complete_Registration_Button_Label}}
    </button>
  </form>

  <div class="GuestRegistrationModal__keyboard-container w-100 mt-auto px-3 pb-3">
    <app-form-keyboard [suggestions]="emailDomains"
                       [form]="guestRegistrationForm || null">
    </app-form-keyboard>
  </div>
</ng-template>

<ng-template #registrationFinished>
  <div class="h-100 modal-body row mx-1 d-flex align-items-center justify-content-center">
    <span *ngIf="registrationResponse"
           class="GuestRegistrationModal__title modal-title text-center text-white mx-auto font-weight-light px-2 pb-5">
      {{registrationResponse.IsSuccessful ? data.Successful_Registration_Label : data.Failed_Registration_Label}}
    </span>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="h-100 modal-body row mx-1 d-flex align-items-center justify-content-center">
    <app-loading-spinner [loading]="loading" [scale]="4"></app-loading-spinner>
  </div>
</ng-template>
