import { Component, OnInit, Input } from '@angular/core';
import { PageHeaderModel } from 'src/app/models/content/editorial/page-header.model';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() data: PageHeaderModel;

  constructor(private location: Location,
              private modalService: NgbModal) { }

  ngOnInit() {
  }

  closeButton(): void {
    this.modalService.hasOpenModals() ? this.modalService.dismissAll() : this.location.back();
  }

}
