import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { HersScoreModalComponent } from '../../shared/modals/hers-score-modal/hers-score-modal.component';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Plan } from 'src/app/models/product/plan.model';
import { PlanCardModel } from 'src/app/models/content/plan/plan-card.model';
import { createStatusOverlayText } from 'src/helper-functions/plan-data-helper';
import { Router } from '@angular/router';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';

@Component({
  selector: 'app-horizontal-plan-card',
  templateUrl: './horizontal-plan-card.component.html',
  styleUrls: ['./horizontal-plan-card.component.scss']
})
export class HorizontalPlanCardComponent implements OnInit {

  @Input() data: PlanCardModel;
  @Input() plan: Plan;
  @Input() large?: boolean;
  @Input() statusOverlayText: string[];
  @Input() selectedPlanId: number = 0;
  @Input() numberOfLotsAvailableForSelectedPlan: number;

  @Output() setSelectPlanId = new EventEmitter<number>();

  hasHersScore: boolean;
  hasAvailability: boolean;

  constructor(private modalService: NgbModal, public router: Router, private adobeLaunchService: AdobeLaunchService) { }

  ngOnInit() {
    if (!this.statusOverlayText) {
      this.statusOverlayText = createStatusOverlayText(this.plan, this.data);
    }

    if (this.plan && this.plan.HERSScore && this.data && this.data.HersScore &&
        this.data.HersScore.Hers_Score_CTA_ToolTip && this.data.HersScore.Hers_Score_CTA_Label) {
        this.hasHersScore = true;
    }
  }

  openHersModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(HersScoreModalComponent,
      { windowClass: `${ModalClasses.Full}`, backdrop: false });
    modalRef.componentInstance.HersScore = this.data.HersScore;
    modalRef.componentInstance.plan = this.plan;
  }

  setSelectedPlanId(planId: number) {
    this.setSelectPlanId.emit(planId);
  }

  handlePlanClicked(plan: Plan) {
    if (plan) {
      this.adobeLaunchService.pushPlanListingClickedEvent(plan, plan.Price);
    }
  }
}
