import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModalComponent } from '../../modals/gallery-modal/gallery-modal.component';
import { Image } from 'src/app/models/product/image.model';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';
import { ModalClasses } from '../../../../shared/modal-classes';
import { FilterGalleryModalComponent } from '../../modals/filter-gallery-modal/filter-gallery-modal.component';
import { GalleryModalModel } from 'src/app/models/content/media/gallery-modal.model';
import { PagesService } from 'src/app/core-services/pages.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { sortImagesByImageRank, sortImagesByKioskRank } from 'src/helper-functions/image-helper';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent extends BaseComponent implements OnInit {

  @Input() public images: Image[];
  @Input() public statusOverlayText: string[];
  @Input() public galleryModalModel: GalleryModalModel;
  @Input() public showModalCTA: boolean;
  @Input() public useKioskRank: boolean;
  carouselOptions: CarouselOptionsModel;
  hasSummary: boolean;

  constructor(private modalService: NgbModal,
              private pageService: PagesService) { super(); }

  ngOnInit() {
    // Filter and Sort images
    const sortedImages: Image[] = this.filterAndSortImages(this.images);

    // Set Carousel Options
    this.carouselOptions = new CarouselOptionsModel(sortedImages);
    this.carouselOptions.StatusOverlayText = this.statusOverlayText;
    this.carouselOptions.ShowModalCta = this.showModalCTA;

    this.pageService.hasSummary.pipe(
      takeUntil(this.unsubscribe$)).subscribe((response: any) => {
        this.hasSummary = response;
      });
  }

  openGallery(slideId: number): void {
    const modalClass = this.hasSummary ? ModalClasses.Summary : '';
    this.carouselOptions.InitialSlideId = slideId.toString();

    const modalType = this.setModalType();

    const modalRef: NgbModalRef =
      this.modalService.open(modalType,
      { windowClass: `${ModalClasses.Full} ${modalClass}`, backdrop: false });

    this.setModalInputs(modalType, modalRef);
  }

  setModalType(): any {
    return this.galleryModalModel && this.galleryModalModel.IsFilterGallery ? FilterGalleryModalComponent : GalleryModalComponent;
  }

  setModalInputs(modalType: any, modalRef: NgbModalRef): void {
    if (modalType === FilterGalleryModalComponent) {
      modalRef.componentInstance.allImages = this.carouselOptions.Images;
    } else {
      modalRef.componentInstance.carouselOptions = this.carouselOptions;
    }

    if (this.galleryModalModel) {
      modalRef.componentInstance.galleryModalModel = this.galleryModalModel;
    }
  }

  filterAndSortImages(images: Image[]): Image[] {
    let sortedImages: Image[] = [];

    if (this.useKioskRank) {
      sortedImages = sortImagesByKioskRank(images);
    } else {
      sortedImages = sortImagesByImageRank(images);
    }

    return sortedImages;
  }
}
