import { Pipe, PipeTransform } from '@angular/core';
import { range } from 'rxjs';

// Provides an array of numeral values between two ranges, iterated by an optional input as well.
@Pipe({
  name: 'rangeArray'
})
export class RangeArrayPipe implements PipeTransform {

  transform(min, max, minInclusive, maxInclusive, iteration?): number[] {
    if (min && max) {
      const rangeArray: number[] = [];
      let minValue = parseInt(min, 0);
      const maxValue = parseInt(max, 0);
      const i = iteration ? iteration : 1;

      if (!minInclusive) {
        minValue += i;
      }

      for (minValue; minValue <= maxValue; minValue += i) {
        if (minValue < maxValue) {
          rangeArray.push(minValue);
        }
      }

      if ((!rangeArray.includes(maxValue) && maxInclusive) || !rangeArray.length) {
        rangeArray.push(maxValue);
      }

      return rangeArray;
    }
    return [];
  }
}
