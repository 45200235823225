import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ProductFilterDataModel } from '../models/content/community/product-filter-data.model';

@Injectable({
  providedIn: 'root'
})
export class ProductFilterService {

  filterData = new BehaviorSubject<ProductFilterDataModel>(null);
  exactHomes = new ReplaySubject<any>(1);
  closeHomes = new ReplaySubject<any>(1);

  constructor() { }

  updateFilterData(filterData: ProductFilterDataModel) {
    // Update this service's filter data subject
    this.filterData.next(filterData);
  }

  updateExactHomes(homes) {
    this.exactHomes.next(homes);
  }

  updateCloseHomes(homes) {
    this.closeHomes.next(homes);
  }
}
