import { Component, OnInit, Input } from '@angular/core';
import { QmiLocationModel } from 'src/app/models/content/qmi/qmi-location.model';
import { Observable } from 'rxjs';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { QMI } from 'src/app/models/product/qmi.model';
import { BaseComponent } from '../../shared/base/base.component';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-qmi-location',
  templateUrl: './qmi-location.component.html',
  styleUrls: ['./qmi-location.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800, delay: 750 })
  ]
})
export class QmiLocationComponent extends BaseComponent implements OnInit {

  @Input() data: QmiLocationModel;
  qmi$: Observable<QMI>;

  constructor(private communityService: CommunityService, private route: ActivatedRoute) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      takeUntil(this.unsubscribe$)
    ).subscribe((qmiId: string) => {
      this.qmi$ = this.communityService.getQmiById(qmiId);
    });
  }
}
