import { CTAPageItemRef } from '../../cta-page-item-ref.model';
import { LmpConfigurationModel } from '../../lmp-configuration.model';

export class QmiCardModel {
  Series: string;
  Series_Name: string;
  Price: string;
  Bedrooms: string;
  Bathrooms: string;
  Garage: string;
  Square_Feet: string;
  Lot: string;
  Availability_Date: string;
  QMI_CTA_Text: string;
  View_All_Button_Text: string;
  QMI_CTA_Page_Item_Ref: CTAPageItemRef;
  Price_Discount: string;
  Call_For_Pricing_Text: string;
  LmpConfiguration: LmpConfigurationModel;
  LMPStartingFrom: string;
  PITIPriceLabel: string;
}
