export class ProductFilterModel {
  Filter_Bathrooms_Maximum: string;
  Filter_Bathrooms_Minimum: string;
  Filter_Bedroom_Count: string;
  Filter_Bedrooms_Maximum: string;
  Filter_Bedrooms_Minimum: string;
  Filter_Clear_Filters_CTA: string;
  Filter_Bathroom_Count: string;
  Filter_Name: string;
  Filter_Livable_Space: string;
  Filter_Living_Space_Maximum: string;
  Filter_Living_Space_Minimum: string;
  Filter_Model_Availability: string;
  Filter_Price: string;
  Filter_Price_Range_Maximum: string;
  Filter_Price_Range_Minimum: string;
  Filter_QMI_Availability: string;
  Filter_View_Results: string;
  Filter_Close_Matches: string;
  Filter_Exact_Matches_Count: string;
  Filter_Close_Matches_Count: string;
  Filter_Available: string;
  Filter_Sold: string;
  Filter_Not_Released: string;
}
