import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppInsightsService } from './core-services/app-insights.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { AdobeLaunchService } from './core-services/adobe-launch.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject();

  constructor(private appInsightsService: AppInsightsService, private router: Router, private adobeLaunchService: AdobeLaunchService) {
    // Adobe Launch Init
    this.adobeLaunchService.initAdobeLaunchDataLayer();
    this.adobeLaunchService.injectAdobeLaunchScript();
  }

  ngOnInit() {
    // Log page views to App Insights and Adobe Target Trigger
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.appInsightsService.logPageView(event.urlAfterRedirects, event.urlAfterRedirects);
          this.adobeLaunchService.triggerView(event.urlAfterRedirects);
        }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
