
<section *ngIf="!noPromotions; else noOffers" class="d-flex flex-wrap justify-content-center">
    <div *ngFor="let promotion of communityPromotions; let i = index;"
          class="card__container--special-offers rounded-lg d-flex m-4 col-5 p-0"
          [@fadeInOnEnter]="{ value: '', params: { duration: 800, translate: '200px', delay: i * 150 }}">
        <div *ngIf="promotion.DisplayImage" class="col-4 p-0">
            <cl-image
            class="card__special-offers-image w-100 rounded-left h-100"
            public-id="{{promotion.DisplayImage}}"
            format="auto"
            quality="auto"
            type="fetch"
            crop="fill"
            [attr.aspect-ratio]="1"></cl-image>
        </div>
        <div class="col-8 py-2 px-4 d-flex flex-wrap">
           <div class="d-flex flex-wrap align-content-around">
               <div *ngIf="promotion.Title" class="d-flex w-100 {{data.Header_Alignment | alignmentClass}}">
                    <h2 class="card__header--special-offers mt-1">
                        {{promotion.Title}}
                    </h2>
                    <app-tooltip *ngIf="promotion.Disclaimer" [description]="promotion.Disclaimer"></app-tooltip>
                </div>
                <div class="d-flex {{data.Description_Alignment | alignmentClass}}" [innerHTML]="promotion.Description">
                </div>
            </div>
            <div *ngIf="promotion.CallToActionText" class="d-flex py-3 text-white align-items-end w-100 {{data.CTA_Alignment | alignmentClass}}" >
                <a class="btn btn-card {{promotion.CTA_Style | buttonStyle}}" (click)="openSpecialOffers(promotion)">
                    {{promotion.CallToActionText}}
                </a>
            </div>
        </div>
    </div>
</section>

<ng-template #noOffers>
    <h1 class="card__container--special-offers--no-offers text-center font-weight-bold">{{data.No_Special_Offers_Message}}</h1>
</ng-template>
