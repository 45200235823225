import { Pipe, PipeTransform } from '@angular/core';
import { HorizontalAlignment } from 'src/app/models/enums/dropdown-enum';

@Pipe({
  name: 'orientationClass'
})
export class OrientationStylePipe implements PipeTransform {

  transform(sitecoreOrientation: string): string {
    let orientationClass: string;
    switch (sitecoreOrientation) {
      case HorizontalAlignment.Left:
        orientationClass = 'flex-row';
        break;
      default:
        orientationClass = 'flex-row-reverse';
    }
    return orientationClass;
  }

}
