import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { Observable } from 'rxjs';
import { Community } from 'src/app/models/product/community.model';
import { CommunityDescriptionModel } from 'src/app/models/content/community/community-description.model';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-community-description',
  templateUrl: './community-description.component.html',
  styleUrls: ['./community-description.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800 })
  ]
})
export class CommunityDescriptionComponent implements OnInit {

  @Input() data: CommunityDescriptionModel;

  community$: Observable<Community>;

  constructor(private communityService: CommunityService) { }

  ngOnInit() {
    this.community$ = this.communityService.community;
  }
}
