import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { LmpConfigurationModel } from '../models/lmp-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class LmpService {

  LmpConfiguration = new ReplaySubject<LmpConfigurationModel>(1);

  updateLmpConfiguration(LmpConfiguration: LmpConfigurationModel): void {
    this.LmpConfiguration.next(LmpConfiguration);
    this.LmpConfiguration.complete();
  }
}
