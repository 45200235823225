import { Pipe, PipeTransform } from '@angular/core';

// this pipe takes in two numbers and returns the range as a string, given that the values are valid
@Pipe({
  name: 'range'
})
export class RangePipe implements PipeTransform {

  transform(minValue: number, maxValue: number): string {
    let range: string;

    if (minValue >= 0) {
      range = minValue.toString();

      if (maxValue && maxValue > minValue) {
        range = `${minValue} - ${maxValue}`;
      }

      return range;
    }
  }

}
