import { Component, OnInit, Input } from '@angular/core';
import { ContentCardModel } from 'src/app/models/content/editorial/card.model';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';
import { Image } from 'src/app/models/product/image.model';
import { VideoModalComponent } from '../../shared/modals/video-modal/video-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VideoModalModel } from 'src/app/models/content/media/video-modal.model';
import { ModalClasses } from 'src/app/shared/modal-classes';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() data: ContentCardModel;
  @Input() videoModalModel: VideoModalModel;
  carouselOptions: CarouselOptionsModel;
  carouselImages: Image[] = [];
  videoUrl: string = "";
  videoThumbnailUrl: string = "";
  videoId: string = "";
  videoButtonUrl: string = "";
  videoCaption: string = "";
  videoTitle: string = "";
  modalClass: string;

  constructor(private _modalService: NgbModal) {
  }

  ngOnInit() {

    this.videoId = this.data.YouTube_Id;
    this.setVideoUrl(this.videoId);
    this.setVideoThumbnailUrl(this.videoId);
    this.setVideoButtonUrl(this.videoId);


    if (this.data.Carousel_Images && this.data.Carousel_Images.length) {
      this.data.Carousel_Images.map(image => {
        const convertedImage: Image = new Image();
        convertedImage.Path = image.Src;
        convertedImage.AltText = image.Alt;
        this.carouselImages.push(convertedImage);
      });
      if (this.carouselImages.length) {
        this.carouselOptions = new CarouselOptionsModel(this.carouselImages);
        this.carouselOptions.ShowPips = true;
        this.carouselOptions.ShowImageIndex = false;
        this.carouselOptions.ShowImageCaption = false;
        if (this.data.Carousel_Flag) {
          this.carouselOptions.StatusOverlayText = [this.data.Carousel_Flag];
        }
      }
    }
  }

  openVideoModal(videoId: string, caption: string, modalTitle: string): void {
    if (videoId) {
      const modalRef: NgbModalRef = this._modalService.open(VideoModalComponent, {
        backdrop: false,
        windowClass: `${ModalClasses.Full} ${this.modalClass}`
      });
      const videoModalModel: VideoModalModel = new VideoModalModel();
      videoModalModel.ModalHeader = modalTitle;
      videoModalModel.ModalDescription = caption;
      videoModalModel.VideoId = videoId;
      videoModalModel.EnableAutoplay = true;
      modalRef.componentInstance.videoModalModel = videoModalModel;
    }
  }

  setVideoUrl(videoId: string): string {
    if (videoId) {
      this.videoUrl = `https://youtube.com/embed/${videoId}`;
      return this.videoUrl;
    }
  }

  setVideoThumbnailUrl(videoId: string): string {
    if (videoId) {
      this.videoThumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
      return this.videoThumbnailUrl;
    }
  }

  setVideoButtonUrl(videoId: string): string {
    if (videoId) {
      this.videoButtonUrl = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
      return this.videoButtonUrl;
    }
  }

}

