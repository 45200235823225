<section class="NgGoogleMaps d-flex"
        [ngClass]="{'NgGoogleMaps--fullscreen': fullscreenEnabled}">

  <agm-map class="NgGoogleMaps__agm-map" appDirectionsMap

    (mapClick)="mapClick()"
    (mapReady)="mapLoaded()"

    [latitude]="mapLatitude"
    [longitude]="mapLongitude"

    [zoom]="mapOptions?.Zoom || 15"
    [streetViewControl]="mapOptions ? mapOptions.StreetViewControl : true"
    [zoomControl]="mapOptions ? mapOptions.StreetViewControl : true"
    [fullscreenControl]="mapOptions ? mapOptions.StreetViewControl : true"
    [clickableIcons]="mapOptions?.ClickableIcons"
    [maxZoom]="mapOptions?.MaxZoom || 25"
    [minZoom]="mapOptions?.MinZoom || 1"
    [mapTypeId]="mapOptions?.MapType"

    [keyboardShortcuts]="false"
    [fitBounds]="true">

    <agm-marker
      *ngFor="let marker of markers; let i = index;"
      (markerClick)="markerClick(marker)"
      [latitude]="marker.Latitude"
      [longitude]="marker.Longitude"
      [agmFitBounds]="true"
      [zIndex]="marker.zIndex || (markers.length - i)"
      [iconUrl]="{
        url: marker.IsSelected ? marker.IconSelectedUrl : marker.IconUrl,
        labelOrigin: marker.IconOptions?.labelOrigin,
        scaledSize: marker.IconOptions?.scaledSize
      }"
      [label]="{
        text: marker.LabelText,
        color: marker.LabelOptions?.color,
        fontSize: marker.LabelOptions?.fontSize + 'rem',
        fontWeight: marker.LabelOptions?.fontWeight
      }">
    </agm-marker>

    <agm-snazzy-info-window [isOpen]="showDirectionsEnabled && directionsRouteData"
                            [latitude]="directionsMidwayLatitude"
                            [longitude]="directionsMidwayLongitude"
                            [closeWhenOthersOpen]="true"
                            [showCloseButton]="false"
                            [placement]="'bottom'"
                            [padding]="'0'">
      <ng-template>
        <div  class="py-2 px-3">
          <div class="d-flex flex-row">
            <div class="NgGoogleMaps__commute-icon"></div>
            <span class="NgGoogleMaps__commute-duration pl-1">{{directionsRoute?.legs[0]?.duration?.text}}</span>
          </div>
          <span class="NgGoogleMaps__commute-distance">{{directionsRoute?.legs[0]?.distance?.text}}</span>
        </div>
      </ng-template>
    </agm-snazzy-info-window>

  </agm-map>

  <div *ngIf="mapOptions?.MapTypeControl"
    class="NgGoogleMaps__map-type-control btn-group btn-group-toggle ml-4"
    name="mapTypeRadio"
    [(ngModel)]="mapOptions.MapType"
    [@fadeInOnEnter]
    ngbRadioGroup>
    <label ngbButtonLabel class="NgGoogleMaps__map-type-control--button mr-0">
      <input ngbButton type="radio" class="d-none" [value]="googleMapTypes.ROADMAP">
      Map
    </label>
    <label ngbButtonLabel class="NgGoogleMaps__map-type-control--button">
      <input ngbButton type="radio" class="d-none" [value]="googleMapTypes.SATELLITE">
      Satellite
    </label>
  </div>

  <div *ngIf="mapOptions"
       class="NgGoogleMaps__map-controls d-flex justify-content-end align-items-end">
    <div *ngIf="mapOptions.ZoomControl"
         class="NgGoogleMaps__map-controls__control NgGoogleMaps__map-controls__zoom-in-button mr-3 mb-4"
         (click)="mapZoomIn()"
         [@fadeInOnEnter]>
    </div>
    <div *ngIf="mapOptions.ZoomControl"
         class="NgGoogleMaps__map-controls__control NgGoogleMaps__map-controls__zoom-out-button mr-3 mb-4"
         (click)="mapZoomOut()"
         [@fadeInOnEnter]>
    </div>
    <div *ngIf="mapOptions.FullscreenControl"
         class="NgGoogleMaps__map-controls__control NgGoogleMaps__map-controls__fullscreen-button mr-4 mb-4"
         [ngClass]="{'fullscreen-enabled': fullscreenEnabled}"
         (click)="mapFullscreen()"
         [@fadeInOnEnter]>
    </div>
  </div>
</section>
