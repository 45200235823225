import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";
import { map, share, tap } from "rxjs/operators";
import { Environment } from "src/environments/environment";
import { hostnameContainsPreviewPrefix } from "src/helper-functions/sitecore-api-url-helper";
import { getBrandNameForApi } from "src/helper-functions/brand-specific-configuration";

@Injectable()
export class SettingsService extends Environment
{
    constructor()
    {
        super();
        let settings = <Environment>window["settings"];

        // point the apiUrl to the preview api url if the hostname contains the preview prefix
        if (hostnameContainsPreviewPrefix()) {
            settings.apiUrl = settings.previewApiUrl;
        }

        // store the brand name set by configuration
        const brandNameForApi = getBrandNameForApi();

        // replace the brand name in our apiUrl with the brand name retrieved from configuration
        settings.apiUrl = settings.apiUrl.replace('pulte', brandNameForApi);

        Object.keys(settings).forEach(k => this[k] = settings[k]);
    }
}