import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AlphaVisionLot,
         AlphaVisionAmenity,
         AlphaVisionAttributeValues,
         AlphaVisionLotFilterAttributes, 
         AlphaVisionLotFacing,
         AlphaVisionLotType,
         AlphaVisionLotStatusType} from '../models/maps/alphavision-map-data.model';
import { Plan } from '../models/product/plan.model';

@Injectable({
  providedIn: 'root'
})
export class AlphaVisionMapService {

  statuses = new ReplaySubject<AlphaVisionLotStatusType[]>(1);
  mapPlanIds = new ReplaySubject<string[]>(1);
  lotTypes = new ReplaySubject<AlphaVisionLotType[]>(1);
  lotFacings = new ReplaySubject<AlphaVisionLotFacing[]>(1);
  lotAttributes = new ReplaySubject<AlphaVisionLotFilterAttributes>(1);
  initialLots = new ReplaySubject<AlphaVisionLot[]>(1);
  selectedLot = new ReplaySubject<AlphaVisionLot>(1);
  selectedPlan = new ReplaySubject<Plan>(1);
  selectedAmenity = new ReplaySubject<AlphaVisionAmenity>(1);

  constructor() { }

  updateStatuses(statuses: AlphaVisionLotStatusType[]): void {
    this.statuses.next(statuses);
  }

  updateMapPlanIds(planIds: string[]): void {
    if (planIds && planIds.length) {
      this.mapPlanIds.next(planIds);
    } else {
      this.mapPlanIds.next([]);
    }
  }

  updateLotAttributes(types: AlphaVisionLotType[], facings: AlphaVisionLotFacing[]) {
    const lotFilterAttributes = new AlphaVisionLotFilterAttributes(types, facings);
    this.lotAttributes.next(lotFilterAttributes);
  }

  updateInitialLots(initialLots: AlphaVisionLot[]) {
    if (initialLots) {
      // Set Service Subject
      this.initialLots.next(initialLots);

      const lotFacingValues = [];
      const lotTypeValues = [];
      // We need to parse the lot attribute data this way because of the
      // way AlphaVision returns them in an array of single property objects.
      initialLots.forEach(intialLot => intialLot.lotAttributes.forEach(lot => {
        const keys = Object.keys(lot) as string[];
        const values = Object.values(lot) as string[];

        if (keys[0] && values[0]) {
          if (keys[0] === AlphaVisionAttributeValues.LotType && !lotTypeValues.includes(values[0])) {
            const lotTypeVal = values[0];
            lotTypeValues.push(lotTypeVal);
          }

          if (keys[0] === AlphaVisionAttributeValues.LotFacing && !lotFacingValues.includes(values[0])) {
            const lotFacingVal = values[0];
            lotFacingValues.push(lotFacingVal);
          }
        }
      }));
      this.updateLotAttributes(lotTypeValues, lotFacingValues);
    }
  }

  updateSelectedLot(lot: AlphaVisionLot): void {
    this.selectedLot.next(lot);
  }

  updateSelectedPlan(plan: Plan): void {
    this.selectedPlan.next(plan);
  }

  updateSelectedAmenity(amenity: AlphaVisionAmenity): void {
    this.selectedAmenity.next(amenity);
  }

  resetHomesMapServiceData() {
    this.selectedLot.next();
    this.selectedPlan.next();
    this.selectedAmenity.next();
    this.mapPlanIds.next();
  }

  resetAllMapServiceData() {
    this.selectedLot.next();
    this.selectedPlan.next();
    this.selectedAmenity.next();
    this.mapPlanIds.next();
    this.statuses.next();
    this.lotAttributes.next();
  }
}
