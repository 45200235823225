import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

  @Input() date: Date = new Date();
  @Input() minuteStep: number = 30;
  @Input() allowPastDates?: boolean = false;

  @Output() submitSelectedDate = new EventEmitter<Date>(true);

  currentDate: Date = new Date();
  timeList: Date[] = [];
  disablePreviousTime: boolean = false;
  disablePreviousDate: boolean = false;

  constructor() { }

  ngOnInit() {
    this.populateTimeDropdown();
    this.disableOrEnablePreviousArrows();
  }

  populateTimeDropdown() {
    const startTime = new Date();
    startTime.setHours(0, 0);
    this.timeList.push(startTime);
    const timeStep = new Date(startTime);

    for (let i = 0; i < 47; i++) {
      timeStep.setMinutes(timeStep.getMinutes() + 30);
      const nextTime = new Date(timeStep);
      this.timeList.push(nextTime);
    }
  }

  disableOrEnablePreviousArrows() {
    if (!this.allowPastDates &&
        this.date.getFullYear() === this.currentDate.getFullYear() &&
        this.date.getMonth() === this.currentDate.getMonth() &&
        this.date.getDate() === this.currentDate.getDate()) {

      this.disablePreviousDate = true;

      this.disablePreviousTime = !!(this.date.getHours() === 0 && this.date.getMinutes() < this.minuteStep);
    } else {
      this.disablePreviousDate = false;
      this.disablePreviousTime = false;
    }
  }

  incrementTime() {
    this.date = new Date(this.date.getFullYear(),
                         this.date.getMonth(),
                         this.date.getDate(),
                         this.date.getHours(),
                         this.date.getMinutes() + this.minuteStep);
    this.emitSelectedDate();
    this.disableOrEnablePreviousArrows();
  }

  decrementTime() {
    if (!this.disablePreviousTime) {
      this.date = new Date(this.date.getFullYear(),
                         this.date.getMonth(),
                         this.date.getDate(),
                         this.date.getHours(),
                         this.date.getMinutes() - this.minuteStep);
      this.emitSelectedDate();
      this.disableOrEnablePreviousArrows();
    }
  }

  incrementDate() {
    this.date = new Date(this.date.getFullYear(),
                         this.date.getMonth(),
                         this.date.getDate() + 1,
                         this.date.getHours(),
                         this.date.getMinutes());
    this.emitSelectedDate();
    this.disableOrEnablePreviousArrows();
  }

  decrementDate() {
    if (!this.disablePreviousDate) {
      this.date = new Date(this.date.getFullYear(),
                         this.date.getMonth(),
                         this.date.getDate() - 1,
                         this.date.getHours(),
                         this.date.getMinutes());
      this.emitSelectedDate();
      this.disableOrEnablePreviousArrows();
    }
  }

  setTimeFromDropdown(selectedTime: Date) {
    this.date = new Date(this.date.getFullYear(),
                         this.date.getMonth(),
                         this.date.getDate(),
                         selectedTime.getHours(),
                         selectedTime.getMinutes());
    this.emitSelectedDate();
    this.disableOrEnablePreviousArrows();
  }

  emitSelectedDate() {
    this.submitSelectedDate.emit(this.date);
  }

}
