<section *ngIf="data"
  class="PageHeader d-flex align-items-center justify-content-between p-4"
  [ngClass]="{'fixed-top': !data.Scrollable_With_Content, 'position-relative': data.Scrollable_With_Content}">
  <div class="d-flex flex-column">
    <h1 *ngIf="data.Header_Text" class="PageHeader__header-text mb-0">
      {{data.Header_Text}}
    </h1>
    <div *ngIf="data.Subheader_Text" class="PageHeader__subheader-text">
      {{data.Subheader_Text}}
    </div>
  </div>
  <div *ngIf="data.Enable_Close_Button"
    class="PageHeader__close-btn d-flex align-items-center cursor-pointer font-weight-bold pr-4">
    <span (click)='closeButton()'>
      <app-close-button></app-close-button>
    </span>
  </div>
</section>
