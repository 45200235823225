import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-community-poi-marker',
  templateUrl: './community-poi-marker.component.html',
  styleUrls: ['./community-poi-marker.component.scss']
})
export class CommunityPoiMarkerComponent implements OnInit {

  @Input() public label: string;

  constructor() { }

  ngOnInit() {
  }

}
