import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { GMapsOptionsModel } from 'src/app/models/maps/gmaps-options.model';
import { GMapsMarker } from 'src/app/models/maps/gmaps-marker.model';
import { AgmMap } from '@agm/core';
import { GMapsMarkerService } from 'src/app/core-services/gmaps-marker.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { GoogleDirectionsRouteData } from 'src/app/models/maps/google-directions-route-data.model';
import { GmapsDirectionsService } from 'src/app/core-services/gmaps-directions.service';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-ng-google-map',
  templateUrl: './ng-google-map.component.html',
  styleUrls: ['./ng-google-map.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class NgGoogleMapComponent extends BaseComponent implements OnInit {

  @ViewChild(AgmMap) private agmMap;

  @Input() mapOptions?: GMapsOptionsModel;
  @Output() markerClicked: EventEmitter<any> = new EventEmitter();
  @Output() mapClicked: EventEmitter<any> = new EventEmitter();

  fullscreenEnabled: boolean;

  markers: GMapsMarker[];
  mapLatitude: number = 33.7490; // Default initial Latitude if none passed in.
  mapLongitude: number = 84.3880; // Default initial Longitude if none passed in.

  showDirectionsEnabled: boolean = false;
  directionsRoute: google.maps.DirectionsRoute;
  travelTime: Date;

  directionsRouteData: GoogleDirectionsRouteData;
  directionsMidwayLatitude: number;
  directionsMidwayLongitude: number;

  googleMapTypes = google.maps.MapTypeId;

  constructor(private gmapsMarkerService: GMapsMarkerService,
              private gmapsDirectionsService: GmapsDirectionsService) { super(); }

  ngOnInit() {
    // Set initial map longitude & longitude
    if (this.mapOptions) {
      this.mapLatitude = this.mapOptions.InitialLatitude;
      this.mapLongitude = this.mapOptions.InitialLongitude;
    }

    this.gmapsMarkerService.markers
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((markers: GMapsMarker[]) => {
        if (markers) {
          this.markers = markers;
          this.setMarkersForDisplay();
          if (this.markers.every(mkr => !mkr.IsSelected)) {
            this.resetMapBounds();
          }
        }
      });

    this.gmapsDirectionsService.showDirection$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(showDirection => {
        this.showDirectionsEnabled = showDirection;
      });

    this.gmapsDirectionsService.travelTime$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(travelTime => {
        this.travelTime = travelTime;
      });

    this.gmapsDirectionsService.directionsRouteData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(directionsRouteData => {
        this.setDirectionsRouteData(directionsRouteData);
      });
  }

  public mapZoomIn() {
    this.mapOptions.Zoom = this.agmMap.zoom + 1;
  }

  public mapZoomOut() {
    this.mapOptions.Zoom = this.agmMap.zoom - 1;
  }

  public mapFullscreen() {
    this.fullscreenEnabled = !this.fullscreenEnabled;
  }

  // Set new map active coordinates then set clicked marker as selected.
  // Optionally, send map object data if we need as an output to parent components.
  public markerClick(marker: GMapsMarker) {
    this.mapLatitude = marker.Latitude;
    this.mapLongitude = marker.Longitude;

    this.setMarkersForDisplay();
    this.markerClicked.next(marker);
  }

  // Reset the currently selected marker and marker(s) appearance on map click
  public mapClick() {
    this.resetMapBounds();
    this.mapClicked.next(this.agmMap);
  }

  public mapLoaded() {
    // Do something here when map fully loaded?
    // console.log(this.agmMap);
  }

  public setDirectionsRouteData(routeData: GoogleDirectionsRouteData) {
    this.directionsRouteData = routeData;
    if (routeData) {
      this.gmapsDirectionsService.updateShowDirection(true);

      if (routeData.Route && routeData.Route.legs && routeData.Route.legs.length) {
        this.directionsRoute = routeData.Route;
        this.gmapsDirectionsService.updateDirectionsRoute(this.directionsRoute);
      }

      if (routeData.MidwayLatLng) {
        this.directionsMidwayLatitude = routeData.MidwayLatLng.lat();
        this.directionsMidwayLongitude = routeData.MidwayLatLng.lng();
      }
    } else {
      this.gmapsDirectionsService.updateDirectionsRoute(null);
    }
  }

  // Reset marker appearance based on selection and origin state
  private setMarkersForDisplay() {
    this.resetDirections();

    if (this.markers) {
      this.markers.map((mkr) => {

        if (mkr.IsOrigin) { // set Origin LatLng
          this.gmapsDirectionsService.updateOrigin(new google.maps.LatLng(mkr.Latitude, mkr.Longitude));
          return;
        }

        if (mkr.IsDestination) { // set Destination LatLng
          this.gmapsDirectionsService.updateDestination(new google.maps.LatLng(mkr.Latitude, mkr.Longitude));
          return;
        }

        if (mkr.IsSelected) {
          this.mapLatitude = mkr.Latitude;
          this.mapLongitude = mkr.Longitude;
          mkr.zIndex = 10000;
          mkr.setScale(1.3); // Set Marker Scale and zIndex depending on if a particular marker is selected or not.

        } else {
          mkr.zIndex = null;
          mkr.setScale(1);
        }
      });
    }
  }

  private resetMapBounds() {
    if (this.markers && this.agmMap) {
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
        bounds.extend(new google.maps.LatLng(marker.Latitude, marker.Longitude));
      });
      this.agmMap._mapsWrapper.fitBounds(bounds);
      this.mapLatitude = this.mapOptions.InitialLatitude;
      this.mapLongitude = this.mapOptions.InitialLongitude;
    }
  }

  private resetDirections() {
    this.gmapsDirectionsService.clearDirectionsData();
  }
}
