export enum HorizontalAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right'
}

export enum ButtonStyle {
  Default = 'Default',
  Link = 'Link',
  Primary = 'Primary'
}
