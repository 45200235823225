<div *ngIf="(qmi$ | async) as qmi" class="QmiDescription" [@fadeInOnEnter]>
  <div *ngIf="data?.Header_Text && qmi.Plan && (qmi.PlanDescription || qmi.InventoryDescription || qmi.HotHomeDescription)">
    <div class="QmiDescription__title">{{data.Header_Text}}</div>
    <div *ngIf="data.Series_Label && qmi.Plan.SeriesName" class="QmiDescription__series mb-4">
      <i>{{data.Series_Label | mergeField: '{series}':qmi.Plan.SeriesName}}</i>
    </div>
    <div *ngIf="qmi.InventoryDescription || qmi.PlanDescription" class="QmiDescription__description-text mb-3">
      {{qmi.InventoryDescription ? qmi.InventoryDescription : qmi.PlanDescription}}
    </div>
    <div *ngIf="qmi.HotHomeDescription?.length" class="QmiDescription__description-text ml-4">
      <div *ngFor="let sellingPoint of qmi.HotHomeDescription" class="d-flex align-items-center my-2">
        <div class="QmiDescription__bullet-point rounded-circle mr-3"></div>
        {{sellingPoint}}
      </div>
    </div>
  </div>
</div>
