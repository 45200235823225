<div class="FloorPlanModal">
  <div class="modal-header">
    <h4 class="modal-title floorPlanModal__modalTitle">Floor Plan</h4>
    <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div *ngIf="modalConfigOptions && modalConfigOptions?.length" class="col-4 col-offset-6">
      <div *ngIf="modalConfigOptions?.length > 1" class="row d-flex justify-content-start">
        <div *ngFor="let floorPlanConfig of modalConfigOptions">
          <div *ngIf="floorPlanConfig"
            class="FloorPlanModal__planConfiguration col-auto mb-1"
            [ngClass]="{'FloorPlanModal__selectedConfig': floorPlanConfig.IsSelected}"
            (click)="selectConfiguration(floorPlanConfig.Index)">
            {{floorPlanConfig.ConfigurationName}}
          </div>
        </div>
      </div>
      <div *ngFor="let floorPlanConfig of modalConfigOptions">
        <div *ngIf="floorPlanConfig && floorPlanConfig?.CarouselOptions
          && floorPlanConfig.CarouselOptions.Images"
          [ngClass]="{'d-none': !floorPlanConfig.IsSelected}">
          <app-carousel [carouselOptions]="floorPlanConfig.CarouselOptions"
            (activeSlideChange)="setActiveImageSelection($event)"
            ></app-carousel>
        </div>
      </div>
    </div>
    <div class="FloorPlanModal__buttons col-3">
      <div *ngFor="let floorPlanConfig of modalConfigOptions">
        <div *ngIf="floorPlanConfig && floorPlanConfig?.CarouselOptions
          && floorPlanConfig.CarouselOptions.Images"
          [ngClass]="{'d-none': !floorPlanConfig.IsSelected}">
          <div *ngFor="let floorPlanImage of floorPlanConfig.CarouselOptions.Images; index as i">
            <div class="d-flex align-items-center my-4 px-2">
              <span *ngIf="floorPlanConfig.ActiveImageIndex === i && !floorPlanConfig.ValidSingleImage" class="FloorPlanModal__arrow"></span>
              <div class="FloorPlanModal__image-type my-1"
                *ngIf="!floorPlanConfig.ValidSingleImage"
                [ngClass]="{'FloorPlanModal__floorTypeSelected': floorPlanConfig.ActiveImageIndex === i}"
                (click)="selectFloorPlanImage(floorPlanConfig.Index, floorPlanConfig.CarouselIndex, i)">
                {{floorPlanImage.FloorPlanType | uppercase}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button *ngIf="hasInteractiveTool && data?.Button_CTA && data?.Button_Icon" (click)="openPlanTools()" class="ml-2 btn btn-primary FloorPlanModal__CTA">
        <cl-image
          class="FloorPlanModal__modal-icon"
          public-id="{{data.Button_Icon.Src}}"
          format="auto"
          quality="auto"
          type="fetch"
          crop="fill"
          height="40"
          width="40"
          [attr.aspect-ratio]="1"></cl-image>{{data.Button_CTA}}
      </button>
    </div>
  </div>
  <div class="back-wrapper modal-footer">
    <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
  </div>
</div>
