import { Community } from 'src/app/models/product/community.model';

export function getDistance(origLat: number, origLng: number, lat: number, lng: number) {
  if (origLat && origLng && lat && lng) {
    const origin = new google.maps.LatLng(origLat, origLng);
    const destination = new google.maps.LatLng(lat, lng);
    return google.maps.geometry.spherical.computeDistanceBetween(origin, destination);
  }
}

export function getDistanceInMiles(origLat: number, origLng: number, lat: number, lng: number) {
  if (origLat && origLng && lat && lng) {
    return getDistance(origLat, origLng, lat, lng) / 1609; // # of Miles = (Meters / 1609)
  }
}

export function getDistanceFromCommunity(community: Community, lat: number, lng: number) {
  return parseFloat((getDistanceInMiles(community.Latitude, community.Longitude,
                                        lat, lng).toFixed(1)));
}
