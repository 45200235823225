import { Component, OnInit, Input } from '@angular/core';
import { VRTourPlanModel } from 'src/app/models/content/editorial/vr-tour-plan.model';
import { Plan } from 'src/app/models/product/plan.model';
import { PlanCardModel } from 'src/app/models/content/plan/plan-card.model';
import { combineLatest } from 'rxjs';
import { CommunityService } from 'src/app/core-services/community.service';
import { HersScoreService } from 'src/app/core-services/hers-score.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../shared/base/base.component';
import { LmpService } from 'src/app/core-services/lmp.service';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { fadeInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-vr-tour-plan',
  templateUrl: './vr-tour-plan.component.html',
  styleUrls: ['./vr-tour-plan.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation()
  ]
})
export class VRTourPlanComponent extends BaseComponent implements OnInit {

  @Input() data: VRTourPlanModel;

  plans: Plan[] = [];

  planCardModel: PlanCardModel;

  constructor(private communityService: CommunityService,
              private hersScoreService: HersScoreService,
              private lmpService: LmpService,
              private adobeLaunchService: AdobeLaunchService) { super(); }

  ngOnInit() {
    const observables = [this.communityService.plans,
                         this.hersScoreService.HersScoreitem,
                         this.lmpService.LmpConfiguration];
    combineLatest(observables)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([plans, hers, lmp]: any[]) => {
      if (plans) {
        this.plans = plans.filter(plan => plan.VirtualTour || (plan.Videos && plan.Videos.length && plan.Videos[0].VideoPath));
        this.plans.map(plan => {
          if (plan.Images && plan.Images.length) {
            plan.Images = plan.Images.filter(image => image.ImageRank > 0).sort((a, b) => a.ImageRank - b.ImageRank);
          }
        });
        this.planCardModel = this.mapSitecoreLabelsToPlanCard(this.data);
        if (hers) {
          this.planCardModel.HersScore = hers;
        }
        if (lmp) {
          this.planCardModel.LmpConfiguration = lmp;
        }
        this.pushAdobeLaunchPlanListingEvent();
      }
    });
  }

  private mapSitecoreLabelsToPlanCard(sitecoreLabels: VRTourPlanModel): PlanCardModel {
    const planCardModel = new PlanCardModel();
    if (sitecoreLabels) {
      planCardModel.Series_Name = sitecoreLabels.Series_Label;
      planCardModel.Price = sitecoreLabels.Price;
      planCardModel.Bedrooms = sitecoreLabels.Bedrooms;
      planCardModel.Bathrooms = sitecoreLabels.Bathrooms;
      planCardModel.Garage = sitecoreLabels.Garage;
      planCardModel.Square_Feet = sitecoreLabels.Square_Feet;
      planCardModel.CTA_Page_Item_Ref = sitecoreLabels.CTA_Page_Item_Ref;
      planCardModel.CTA_Detail = sitecoreLabels.View_Home_Label;
      planCardModel.LMP_Starting_From = sitecoreLabels.LMP_Starting_From;
      planCardModel.Virtual_Tour_CTA_Text = sitecoreLabels.Virtual_Tour_CTA_Text;
      planCardModel.Virtual_Tour_CTA_Icon = sitecoreLabels.Virtual_Tour_CTA_Icon;
      planCardModel.Virtual_Tour_CTA_Style = sitecoreLabels.Virtual_Tour_CTA_Style;
      planCardModel.Virtual_Tour_Modal_Header_Text = sitecoreLabels.Virtual_Tour_Modal_Header_Text;
      planCardModel.Video_Tour_CTA_Text = sitecoreLabels.Video_Tour_CTA_Text;
      planCardModel.Video_Tour_CTA_Icon = sitecoreLabels.Video_Tour_CTA_Icon;
      planCardModel.Video_Tour_CTA_Style = sitecoreLabels.Video_Tour_CTA_Style;
      planCardModel.Video_Tour_Modal_Header_Text = sitecoreLabels.Video_Tour_Modal_Header_Text;
      planCardModel.Video_Tour_Modal_Caption_Placement = sitecoreLabels.Video_Tour_Modal_Caption_Placement;
    }
    return planCardModel;
  }

  // Adobe Launch Listing Analytics
  private pushAdobeLaunchPlanListingEvent() {
    if (this.plans && this.plans.length) {
      this.adobeLaunchService.pushPlanListingDisplayedEvent(this.plans);
    }
  }
}
