import { Pipe, PipeTransform } from '@angular/core';
import { ButtonStyle } from 'src/app/models/enums/dropdown-enum';
import { ButtonClass } from 'src/app/models/enums/style-enum';

@Pipe({
  name: 'buttonStyle'
})
export class ButtonStylePipe implements PipeTransform {

  transform(buttonType: string): string {
    let buttonStyle: string;

    switch (buttonType) {
      case ButtonStyle.Default:
        buttonStyle = ButtonClass.Default;
        break;
      case ButtonStyle.Link:
        buttonStyle = ButtonClass.Link;
        break;
      default:
        buttonStyle = ButtonClass.Primary;
    }

    return buttonStyle;
  }

}
