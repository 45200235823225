// Used for getting assets/resources/variables that we save per brand,
// but if we need the values/data in our component code, not just styles.
import { getBrandName } from 'src/helper-functions/brand-specific-configuration';

export function getLocalAssetUrl(path: string): string {
  return `assets/${getBrandName()}/` + path;
}

export function getLocalStyleVar(key: string): any {
  return getComputedStyle(document.body).getPropertyValue(key);
}

export function getLocalStyleVarUrl(key: string): any {
  const value = getLocalStyleVar(key);
  // regex to strip value of 'url()' to return url paths from SASS variables,
  // even ignorant of enclosing folders we may create in the future for assets
  const reg = /(?:\(['"]?)(.*?)(?:['"]?\))/;
  return reg.exec(value)[1];
}

export function convertSvgToUrl(svgHtmlString: string): string {
  return 'data:image/svg+xml;base64,' + window.btoa(svgHtmlString);
}
