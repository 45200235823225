<section *ngIf="data" class="LocalAreaMap d-flex">
  <div class="LocalAreaMap__community-poi-categories d-flex flex-column">
    <h1 class="LocalAreaMap__poi-categories-header pl-4 pt-4 pb-2">
      {{ data.Header }}
    </h1>
    <div *ngFor="let cat of communityCategories"
          class="LocalAreaMap__poi-categories-container d-flex align-items-center">
      <div class="LocalAreaMap__poi-category pl-4 py-3"
           [ngClass]="{'LocalAreaMap--poi-category-selected d-flex align-items-center': cat.isSelected,
                       'LocalAreaMap--poi-category-not-selected': !cat.isSelected}"
           attr.data-analytics-cta="{{'Local Area|' + cat?.item?.PointOfInterestName}}"
           (click)="toggleCategory(cat?.index)">
        {{ cat?.item?.PointOfInterestName | uppercase }}
        <div [ngClass]="{'LocalAreaMap--poi-category-selected__diagonal': cat.isSelected,
                         'LocalAreaMap--poi-category-not-selected__diagonal': !cat.isSelected}">
        </div>
      </div>
    </div>
    <div class="my-3 text-center" *ngIf="data.Commute_CTA_Toggle">
      <hr class="mx-3 mt-auto"/>
      <button class="btn d-inline-flex justify-content-center align-items-center px-4 mt-4 mx-3 {{data.Commute_CTA_Style | buttonStyle}}"
              [@fadeInOnEnter]="{value: '', params: {duration: 800, delay: 600 }}"
              data-analytics-cta="Map My Commute"
              (click)="openMapMyCommuteModal()">
        {{data.Commute_CTA_Label}}
      </button>
    </div>
  </div>

  <div class="LocalAreaMap__community-poi-listings pl-3 pr-2 pt-4">
    <span *ngIf="!loading && selectedCategoryPOI"
           class="LocalAreaMap__close-selected-category"
          (click)="clearSelectedPOIData()">
        <app-close-button></app-close-button>
    </span>

    <ng-container *ngIf="isDirectionsMode; then directionsInfo else poiListings"></ng-container>

    <span *ngIf="!loading && selectedCategoryPOI"
          class="LocalAreaMap__back-selected-category"
          (click)="clearSelectedPOIData()">
      <app-back-button></app-back-button>
    </span>

    <app-loading-spinner [loading]="loading" [scale]="4"></app-loading-spinner>
  </div>

  <app-ng-google-map
    [mapOptions]="mapOptions"
    (markerClicked)="markerClicked($event)"
    (mapClicked)="mapClicked($event)">
  </app-ng-google-map>
</section>

<ng-template #directionsInfo>
  <div *ngIf="isDirectionsMode && directionsRoute?.legs?.length && community && selectedCategoryPOI"
       class="LocalAreaMap__poi-directions-container pt-4 pb-0 pl-4 pr-3 d-flex flex-column">
    <span class="LocalAreaMap__poi-directions-header">
      {{data.Commute_Header_Label}}
    </span>
    <span class="LocalAreaMap__poi-directions-info pt-4">
      <span class="LocalAreaMap__poi-directions-duration">{{directionsRoute.legs[0].duration.text}}</span>
      ({{directionsRoute.legs[0].distance.text}})
    </span>
    <span *ngIf="directionsRoute.summary" class="LocalAreaMap__poi-directions-subinfo pt-1">
      via {{directionsRoute.summary}}
    </span>
    <div *ngIf="directionsRoute.legs[0].steps?.length" class="LocalAreaMap__poi-directions-route-container py-3">
      <hr class="mb-3"/>
      <div>
        from
        <span class="LocalAreaMap__poi-directions-info pl-1">{{community.Name || community.Address.Street1}}</span>
        <br/>
        <span class="LocalAreaMap__poi-directions-subinfo pb-2">{{community.Address.Street1}}, {{community.Address.City}}, {{community.Address.State}} {{community.Address.ZipCode}}</span>
      </div>
      <div class="LocalAreaMap__poi-directions-route">
        <div class="LocalAreaMap__poi-directions-steps py-2">
          <div *ngFor="let step of directionsRoute.legs[0].steps" class="LocalAreaMap__poi-directions-step pt-2 d-flex flex-column">
            <span class="LocalAreaMap__poi-directions-step-instruction pl-4" [innerHTML]="step.instructions"></span>
            <span class="LocalAreaMap__poi-directions-subinfo font-italic pl-4 pt-1">{{step.duration.text}} ({{step.distance.text}})</span>
          </div>
        </div>
      </div>
      <div class="pt-2">
        to
        <span class="LocalAreaMap__poi-directions-info pl-1">{{selectedCategoryPOI.POIName || selectedCategoryPOI.POIAddress}}</span>
        <br/>
        <span class="LocalAreaMap__poi-directions-subinfo">{{selectedCategoryPOI.POIAddress}}</span>
      </div>
      <hr class="mt-3"/>
    </div>
  </div>
  <div *ngIf="isDirectionsMode && directionsRoute?.legs?.length && community && selectedCategoryPOI" class="pl-4 pr-3 mb-5">
    <div class="d-inline-block" placement="bottom-left" container="body" ngbDropdown>
      <button class="LocalAreaMap__poi-directions-travel-time-button btn btn-dropdown justify-content-between mt-2" id="travelOptionsDropdown" ngbDropdownToggle>
        {{departureOption}}
      </button>
      <div aria-labelledby="travelOptionsDropdown" ngbDropdownMenu>
        <button (click)="updateDepartureOptions(leaveNow)" ngbDropdownItem>{{leaveNow}}</button>
        <button (click)="updateDepartureOptions(departAt)"  ngbDropdownItem>{{departAt}}</button>
      </div>
    </div>
    <div *ngIf="departureOption === departAt">
      <app-date-time-picker class="w-100" (submitSelectedDate)="setTravelTime($event)"></app-date-time-picker>
    </div>
  </div>
</ng-template>

<ng-template #poiListings>
   <span class="LocalAreaMap__poi-listings-header pt-4 pl-4 mb-1">
      {{ selectedCategory?.item?.PointOfInterestName }}
    </span>

    <div *ngIf="!loading && !selectedCategoryPOI"
         class="LocalAreaMap__poi-listings-container mt-5 py-4 px-3">
      <div *ngFor="let poi of categoryPOIs" [@fadeInOnEnter]>
        <div class=" LocalAreaMap__community-poi d-flex flex-row justify-content-center w-100 rounded"
             (click)="setSelectedCategoryPOIByLocationID(poi.POILocationID)">
          <div class="LocalAreaMap__map-marker-icon-wrapper">
            <app-community-poi-marker [label]="getPOILocationIndexFromID(poi.POILocationID)"></app-community-poi-marker>
          </div>
          <div class="LocalAreaMap__listing-info w-100 pl-2">
            <div class="LocalAreaMap__listing-info-name w-100">
              {{ poi?.POIName }}
            </div>
            <span class="LocalAreaMap__listing-info-distance w-100 d-block font-italic">
              {{ poi?.Distance }} miles
            </span>
            <span class="LocalAreaMap__listing-info-description w-100 d-block py-1">
              {{ poi?.POIAddress }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="!loading && (!categoryPOIs || categoryPOIs.length < 1)"
            class="LocalAreaMap__none-listings d-flex align-items-center justify-content-center p-3 m-2 text-center">
        There are currently no points of interest to display for this community.
      </div>
    </div>

    <div *ngIf="!loading && selectedCategoryPOI"
          class="LocalAreaMap__poi-details-container d-flex overflow-hidden flex-column mt-5 py-4 mb-5">
      <div class="d-flex flex-row justify-content-center w-100 overflow-auto">
        <div class="LocalAreaMap__details-info w-100 pl-2">
          <div class="LocalAreaMap__details-info-name w-100">
            {{ selectedPOIDetails?.Name ||  selectedCategoryPOI?.POIName || ""}}
          </div>
          <div class="d-flex flex-column mw-100">
              <div class="LocalAreaMap__details-info-ratings mw-100 d-flex flex-row align-items-center
                          justify-content-start overflow-hidden align-top">
                <div *ngIf="selectedPOIDetails?.Rating && selectedPOIDetails?.RatingsCount"
                      class="d-flex flex-row align-items-center pr-2">
                  <span class="pr-2">{{ selectedPOIDetails.Rating }}</span>
                  <app-ratings [rate]="selectedPOIDetails.Rating" [fontSize]=1.6></app-ratings>
                  <span class="pl-2">({{ selectedPOIDetails.RatingsCount }})</span>
                </div>
                <span  class="LocalAreapMap_details-info-price"
                      *ngIf="selectedPOIDetails?.Price">
                  ∙ {{ selectedPOIDetails.Price }}
                </span>
              </div>
            <span *ngIf="selectedPOIDetails?.TypeDescriptor"
                  class="LocalAreaMap__details-info-type-descriptor w-100 d-block pb-2">
              {{ selectedPOIDetails.TypeDescriptor }}
            </span>
          </div>
          <div *ngIf="selectedPOIDetails?.Description || selectedPOIDetails?.SubDescription">
            <hr/>
            <span *ngIf="selectedPOIDetails.Description"
                  class="LocalAreaMap__details-info-description w-100 d-block pt-2 pb-1 font-weight-normal">
              "{{ selectedPOIDetails.Description }}"
            </span>
            <span *ngIf="selectedPOIDetails.SubDescription"
                  class="LocalAreaMap__details-info-sub-description w-100 d-block font-weight-normal pt-1 pb-2">
              {{ selectedPOIDetails.SubDescription }}
            </span>
          </div>
          <hr/>
          <span *ngIf="selectedPOIDetails?.Address"
                 class="LocalAreaMap__details-info-address w-100 d-block pt-2">
            {{ selectedPOIDetails.Address }}
          </span>
        </div>
      </div>
      <div *ngIf="!loading && !selectedPOIDetails"
            class="LocalAreaMap__none-listings d-flex align-items-center justify-content-center p-3 m-2 text-center">
          There is currently no information to display for this point of interest.
      </div>
      <div class="my-3 text-center" *ngIf="data.Get_Directions_CTA_Toggle">
        <button class="LocalAreaMap__get-directions-button btn btn-link mt-5 ml-2 mr-auto d-flex align-items-center justify-content-center"
                (click)="setMarkersForDirections(selectedCategoryPOI)"
                [innerHTML]="data.Get_Directions_CTA_Label">
      </button>
      </div>
    </div>
</ng-template>
