import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil, concatMap } from 'rxjs/operators';
import { Plan } from 'src/app/models/product/plan.model';
import { PlanGalleryModel } from 'src/app/models/content/plan/plan-gallery.model';
import { GalleryModalModel } from 'src/app/models/content/media/gallery-modal.model';
import { BaseComponent } from '../../shared/base/base.component';

@Component({
  selector: 'app-plan-gallery',
  templateUrl: './plan-gallery.component.html',
  styleUrls: ['./plan-gallery.component.scss']
})
export class PlanGalleryComponent extends BaseComponent implements OnInit {

  @Input() data: PlanGalleryModel;
  @Input() statusOverlayText: string[];

  plan: Plan;
  hasActiveQmi: boolean = false;
  galleryModalModel: GalleryModalModel;

  constructor(private communityService: CommunityService,
              private route: ActivatedRoute) { super(); }

    ngOnInit() {
      this.route.params.pipe(
        map(routeParam => routeParam.id),
        concatMap((planId: string) => {
          return this.communityService.getPlanById(planId);
        }),
        takeUntil(this.unsubscribe$))
        .subscribe((plan: Plan) => {
          this.plan = plan;
          this.statusOverlayText = this.createStatusOverlayText();
        });

      this.galleryModalModel = new GalleryModalModel();
      this.galleryModalModel.ModalTitle = this.data.Modal_Title;
      this.galleryModalModel.ExteriorLabel = this.data.Exterior_Label;
      this.galleryModalModel.InteriorLabel = this.data.Interior_Label;
      this.galleryModalModel.IsFilterGallery = true;
  }

  private createStatusOverlayText() {
    if (this.plan && this.data) {
      const overlayLabels = [];
      if (this.plan.InventoryCount > 0 && this.data.QMI_Availability) {
        overlayLabels.push(this.data.QMI_Availability);
      }
      if (this.plan.ModelExists && this.data.Plan_Model_Open) {
        overlayLabels.push(this.data.Plan_Model_Open);
      }
      if (this.plan.IsNewPlan && this.data.Plan_New_Plan && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_New_Plan);
      }
      if (this.plan.IsFutureRelease && this.data.Plan_Future_Release && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_Future_Release);
      }
      if (this.plan.QuickMoveInOnlyFlag && this.data.Plan_QMI_Only && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_QMI_Only);
      }
      if (this.plan.NewModelFlag && this.data.Plan_New_Model && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_New_Model);
      }
      if (this.plan.IsSoldOut && this.data.Plan_Sold_Out && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_Sold_Out);
      }
      if (this.plan.LastHomeDesignFlag && this.data.Plan_Last_Home_Design && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_Last_Home_Design);
      }
      if (this.plan.LastChanceFlag && this.data.Plan_Last_Chance && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_Last_Chance);
      }
      if (this.plan.ModelForSaleFlag && this.data.Plan_Model_For_Sale && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_Model_For_Sale);
      }
      if (this.plan.AlmostSoldOutFlag && this.data.Plan_Almost_Sold_Out && overlayLabels.length < 2) {
        overlayLabels.push(this.data.Plan_Almost_Sold_Out);
      }
      return overlayLabels.length > 0 ? overlayLabels : undefined;
    }
    return undefined;
  }
}
