import { Component, OnInit, Input } from '@angular/core';
import { ArticleCardModel } from 'src/app/models/content/editorial/card.model';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss']
})

export class ArticleCardComponent implements OnInit {

  @Input() data: ArticleCardModel;

  constructor() { }

  ngOnInit() {
  }

}
