import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HersScoreModel } from 'src/app/models/hers-score-configuration.model';
import { Plan } from 'src/app/models/product/plan.model';
import { Community } from 'src/app/models/product/community.model';
import { CommunityService } from 'src/app/core-services/community.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-hers-score-modal',
  templateUrl: './hers-score-modal.component.html',
  styleUrls: ['./hers-score-modal.component.scss']
})
export class HersScoreModalComponent extends BaseComponent implements OnInit {

  @Input() HersScore: HersScoreModel;
  @Input() plan: Plan;
  community: Community;

  constructor(public activeModal: NgbActiveModal,
              private communityService: CommunityService) { super(); }

  ngOnInit() {
    this.HersScore = this.hersPropertySetup(this.HersScore, this.plan);
    this.communityService.community
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((community: Community) => {
      this.community = community;
    });
  }

  private hersPropertySetup(hersScore: HersScoreModel, plan: Plan) {
    if (plan && hersScore && this.checkHERSCost()) {
      hersScore.HasSavings = true;
      this.setHERSScoreDataFromPlan();
    } else {
      hersScore.HasSavings = false;
    }
    return hersScore;
  }

  private setHERSScoreDataFromPlan() {
    this.HersScore.Hers_Savings_1 = this.plan.HERSEstimatedAnnualCostsRowOne;
    this.HersScore.Hers_Savings_2 = this.plan.HERSEstimatedAnnualCostsRowTwo;
    this.HersScore.Hers_Savings_3 = this.plan.HERSEstimatedAnnualCostsRowThree;
    this.HersScore.Hers_Savings_4 = this.plan.HERSEstimatedAnnualCostsRowFour;
    this.HersScore.Hers_Savings_5 = this.plan.HERSEstimatedAnnualCostsRowFive;

    this.HersScore.Hers_Difference_2 = this.plan.HERSEstimatedAnnualCostsRowTwo - this.plan.HERSEstimatedAnnualCostsRowOne;
    this.HersScore.Hers_Difference_3 = this.plan.HERSEstimatedAnnualCostsRowThree - this.plan.HERSEstimatedAnnualCostsRowOne;
    this.HersScore.Hers_Difference_4 = this.plan.HERSEstimatedAnnualCostsRowFour - this.plan.HERSEstimatedAnnualCostsRowOne;
    this.HersScore.Hers_Difference_5 = this.plan.HERSEstimatedAnnualCostsRowFive - this.plan.HERSEstimatedAnnualCostsRowOne;
  }

  private checkHERSCost(): boolean {
    if (this.plan.HERSEstimatedAnnualCostsRowOne > 0 &&
      this.plan.HERSEstimatedAnnualCostsRowTwo > 0 &&
      this.plan.HERSEstimatedAnnualCostsRowThree > 0 &&
      this.plan.HERSEstimatedAnnualCostsRowFour > 0 &&
      this.plan.HERSEstimatedAnnualCostsRowFive > 0) {
        return true;
      }
  }
}
