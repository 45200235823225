import { Injectable } from '@angular/core';
import { CrossBrandConfiguration } from '../models/cross-brand-configuration.model';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrossBrandService {

  crossBrandConfig = new ReplaySubject<CrossBrandConfiguration>(1);

  updateCrossBrandConfiguration(crossBrandConfig: CrossBrandConfiguration): void {
    this.crossBrandConfig.next(crossBrandConfig);
  }
}
