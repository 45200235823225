import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Page } from '../models/page.model';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  pages = new ReplaySubject<Record<string, Page>>(1);
  hasSummary = new ReplaySubject<boolean>(1);
  hasFixedHeader = new ReplaySubject<boolean>(1);
  navBarPageId = new Subject<string>();


  updatePages(pages: Record<string, Page>): void {
    this.pages.next(pages);
  }

  updateHasSummary(hasSummary: boolean): void {
    this.hasSummary.next(hasSummary);
    this.hasSummary.complete();
  }

  updateHasFixedHeader(hasFixedHeader: boolean): void {
    this.hasFixedHeader.next(hasFixedHeader);
    this.hasFixedHeader.complete();
  }

  updateNavBarSelection(NavBarPageId: string): void {
    this.navBarPageId.next(NavBarPageId);
  }
}
