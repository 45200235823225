import { Component, OnInit, Input } from '@angular/core';
import { TileCardModel } from 'src/app/models/content/editorial/card.model';

@Component({
  selector: 'app-tile-card',
  templateUrl: './tile-card.component.html',
  styleUrls: ['./tile-card.component.scss']
})
export class TileCardComponent implements OnInit {

  @Input() data: TileCardModel;

  constructor() { }

  ngOnInit() {
  }

}
