export class CommunityPOICategory {
  PointOfInterestID: number;
  PointOfInterestName: string;
  SourceType: string;
}

export class CommunityPOICategoryModel {
  item: CommunityPOICategory;
  isSelected: boolean;
  index: number;

  constructor(communityPOICategory, index) {
    this.item = communityPOICategory;
    this.isSelected = false;
    this.index = index;
  }
}
