import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptInjectorService {

  constructor(@Inject(DOCUMENT) private document: Document, private zone: NgZone) {}

  async load(id: string, src: string) {
    if (src) {
      // Init new script tag element to inject.
      const scriptElement: HTMLScriptElement = this.document.createElement('script');
      scriptElement.id = id;
      scriptElement.src = src;
      scriptElement.async = true;

      const promise = new Promise<void>((resolve, reject) => {
        scriptElement.addEventListener('load', () => {
          setTimeout(resolve, 10);
        });
        scriptElement.addEventListener('error', (err) => {
          reject(err);
        });
      });

      // Run outside the angular zone, i.e. won't fire change detection.
      this.zone.runOutsideAngular(() => {
        // Add new script element object to document HEAD
        this.document.head.appendChild(scriptElement);
      });

      return promise;
    }
  }
}
