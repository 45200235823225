import { Injectable } from '@angular/core';
import { AppInsights, Microsoft } from 'applicationinsights-js';
import { SettingsService } from './settings.service';

// methods checking for instrumentationKey are logging to console instead if one does not exist. This is intended for local logging
@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  constructor(private settingsService: SettingsService) {
    // only instantiate app insights if the instrumentationKey exists
    if (!AppInsights.config && this.settingsService.applicationInsights.instrumentationKey) {
      AppInsights.downloadAndSetup({
        instrumentationKey: this.settingsService.applicationInsights.instrumentationKey
      });
      AppInsights.queue.push(() => {
        AppInsights.context.addTelemetryInitializer(this.customTelemetry);
      });
    }
  }

  logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
    if (this.settingsService.applicationInsights.instrumentationKey) {
      this.setUser();
      AppInsights.trackPageView(name, url, properties, measurements, duration);
    } else {
      // name and url are the same at this time so only logging url
      console.log(url, properties, measurements, duration);
    }
  }

  logEvent(name: string, properties?: any, measurements?: any) {
    if (this.settingsService.applicationInsights.instrumentationKey) {
      this.setUser();
      AppInsights.trackEvent(name, properties, measurements);
    } else {
      console.log(name, properties, measurements);
    }
  }

  logException(exception: Error, isHttpErrorResponse: boolean, handledAt?: string, properties?: any, measurements?: any) {
    if (this.settingsService.applicationInsights.instrumentationKey) {
      this.setUser();
      AppInsights.trackException(exception, handledAt, properties, measurements);

      // log the error to the console if its not an http error response
      if (!isHttpErrorResponse) {
        console.error(exception);
      }
    } else {
      console.group(exception.name);
      console.error(exception.message);
      console.error(exception.stack);
      console.warn(handledAt);
      console.groupEnd();
    }
  }

  logTrace(message: string, properties?: any, severityLevel?: any) {
    if (this.settingsService.applicationInsights.instrumentationKey) {
      this.setUser();
      AppInsights.trackTrace(message, properties, severityLevel);
    } else {
      console.log(message, properties, severityLevel);
    }
  }

  // No authentication in place currently
  setUser() {
    AppInsights.clearAuthenticatedUserContext();
  }

  customTelemetry(envelope: any) {
    const item = envelope.data.baseData;
    item.properties = item.properties || {};
    item.properties.Role = 'KIOSK';
  }

}
