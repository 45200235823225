import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil, concatMap, takeWhile } from 'rxjs/operators';
import { Plan } from 'src/app/models/product/plan.model';
import { PlanFeaturesModel } from 'src/app/models/content/plan/plan-features.model';
import { InteractiveToolsModel } from 'src/app/models/interactive-tools-configuration.model';
import { PlanToolsModalComponent } from '../../shared/modals/plan-tools-modal/plan-tools-modal.component';
import { Image } from 'src/app/models/product/image.model';
import { PlanFeaturesModalModel } from 'src/app/models/content/plan/plan-features-modal.model';
import { PlanFeaturesModalComponent } from '../../shared/modals/plan-features-modal/plan-features-modal.component';
import { forkJoin } from 'rxjs';
import { ModalClasses } from '../../../shared/modal-classes';
import { PagesService } from 'src/app/core-services/pages.service';
import { InteractiveToolsService } from 'src/app/core-services/interactive-tool.service';
import { BaseComponent } from '../../shared/base/base.component';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-plan-features',
  templateUrl: './plan-features.component.html',
  styleUrls: ['./plan-features.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800 })
  ]
})
export class PlanFeaturesComponent extends BaseComponent implements OnInit {

  @Input() data: PlanFeaturesModel;

  interactiveTools: InteractiveToolsModel;
  plan: Plan;
  modalClass: string;
  hasInteractiveTool: boolean = false;
  hasFeatures: boolean = false;

  planFeaturesModal: PlanFeaturesModalModel = new PlanFeaturesModalModel();

  constructor(private communityService: CommunityService, private route: ActivatedRoute,
              private modalService: NgbModal, private pageService: PagesService,
              private interactiveToolsService: InteractiveToolsService) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      concatMap((planId: string) => {
        const observables = [ this.communityService.getPlanInfoById(planId),
                              this.pageService.hasSummary,
                              this.interactiveToolsService.InteractiveToolsItem
                              ];
        return forkJoin(observables);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((response: any[]) => {
      this.plan = response[0];
      const hasSummary = response[1];
      this.interactiveTools = response[2];
      if (this.plan != null) {
        if (this.interactiveTools != null && (this.plan.InteractiveFloorPlanUrl || this.plan.ExteriorDesignUrl ||
            this.plan.DesignCenterUrl || this.plan.RoomDesignerUrl || this.plan.EnvisionUrl)) {
              if (this.plan.InteractiveFloorPlanUrl) {
                this.interactiveTools.Floor_Plan_Url = this.plan.InteractiveFloorPlanUrl;
              }

              if (this.plan.ExteriorDesignUrl) {
                this.interactiveTools.Exterior_Options_Url = this.plan.ExteriorDesignUrl;
              }

              if (this.plan.DesignCenterUrl) {
                this.interactiveTools.Furniture_Planner_Url = this.plan.DesignCenterUrl;
              }

              if (this.plan.RoomDesignerUrl) {
                this.interactiveTools.Room_Designer_Url = this.plan.RoomDesignerUrl;
              }

              if (this.plan.EnvisionUrl) {
                this.interactiveTools.Finishing_Options_Url = this.plan.EnvisionUrl;
              }
              this.hasInteractiveTool = true;
        }

        this.planFeaturesModal.Features = this.plan.Features;
        this.planFeaturesModal.MetaDescription = this.plan.MetaDescription;
        this.planFeaturesModal.hasSummary = hasSummary;

        if (this.planFeaturesModal && this.planFeaturesModal.Features && this.planFeaturesModal.Features.length) {
          this.hasFeatures = true;
          if (this.plan.Images && this.plan.Images.length) {
            this.getPlanFeatureImages(this.plan.Images);
          }
        }
      }

      this.modalClass = hasSummary ? ModalClasses.Summary : '';
    });
  }

  openPlanTools(): void {
    const modalRef: NgbModalRef = this.modalService.open(PlanToolsModalComponent,
      { windowClass: `${ModalClasses.Full} ${this.modalClass}`, backdrop: false });
    modalRef.componentInstance.interactiveTools = this.interactiveTools;
  }

  openPlanFeaturesModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(PlanFeaturesModalComponent, {
      windowClass: `${ModalClasses.Full} ${ModalClasses.WhiteBackground} ${this.modalClass}`, backdrop: false });
    modalRef.componentInstance.planFeaturesModal = this.planFeaturesModal;
  }

  private getPlanFeatureImages(images: Image[]): void {
    const featureImages = images.filter(image => image.EmbraceYourSpaceRank > 0)
    .sort((a, b) => a.EmbraceYourSpaceRank - b.EmbraceYourSpaceRank);
    if (featureImages && featureImages.length === 3) {
      this.planFeaturesModal.EmbraceYourSpaceImages = featureImages;
    } else {
      this.planFeaturesModal.PlanGalleryImage = images.sort((a, b) => a.ImageRank - b.ImageRank).find(image => image.ImageRank);
    }
  }
}
