import { Directive, OnDestroy, OnInit } from '@angular/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { GoogleDirectionsRouteData } from 'src/app/models/maps/google-directions-route-data.model';
import { AppInsightsService } from 'src/app/core-services/app-insights.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GmapsDirectionsService } from 'src/app/core-services/gmaps-directions.service';
import { Subject } from 'rxjs';

// This will keep typescript from throwing errors with respect to the google object
declare var google: any;

@Directive({
  selector: '[appDirectionsMap]'
})
export class GMapsDirectionsDirective implements OnInit, OnDestroy {

  unsubscribe$ = new Subject();

  origin: google.maps.LatLng;
  destination: google.maps.LatLng;
  showDirection: boolean;
  travelTime: Date = new Date();
  directionsRouteData: GoogleDirectionsRouteData;

  // We'll keep a single google maps directions renderer instance so we get to reuse it.
  // using a new renderer instance every time will leave the previous one still active and visible on the page
  private directionsRenderer: any;

  // We inject AGM's google maps api wrapper that handles the communication with the Google Maps Javascript
  constructor(private gmapsAPI: GoogleMapsAPIWrapper,
              private appInsightsService: AppInsightsService,
              private gmapsDirectionsService: GmapsDirectionsService) { }

  ngOnInit() {
    this.gmapsDirectionsService.origin$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(origin => {
        this.origin = origin;
    });

    this.gmapsDirectionsService.destination$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(destination => {
        this.redrawRouteWithDestination(destination);
    });

    this.gmapsDirectionsService.showDirection$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(showDirection => {
      this.showDirection = showDirection;
      if (!this.showDirection) {
        this.clearRoute();
      }
    });

    this.gmapsDirectionsService.travelTime$
    .pipe(
      debounceTime(600),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$))
    .subscribe(travelTime => {
      if (travelTime) {
        this.setTravelTime(travelTime);
        this.clearRoute();
        this.drawDirectionsRoute();
      } else {
        this.travelTime = null;
      }
    });

    this.drawDirectionsRoute();
  }

  drawDirectionsRoute() {
    this.gmapsAPI.getNativeMap().then(map => {

      // Init directions route renderer
      if (!this.directionsRenderer) {
        this.directionsRenderer = this.getNewDirectionsRenderer();
      }

      if (this.showDirection && this.destination) {
        const directionsService = new google.maps.DirectionsService();
        this.directionsRenderer.setMap(map);

        // Get Route Options, Data, Origin, and Destination and pass to the directions service API.
        directionsService.route(this.getDirectionsRouteOptions(), (response, status) => {
          if (response && status === 'OK') {

            this.directionsRenderer.setDirections(response);

            // Parse route data from successful response and emits back out of directive.
            if (response.routes && response.routes.length) {
              const midwayPointLatLng = this.getMidWayLatLngFromRoute(response.routes[0]); // Used for Info Window position
              const responseRouteData = new GoogleDirectionsRouteData(response.routes[0], midwayPointLatLng);
              this.gmapsDirectionsService.updateDirectionsRouteData(responseRouteData);
            }
          } else {
            this.appInsightsService.logException(
              new Error(`An error occurred for Google Places Service request: ${status}`), false);
            this.gmapsDirectionsService.updateDirectionsRouteData(null);
          }
        });
      }
    });
  }

  redrawRouteWithDestination(newDestination: google.maps.LatLng) {
    if (newDestination && newDestination !== this.destination && this.showDirection) {
      this.destination = newDestination;
      this.drawDirectionsRoute();
    } else {
      this.clearRoute();
    }
  }

  clearRoute() {
    if (this.directionsRenderer) {
      this.directionsRenderer.setDirections({ routes: [] });
      return;
    }
  }

  getNewDirectionsRenderer() {
    return new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      draggable: false,
      polylineOptions: {
        strokeColor: '#679DF6',
        strokeWeight: 7,
        zIndex: 9999999
      }
    });
  }

  getDirectionsRouteOptions() {
    return {
      origin: { lat: this.origin.lat(), lng: this.origin.lng() },
      destination: { lat: this.destination.lat(), lng: this.destination.lng() },
      waypoints: [],
      provideRouteAlternatives: false,
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      drivingOptions: {
        departureTime: this.travelTime || new Date(), // TODO: Pass in a departure time for future story.
        trafficModel: 'bestguess'
      }
    };
  }

  getMidWayLatLngFromRoute(route: google.maps.DirectionsRoute) {
    const midwayPoint = route.overview_path.length / 2;
    // tslint:disable-next-line: no-bitwise
    return route.overview_path[midwayPoint | 0];
  }

  setTravelTime(travelTime: Date) {
    this.travelTime = travelTime;
    const currentTime = new Date();
    if (this.travelTime && this.travelTime.getTime() < currentTime.getTime()) {
      this.travelTime = new Date(this.travelTime);
      this.travelTime.setDate(this.travelTime.getDate() + 7);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
