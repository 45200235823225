import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FormValidationConfigurationModel } from '../models/form-validation-configuration';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  formValidationConfiguration = new ReplaySubject<FormValidationConfigurationModel>(1);

  constructor() { }

  updateFormValidationConfiguration(formValidationConfiguration: FormValidationConfigurationModel) {
    this.formValidationConfiguration.next(formValidationConfiguration);
    this.formValidationConfiguration.complete();
  }
}
