<section *ngIf="data && (community$ | async) as community"
         class="CommunitySummary text-center py-3 fixed-bottom d-flex justify-content-center align-items-center"
         [@fadeInUpOnEnter]>
  <div [ngClass]="community.Name.length > 25 ? 'col-4' : 'col-2'">
    <div class="CommunitySummary__community-name">{{community.Name}}</div>
  </div>
  <div *ngIf="community.Address" class="CommunitySummary__address col-auto">
    <div>{{community.Address.Street1}}</div>
    <div>{{community.Address.City}}, {{community.Address.State}} {{community.Address.ZipCode}}</div>
  </div>
  <div class="d-flex justify-content-center align-items-center mr-5">
    <div class="col-auto mx-2">
      <div class="CommunitySummary__value">{{community.StartingFromPrice | currency: 'USD':'symbol':'1.0-0'}}</div>
      <div class="CommunitySummary__label">{{data.Price}}</div>
    </div>
    <div class="col-auto mx-2">
      <div class="CommunitySummary__value">{{community.MinBedrooms | range : community.MaxBedrooms}}</div>
      <div class="CommunitySummary__label">{{data.Bedrooms}}</div>
    </div>
    <div class="col-auto mx-2">
      <div class="CommunitySummary__value">{{community.MinBathrooms | range : community.MaxBathrooms}}</div>
      <div class="CommunitySummary__label">{{data.Bathrooms}}</div>
    </div>
    <div class="col-auto ml-2 mr-5">
      <div class="CommunitySummary__value">{{community.MinGarages | range : community.MaxGarages}}</div>
      <div class="CommunitySummary__label">{{data.Garage}}</div>
    </div>
  </div>
  <app-request-brochure-cta [ctaData]="data" [@fadeInOnEnter]></app-request-brochure-cta>
  <div class="col-auto CommunitySummary__back pl-3">
    <span class="CommunitySummary__back-button" (click)='backButton()'><app-back-button></app-back-button></span>
  </div>
</section>
