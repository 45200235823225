import { Image } from 'src/app/models/product/image.model';
import { LatLng } from '@agm/core';

export class GMapsOptionsModel {
  public InitialLatitude: number;
  public InitialLongitude: number;
  public Zoom: number;
  public FullscreenControl: boolean;
  public ZoomControl: boolean;
  public StreetViewControl: boolean;
  public ClickableIcons: boolean;
  public MaxZoom: number;
  public MinZoom: number;
  public MapTypeControl: boolean;
  public MapType: string;

  constructor() {
    this.InitialLatitude = 33.7490;
    this.InitialLongitude = 84.3880;
    this.Zoom = 15;
    this.FullscreenControl = true;
    this.ZoomControl = true;
    this.StreetViewControl = false;
    this.ClickableIcons = false;
    this.MaxZoom = 24;
    this.MinZoom = 2;
    this.MapTypeControl = true;
    this.MapType = google.maps.MapTypeId.ROADMAP;
  }
}
