<div *ngIf="series && data" class="card__container card__container--series">
  <div *ngIf="series.SeriesImage">
    <cl-image
      class="w-100"
      public-id="{{series.SeriesImage}}"
      format="auto"
      quality="auto"
      width="auto"
      type="fetch"
      crop="fill"
      [attr.aspect-ratio]="1.5"></cl-image>
  </div>
  <h1 *ngIf="series.SeriesName" class="card__header card__header--series">{{data.Series_Name | mergeField: '{series}':series.SeriesName}}</h1>
  <div class="container card__grid-container">
    <div class="col-12 card__grid-item--series" *ngIf="series.SeriesDescription">
      {{series.SeriesDescription}}
    </div>
    <div *ngIf="startingPrice" class="col-12 card__grid-item">
      <div class="card__grid-item-value-price">
        {{startingPrice | currency: "USD":"symbol":"1.0-0" }}
        <br>
      </div>
      <div class="card__grid-item-text-price pb-0">{{data.Series_Price}}</div>
    </div>
    <div class="col-6 card__grid-item" *ngIf="series.MinBedrooms">
      <div class="card__grid-item-value">{{series.MinBedrooms | range: series.MaxBedrooms}}<br></div>
      <div class="card__grid-item-text">{{data.Series_Bedrooms}}</div>
    </div>
    <div class="col-6 card__grid-item" *ngIf="series.MinBathrooms">
      <div class="card__grid-item-value">{{series.MinBathrooms | range: series.MaxBathrooms}}<br></div>
      <div class="card__grid-item-text">{{data.Series_Bathrooms}}</div>
    </div>
    <div class="col-6 card__grid-item" *ngIf="series.MinGarages">
      <div class="card__grid-item-value">{{series.MinGarages | range: series.MaxGarages}}<br></div>
      <div class="card__grid-item-text">{{data.Series_Garage}}</div>
    </div>
    <div class="col-6 card__grid-item" *ngIf="series.MinSquareFeet">
      <div class="card__grid-item-value">{{series.MinSquareFeet | range: series.MaxSquareFeet}}<br></div>
      <div class="card__grid-item-text">{{data.Series_Square_Feet}}</div>
    </div>
  </div>
  <div *ngIf="series.SeriesID" class="card__button-container card__button-container--series">
    <a class="btn-card btn btn-primary" (click)="onButtonClick(series.SeriesID)">{{data.Series_CTA_Detail}}</a>
  </div>
</div>
