<div class="RequestBrochureModal d-flex flex-column pt-2" [@fadeInOnEnter]>

  <div class="RequestBrochureModal__header modal-header pb-0">
    <h3 class="modal-title"></h3>
    <span class="modal-action text-white" (click)="activeModal.close()"><app-close-button [isWhiteIcon]="true"></app-close-button></span>
  </div>

  <ng-container *ngTemplateOutlet="formSubmitted ? formSubmittedView : brochureSelect"></ng-container>
</div>

<ng-template #brochureSelect>
  <form class="RequestBrochureModal__body modal-body row pt-0 mx-1 text-center"
        #requestBrochureForm="ngForm" id="requestBrochureForm" name="requestBrochureForm">

    <h3 class="modal-title text-white mx-auto font-weight-light">
      {{requestBrochureModal.Get_Brochure_Header}}
    </h3>
    <div class="text-white mx-auto">
      {{requestBrochureModal.Get_Brochure_Description}}
    </div>

    <hr class="w-100 my-3" />

    <div class="RequestBrochureModal__plan-list w-100 px-2 text-white overflow-y-auto">

      <div *ngIf="series?.length">
        <div *ngFor='let seriesItem of series'>
          <div class="mx-auto text-left pl-3 font-weight-light">
            <h4 class="pl-3">{{seriesItem.SeriesName}}</h4>
          </div>
          <div *ngIf="plans?.length">
            <div class="mx-auto">{{requestBrochureModal.Plans_Subheader}}</div>
            <div *ngFor='let plan of plans' class="px-3">
              <div *ngIf='plan.SeriesRank === seriesItem.SeriesRank'>
                <ng-container *ngTemplateOutlet="planRow; context: {plan:plan}"></ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="qmis?.length">
            <div class="mx-auto">{{requestBrochureModal.Quick_Move_Ins_Subheader}}</div>
            <div *ngFor='let qmi of qmis' class="px-3">
              <div *ngIf='qmi.Plan.SeriesRank === seriesItem.SeriesRank'>
                <ng-container *ngTemplateOutlet="qmiRow; context: {qmi:qmi}"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='plansWithoutSeries?.length'>
        <hr *ngIf="series?.length" class="w-75 mt-4 mb-3 mx-auto" />
        <div class="mx-auto">{{requestBrochureModal.Plans_Subheader}}</div>
        <div *ngFor='let plan of plansWithoutSeries' class="px-3">
          <ng-container *ngTemplateOutlet="planRow; context: {plan:plan}"></ng-container>
        </div>
      </div>
      <div *ngIf='qmisWithoutSeries?.length'>
        <hr *ngIf="series?.length && !plansWithoutSeries?.length" class="w-75 mt-4 mb-3 mx-auto" />
        <div class="mx-auto">{{requestBrochureModal.Quick_Move_Ins_Subheader}}</div>
        <div *ngFor='let qmi of qmisWithoutSeries' class="px-3">
          <ng-container *ngTemplateOutlet="qmiRow; context: {qmi:qmi}"></ng-container>
        </div>
      </div>
    </div>

    <hr class="w-100 my-3" />

    <input id="email" name="email" type="email" #brochureEmail #email="ngModel"
           [(ngModel)]="emailAddress"
           class="RequestBrochureModal__input-email w-100 form-input input rounded mx-4"
           [ngClass]="{'form-input-error': email.touched && email.invalid}"
           required
           placeholder={{requestBrochureModal.Email_Address_Placeholder_Text}}
           pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
  </form>

  <div class="RequestBrochureModal__keyboard-container w-100 mt-auto px-3 pb-3">
    <app-form-keyboard [suggestions]="emailDomains"
                       (formSubmitted)="submitForm($event)"
                       [form]="requestBrochureForm || null">
    </app-form-keyboard>
  </div>

  <div class="back-wrapper modal-footer"></div>
</ng-template>

<ng-template #planRow let-plan="plan">
  <div class="d-flex align-items-center font-italic my-3 pl-3">
    <input type="checkbox" class="form-checkbox RequestBrochureModal__input-checkbox" name="plan.PlanName"
            [checked]='currentPlanData?.Id === plan.Id'
            (change)="planChecked($event, plan)" />
    <div class="pl-2 d-flex">
      {{plan.PlanName}}
      <div *ngIf='currentPlanData?.Id === plan.Id' class="pl-2">
        {{requestBrochureModal.Current_Plan_Indicator}}
      </div>
    </div>
    <div class="ml-auto">
      {{requestBrochureModal.Starting_From_Price}} {{plan.Price | currency: 'USD':'symbol':'1.0-0'}}
    </div>
  </div>
</ng-template>

<ng-template #qmiRow let-qmi="qmi">
  <div class="d-flex align-items-center font-italic my-3 pl-3">
    <input type="checkbox" class="form-checkbox RequestBrochureModal__input-checkbox" name="qmi.PlanName"
            [checked]='currentQmiData?.InventoryHomeID === qmi.InventoryHomeID'
            (change)="qmiChecked($event, qmi)" />
    <div class="pl-2 d-flex">
      {{qmi.PlanName}} (Lot #{{qmi.LotBlock}})
      <div *ngIf='currentQmiData?.InventoryHomeID === qmi.InventoryHomeID' class="pl-2">
        {{requestBrochureModal.Current_Plan_Indicator}}
      </div>
    </div>
    <div class="ml-auto">
      {{requestBrochureModal.Starting_From_Price}} {{(qmi.PriceDiscount ? qmi.FinalPrice : qmi.Price) | currency: 'USD':'symbol':'1.0-0'}}
    </div>
  </div>
</ng-template>

<ng-template #formSubmittedView>
  <div class="d-flex flex-column align-items-center justify-content-center h-100 w-100 px-5 pb-5">
    <app-loading-spinner [loading]="loading" [scale]="4"></app-loading-spinner>

    <div *ngIf="formSubmittedResponse?.isSuccessful">
      <div *ngIf="!requestBrochureModal.Submission_Icon"
            class="RequestBrochureModal__sent-icon">
      </div>
      <cl-image *ngIf="requestBrochureModal.Submission_Icon"
                 public-id="{{requestBrochureModal.Submission_Icon.Src}}"
                 format="auto"
                 quality="auto"
                 type="fetch"
                 crop="fill"
                 [attr.width]="100"
                 [attr.height]="100"
                 [attr.alt]="requestBrochureModal.Submission_Icon.Src || ''"
                 [attr.aspect-ratio]="1">
      </cl-image>
    </div>

    <div *ngIf="!loading" class="text-center mx-auto">
      <h3 class="modal-title text-white font-weight-light">
        {{formSubmittedResponse?.isSuccessful ? requestBrochureModal.Submission_Header : requestBrochureModal.Exception_Header}}
      </h3>

      <h5 class="pt-3 text-white font-weight-light"
          [innerHTML]="formSubmittedResponse?.isSuccessful ? requestBrochureModal.Submission_Description : requestBrochureModal.Exception_Description">
      </h5>
    </div>
  </div>
</ng-template>
