import { KioskImage } from '../media/kiosk-image.model';
import { Community } from '../../product/community.model';
import { Plan } from '../../product/plan.model';

export class RequestBrochureParams {
    CommunityId: string;
    PlanIds: string[];
    QmiIds: string[];
    EmailAddress: string;
}
