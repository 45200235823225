<div *ngIf="galleryModalModel" class="FilterGalleryModal">
  <div class="modal-header">
    <h4 class="modal-title">{{galleryModalModel.ModalTitle}}</h4>
    <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
  </div>
  <div class="modal-body row">
    <div class="col-3"></div>
    <div class="col-6">
      <app-carousel *ngIf="exteriorCarouselOptions && exteriorCarouselOptions.HasImages" [carouselOptions]="exteriorCarouselOptions" [ngClass]="{'d-none': selectedImageType !== 'Exterior'}"></app-carousel>
      <app-carousel *ngIf="interiorCarouselOptions && interiorCarouselOptions.HasImages" [carouselOptions]="interiorCarouselOptions" [ngClass]="{'d-none': selectedImageType !== 'Interior'}"></app-carousel>
    </div>
    <div *ngIf="galleryModalModel && displayControls" class="FilterGalleryModal__controls col-2">
      <div *ngIf="galleryModalModel.ExteriorLabel" class="d-flex align-items-center my-5 px-2" (click)="selectImageType('Exterior')">
        <span [ngClass]="{'d-none': selectedImageType !== 'Exterior'}" class="FilterGalleryModal__arrow"></span>
        <span [ngClass]="{'FilterGalleryModal__selected-image-type': selectedImageType === 'Exterior'}" class="FilterGalleryModal__image-type">
          {{galleryModalModel.ExteriorLabel | uppercase}}
        </span>
      </div>
      <div *ngIf="galleryModalModel.InteriorLabel" class="d-flex align-items-center my-5 px-2" (click)="selectImageType('Interior')">
        <span [ngClass]="{'d-none': selectedImageType !== 'Interior'}" class="FilterGalleryModal__arrow"></span>
        <span [ngClass]="{'FilterGalleryModal__selected-image-type': selectedImageType === 'Interior'}" class="FilterGalleryModal__image-type">
          {{galleryModalModel.InteriorLabel | uppercase}}
        </span>
      </div>
    </div>
  </div>
  <div class="back-wrapper modal-footer">
    <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
  </div>
</div>
