

<form *ngIf="legendData" class="lotMapLegend position-absolute lotMapLegend--rounded"
      [ngClass]="{'lotMapLegend--lot': selectedLot,
      'lotMapLegend--expand': selectedPlan,
      'lotMapLegend--amenity': selectedAmenity}"
      id="refine-search">
    <div *ngIf="!selectedPlan && !selectedLot && !selectedAmenity" class="lotMapLegend__item-wrapper my-3">
      <ng-container *ngIf="lotFilterStatuses?.length; then legendStatuses" ></ng-container>
      <hr *ngIf="!isEmptyFacingFilters"/>
      <ng-container *ngIf="!isEmptyFacingFilters; then legendFacings" ></ng-container>
      <hr *ngIf="!isEmptyTypeFilters"/>
      <ng-container *ngIf="!isEmptyTypeFilters; then legendTypes" ></ng-container>
    </div>
    <ng-container *ngIf="selectedAmenity; then amenityLegend else homesLegend" ></ng-container>
    <div *ngIf="showBackButton">
      <hr/>
      <div class="lotMapLegend__back-button-wrapper" (click)='backButton()'>
          <span class="lotMapLegend__back-button h-100 d-flex align-items-center pl-3 pb-2 w-100 text-left"><app-back-button></app-back-button></span>
      </div>
    </div>
</form>

<ng-template #legendStatuses>
  <div class="mx-4">
    <div (click)="toggleStatus()" class="cursor-pointer d-flex flex-row align-items-center justify-content-center">
      <div class="mt-1 mr-auto lotMapLegend__item-wrapper"
          [ngClass]="{'mr-5' :  !isCollapsedStatus && (selectedAmenity || selectedLot || selectedPlan)}">
        <h6>{{legendData.Home_Status_Filter}}</h6>
      </div>

      <div class="lotMapLegend__legend-arrow p-3 mt-2"
          [ngClass]="{'lotMapLegend__legend-arrow-down  mb-1': !isCollapsedStatus, 'mt-2' : isCollapsedStatus}">
      </div>
    </div>
    <div *ngIf="!isCollapsedStatus" class="mb-4">
      <div *ngFor="let item of lotFilterStatuses" class="d-flex flex-row align-items-center my-2">
        <div *ngIf="item && item?.name" class="lotMapLegend__checkbox-input-wrapper d-flex">
          <input
            type="checkbox"
            class="form-checkbox"
            name="{{item.name}}"
            [(ngModel)]="item.checked.value"
            (change)="updateMapStatus()"
          />
        </div>
        <div [ngClass]="'lotMapLegend__dot lotMapLegend__status-color--' + item.className"></div>
        <span class="lotMapLegend__text pl-2 position-relative">{{item.label}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #legendFacings>
  <div class="mx-4">
    <div (click)="toggleFacing()" class="cursor-pointer d-flex flex-row align-items-center justify-content-center">
      <div class="mt-1 mr-auto"
          [ngClass]="{'mr-4' : !isCollapsedFacing, 'mr-5' :  !isCollapsedFacing && (selectedAmenity || selectedLot || selectedPlan)}">
          <h6>{{legendData.Lot_Direction_Filter}}</h6>
      </div>
      <div class="lotMapLegend__legend-arrow p-3 mt-2"
          [ngClass]="{'lotMapLegend__legend-arrow-down  mb-1': !isCollapsedFacing, 'mt-2' : isCollapsedFacing}"></div>
    </div>
    <div *ngIf="!isCollapsedFacing" class="mb-4">
      <div *ngFor="let facing of lotFilterFacings" class="d-flex flex-row align-items-center my-2">
        <div *ngIf="facing && facing?.name" class="lotMapLegend__checkbox-input-wrapper d-flex">
            <input
            type="checkbox"
            class="form-checkbox"
            name="{{facing.name}}"
            [(ngModel)]="facing.checked"
            (change)="updateMapLotAttributes()"
          />
        </div>
        <span class="lotMapLegend__text pl-2 position-relative">{{facing.label}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #legendTypes>
  <div class="mx-4">
    <div (click)="toggleType()" class="cursor-pointer d-flex flex-row align-items-center justify-content-center">
      <div class="mt-1 mr-auto" [ngClass]="{'mr-5' : !isCollapsedType}">
        <h6>{{legendData.Lot_Type_Filter}}</h6>
      </div>
      <div class="lotMapLegend__legend-arrow p-3 mt-2"
          [ngClass]="{'lotMapLegend__legend-arrow-down mb-1': !isCollapsedType, 'mt-2' : isCollapsedType}"></div>
    </div>
    <div *ngIf="!isCollapsedType">
      <div *ngFor="let type of lotFilterTypes" class="d-flex flex-row align-items-center my-2">
        <div *ngIf="type && type?.name" class="lotMapLegend__checkbox-input-wrapper d-flex">
            <input
            type="checkbox"
            class="form-checkbox"
            name="{{type.name}}"
            [(ngModel)]="type.checked"
            (change)="updateMapLotAttributes()"
          />
        </div>
        <span class="lotMapLegend__text pl-2 position-relative">{{type.label}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #amenityLegend>
    <div class="d-flex flex-column align-items-center justify-content-center px-3 pt-3">
      <span  class="lotMapLegend__legend-header-text w-100 text-left"
             [innerHTML]="selectedAmenity.zoneName">
      </span>
      <span class="lotMapLegend__legend--location w-100 text-left pb-4"
            [innerHTML]="selectedAmenity.location">
      </span>
      <span class="lotMapLegend__legend--description w-100 text-left"
            [innerHTML]="selectedAmenity.description">
      </span>
    </div>
</ng-template>

<ng-template #homesLegend>
  <ng-container *ngIf="selectedLot; then lotLegend else planLegend"></ng-container>
</ng-template>

<ng-template #lotLegend>
    <div class="d-flex flex-column align-items-center px-3 pt-3">
      <span *ngIf="legendData.Homesite && selectedLot.lot"
             class="lotMapLegend__legend-header-text lotMapLegend__legend-header-text--lotmap w-100 text-left pb-2"
             [innerHTML]="legendData.Homesite | mergeField: '{lotName}':selectedLot.lot">
      </span>
      <div class="d-flex flex-column row">
        <ng-container *ngTemplateOutlet="attributeList; context:{attribute: selectedLot.status, data: legendData.Lot_Status}"></ng-container>
        <div *ngIf="lotAttributes">
          <ng-container *ngTemplateOutlet="attributeList; context:{attribute: lotAttributes.lotFacing, data: legendData.Lot_Facing}"></ng-container>
          <ng-container *ngTemplateOutlet="attributeList; context:{attribute: lotAttributes.lotType, data: legendData.Lot_Type}"></ng-container>
          <div *ngIf="selectedLot.status !== 'Sold'"><ng-container *ngTemplateOutlet="attributeList; context:{attribute: lotAttributes.lotPremium, data: legendData.Lot_Premium}"></ng-container></div>
          <ng-container *ngTemplateOutlet="attributeList; context:{attribute: lotAttributes.address, data: legendData.Lot_Address}"></ng-container>
          <ng-container *ngTemplateOutlet="attributeList; context:{attribute: lotAttributes.elevation, data: legendData.Plan_Elevation}"></ng-container>
          <ng-container *ngTemplateOutlet="attributeList; context:{attribute: lotAttributes.estMoveInDate, data: legendData.Estimated_Move_In_Date}"></ng-container>
        </div>
      </div>
    </div>

    <ng-template #attributeList let-attribute="attribute" let-data="data">
      <div *ngIf="data && attribute" class="d-flex">
        <span class="lotMapLegend__legend-info-text text-left font-weight-bold col-4"
              [innerHTML]="data">
        </span>
        <span class="lotMapLegend__legend-info-text lotMapLegend__legend-info-text--value pl-1 col-8"
              [innerHTML]="attribute">
        </span>
      </div>
    </ng-template>
</ng-template >

<ng-template #planLegend>
  <div *ngIf="selectedPlan"
        class="d-flex flex-column align-items-center justify-content-center">
    <span class="lotMapLegend__legend-header-text w-100 text-left pl-3 pr-1 py-1">{{selectedPlan.PlanName}}</span>
    <span *ngIf="lotsCount?.HomesiteLotsCount >= 0 && legendData.Homesites_Count"
            class="lotMapLegend__legend-info-text w-100 text-left pl-3 pr-1"
            [innerHTML]="legendData.Homesites_Count | mergeField: '{count}':lotsCount.HomesiteLotsCount:{boldValue:true}">
    </span>
    <span *ngIf="lotsCount?.QmiLotsCount && legendData.Quick_Move_In_Count"
            class="lotMapLegend__legend-info-text w-100 text-left pl-3 pr-1"
            [innerHTML]="legendData.Quick_Move_In_Count | mergeField: '{count}':lotsCount.QmiLotsCount:{boldValue:true}">
    </span>
  </div>
</ng-template>
