import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Series } from 'src/app/models/product/series.model';
import { ProductOverviewModel } from 'src/app/models/content/editorial/product-overview.model';
import { BaseComponent } from '../../shared/base/base.component';
import { CommunityService } from 'src/app/core-services/community.service';
import { Plan } from 'src/app/models/product/plan.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-plan-card-series',
  templateUrl: './series-card.component.html',
  styleUrls: ['./series-card.component.scss']
})
export class SeriesCardComponent extends BaseComponent implements OnInit {

  @Input() series: Series;
  @Input() data: ProductOverviewModel;
  @Output() seriesSelected: EventEmitter<number> = new EventEmitter();

  private plans: Plan[];
  public startingPrice: number;

  constructor(private communityService: CommunityService) { super(); }

  ngOnInit() {
    this.communityService.plans
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: Plan[]) => {
      if (plans && plans.length) {
        this.plans = plans.filter(p => p.SeriesName === this.series.SeriesName);
        this.startingPrice = this.getStartingPrice(this.plans);
      }
    });
  }

  onButtonClick(seriesId: number): void {
    this.seriesSelected.next(seriesId);
  }

  // Getting lowest home price from plans data since we don't explitcly set a Series starting price yet.
  getStartingPrice(plans: Plan[]): number {
    if (plans && plans.length) {
      const cheapestPlan: Plan = plans.reduce((prev, curr) => prev.Price < curr.Price ? prev : curr);
      if (cheapestPlan) {
        return cheapestPlan.Price;
      }
    }
    return null;
  }
}
