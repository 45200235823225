import { Component, OnInit, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CommunityService } from 'src/app/core-services/community.service';
import { QMI } from 'src/app/models/product/qmi.model';
import { PlanQmiListingModel } from 'src/app/models/content/plan/plan-qmi-listing.model';
import { QmiCardModel } from 'src/app/models/content/qmi/qmi-card.model';
import { BaseComponent } from 'src/app/community/shared/base/base.component';
import { Plan } from 'src/app/models/product/plan.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { setTrimUrl } from 'src/helper-functions/sitecore-api-url-helper';
import { sortImagesByImageRank } from 'src/helper-functions/image-helper';

@Component({
  selector: 'app-plan-qmi-listing-modal',
  templateUrl: './plan-qmi-listing-modal.component.html',
  styleUrls: ['./plan-qmi-listing-modal.component.scss']
})
export class PlanQmiListingModalComponent extends BaseComponent implements OnInit {

  @Input() data: PlanQmiListingModel;
  @Input() plan: Plan;

  qmis: QMI[];
  qmiCardModel: QmiCardModel;
  planName: string;
  trimmedUrl: string = setTrimUrl(this.router);

  constructor(private communityService: CommunityService,
              public activeModal: NgbActiveModal,
              private router: Router) { super(); }

  ngOnInit() {
    this.communityService.qmis.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((qmis: QMI[]) => {
      if (this.plan && this.plan.Id) {
        this.qmis = qmis.filter(qmi => qmi.PlanId === this.plan.Id);
        this.planName = this.plan.PlanName;
      }

      this.qmis.map(qmi => {
        if (qmi.Images && qmi.Images.length) {
          qmi.Images = sortImagesByImageRank(qmi.Images);
        }
        return qmi;
      });

      this.qmiCardModel = this.mapSitecoreLabelsToQmiCard(this.data);
    });
  }

  closeModal() {
    if (this.activeModal) {
      this.activeModal.close();
    }
  }

  private mapSitecoreLabelsToQmiCard(sitecoreLabels: PlanQmiListingModel): QmiCardModel {
    const qmiCardModel = new QmiCardModel();
    qmiCardModel.Series_Name = sitecoreLabels.Series_Name;
    qmiCardModel.Price = sitecoreLabels.Price;
    qmiCardModel.Bedrooms = sitecoreLabels.Bedrooms;
    qmiCardModel.Bathrooms = sitecoreLabels.Bathrooms;
    qmiCardModel.Garage = sitecoreLabels.Garage;
    qmiCardModel.Square_Feet = sitecoreLabels.Square_Feet;
    qmiCardModel.Price_Discount = sitecoreLabels.Price_Discount;
    qmiCardModel.QMI_CTA_Text = sitecoreLabels.View_Home_CTA_Text;
    qmiCardModel.View_All_Button_Text = sitecoreLabels.View_All_Button_Text;
    qmiCardModel.QMI_CTA_Page_Item_Ref = sitecoreLabels.View_Home_CTA_Page_Item_Ref;

    return qmiCardModel;
  }

}
