import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GuestRegistrationModel } from 'src/app/models/content/editorial/guest-registration.model';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { CommunityService } from 'src/app/core-services/community.service';
import { combineLatest } from 'rxjs';
import { setSuggestionText } from 'src/helper-functions/keyboard-helper';
import { Community } from 'src/app/models/product/community.model';
import { GuestRegistrationService } from 'src/app/core-services/guest-registration.service';
import { NgForm } from '@angular/forms';
import { GuestRegistrationResponseModel } from 'src/app/models/content/editorial/guest-registration-response.model';
import { GuestRegistrationModalModel } from 'src/app/models/content/editorial/guest-registration-modal.model';
import { fadeInRightOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { GuestRegistrationContactModel } from 'src/app/models/content/editorial/guest-registration-contact.model';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { AdobeLaunchFormName, AdobeLaunchFormType } from 'src/app/models/analytics/adobe-launch-event-enum';

@Component({
  selector: 'app-guest-registration-modal',
  templateUrl: './guest-registration-modal.component.html',
  styleUrls: ['./guest-registration-modal.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeInRightOnEnterAnimation(),
    fadeOutOnLeaveAnimation()
  ]
})
export class GuestRegistrationModalComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() data: GuestRegistrationModalModel;

  @ViewChild('guestRegistrationForm') guestRegistrationForm: NgForm;

  public community: Community;

  public guestRegistrationModel: GuestRegistrationModel = new GuestRegistrationModel();
  public maxAmountOfContacts = 3;
  public get atMaxContactsLimit(): boolean {
    return this.guestRegistrationModel.Contacts && this.guestRegistrationModel.Contacts.length >= this.maxAmountOfContacts;
  }

  public formSubmitted: boolean = false;
  public registrationResponse: GuestRegistrationResponseModel;

  public emailDomains: string[] = [];
  public loading: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private communityService: CommunityService,
              private guestRegistrationService: GuestRegistrationService,
              private cdr: ChangeDetectorRef,
              private adobeLaunchService: AdobeLaunchService) { super(); }

  ngOnInit() {
    const observables = [this.communityService.community];
    combineLatest(observables)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([community]: [Community]) => {
        if (community) {
          this.community = community;
          this.guestRegistrationModel.CommunityId = community.Id;
        }
      });

    if (this.data) {
      this.emailDomains = setSuggestionText(this.data.Domain_1, this.data.Domain_2, this.data.Domain_3);
    }

    this.addContactToForm();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public addContactToForm() {
    if (this.guestRegistrationModel && this.guestRegistrationModel.Contacts) {
      this.guestRegistrationModel.Contacts.push(new GuestRegistrationContactModel());
    }
  }

  public removeContactFromForm(index) {
    if (this.guestRegistrationModel && this.guestRegistrationModel.Contacts && this.guestRegistrationModel.Contacts[index]) {
      if (index > -1) {
        this.guestRegistrationModel.Contacts.splice(index, 1);
      }
    }
  }

  public async submitGuestRegistrationForm() {
    this.formSubmitted = true;
    if (this.guestRegistrationForm && this.guestRegistrationForm.valid && this.guestRegistrationModel) {
      this.loading = true;
      const resp: GuestRegistrationResponseModel = await this.guestRegistrationService.registerContact(this.guestRegistrationModel);
      this.registrationResponse = resp || new GuestRegistrationResponseModel();

      // on successful submission, push form analytics event
      if (this.registrationResponse.IsSuccessful) {
        this.adobeLaunchService.pushFormSubmittedEvent(AdobeLaunchFormName.GuestRegistration, AdobeLaunchFormType.Modal);
      }
      this.loading = false;
    }
  }
}
