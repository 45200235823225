import { Component, OnInit, ViewChild, Input, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Series } from 'src/app/models/product/series.model';
import { CommunityService } from 'src/app/core-services/community.service';
import { Community } from 'src/app/models/product/community.model';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../shared/base/base.component';
import { ProductFilterDataModel } from 'src/app/models/content/community/product-filter-data.model';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { ProductFilterService } from 'src/app/core-services/product-filter.service';
import { fadeInAnimation, fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-community-homes-filter',
  templateUrl: './community-homes-filter.component.html',
  styleUrls: ['./community-homes-filter.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeInAnimation({ duration: 200 })
  ]
})
export class CommunityHomesFilterComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() homes: any[];
  @Input() series?: Series;
  @Input() data: any;

  checkboxes: any[] = [];
  showFilters: boolean = false;
  formFilterCount: number = 0;

  private community: Community;

  private exactFilteredHomes: any[];
  private closeFilteredHomes: any[];
  private filteredHomesLabel: string;
  private exactResultsCount: number = 0;
  private closeResultsCount: number = 0;
  private modelExists: boolean = false;
  private qmiExists: boolean = false;

  @ViewChild('homeFiltersForm', { static: true }) private homeFiltersForm: NgForm;

  private focusedInput: NgModel;
  private minPrice: number;
  private maxPrice: number;
  private minSquareFoot: number;
  private maxSquareFoot: number;
  private minBedrooms: number;
  private maxBedrooms: number;
  private minBathrooms: number;
  private maxBathrooms: number;

  private minPriceFilterValue: number;
  private maxPriceFilterValue: number;
  private minSquareFootFilterValue: number;
  private maxSquareFootFilterValue: number;
  private minBedroomsFilterValue: number;
  private maxBedroomsFilterValue: number;
  private minBathroomsFilterValue: number;
  private maxBathroomsFilterValue: number;
  private modelAvailableOnlyFilterValue: any = {value: false};
  private qmiAvailableOnlyFilterValue: any = {value: false};
  private availableFilterValue: any = {value: false};
  private soldFilterValue: any = {value: false};
  private notReleasedFilterValue: any = {value: false};

  constructor(private communityService: CommunityService, private cdr: ChangeDetectorRef,
              private productFilterService: ProductFilterService, private adobeLaunchService: AdobeLaunchService) { super(); }

  ngOnInit() {
    this.communityService.community
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((community: Community) => {
        if (community) {
          this.community = community;
          this.setMinMaxFilterLimits();
        }
      });
    this.plansAndQMIExist(this.homes);
    this.setCheckboxes();
  }

  ngOnChanges() {
    this.updateFilterHomesComponent();
  }

  ngOnDestroy() {
    this.productFilterService.updateFilterData(null);
    this.adobeLaunchService.updateCurrentFilterData(null);
  }

  toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  clearAdditionalFilters() {
    this.plansAndQMIExist(this.homes);
    this.minPriceFilterValue = null;
    this.maxPriceFilterValue = null;
    this.minSquareFootFilterValue = null;
    this.maxSquareFootFilterValue = null;
    this.minBedroomsFilterValue = null;
    this.maxBedroomsFilterValue = null;
    this.minBathroomsFilterValue = null;
    this.maxBathroomsFilterValue = null;
    this.modelAvailableOnlyFilterValue.value = false;
    this.qmiAvailableOnlyFilterValue.value = false;
    this.availableFilterValue.value = false;
    this.soldFilterValue.value = false;
    this.notReleasedFilterValue.value = false;
    this.focusedInput = null;
    this.setMinMaxFilterLimits();
    this.updateFilterHomesComponent();
  }

  // Count for label of filter component
  getFiltersCount() {
    let count = 0;
    if (this.minPriceFilterValue || this.maxPriceFilterValue) {
      count++;
    }
    if (this.minSquareFootFilterValue || this.maxSquareFootFilterValue) {
      count++;
    }
    if (this.minBedroomsFilterValue || this.maxBedroomsFilterValue) {
      count++;
    }
    if (this.minBathroomsFilterValue || this.maxBathroomsFilterValue) {
      count++;
    }
    if (this.modelAvailableOnlyFilterValue.value) {
      count++;
    }
    if (this.qmiAvailableOnlyFilterValue.value) {
      count++;
    }
    if (this.availableFilterValue.value) {
      count++;
    }
    if (this.soldFilterValue.value) {
      count++;
    }
    if (this.notReleasedFilterValue.value) {
      count++;
    }
    return count > 0 ? count : null;
  }

  // Create and set (in services), the filter criteria model
  setProductFilterModel() {
    if (this.formFilterCount > 0) {
      const filterData = new ProductFilterDataModel();
      filterData.minPrice = this.minPriceFilterValue;
      filterData.maxPrice = this.maxPriceFilterValue;
      filterData.minSquareFoot = this.minSquareFootFilterValue;
      filterData.maxSquareFoot = this.maxSquareFootFilterValue;
      filterData.minBedrooms = this.minBedroomsFilterValue;
      filterData.maxBedrooms = this.maxBedroomsFilterValue;
      filterData.minBathrooms = this.minBathroomsFilterValue;
      filterData.maxBathrooms = this.maxBathroomsFilterValue;
      filterData.modelAvailableOnly = this.modelAvailableOnlyFilterValue.value;
      filterData.qmiAvailableOnly = this.qmiAvailableOnlyFilterValue.value;
      filterData.notReleased = this.notReleasedFilterValue.value;
      filterData.sold = this.soldFilterValue.value;

      this.productFilterService.updateFilterData(filterData);
      this.adobeLaunchService.updateCurrentFilterData(filterData);
    } else {
      this.productFilterService.updateFilterData(null);
      this.adobeLaunchService.updateCurrentFilterData(null);
    }
  }

  setCurrentFocusedInput(control): void {
    if (control) {
      this.focusedInput = control;
    }
  }

  clearInputValue(control): void {
    if (control) {
      control.reset();
      this.focusedInput = null;
    }
    this.updateFilterHomesComponent();
  }

  // Filter based on component filter properties set. If not set, use max/min integer values to filter.
  updateFilterHomesComponent() {
    // Module Data
    this.focusedInput = null;
    this.setMinMaxFilterLimits();
    this.formFilterCount = this.getFiltersCount();

    // Service Data
    this.setProductFilterModel();

    // Outputted filter data
    this.filterHomes(this.homes);

    // Removed this for now as we evaluate if we want to hide/show checkboxes during filtering
    // this.plansAndQMIExist(this.exactResultsCount > 0 ? this.exactFilteredHomes : this.homes);
  }

  plansAndQMIExist(homes) {
    if (homes && homes.length) {
      homes.forEach(home => {
        if (home.ModelExists) {
          this.modelExists = true;
        }
        if (home.InventoryCount) {
          this.qmiExists = true;
        }
      });
    } else {
      this.modelExists = false;
      this.qmiExists = false;
    }
    this.updateCheckboxes();
  }

  private filterHomes(homes) {
    if (homes && this.formFilterCount > 0) {
      this.exactFilteredHomes = []; // reset filtered homes
      this.closeFilteredHomes = []; // reset filered homes
      homes.forEach(home => {
        if (this.filterBySeries(home)) {
          if (this.homeMeetsFilterCriteria(home)) {
            this.exactFilteredHomes.push(home);
          } else {
            this.closeFilteredHomes.push(home); // "Close" matches are just anything didn't match perfectly with filter criteria.
          }
        }
      });
    } else {
      // Return null if no home data given or no filters applied
      this.exactFilteredHomes = null;
      this.closeFilteredHomes = null;
    }
    this.exactResultsCount = this.exactFilteredHomes && this.exactFilteredHomes.length || 0;
    this.closeResultsCount = this.closeFilteredHomes && this.closeFilteredHomes.length || 0;
    this.sendFilteredResults();
  }

  private homeMeetsFilterCriteria(home): boolean {
    return this.filterByPrice(home)              &&
           this.filterBySquareFeet(home)         &&
           this.filterByBedrooms(home)           &&
           this.filterByBathrooms(home)          &&
           this.filterByStatus(home);
  }

  private filterByStatus(home): boolean {
    return this.filterByModelAvailableOnly(home)       ||
            this.filterByQMIAvailableOnly(home)        ||
            this.filterByAvailable(home)               ||
            this.filterBySold(home)                    ||
            this.filterByNotReleased(home);
  }

  private filterByPrice(home): boolean {
    if (home) {
      if ((this.minPriceFilterValue && home.Price < this.minPriceFilterValue) ||
          (this.maxPriceFilterValue && home.Price > this.maxPriceFilterValue)) {
        return false;
      }
    }
    return true;
  }

  private filterBySquareFeet(home): boolean {
    if (home) {
      if ((this.minSquareFootFilterValue && home.SquareFeet < this.minSquareFootFilterValue) ||
          (this.maxSquareFootFilterValue && home.SquareFeet > this.maxSquareFootFilterValue)) {
        return false;
      }
    }
    return true;
  }

  private filterByBedrooms(home): boolean {
    if (home) {
      if ((this.minBedroomsFilterValue && home.Bedrooms < this.minBedroomsFilterValue) ||
          (this.maxBedroomsFilterValue && home.Bedrooms > this.maxBedroomsFilterValue)) {
        return false;
      }
    }
    return true;
  }

  private filterByBathrooms(home): boolean {
    if (home) {
      if ((this.minBathroomsFilterValue && home.Bathrooms < this.minBathroomsFilterValue) ||
          (this.maxBathroomsFilterValue && home.Bathrooms > this.maxBathroomsFilterValue)) {
        return false;
      }
    }
    return true;
  }

  private filterBySeries(home): boolean {
    if (home) {
      if (this.series) {
        if ((home.Plan && home.Plan.SeriesName === this.series.SeriesName) ||
            (home.SeriesName === this.series.SeriesName)) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  private filterByModelAvailableOnly(home): boolean {
    if (home) {
      if (this.modelAvailableOnlyFilterValue.value) {
        if ((home.Plan && home.Plan.ModelExists !== this.modelAvailableOnlyFilterValue.value) ||
          (home.ModelExists !== this.modelAvailableOnlyFilterValue.value)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private filterByQMIAvailableOnly(home): boolean {
    if (home) {
      if (this.qmiAvailableOnlyFilterValue.value) {
        if ((!home.InventoryCount && home.Id) || (!home.InventoryCount && !home.InventoryHomeID)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private filterByAvailable(home): boolean {
    if (home) {
      if (this.availableFilterValue.value) {
        if ((home.Plan && (home.Plan.NumAvailable > 0) !== this.availableFilterValue.value) ||
          ((home.NumAvailable > 0) !== this.availableFilterValue.value)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private filterBySold(home): boolean {
    if (home) {
      if (this.soldFilterValue.value) {
        if (home.Plan && (home.Plan.IsSold !== this.soldFilterValue.value) ||
          (home.IsSold !== this.soldFilterValue.value)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private filterByNotReleased(home): boolean {
    if (home) {
      if (this.notReleasedFilterValue.value) {
        if (home.Plan && (home.Plan.IsFutureRelease !== this.notReleasedFilterValue.value) ||
          (home.IsFutureRelease !== this.notReleasedFilterValue.value)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private sendFilteredResults() {
    // Sort by price before emmitting if collections exist and are greater than 1 length.
    if (this.exactFilteredHomes && this.exactFilteredHomes.length > 1) {
      this.exactFilteredHomes = this.exactFilteredHomes.sort((a, b) => a.Price - b.Price);
    }
    if (this.closeFilteredHomes && this.closeFilteredHomes.length > 1) {
      this.closeFilteredHomes = this.closeFilteredHomes.sort((a, b) => a.Price - b.Price);
    }
    this.productFilterService.updateExactHomes(this.exactFilteredHomes);
    this.productFilterService.updateCloseHomes(this.closeFilteredHomes);
  }

  private setMinMaxFilterLimits() {
    if (this.community) {
      this.minPrice = Math.floor(this.community.StartingFromPrice / 50000) * 50000; // round limits low
      this.maxPrice = Math.ceil(this.community.MaxBasePrice / 50000) * 50000; // round limits high
      this.minBedrooms = this.community.MinBedrooms;
      this.maxBedrooms = this.community.MaxBedrooms;
      this.minBathrooms = this.community.MinBathrooms;
      this.maxBathrooms = this.community.MaxBathrooms;
    }
    if (this.homes && this.homes.length) {
      // Setting limits for highest/lowest squarefeet from home data. Not available on community model.
      this.minSquareFoot = Math.floor(Math.min.apply(Math, this.homes.map(h => h.SquareFeet)) / 10) * 10;
      this.maxSquareFoot = Math.ceil(Math.max.apply(Math, this.homes.map(h => h.SquareFeet)) / 10) * 10;
    }
  }

  private setCheckboxes() {
    if (this.data && (!this.checkboxes || !this.checkboxes.length)) {
      if (this.data.Filter_Available) {
        this.checkboxes.push({
          label: this.data.Filter_Available,
          name: 'availableFilterValue',
          checked: this.availableFilterValue});
      }

      if (this.data.Filter_Model_Availability && this.community && this.community.PlanCount !== 0 && this.modelExists) {
        this.checkboxes.push({
          label: this.data.Filter_Model_Availability,
          name: 'modelAvailableOnlyFilterValue',
          checked: this.modelAvailableOnlyFilterValue});
      }

      if (this.data.Filter_QMI_Availability && this.community && this.community.InventoryCount !== 0 && this.qmiExists) {
        this.checkboxes.push({
          label: this.data.Filter_QMI_Availability,
          name: 'qmiAvailableOnlyFilterValue',
          checked: this.qmiAvailableOnlyFilterValue});
      }

      if (this.data.Filter_Sold) {
        this.checkboxes.push({
          label: this.data.Filter_Sold,
          name: 'soldFilterValue',
          checked: this.soldFilterValue});
      }

      if (this.data.Filter_Not_Released) {
        this.checkboxes.push({
          label: this.data.Filter_Not_Released,
          name: 'notReleasedFilterValue',
          checked: this.notReleasedFilterValue});
      }
    }
  }

  private updateCheckboxes() {
    if (this.checkboxes && this.checkboxes.length) {
      const modelCheckbox = this.checkboxes.find(x => x.name === 'modelAvailableOnlyFilterValue');
      if (modelCheckbox) {
        modelCheckbox.hideCheckbox = !this.modelExists;
        if (modelCheckbox.checked.value && !this.modelExists) {
          modelCheckbox.checked.value = false;
        }
      }
      const qmiCheckbox = this.checkboxes.find(x => x.name === 'qmiAvailableOnlyFilterValue');
      if (qmiCheckbox) {
        qmiCheckbox.hideCheckbox = !this.qmiExists;
        if (qmiCheckbox.checked.value && !this.qmiExists) {
          qmiCheckbox.checked.value = false;
        }
      }
    }
    // Force re-render of DOM after ngModel changes for checkboxes
    // https://stackoverflow.com/a/35106069
    this.cdr.detectChanges();
  }
}
