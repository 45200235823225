<div  class="VideoModal" appPauseSleepState>
    <div class="modal-header justify-content-end ">
      <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
    </div>
    <div class="d-flex flex-column justify-content-center modal-body">
      <div class="VideoModal__iframeWrapper position-relative d-flex justify-content-center w-100">
        <iframe *ngIf="Url" class="position-absolute border-0 h-100" [src]="Url | safeUrl"></iframe>
      </div>
    </div>
    <div class="back-wrapper modal-footer">
      <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
    </div>
  </div>
  