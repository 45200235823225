<div class="VRTourPlan container-fluid">
  <div *ngIf="plans?.length"
    class="VRTourPlan__card row"
    [ngClass]="{'justify-content-around': plans.length < 4}">
    <app-plan-card *ngFor="let plan of plans; let i = index;"
      class="col-4 card"
      [@fadeInUpOnEnter]="{ value: '', params: { duration: 800, translate: '200px', delay: i * 150 } }"
      [data]="planCardModel"
      [plan]="plan"
      [isTourCard]="true">
    </app-plan-card>
  </div>
</div>
