<div *ngIf="data" class="CommunityLotMap d-flex flex-column overflow-hidden h-100 w-100">
  <div class="d-flex flex-row h-100 w-100">

    <div *ngIf="community" class="col-6 pl-0" [ngClass]="{'col-12': (!plans?.length || !planCardModel)}">
      <app-alphavision-map [data]="data"
                           [community]="community"
                           [selectedLotId]="selectedLotId"
                           (setLotCount)="setLotsCount($event)"
                           (mapLoaded)="setMapLoaded($event)">
      </app-alphavision-map>
    </div>

    <ng-container *ngIf="selectedAmenity; then amenityCarousel else planColumn"></ng-container>

  </div>
</div>

<ng-template #amenityCarousel>
  <div class="col-6 d-flex flex-column w-100" [@fadeInRightOnEnter]>
    <app-carousel *ngIf="amenityCarouselOptions && amenityCarouselOptions.HasImages"
                   [carouselOptions]="amenityCarouselOptions">
    </app-carousel>
  </div>
</ng-template>

<ng-template #planColumn>
  <div class="col-6 d-flex justify-content-end align-items-center flex-column">
    <div *ngIf="plans?.length && planCardModel"
         id="lotMapCardColumn"
         [ngClass]="{'CommunityLotMap__cardColumn--with-header': hasFixedHeader}"
         class="CommunityLotMap__cardColumn overflow-y-auto w-100 px-4">

      <span *ngIf="selectedLot?.plans && data.Homesite_Selected_Lot_Label"
            class="CommunityLotMap__selected-lot-label pl-3 py-2"
            [innerHTML]="data.Homesite_Selected_Lot_Label | mergeField: '{count}':selectedLotPlanCount:{boldValue:true}">
      </span>

      <app-horizontal-plan-card *ngIf="selectedPlan"
                                class="d-block"
                                [@fadeInOnEnter]
                                [large]="true"
                                [data]="planCardModel"
                                [plan]="selectedPlan"
                                [selectedPlanId]="selectedPlan.Id"
                                [numberOfLotsAvailableForSelectedPlan]="numberOfAvailableLotsForSelectedPlan"
                                (setSelectPlanId)="setSelectedPlan($event)">
      </app-horizontal-plan-card>

      <app-horizontal-plan-card *ngFor="let plan of filteredPlans | orderBy:'PlanRank'; let i = index"
                                [ngClass]="mapLoaded ? 'd-block' : 'd-none'"
                                [@fadeInRightOnEnter]="{value: '', params: { delay: i * 150 } }"
                                [large]="closeFilteredPlans?.length"
                                [data]="planCardModel"
                                [plan]="plan"
                                [selectedPlanId]="selectedPlan?.Id"
                                (setSelectPlanId)="setSelectedPlan($event)">
      </app-horizontal-plan-card>


      <div *ngIf="closeFilteredPlans?.length && data.Filter_Close_Matches">
        <div class="CommunityLotMap__additional-filter-header w-100 px-4 pb-4 font-weight-normal
                      d-flex flex-row align-items-center justify-content-center">
          <hr />
          <span class="px-5 d-flex align-items-center text-center text-nowrap">
            {{ data.Filter_Close_Matches }}
          </span>
          <hr />
        </div>
        <app-horizontal-plan-card *ngFor="let plan of closeFilteredPlans | orderBy:'PlanRank'; let i = index"
                                  class="CommunityLotMap__card d-block"
                                  [@fadeInRightOnEnter]="{value: '', params: { delay: i * 150 } }"
                                  [data]="planCardModel"
                                  [plan]="plan"
                                  [selectedPlanId]="selectedPlan?.Id"
                                  (setSelectPlanId)="setSelectedPlan($event)">
        </app-horizontal-plan-card>
      </div>
    </div>
  </div>
</ng-template>
