import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../base/base.component';
import { PointOfInterest } from 'src/app/models/product/point-of-interest.model';
import { Result } from 'src/app/models/maps/google-places-result.model';
import { getDistanceFromCommunity } from 'src/helper-functions/geolocation-helper';
import { MapCommuteModalModel } from 'src/app/models/maps/map-commute-modal.model';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { AdobeLaunchFormName, AdobeLaunchFormType } from 'src/app/models/analytics/adobe-launch-event-enum';

@Component({
  selector: 'app-map-commute-modal',
  templateUrl: './map-commute-modal.component.html',
  styleUrls: ['./map-commute-modal.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class MapCommuteModalComponent extends BaseComponent implements OnInit, AfterViewInit {

  constructor(public activeModal: NgbActiveModal, private adobeLaunchService: AdobeLaunchService) { super(); }

  @Input() data: MapCommuteModalModel;
  @Output() poiSubmitted = new EventEmitter<PointOfInterest>(true);

  @ViewChild('destinationAddressInput') destinationAddressInput: ElementRef;

  public destinationPOI: PointOfInterest = null;
  public allowSubmit: boolean = false;

  ngOnInit() {}

  ngAfterViewInit() {
    this.focusAddressInput();
  }

  focusAddressInput() {
    if (this.destinationAddressInput && this.destinationAddressInput.nativeElement) {
      this.destinationAddressInput.nativeElement.focus();
      google.maps.event.trigger(this.destinationAddressInput.nativeElement, 'focus', {});
    }
  }

  handleAddressChange(placesResult: google.maps.places.PlaceResult) {
    if (placesResult) {
      const destinationPOI = new PointOfInterest(placesResult.place_id, null);
      destinationPOI.GooglePlaceID = placesResult.place_id;
      destinationPOI.POIName = placesResult.name;
      destinationPOI.POIAddress = placesResult.formatted_address;
      destinationPOI.POILatitude = placesResult.geometry.location.lat();
      destinationPOI.POILongitude = placesResult.geometry.location.lng();
      this.destinationPOI = destinationPOI;
      this.allowSubmit = true;
    }
  }

  submitDestinationPOI() {
    if (this.allowSubmit && this.destinationPOI && this.destinationPOI.POILatitude && this.destinationPOI.POILongitude) {
      this.poiSubmitted.emit(this.destinationPOI);

      // push analytics form event
      this.adobeLaunchService.pushFormSubmittedEvent(AdobeLaunchFormName.MayMyCommute, AdobeLaunchFormType.Modal);

      if (this.activeModal) {
        this.activeModal.close();
      }
    }
  }
}
