import { Component, OnInit, Input } from '@angular/core';
import { PlanFeaturesModalModel } from 'src/app/models/content/plan/plan-features-modal.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plan-features-modal',
  templateUrl: './plan-features-modal.component.html',
  styleUrls: ['./plan-features-modal.component.scss']
})
export class PlanFeaturesModalComponent implements OnInit {

  @Input() public planFeaturesModal: PlanFeaturesModalModel;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // ensure features sorted by rank
    this.planFeaturesModal.Features.sort((a, b) => {
      if (a.Rank === b.Rank) {
        if (a.FeatureName > b.FeatureName) {
          return 1;
        } else {
          return -1;
        }
      }
      return a.Rank - b.Rank;
    });
  }
}
