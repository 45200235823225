<section *ngIf="plan && (hasFeatures || hasInteractiveTool)" class="PlanFeatures"
      [@fadeInOnEnter]>
  <h3>{{data.Plan_Features_Header}}</h3>
  <div>
    <!-- Features Modal CTA -->
    <app-icon-cta *ngIf="hasFeatures" [label]='data.Features_CTA_Label' [icon]='data.Features_CTA_Icon'
                   data-analytics-cta="Plan Features"
                   (click)='openPlanFeaturesModal()'>
    </app-icon-cta>
    <!-- Interactive Tools Modal CTA -->
    <app-icon-cta *ngIf="hasInteractiveTool" [label]='data.Interactive_Tools_CTA_Label' [icon]='data.Interactive_Tools_CTA_Icon'
                   data-analytics-cta="Interactive Tools"
                   (click)='openPlanTools()'>
    </app-icon-cta>
  </div>
</section>
