import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Query } from '../models/query.model';
import { CommunityResponse } from '../models/community-response.model';
import { KioskCommunity } from '../models/content/community/kiosk-community.model';
import { Observable, ReplaySubject } from 'rxjs';
import { Plan } from '../models/product/plan.model';
import { Series } from '../models/product/series.model';
import { QMI } from '../models/product/qmi.model';
import { Community } from '../models/product/community.model';
import { map } from 'rxjs/operators';
import { FloorPlanImage } from '../models/product/floor-plan-image.model';
import { CommunityPOICategory } from '../models/product/community-poi-category';
import { KioskSleepStateImage } from '../models/product/kiosk-sleep-state-image.model';
import { CommunityPOILocation } from '../models/product/community-poi-location';
import { CommunityAmenitiesModel } from '../models/product/community-amenities.model';
import { CommunityPromotion } from '../models/product/community-promotions.model';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  kioskCommunity = new ReplaySubject<KioskCommunity>(1);
  community = new ReplaySubject<Community>(1);
  plans = new ReplaySubject<Plan[]>(1);
  series = new ReplaySubject<Series[]>(1);
  qmis = new ReplaySubject<QMI[]>(1);
  amenities = new ReplaySubject<CommunityAmenitiesModel>(1);
  promotions = new ReplaySubject<CommunityPromotion[]>(1);
  communityPOICategories = new ReplaySubject<CommunityPOICategory[]>(1);
  communityPOILocations = new ReplaySubject<CommunityPOILocation[]>(1);
  nearbyCommunities = new ReplaySubject<Community[]>(1);

  private apiUrl: string;
  private productApiUrl: string;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {
    this.apiUrl = this.settingsService.apiUrl + '/kiosk';
    this.productApiUrl = this.settingsService.productApiUrl;
  }

  getKioskCommunityById(communityRequest: Query): Observable<CommunityResponse> {
    return this.httpClient.get<CommunityResponse>(`${this.apiUrl}/QueryCommunity`, { params: communityRequest as any });
  }

  getAllCommunities(): Observable<KioskCommunity[]> {
    return this.httpClient.get<KioskCommunity[]>(`${this.apiUrl}/QueryAllCommunities`);
  }

  getCommunityInfoById(communityId: string): Observable<Community> {
    return this.httpClient.get<Community>(`${this.productApiUrl}/communities/${communityId}`);
  }

  getPlansByCommunityId(communityId: string): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(`${this.productApiUrl}/communities/${communityId}/plans?page_all=true`);
  }

  getSeriesByCommunityId(communityId: string): Observable<Series[]> {
    return this.httpClient.get<Series[]>(`${this.productApiUrl}/communities/${communityId}/series`);
  }

  getQmisByCommunityId(communityId: string): Observable<QMI[]> {
    return this.httpClient.get<QMI[]>(`${this.productApiUrl}/communities/${communityId}/inventory?page_all=true`);
  }

  getPoiCategoriesByCommunityId(communityId: string): Observable<CommunityPOICategory[]> {
    return this.httpClient.get<CommunityPOICategory[]>(`${this.productApiUrl}/communities/${communityId}/communitymappoicategories`);
  }

  getPoiLocationsByCommunityId(communityId: string): Observable<CommunityPOILocation[]> {
    return this.httpClient.get<CommunityPOILocation[]>(
        `${this.productApiUrl}/communities/${communityId}/communitypoilocations?page_all=true`);
  }

  getAmenitiesByCommunityId(communityId: string): Observable<CommunityAmenitiesModel> {
    return this.httpClient.get<CommunityAmenitiesModel>(`${this.productApiUrl}/communities/${communityId}/amenities?page_all=true`);
  }

  getPromotionsByCommunityId(communityId: string): Observable<CommunityPromotion[]> {
    return this.httpClient.get<CommunityPromotion[]>(`${this.productApiUrl}/communities/${communityId}/promotions`);
  }

  getFloorPlanImagesByPlanId(planId: string): Observable<FloorPlanImage[]> {
    return this.httpClient.get<FloorPlanImage[]>(`${this.productApiUrl}/plans/${planId}/floorplanimages?page_all=true`);
  }

  getPlanInfoById(planId: string): Observable<Plan> {
    return this.httpClient.get<Plan>(`${this.productApiUrl}/plans/${planId}`);
  }

  getSleepStateImagesByCommunityId(communityId: string): Observable<KioskSleepStateImage[]> {
    return this.httpClient.get<KioskSleepStateImage[]>(
        `${this.productApiUrl}/communities/${communityId}/kiosksleepstateimages?page_all=true`);
  }

  getNearbyCommunities(community: Community): Observable<Community[]> {
    const nearbyCommunityParameters = {
      BrandName: community.BrandName,
      StateName: community.Address.State,
      RegionName: community.Region[0]
    };
    return this.httpClient.post<Community[]>(`${this.productApiUrl}/communities/search`, nearbyCommunityParameters);
  }

  getPlanById(planId: string): Observable<Plan> {
    return this.plans.pipe(
      map(plans => {
        return plans.find(x => x.Id.toString() === planId);
      })
    );
  }

  getQmiById(inventoryHomeId: string): Observable<QMI> {
    return this.qmis.pipe(
      map(qmis => {
        return qmis.find(x => x.InventoryHomeID.toString() === inventoryHomeId);
      })
    );
  }

  updateKioskCommunity(community: KioskCommunity): void {
    this.kioskCommunity.next(community);
  }

  updateCommunityInfo(community: Community): void {
    this.community.next(community);
    this.community.complete();
  }

  updatePlans(plans: Plan[]): void {
    this.plans.next(plans);
  }

  updateAmenities(amenities: CommunityAmenitiesModel): void {
    this.amenities.next(amenities);
  }

  updatePromotions(promotions: CommunityPromotion[]): void {
    this.promotions.next(promotions);
  }

  updateSeries(series: Series[]): void {
    this.series.next(series);
  }

  updateQmis(qmis: QMI[]): void {
    this.qmis.next(qmis);
  }

  updateCommunityPoiCategories(communityPOICategories: CommunityPOICategory[]): void {
    this.communityPOICategories.next(communityPOICategories);
  }

  updateCommunityPoiLocations(communityPOILocations: CommunityPOILocation[]): void {
    this.communityPOILocations.next(communityPOILocations);
  }

  updateNearbyCommunities(nearbyCommunities: Community[]): void {
    this.nearbyCommunities.next(nearbyCommunities);
  }
}
