export class PointOfInterest {
  public POILocationID: string;
  public CommunityID: number;
  public PointOfInterestID: number;
  public GooglePlaceID: string;
  public POIName: string;
  public POIAddress: string;
  public POIPhone: string;
  public POILatitude: number;
  public POILongitude: number;
  public CreatedDate: Date;
  public Distance: number;

  constructor(poiLocationID: string, poiID: number) {
    this.POILocationID = poiLocationID;
    this.PointOfInterestID = poiID;
  }
}
