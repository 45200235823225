<section *ngIf="data" class="InlineVideo p-3">
  <div *ngIf="data.Header || data.Description" class="d-flex flex-column">
    <div *ngIf="data.Header" class="InlineVideo__header d-inline-flex pt-2 {{data.Header_Alignment | alignmentClass}}">{{data.Header}}</div>
    <div *ngIf="data.Description" class="InlineVideo__description d-inline-flex {{data.Description_Alignment | alignmentClass}}">{{data.Description}}</div>
  </div>
  <div *ngIf="videoFilePath" class="position-relative d-flex justify-content-center align-items-center px-3">
      <cl-video
        style="max-height: 77vh;"
        class="w-100 h-100"
        secure="true"
        fallback-content="Your browser does not support HTML5 video tags"
        public-id="{{videoFilePath}}"
        format="auto"
        autoplay="data.Enable_Autoplay"
        controls
        width="auto"
        height="auto"
        >
      </cl-video>
  </div>
  <div *ngIf="videoUrl" class="InlineVideo__iframeWrapper position-relative d-flex justify-content-center align-items-center px-3">
    <iframe class="position-absolute border-0 w-100 h-100" [src]="videoUrl | safeUrl"></iframe>
  </div>
</section>