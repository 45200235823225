import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy, Output } from '@angular/core';
import { CommunityLotMapModel } from 'src/app/models/content/community/community-lot-map.model';
import { Plan } from 'src/app/models/product/plan.model';
import { AlphaVisionMapService } from 'src/app/core-services/alphavision-map.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { Community } from 'src/app/models/product/community.model';
import { AlphaVisionClickResponse, AlphaVisionLot, AlphaVisionDataType,
         AlphaVisionEventType, AlphaVisionFilterLotsResponse, AlphaVisionLotStatusType,
         AlphaVisionSitePlan, AlphaVisionAmenity, AlphaVisionGeneralResponse,
         AlphaVisionFilterLotsResponseData, AlphaVisionLotCounts, AlphaVisionSelectLotResponse,
         AlphaVisionSelectMapResponse,
         AlphaVisionAttributes,
         AlphaVisionLotFilterAttributes} from 'src/app/models/maps/alphavision-map-data.model';
import { EventEmitter } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { SettingsService } from 'src/app/core-services/settings.service';
declare let AVSiteplan: any;

@Component({
  selector: 'app-alphavision-map',
  templateUrl: './alphavision-map.component.html',
  styleUrls: ['./alphavision-map.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class AlphaVisionMapComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() data: CommunityLotMapModel;
  @Input() community: Community;
  @Input() selectedLotId: string;

  @Output() setLotCount = new EventEmitter<AlphaVisionLotCounts>(true);
  @Output() mapLoaded ? = new EventEmitter<boolean>(true);

  @ViewChild('divSitePlan', { static: true }) divSitePlan: ElementRef;

  public mapInitialized: boolean = false;
  public mapLotsReady: boolean = false;
  public masterMapName: string;

  public loading: boolean = true;
  public hasError: boolean = false;
  public fullscreenEnabled: boolean = false;

  public selectedPlan: Plan;
  public selectedLot: AlphaVisionLot;
  public lotAttributes: AlphaVisionAttributes;
  public selectedAmenity: AlphaVisionAmenity;
  public initialLots: AlphaVisionLot[] = [];
  public lotsCount: AlphaVisionLotCounts = new AlphaVisionLotCounts();

  private mapAPI = null;
  private sitePlanMapName: string;
  private planIds: string[] = [];
  private statuses: string[] = [];
  private filterLotAttributes: AlphaVisionLotFilterAttributes;
  private allStatuses: string[];
  private displayLotNumberLayer: boolean = true;

  constructor(private alphaVisionMapService: AlphaVisionMapService,
    private settingsService: SettingsService) { super(); }

  ngOnInit() {
    this.alphaVisionMapService.statuses
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((statuses: string[]) => {
        if (statuses) {
          this.statuses = statuses.length ? statuses : ['']; // AV's API interprets [''] as to show no lots.
          this.filterLots();
        }
      });

    this.alphaVisionMapService.lotAttributes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((lotAttributes: AlphaVisionLotFilterAttributes) => {
        if (lotAttributes) {
          this.filterLotAttributes = lotAttributes;
          this.filterLots();
        }
      });

    this.alphaVisionMapService.selectedPlan
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedPlan: Plan) => {
        this.selectedPlan = selectedPlan;
        // If we have a selected Plan, clear out any selected Lot for filtering.
        if (this.selectedPlan && this.selectedLot) {
          this.selectedLot = null;
          this.selectedLotId = null;
          this.alphaVisionMapService.selectedLot.next();
        }
        this.filterLots();
      });

    this.allStatuses = Object.keys(AlphaVisionLotStatusType).map(status => AlphaVisionLotStatusType[status]);
  }

  ngAfterViewInit() {
    this.initializeAlphaVisionSiteplan();
  }

  ngOnDestroy() {
    // Clear out saved service data such as selected lots, plans, and statuses if User navigates away.
    this.alphaVisionMapService.resetAllMapServiceData();
    super.ngOnDestroy();
  }

  private initializeAlphaVisionSiteplan() {
    // On Map Init, if we loaded with a selected plan, init only with that Plan ID, otherwise show all plans.
    const mapInitStatuses = this.statuses && this.statuses.length ? this.statuses : this.allStatuses;
    const mapInitPlanIds = this.selectedPlan ? this.selectedPlan.Id.toString() : this.planIds;

    if (this.settingsService.pulteAVID && this.community && this.divSitePlan) {
      this.mapAPI = new AVSiteplan(this.settingsService.pulteAVID, // Builder GUID
        this.community.Id,                                // Comunity ID
        this.divSitePlan.nativeElement,                   // Parent DOM Element
        res => this.mapGeneralCallback(res),              // Generic Map Callback
        res => this.mapClickEventCallback(res),           // Event Map Callback (lot click/ amenity click)
        mapInitStatuses,                                  // Status
        mapInitPlanIds,                                   // Plans
        this.displayLotNumberLayer);                      // Display Lot Number Layer
    } else {
      this.setMapFinishedLoading();
    }
  }

  private setMapFinishedLoading() {
    this.loading = false;
    this.mapLoaded.emit(true);
  }

  private mapGeneralCallback(res: AlphaVisionGeneralResponse) {
    if (res && res.datatype) {
      switch (res.datatype) {
        case (AlphaVisionDataType.MasterMap):
          const masterMap = res.data as AlphaVisionSitePlan;

          if (res.eventtype === AlphaVisionEventType.Init && !this.mapInitialized) {
            if (masterMap && masterMap.mapName) {
              this.masterMapName = masterMap.mapName;
              // If we have a saved off Map Name to init to (selected from a Master Map), show/select that map during init.
              if (this.sitePlanMapName) {
                this.mapAPI.selectMap(this.sitePlanMapName, selectedMapResponse => this.selectMapCallback(selectedMapResponse));
                this.mapLotsReady = true;
              }
            }
          }
          this.mapInitialized = true;
          this.setMapFinishedLoading();
          break;

        case (AlphaVisionDataType.SitePlan):
          const sitePlan = res.data as AlphaVisionSitePlan;

          if (res.eventtype === AlphaVisionEventType.Init && !this.mapInitialized) {
            if (sitePlan && sitePlan.mapName) {
              this.sitePlanMapName = sitePlan.mapName;
              this.mapLotsReady = true;
              this.setInitialLots(sitePlan.lots);

              // Set Homesite and QMI counts if we initialized map with a Selected Plan.
              if (this.selectedPlan && this.selectedPlan.Id) {
                const filteredInitLots = this.getLotsFilteredByStatus(sitePlan.lots);
                this.setLotAndQMICountsFromLots(filteredInitLots);
              }
              // Select Specific Lot on SitePlan Map Click to show and filter if we initialized with a Selected Lot Block
              if (this.selectedLotId && sitePlan.lots && sitePlan.lots.some(lot => lot.lot === this.selectedLotId)) {
                this.mapAPI.selectLot(this.selectedLotId, selectedLotResponse => this.selectLotCallback(selectedLotResponse));
              }
            }
          }

          this.mapInitialized = true;
          this.setMapFinishedLoading();
          break;

        case (AlphaVisionDataType.Error):
          // If we tried to init the Map and got an error response, set error flag.
          if (res.eventtype === AlphaVisionEventType.Init && !this.mapInitialized) {
            this.hasError = true;
          }
          this.setMapFinishedLoading();
          break;

        case (AlphaVisionDataType.ZoomLevel):
        case (AlphaVisionDataType.Lot):
        case (AlphaVisionDataType.Plan):
        default:
          break;
      }
    }
  }

  private mapClickEventCallback(res: AlphaVisionClickResponse) {
    if (res.eventtype === AlphaVisionEventType.Click && res.datatype && res.data) {
      // Click callback can return 3 different types of click data responses,
      // (Lot, Amenity, or Map), depending on map element clicked.
      switch (res.datatype) {
        case AlphaVisionDataType.Lot:
          this.selectedLot = res.data as AlphaVisionLot;
          this.selectedLotId = this.selectedLot.lot;
          this.updateSelectedLotData(this.selectedLot);
          break;

        case AlphaVisionDataType.Amenity:
          this.selectedAmenity = res.data as AlphaVisionAmenity;
          this.alphaVisionMapService.updateSelectedAmenity(this.selectedAmenity);
          this.mapLotsReady = true;
          break;

        case AlphaVisionDataType.SitePlan:
          const sitePlan = res.data as AlphaVisionSitePlan;
          this.sitePlanMapName = sitePlan.mapName;
          this.mapLotsReady = true;
          this.setInitialLots(sitePlan.lots);

          // Select Specific Lot on SitePlan Map Click to show and filter if we have a Specific Lot Block
          if (this.selectedLotId && sitePlan.lots && sitePlan.lots.some(lot => lot.lot === this.selectedLotId)) {
            this.mapAPI.selectLot(this.selectedLotId, selectedLotResponse => this.selectLotCallback(selectedLotResponse));
          }
          // need to reset filter criteria when selecting new submap in master maps
          this.resetMapFilters();
          this.filterLots();
          break;
        default:
          break;
      }
    }
  }

  private filterLots() {
    if (this.mapAPI && this.mapInitialized) {
      // If we don't have statuses currently stored from the Map Service, use all statuses from AV Lot Status enum.
      const filterLotsStatuses = this.statuses && this.statuses.length ?
                                 this.statuses                         :
                                 this.allStatuses;
      // If we currently have a selected plan, filter Lots by selected plan ID, otherwise show currently set plan IDs.
      const filterLotsPlanIds = (this.selectedPlan && this.selectedPlan.Id) ?
                                 this.selectedPlan.Id.toString()            :
                                 this.planIds;
      // If we currently have any Lot Attributes for filtering, filter by those.
      const filterLotAttributes = this.getAttributesArrayFromFilterLotAttributes(this.filterLotAttributes);
      this.mapAPI.filterLots(filterLotsStatuses, filterLotsPlanIds, filterLotAttributes, res => this.filterLotsCallback(res));
    }
  }

  private filterLotsCallback(res: AlphaVisionFilterLotsResponse) {
    if (res && res.datatype === AlphaVisionDataType.Lot) {
      const responseData = res.data as AlphaVisionFilterLotsResponseData;
      if (this.selectedPlan && responseData.lots) {
        // Set Homesite and QMI lots counts for Legend labels, if filtered with a selected Plan.
        this.setLotAndQMICountsFromLots(responseData.lots);
        this.mapAPI.reset();
      } else if (responseData.plans) {
        const planIds = responseData.plans.map(p => p.id);
        this.alphaVisionMapService.updateMapPlanIds(planIds);
      }
    }
  }

  private selectMapCallback(res: AlphaVisionSelectMapResponse) {
    if (res && res.datatype === AlphaVisionDataType.SitePlan) {
      const sitePlan = res.data as AlphaVisionSitePlan;
      // Select Specific Lot if we're selecting a Map (most likely because we went fullscreen) and had a Lot already Selected
      if (sitePlan && this.selectedLotId && sitePlan.lots && sitePlan.lots.some(lot => lot.lot === this.selectedLotId)) {
        this.mapAPI.selectLot(this.selectedLotId, selectedLotResponse => this.selectLotCallback(selectedLotResponse));
      }
    }
  }

  private selectLotCallback(res: AlphaVisionSelectLotResponse) {
    if (res && res.datatype === AlphaVisionDataType.Lot) {
      this.selectedLot = res.data as AlphaVisionLot;
      this.updateSelectedLotData(this.selectedLot);
    }
  }

  private getAttributesArrayFromFilterLotAttributes(filterLotAttributes: AlphaVisionLotFilterAttributes) {
    let attributesArray = null;
    if (filterLotAttributes !== null) {
      if (filterLotAttributes.LotFacings && filterLotAttributes.LotFacings.length === 0) {
        attributesArray = filterLotAttributes ? [{ LOTTYPE: filterLotAttributes.LotTypes }] : null;
      } else if (filterLotAttributes.LotTypes && filterLotAttributes.LotTypes.length === 0) {
        attributesArray = this.filterLotAttributes ? [{ LOTFACING: filterLotAttributes.LotFacings }] : null;
      } else {
        attributesArray = filterLotAttributes ? [{ LOTFACING: filterLotAttributes.LotFacings },
          { LOTTYPE: filterLotAttributes.LotTypes }] : null;
      }
    }
    return attributesArray;
  }

  private updateSelectedLotData(selectedLot: AlphaVisionLot) {
    this.alphaVisionMapService.updateSelectedLot(selectedLot);
    if (selectedLot.lotAttributes.length > 0) {
      this.lotAttributes = new AlphaVisionAttributes(selectedLot.lotAttributes);
    }

    if (selectedLot.plans) {
      const lotPlanIds = selectedLot.plans.map(p => p.id);
      this.alphaVisionMapService.updateMapPlanIds(lotPlanIds);
    }
  }

  private setLotAndQMICountsFromLots(lots: AlphaVisionLot[]) {
    if (lots && this.selectedPlan && this.selectedPlan.Id) {
      this.lotsCount.HomesiteLotsCount = lots.filter(l => l.plans && l.plans.some(p => p.id === this.selectedPlan.Id.toString())).length;
      this.lotsCount.QmiLotsCount = lots.filter(l => l.status === AlphaVisionLotStatusType.QuickMoveIn &&
                                                     l.plans &&
                                                     l.plans.some(p => p.id === this.selectedPlan.Id.toString())).length;
      if (this.initialLots.length) {
        this.lotsCount.AvailableHomesiteLotsCount = this.initialLots.filter(l =>
          l.status === AlphaVisionLotStatusType.Available &&
          l.plans &&
          l.plans.some(p => p.id === this.selectedPlan.Id.toString())).length;
      }
      this.setLotCount.emit(this.lotsCount);
    }
  }

  private setInitialLots(lots: AlphaVisionLot[]) {
    if (lots) {
      this.initialLots = lots;
      this.alphaVisionMapService.updateInitialLots(lots);
    }
  }

  private showPlans() {
    if (this.mapAPI) {
      this.mapAPI.showPlans(this.planIds);
    }
  }

  private getLotsFilteredByStatus(lots: AlphaVisionLot[]) {
    const filterLotsStatuses = this.statuses && this.statuses.length ?
                               this.statuses                         :
                               this.allStatuses;
    return lots.filter(lot => filterLotsStatuses.includes(lot.status));
  }

  public resetMapFilters() {
    this.statuses = this.allStatuses;
    this.planIds = [];
    this.filterLotAttributes = null;
  }

  public resetMapData() {
    // If viewing a selected amenity, clear then set mapLotsReady (show legend and nav controls)
    // for if we have a map with lots or not.
    if (this.selectedAmenity) {
      this.resetHomesData();
      this.mapLotsReady = this.sitePlanMapName != null;
      this.filterLots();
    } else if (this.selectedLot) {
      // If viewing a selected Lot or Plan, clear that out and reset Map.
      this.selectedLot = null;
      this.selectedLotId = null;
      this.alphaVisionMapService.selectedLot.next();
      this.filterLots();
    } else if (this.selectedPlan) {
      this.resetHomesData();
    } else {
      // Otherwise, let the back button function navigate back to the Master Map.
      this.alphaVisionMapService.resetAllMapServiceData();
      this.navigateToMasterMap();
    }
    this.mapAPI.reset();
  }

  private resetHomesData() {
    this.selectedLot = null;
    this.selectedLotId = null;
    this.selectedPlan = null;
    this.selectedAmenity = null;
    this.alphaVisionMapService.resetHomesMapServiceData();
  }

  private navigateToMasterMap() {
    if (this.masterMapName) {
      this.mapAPI.selectMap(this.masterMapName);
      this.sitePlanMapName = null;
      this.mapLotsReady = false;
    }
  }

  public mapZoomIn() {
    this.mapAPI.zoomIn();
  }

  public mapZoomOut() {
    this.mapAPI.zoomOut();
  }

  public mapFullscreen() {
    this.fullscreenEnabled = !this.fullscreenEnabled;
    // Due to nature of AV's Map not being responseive to re-sizing,
    // we need to reset the Map DOM and re-init on re-size/fullscreen change
    this.divSitePlan.nativeElement.innerHTML = null;
    this.mapInitialized = false;
    this.initializeAlphaVisionSiteplan();
  }
}
