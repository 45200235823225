
export class CommunityPromotion {
    Category: Promotions;
    Type: string;
    Title: string;
    Description: string;
    Disclaimer: string;
    Rank: number;
    CallToActionText: string;
    DocumentFlag: boolean;
    URL: string;
    PopUpFlag: boolean;
    StartDate: string;
    EndDate: string;
    CallToActionImage: string;
    DisplayImage: string;
  }

export enum Promotions {
  Offers = 'Offer',
  Events = 'Event'
}
