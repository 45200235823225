import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FormValidationService } from 'src/app/core-services/form-validation.service';
import { FormValidationConfigurationModel } from 'src/app/models/form-validation-configuration';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss']
})
export class FormControlErrorComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() input: FormControl;
  @Input() displayError?: boolean = false;
  @Input() type?: string;

  public data: FormValidationConfigurationModel;

  constructor(private formValidationService: FormValidationService) { super(); }

  ngOnInit() {
    this.formValidationService.formValidationConfiguration
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((config: FormValidationConfigurationModel) => {
        if (config) {
          this.data = config;
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
