import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SleepStateConfiguration } from '../models/sleep-state-configuration.model';
import { SleepStateMedia } from '../models/sleep-state-media.model';


@Injectable({
  providedIn: 'root'
})
export class SleepStateService {

  sleepStateConfig = new ReplaySubject<SleepStateConfiguration>(1);
  sleepStateMedia = new ReplaySubject<SleepStateMedia>(1);
  isManuallyTriggered = new ReplaySubject<boolean>(1);

  updateSleepStateConfiguration(sleepStateConfig: SleepStateConfiguration): void {
    this.sleepStateConfig.next(sleepStateConfig);
    this.sleepStateConfig.complete();
  }

  updateSleepStateMedia(sleepStateMedia: SleepStateMedia): void {
    this.sleepStateMedia.next(sleepStateMedia);
    this.sleepStateMedia.complete();
  }

  updateManualTrigger(isManuallyTriggered: boolean) {
    this.isManuallyTriggered.next(isManuallyTriggered);
  }

}


