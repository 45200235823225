import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QMI } from 'src/app/models/product/qmi.model';
import { QmiDescriptionModel } from 'src/app/models/content/qmi/qmi-description.model';
import { BaseComponent } from '../../shared/base/base.component';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-qmi-description',
  templateUrl: './qmi-description.component.html',
  styleUrls: ['./qmi-description.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800 })
  ]
})
export class QmiDescriptionComponent extends BaseComponent implements OnInit {

  @Input() data: QmiDescriptionModel;

  qmi$: Observable<QMI>;

  constructor(private communityService: CommunityService,
              private route: ActivatedRoute) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      takeUntil(this.unsubscribe$)
    ).subscribe((inventoryHomeId: string) => {
      this.qmi$ = this.communityService.getQmiById(inventoryHomeId);
    });
  }

}
