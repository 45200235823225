<section class="interactive-tool" data-analytics-zone="Interactive Tools Modal">
  <div class="modal-header">
    <h4 class="interactive-tool__title modal-title">Interactive Tools</h4>
    <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
  </div>
  <div class="modal-body">
    <div *ngIf="!interactiveTools">Sorry, there are no interactive tools available at this time.</div>
    <div *ngIf="interactiveTools"
          class="interactive-tool__container row justify-content-around">

      <!-- Floor Plan -->
      <div *ngIf="interactiveTools.Floor_Plan_Url"
            class="interactive-tool__item col"
            data-analytics-cta="Interactive Floor Plan"
            (click)='openInteractiveToolModal(interactiveTools.Floor_Plan_Url, interactiveTools.Floor_Plan_Label)'>
       <cl-image *ngIf="interactiveTools.Floor_Plan_Icon"
                  class="w-100"
                  public-id="{{interactiveTools.Floor_Plan_Icon.Src}}"
                  format="auto"
                  quality="auto"
                  type="fetch"
                  crop="fill"
                  width="auto"
                  [attr.alt]="interactiveTools.Floor_Plan_Icon.Title"
                  [attr.aspect-ratio]="1.0">
        </cl-image>
        <span class="interactive-tool__item-label">{{interactiveTools.Floor_Plan_Label}}</span>
      </div>

      <!-- Exterior Options -->
      <div *ngIf="interactiveTools.Exterior_Options_Url"
            class="interactive-tool__item col"
            data-analytics-cta="Interactive Exterior Options"
            (click)='openInteractiveToolModal(interactiveTools.Exterior_Options_Url, interactiveTools.Exterior_Options_Label)'>
        <cl-image *ngIf="interactiveTools.Exterior_Options_Icon"
                   class="w-100"
                   public-id="{{interactiveTools.Exterior_Options_Icon.Src}}"
                   format="auto"
                   quality="auto"
                   type="fetch"
                   crop="fill"
                   width="auto"
                   [attr.alt]="interactiveTools.Exterior_Options_Icon.Title"
                   [attr.aspect-ratio]="1.0">
        </cl-image>
        <span class="interactive-tool__item-label">{{interactiveTools.Exterior_Options_Label}}</span>
      </div>

      <!-- Furniture Planner -->
      <div *ngIf="interactiveTools.Furniture_Planner_Url"
            class="interactive-tool__item col"
            data-analytics-cta="Interactive Furniture Planner"
            (click)='openInteractiveToolModal(interactiveTools.Furniture_Planner_Url, interactiveTools.Furniture_Planner_Label)'>
        <cl-image *ngIf="interactiveTools.Furniture_Planner_Icon"
                   class="w-100"
                   public-id="{{interactiveTools.Furniture_Planner_Icon.Src}}"
                   format="auto"
                   quality="auto"
                   type="fetch"
                   crop="fill"
                   width="auto"
                   [attr.alt]="interactiveTools.Furniture_Planner_Icon.Title"
                   [attr.aspect-ratio]="1.0">
        </cl-image>
        <span class="interactive-tool__item-label">{{interactiveTools.Furniture_Planner_Label}}</span>
      </div>

      <!-- Room Designer -->
      <div *ngIf="interactiveTools.Room_Designer_Url"
            class="interactive-tool__item col"
            data-analytics-cta="Room Designer"
            (click)='openInteractiveToolModal(interactiveTools.Room_Designer_Url, interactiveTools.Room_Designer_Label)'>
        <cl-image *ngIf="interactiveTools.Room_Designer_Icon"
                   class="w-100"
                   public-id="{{interactiveTools.Room_Designer_Icon.Src}}"
                   format="auto"
                   quality="auto"
                   type="fetch"
                   crop="fill"
                   width="auto"
                   [attr.alt]="interactiveTools.Room_Designer_Icon.Title"
                   [attr.aspect-ratio]="1.0">
        </cl-image>
        <span class="interactive-tool__item-label">{{interactiveTools.Room_Designer_Label}}</span>
      </div>

      <!-- Finishing Options -->
      <div *ngIf="interactiveTools.Finishing_Options_Url"
            class="interactive-tool__item col"
            data-analytics-cta="Interactive Finishing Options"
            (click)='openInteractiveToolModal(interactiveTools.Finishing_Options_Url, interactiveTools.Finishing_Options_Label, isFinshingOptions = true)'>
        <cl-image *ngIf="interactiveTools.Finishing_Options_Icon"
                   class="w-100"
                   public-id="{{interactiveTools.Finishing_Options_Icon.Src}}"
                   format="auto"
                   quality="auto"
                   type="fetch"
                   crop="fill"
                   width="auto"
                   [attr.alt]="interactiveTools.Finishing_Options_Icon.Title"
                   [attr.aspect-ratio]="1.0">
        </cl-image>
        <span class="interactive-tool__item-label">{{interactiveTools.Finishing_Options_Label}}</span>
      </div>
    </div>
  </div>
  <div class="back-wrapper modal-footer">
    <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
  </div>
</section>
