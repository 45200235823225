import { Component, OnInit, Input, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { PagesService } from 'src/app/core-services/pages.service';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommunityService } from 'src/app/core-services/community.service';
import { InteractiveToolsModel } from 'src/app/models/interactive-tools-configuration.model';
import { PlanToolsModalComponent } from '../plan-tools-modal/plan-tools-modal.component';
import { InteractiveToolsService } from 'src/app/core-services/interactive-tool.service';
import { Plan } from 'src/app/models/product/plan.model';
import { PlanFloorPlansModel, FloorPlansConfigurationModel } from 'src/app/models/content/plan/plan-floor-plans.model';
import { CarouselComponent } from '../../media/carousel/carousel.component';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-floor-plan-modal',
  templateUrl: './floor-plan-modal.component.html',
  styleUrls: ['./floor-plan-modal.component.scss']
})
export class FloorPlanModalComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() public data: PlanFloorPlansModel;
  @Input() public planId: string;
  @Input() public floorPlanConfigs: FloorPlansConfigurationModel[] = [];
  @Input() public floorPlanCarousels: CarouselComponent[] = [];

  @ViewChildren(CarouselComponent) carousels !: QueryList<CarouselComponent>;

  interactiveTools: InteractiveToolsModel;
  hasSummary: boolean;
  modalClass: string;
  hasInteractiveTool: boolean = false;
  plan: Plan;
  modalConfigOptions: FloorPlansConfigurationModel[] = [];

  constructor(private communityService: CommunityService,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private pageService: PagesService,
              private interactiveToolsService: InteractiveToolsService) { super(); }

  ngOnInit() {
    combineLatest([ this.communityService.getPlanInfoById(this.planId),
      this.pageService.hasSummary,
      this.interactiveToolsService.InteractiveToolsItem])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((response: any[]) => {
      this.plan = response[0];
      this.hasSummary = response[1];
      this.interactiveTools = response[2];

      if (this.plan != null && this.interactiveTools != null) {
        if (this.plan.InteractiveFloorPlanUrl || this.plan.ExteriorDesignUrl || this.plan.DesignCenterUrl ||
          this.plan.RoomDesignerUrl || this.plan.EnvisionUrl) {
            this.interactiveTools.Floor_Plan_Url = this.plan.InteractiveFloorPlanUrl;
            this.interactiveTools.Exterior_Options_Url = this.plan.ExteriorDesignUrl;
            this.interactiveTools.Furniture_Planner_Url = this.plan.DesignCenterUrl;
            this.interactiveTools.Room_Designer_Url = this.plan.RoomDesignerUrl;
            this.interactiveTools.Finishing_Options_Url = this.plan.EnvisionUrl;

            this.hasInteractiveTool = true;
        }
      }
    });
    // create a deep copy of Config options to avoid altering the previous carousel
    this.modalConfigOptions = JSON.parse(JSON.stringify(this.floorPlanConfigs));
    this.modalConfigOptions.map(config => {
      config.CarouselOptions.InitialSlideId = config.ActiveImageIndex.toString();
      config.ActiveImageIndex = config.ActiveImageIndex;
      config.CarouselOptions.ShowModalCta = false;
      config.CarouselOptions.IsFloorPlan = true;
    });
  }

  ngAfterViewInit() {
    this.carousels.changes
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((carousels: QueryList<CarouselComponent>) => {
      this.floorPlanCarousels = carousels.toArray();
    });
    // this triggers the change event for the carousels so that the first click is not hijacked.
    this.carousels.setDirty();
  }

  setActiveImageSelection(activeSlideId: number): void {
    const activeConfig: FloorPlansConfigurationModel = this.modalConfigOptions.find(config => config.IsSelected === true);
    if (activeConfig) {
      activeConfig.ActiveImageIndex = activeSlideId;
    }
  }

  selectConfiguration(index: number): void {
    if (this.modalConfigOptions && this.modalConfigOptions[index]) {
      this.modalConfigOptions.map(config => config.IsSelected = false);
      this.modalConfigOptions[index].IsSelected = true;
    }
  }

  selectFloorPlanImage(activeConfigIndex: number, carouselIndex: number, imageIndex: number): void {
    if (this.modalConfigOptions && this.modalConfigOptions[activeConfigIndex]
      && this.floorPlanCarousels && this.floorPlanCarousels[carouselIndex]) {
      this.modalConfigOptions[activeConfigIndex].ActiveImageIndex = imageIndex;
      this.floorPlanCarousels[carouselIndex].selectSlide(imageIndex);
    }
  }

  openPlanTools(): void {
    const modalClass = this.hasSummary ? ModalClasses.Summary : '';
    const modalRef: NgbModalRef = this.modalService.open(PlanToolsModalComponent,
      { windowClass: `${ModalClasses.Full} ${modalClass}`, backdrop: false });
    modalRef.componentInstance.interactiveTools = this.interactiveTools;
  }

}
