import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import Keyboard from 'simple-keyboard';
import { KeyboardButtonTheme } from 'src/app/models/enums/keyboard-enum';

@Component({
  selector: 'app-form-keyboard',
  templateUrl: './form-keyboard.component.html',
  styleUrls: ['./form-keyboard.component.scss']
})
export class FormKeyboardComponent implements OnInit, AfterViewInit {

  @Input() theme: string;
  @Input() layout;
  @Input() suggestions ?: string[] = [];
  @Input() form ?: NgForm;
  @Output() keyPress ? = new EventEmitter<string>(true);
  @Output() formSubmitted = new EventEmitter<boolean>(true);

  suggestionButtonRow: string = '';
  keyboard: Keyboard;
  selectedInput: string;

  constructor() { }

  ngOnInit() {
    // Make sure keyboard layout has three suggestion buttons, these buttons should show up even when empty
    this.suggestionButtonRow = `${this.suggestions[0] || ''} ${this.suggestions[1] || ''} ${this.suggestions[2] || ''}`;
  }

  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      preventMouseDownDefault: true,
      theme: this.theme ? this.theme : 'hg-theme-ios',
      layout: this.layout ? this.layout : {
        default: [
          this.suggestionButtonRow,
          'q w e r t y u i o p',
          'a s d f g h j k l',
          '{shift} z x c v b n m {bksp}',
          '{alt} .com {space} @ . {send}'
        ],
        shift: [
          this.suggestionButtonRow,
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          '{shiftactivated} Z X C V B N M {bksp}',
          '{alt} .com {space} @ . {send}'
        ],
        alt: [
          this.suggestionButtonRow,
          '1 2 3 4 5 6 7 8 9 0',
          `# $ & % * ( ) ' -`,
          '{shift} + = / ; : ! ? {bksp}',
          '{default} .com {space} @ . {send}'
        ]
      },
      display: {
        '{alt}': '123',
        '{shift}': '⇧',
        '{shiftactivated}': '⇧',
        '{bksp}': '⌫',
        '{send}': 'Send',
        '{space}': 'Space',
        '{default}': 'ABC'
      }
    });

    this.keyboard.addButtonTheme('{bksp} {shift} {shiftactivated} {alt} {default} .com @ .', KeyboardButtonTheme.Medium);
    this.keyboard.addButtonTheme('{bksp} {shift} {shiftactivated} {alt} {default} .com @ . {send}', KeyboardButtonTheme.Dark);
    this.keyboard.addButtonTheme(this.suggestionButtonRow, KeyboardButtonTheme.Suggestion);

    document.querySelectorAll('.input').forEach(input => {
      input.addEventListener('focus', this.onInputFocus);
      input.addEventListener('input', this.onInputChange);
    });
  }

  onChange(input) {
    (document.querySelector('#' + this.selectedInput || '.input') as HTMLInputElement).value = input;

    // Sometimes we can pass a form into the keyboard component to do an additional FormControl value setting.
    // This does actual input value updating and validation that an ordinary input Element value update would not.
    // This also assumes the form control's name AND id are the same value in the original form template
    if (this.form) {
      const formInput = this.form.controls[this.selectedInput];
      if (formInput) {
        formInput.setValue(input);
      }
    }
  }

  onInputFocus = (event) => {
    this.selectedInput = `${event.target.id}`;

    this.keyboard.setOptions({
      inputName: event.target.id
    });
  }

  onInputChange = (event) => {
    this.keyboard.setInput(event.target.value, event.target.id);
  }

  onKeyPress(button) {
    this.keyPress.emit(button);
    if (button.includes('{') && button.includes('}')) {
      this.handleLayoutChange(button);
    }
  }

  submitForm() {
    this.formSubmitted.emit(true);
  }

  handleLayoutChange(button) {
    const currentLayout = this.keyboard.options.layoutName;
    let layoutName;

    switch (button) {
      case '{shift}':
      case '{shiftactivated}':
      case '{default}':
        layoutName = currentLayout === 'default' ? 'shift' : 'default';
        break;

      case '{alt}':
        layoutName = currentLayout === 'alt' ? 'default' : 'alt';
        break;
      case '{send}':
        this.submitForm();
        break;

      default:
        break;
    }

    if (layoutName) {
      this.keyboard.setOptions({
        layoutName
      });
    }
  }
}
