export class PointOfInterestDetails {
  Name: string;
  Address: string;
  Rating: number;
  RatingsCount: number;
  Price: string;
  Description: string;
  SubDescription: string;
  TypeDescriptor: string;
}
