import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-community-hero',
  templateUrl: './community-hero.component.html',
  styleUrls: ['./community-hero.component.scss']
})
export class CommunityHeroComponent implements OnInit {

  @Input() data: any;

  constructor() { }

  ngOnInit() {

  }

}
