<section *ngIf="carouselOptions" class="Carousel">
  <div *ngIf="!carouselOptions.HasSingleImage"
       class="rounded-top rounded-bottom"
       [ngClass]="{'carousel--floor-plan': carouselOptions.IsFloorPlan,
                   'Carousel--has-caption': carouselOptions.ShowImageCaption,
                   'Carousel--elevated-arrows': carouselOptions.ShowImageCaption && !carouselOptions.UseSmallArrows,
                   'Carousel--small-arrows': carouselOptions.UseSmallArrows,
                   'd-none' : imagesLoading}">
  <ngb-carousel [interval]="carouselOptions.RotationInterval"
                [showNavigationArrows]="carouselOptions.ShowArrows"
                [showNavigationIndicators]="carouselOptions.ShowPips"
                [activeId]="carouselOptions.InitialSlideId"
                (slide)="onSlideChange($event)">
    <ng-template *ngFor="let image of carouselOptions.Images; let i = index" ngbSlide [id]="i.toString()">
      <cl-image class="w-100"
        public-id="{{image.Path}}"
        [ngClass]="[carouselOptions.ShowPips ? 'pb-5' : '']"
        (onLoad)="imageLoaded(i)"
        (onError)="imageError(i)"
        [attr.alt]="image.AltText"
        [attr.width]="carouselOptions.Width"
        [attr.aspect-ratio]="carouselOptions.AspectRatio"
        (click)="onSlideClick(i)">
      </cl-image>
      <div class="carousel-caption" *ngIf="carouselOptions.ShowImageCaption">
        <span *ngIf="carouselOptions.ShowImageIndex" class="pr-3">
          {{i + 1}} / {{carouselOptions.Images.length}}</span>
          {{image.Caption}}
        </div>
    </ng-template>
  </ngb-carousel>
  </div>
  <div *ngIf="carouselOptions.HasSingleImage"
       [ngClass]="{'Carousel--has-caption': carouselOptions.ShowImageCaption,
                   'd-none' : imagesLoading}"
       (click)="onSlideClick(0)">
    <cl-image class="w-100 rounded-top rounded-bottom"
      public-id="{{carouselOptions.Images[0].Path}}"
      format="auto"
      quality="auto"
      type="fetch"
      crop="fill"
      (onLoad)="imageLoaded()"
      (onError)="imageError()"
      [attr.alt]="carouselOptions.Images[0].AltText"
      [attr.width]="carouselOptions.Width"
      [attr.aspect-ratio]="carouselOptions.AspectRatio">
    </cl-image>

    <div class="carousel-caption"
        *ngIf="carouselOptions.ShowImageCaption">
      <span *ngIf="carouselOptions.ShowImageIndex" class="pr-3"></span>
      {{carouselOptions.Images[0].Caption}}
    </div>
  </div>
  <div>
    <div *ngIf="carouselOptions.StatusOverlayText && carouselOptions.StatusOverlayText.length > 0"
          class="Carousel__status-overlay position-absolute text-light bg-dark p-1">
      <div *ngFor="let overlayLabel of carouselOptions.StatusOverlayText;">
        {{overlayLabel}}
      </div>
    </div>
    <div *ngIf="carouselOptions.ShowModalCta"
          class="Carousel__modalCta position-absolute cursor-pointer"
          [ngClass]="{'Carousel__modalCta--has-caption': carouselOptions.ShowImageCaption}"
          (click)="onSlideClick(activeSlideId)">
    </div>
    <div *ngIf="carouselOptions.CarouselBanner?.Src"
          class="Carousel__banner position-absolute h-25 w-100"
          [ngClass]="{'Carousel__banner--has-caption': carouselOptions.ShowImageCaption}">
      <cl-image class="w-100 h-100"
        public-id="{{carouselOptions.CarouselBanner.Src}}"
        format="auto"
        quality="auto"
        type="fetch"
        crop="fill"
        [attr.alt]="carouselOptions.CarouselBanner.Alt"
        [attr.width]="auto"
        [attr.aspect-ratio]="7.5">
      </cl-image>
    </div>
  </div>

  <div *ngIf="imagesLoading" class="Carousel__loading-spinner position-relative h-100 w-100">
      <app-loading-spinner [loading]="imagesLoading" [scale]="3"></app-loading-spinner>
  </div>
</section>
