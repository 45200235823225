import { Injectable } from '@angular/core';
import { HersScoreModel } from '../models/hers-score-configuration.model';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HersScoreService {
  HersScoreitem = new ReplaySubject<HersScoreModel>(1);

  updateHersScore(HersScore: HersScoreModel): void {
    this.HersScoreitem.next(HersScore);
    this.HersScoreitem.complete();
  }
}

