import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { getHttpOptions } from 'src/helper-functions/http-helper';
import { GuestRegistrationResponseModel } from '../models/content/editorial/guest-registration-response.model';
import { GuestRegistrationModel } from '../models/content/editorial/guest-registration.model';
import { AppInsightsService } from './app-insights.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class GuestRegistrationService {

  private apiUrl: string;

  constructor(private http: HttpClient,
              private appInsightsService: AppInsightsService,
              private settingsService: SettingsService) { 
    this.apiUrl = this.settingsService.apiUrl + '/guestregistration';
  }

  async registerContact(model: GuestRegistrationModel) {
    if (model) {
      model.IsKioskRegistration = true;
      const options = getHttpOptions();

      return await this.http.post<GuestRegistrationResponseModel>(`${this.apiUrl}/RegisterContact`, JSON.stringify(model), options).pipe(
        timeout(30000),
        catchError((err: HttpErrorResponse) => {
          this.appInsightsService.logException(new Error(`An error occurred for Guest Registration service: ${err.message}`), false);
          return of(new GuestRegistrationResponseModel()); // default IsSuccessful = false
        })
      )
      .toPromise();
    }
  }
}
