export enum ImageType {
  CommunityOverview = 'Community Overview',
  CommunityAmenity = 'Community Amenity',
  HomeExterior = 'Home Exterior',
  PlanFloorplan = 'Plan Floorplan',
  HomeInterior = 'Home Interior',
  PlanOption = 'Plan Option',
  PlanExterior = 'Plan Exterior',
  Promotion = 'Promotion',
  InventoryInterior = 'Inventory Interior',
  CommunityHOA = 'Community HOA',
  BrandOffer = 'Brand Offer',
  InventoryElevation = 'Inventory Elevation',
  LifeTestedFeature = 'LifeTested Feature',
  SeriesImage = 'Series Image',
  KioskSleepStateIcon = 'Kiosk Sleep State Icon',
  KioskSleepStateImage = 'Kiosk Sleep State Image'
}
