import { CarouselOptionsModel } from '../../carousel-options.model';
import { KioskImage } from '../media/kiosk-image.model';

export class PlanFloorPlansModel {
  Header_Text: string;
  Button_CTA: string;
  Button_Icon: KioskImage;
  Modal_Header: string;
}

export class FloorPlansConfigurationModel {
  IsSelected: boolean;
  CarouselOptions: CarouselOptionsModel;
  ConfigurationName: string;
  Index: number;
  ActiveImageIndex: number;
  CarouselIndex: number;
  ValidSingleImage: boolean;

  constructor() {
    this.IsSelected = false;
    this.ActiveImageIndex = 0;
  }
}
