import { HttpHeaders } from '@angular/common/http';

// Creates component based on type
export function getHttpOptions() {
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return options;
}
