import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';
import { GalleryModalModel } from 'src/app/models/content/media/gallery-modal.model';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit {

  @Input() public carouselOptions: CarouselOptionsModel;
  @Input() public galleryModalModel: GalleryModalModel;
  modalCarouselOptions: CarouselOptionsModel;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // create a deep copy of carousel options to avoid altering the previous carousel
    this.modalCarouselOptions = JSON.parse(JSON.stringify(this.carouselOptions));
    this.modalCarouselOptions.ShowModalCta = false;
  }

}
