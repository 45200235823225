import { Injectable, Type } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GuestRegistrationModalComponent } from '../community/shared/modals/guest-registration-modal/guest-registration-modal.component';
import { DynamicComponentTypes } from '../shared/dynamic-component-types';
import { ModalClasses } from '../shared/modal-classes';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private ngbModalService: NgbModal) { }

  openModalFromModalRef(modalRefData): NgbModalRef {
    if (modalRefData && modalRefData.Module_Type) {
      const modalType: Type<any> = DynamicComponentTypes[modalRefData.Module_Type];
      const modalRef = this.openModal(modalType, modalRefData);
      return modalRef;
    }
  }

  openModal(componentType, modalData?): NgbModalRef {
    if (componentType) {
      const modalOptions = this.getModalOptionsFromType(componentType);
      const modalRef: NgbModalRef = this.ngbModalService.open(componentType, modalOptions);
      modalRef.componentInstance.data = modalData;
      return modalRef;
    }
  }

  getModalOptionsFromType(componentType) {
    switch (componentType) {
      case GuestRegistrationModalComponent:
        return { windowClass: `${ModalClasses.Full} ${ModalClasses.Right} `, backdrop: true, backdropClass: ` ${ModalClasses.BackDrop}` };
      default:
        return {};
    }
  }

  hasOpenModals() {
    return this.ngbModalService.hasOpenModals();
  }

  dismissAll() {
    this.ngbModalService.dismissAll();
  }
}
