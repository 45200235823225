<div class="icon-cta" *ngIf="icon && label">
  <cl-image *ngIf="icon.Src"
  public-id="{{icon.Src}}"
  format="auto"
  quality="auto"
  type="fetch"
  crop="fill"
  [attr.alt]="icon.Alt"
  [attr.width]="55"
  [attr.height]="55"
  [attr.aspect-ratio]="1"></cl-image>
  {{label}}
</div>
