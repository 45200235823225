import { Component, OnInit, Input } from '@angular/core';
import { KioskImage } from 'src/app/models/content/media/kiosk-image.model';

@Component({
  selector: 'app-icon-cta',
  templateUrl: './icon-cta.component.html',
  styleUrls: ['./icon-cta.component.scss']
})
export class IconCtaComponent implements OnInit {

  @Input() public icon: KioskImage;
  @Input() public label: string;

  constructor() { }

  ngOnInit() {
  }

}
