<section *ngIf="data && community" class="CommunityTours my-4" [@fadeInOnEnter]>
  <div *ngIf="(hasVideos || community.VirtualTour)">
    <h3 *ngIf="data.Header_Text">{{data.Header_Text}}</h3>
    <div>
      <app-icon-cta *ngIf="community.VirtualTour && data.Virtual_Tour_CTA_Text"
        [label]='data.Virtual_Tour_CTA_Text'
        [icon]='data.Virtual_Tour_CTA_Icon'
        data-analytics-cta="Virtual Tour"
        (click)='openVirtualTourModal(data.Virtual_Tour_CTA_Text)'>
      </app-icon-cta>
      <app-icon-cta *ngIf="hasVideos && data.Video_CTA_Text"
        [label]='data.Video_CTA_Text'
        [icon]='data.Video_CTA_Icon'
        data-analytics-cta="Video Tour"
        (click)='openVideoModal(community.Videos[0].VideoPath, community.Videos[0].Caption, data.Video_CTA_Text)'>
      </app-icon-cta>
    </div>
  </div>
</section>
