import { Pipe, PipeTransform } from '@angular/core';
import { HorizontalAlignment } from 'src/app/models/enums/dropdown-enum';

@Pipe({
  name: 'alignmentClass'
})
export class AlignmentStylePipe implements PipeTransform {

  transform(sitecorePosition: string): string {
    let alignmentClass: string;
    switch (sitecorePosition) {
      case HorizontalAlignment.Center:
        alignmentClass = 'justify-content-center text-center';
        break;
      case HorizontalAlignment.Right:
        alignmentClass = 'justify-content-end text-right';
        break;
      default:
        alignmentClass = 'justify-content-start text-left';
    }
    return alignmentClass;
  }

}
