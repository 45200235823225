import { Image } from 'src/app/models/product/image.model';
import { LatLng } from '@agm/core';

export class GoogleDirectionsRouteData {
  public Route: google.maps.DirectionsRoute;
  public MidwayLatLng: google.maps.LatLng;

  constructor(route, midwayLatLng) {
    this.Route = route;
    this.MidwayLatLng = midwayLatLng;
  }
}
