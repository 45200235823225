import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { GMapsMarker } from '../models/maps/gmaps-marker.model';
import { GoogleMapsConfiguration } from '../models/maps/google-maps-configuration.model';
import { getLocalStyleVarUrl, convertSvgToUrl, getLocalStyleVar } from 'src/helper-functions/local-resource-helper';

@Injectable({
  providedIn: 'root'
})
export class GMapsMarkerService {

  markers = new ReplaySubject<GMapsMarker[]>(1);
  mapConfiguration = new ReplaySubject<GoogleMapsConfiguration>(1);
  defaultHomeMarkerIcon: string = getLocalStyleVarUrl('--brand-map-marker-home-icon');
  sitecoreHomeMarkerIcon: string;
  brandColorToken: string = '{brandColor}';

  updateGMapsMarkers(markers: GMapsMarker[]): void {
    this.markers.next(markers);
  }

  updateMapConfiguration(mapConfiguration: GoogleMapsConfiguration): void {
    if (mapConfiguration) {
      this.setBrandColorsForPins(mapConfiguration);
      this.mapConfiguration.next(mapConfiguration);
      this.mapConfiguration.complete();
    }
  }

  // Create our Community Point/origin point for the map
  setCommunityMapMarkerForDisplay(brandName: string, latitude: number, longitude: number,
                                  mapConfiguration: GoogleMapsConfiguration, mapMarkers: GMapsMarker[]) {
    if (brandName && latitude && longitude) {
      const originMarker = new GMapsMarker(null, latitude, longitude);
      originMarker.IsOrigin = true;
      originMarker.IsSelected = true;
      const homeMarkerIcon = this.setHomeMarkerIcon(brandName, mapConfiguration);
      originMarker.setIconUrl(homeMarkerIcon);
      originMarker.setScale(1.3);
      originMarker.zIndex = 100;
      mapMarkers.push(originMarker);
    }
  }

  // Deselects all markers in supplied array
  deselectAllMarkers(markerArray: GMapsMarker[]) {
    markerArray.map(m => m.IsSelected = false);
  }

  setSelectedMarker(markerID: string | number, mapMarkers: GMapsMarker[]) {
    this.deselectAllMarkers(mapMarkers);
    if (mapMarkers.find(m => m.MarkerID === markerID)) {
      mapMarkers.find(m => m.MarkerID === markerID).IsSelected = true;
    }
    this.updateGMapsMarkers(mapMarkers);
  }

  // Sets the home community pin for the supplied community brand
  setHomeMarkerIcon(brandName: string, mapConfiguration: GoogleMapsConfiguration): string {
    let homeMarkerIcon;
    if (brandName && mapConfiguration) {
      homeMarkerIcon = convertSvgToUrl(mapConfiguration.Home_Community_Pin);
    } else {
      homeMarkerIcon = this.defaultHomeMarkerIcon;
    }
    return homeMarkerIcon;
  }

  // Replaces merge field tokens with brand colors
  setBrandColorsForPins(mapConfiguration: GoogleMapsConfiguration) {
    if (mapConfiguration) {
      const defaultColor = getLocalStyleVar('--brand-map-default-pin-color');
      const selectedColor = getLocalStyleVar('--brand-map-selected-pin-color');
      mapConfiguration.Home_Community_Pin =
        mapConfiguration.Home_Community_Pin.replace(this.brandColorToken, selectedColor);
      mapConfiguration.Location_Default_Pin =
        mapConfiguration.Location_Default_Pin.replace(this.brandColorToken, defaultColor);
      mapConfiguration.Location_Selected_Pin =
        mapConfiguration.Location_Selected_Pin.replace(this.brandColorToken, selectedColor);
    }
  }
}

