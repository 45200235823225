import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { Observable } from 'rxjs';
import { Community } from 'src/app/models/product/community.model';

@Component({
  selector: 'app-community-gallery',
  templateUrl: './community-gallery.component.html',
  styleUrls: ['./community-gallery.component.scss']
})
export class CommunityGalleryComponent implements OnInit {

  @Input() data: any;

  community$: Observable<Community>;

  constructor(private communityService: CommunityService) { }

  ngOnInit() {
    this.community$ = this.communityService.community;
  }
}
