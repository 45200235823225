<div *ngIf="data?.Enable_LMP && mortgagePayment" class="LmpTooltip">
  <div class="LmpTooltip__button btn-i btn-circle btn-sm cursor-pointer ml-1"
    placement="auto"
    [ngbTooltip]="LmpTooltip"
    tooltipClass="LmpTooltip__body m-1"
    container="body"
    triggers="click:blur">
    i
  </div>
  <ng-template #LmpTooltip>
      <div class="LmpTooltip__content rounded-lg p-4 w-100 d-flex justify-content-center flex-column">
        <div *ngIf="displayPIOnly && data.PI_Only_Disclaimer">
          <div class="LmpTooltip__header mb-2"><u>{{data.PI_Only_Tooltip_Header}}</u></div>
          <div class="LmpTooltip__disclaimer"
            [innerHTML]="data.PI_Only_Disclaimer
              | mergeField: '{DiscountedPrice}':(mortgagePayment.DiscountedPrice | currency: 'USD':'symbol':'1.0-0')
              | mergeField: '{LoanAmount}':(mortgagePayment.LoanAmount | currency: 'USD':'symbol':'1.0-0')
              | mergeField: '{InterestRatePercent}':mortgagePayment.InterestRatePercent + '%'
              | mergeField: '{EffectiveRate}':mortgagePayment.EffectiveRate + '%'
              | mergeField: '{MortgageRateEffectiveDate}':(mortgagePayment.MortgageRateEffectiveDate | date: 'shortDate')
              | mergeField: '{MonthlyTaxes}':(mortgagePayment.MonthlyInsurance | currency: 'USD':'symbol':'1.0-0')
              | mergeField: '{MonthlyInsurance}':(mortgagePayment.MonthlyInsurance | currency: 'USD':'symbol':'1.0-0')">
          </div>
        </div>
        <div *ngIf="!displayPIOnly">
          <div class="LmpTooltip__header">{{data.PITI_Tooltip_Header}}</div>
          <div class="LmpTooltip__close-button position-absolute cursor-pointer pt-2 pr-3">&times;</div>
          <div class="LmpTooltip__divider my-4 w-25"></div>
          <div *ngIf="mortgagePayment.TotalMonthlyPrincipalAndInterest" class="d-flex justify-content-between">
            <div>{{data.Total_Monthly_Principal_And_Interest}}</div>
            <div>{{mortgagePayment.TotalMonthlyPrincipalAndInterest | currency: "USD":"symbol"}}</div>
          </div>
          <div *ngIf="mortgagePayment.MonthlyTaxes" class="d-flex justify-content-between">
            <div>{{data.Monthly_Taxes}}</div>
            <div>{{mortgagePayment.MonthlyTaxes | currency: "USD":"symbol"}}</div>
          </div>
          <div *ngIf="mortgagePayment.MonthlyInsurance" class="d-flex justify-content-between">
            <div>{{data.Monthly_Insurance}}</div>
            <div>{{mortgagePayment.MonthlyInsurance | currency: "USD":"symbol"}}</div>
          </div>
          <div class="LmpTooltip__divider w-100 my-4"></div>
          <div *ngIf="mortgagePayment.TotalMonthlyPayment" class="d-flex justify-content-between">
            <div class="w-75">{{data.Total_Monthly_Payment}}</div>
            <div class="align-self-end">{{mortgagePayment.TotalMonthlyPayment | currency: "USD":"symbol"}}</div>
          </div>
          <div *ngIf="data.PITI_Disclaimer" class="mt-3">
            <div class="mb-2">{{data.Accordion_Headline}}</div>
            <div class="LmpTooltip__disclaimer"
              [innerHTML]="data.PITI_Disclaimer
                | mergeField: '{DiscountedPrice}':(mortgagePayment.DiscountedPrice | currency: 'USD':'symbol':'1.0-0')
                | mergeField: '{LoanAmount}':(mortgagePayment.LoanAmount | currency: 'USD':'symbol':'1.0-0')
                | mergeField: '{InterestRatePercent}':mortgagePayment.InterestRatePercent + '%'
                | mergeField: '{EffectiveRate}':mortgagePayment.EffectiveRate + '%'
                | mergeField: '{MortgageRateEffectiveDate}':(mortgagePayment.MortgageRateEffectiveDate | date: 'shortDate')
                | mergeField: '{MonthlyTaxes}':(mortgagePayment.MonthlyInsurance | currency: 'USD':'symbol':'1.0-0')
                | mergeField: '{MonthlyInsurance}':(mortgagePayment.MonthlyInsurance | currency: 'USD':'symbol':'1.0-0')">
            </div>
          </div>
        </div>
      </div>
  </ng-template>
</div>
