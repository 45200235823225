export class ProductFilterDataModel {
  minPrice: number;
  maxPrice: number;
  minSquareFoot: number;
  maxSquareFoot: number;
  minBedrooms: number;
  maxBedrooms: number;
  minBathrooms: number;
  maxBathrooms: number;
  modelAvailableOnly: boolean;
  qmiAvailableOnly: boolean;
  available: boolean;
  sold: boolean;
  notReleased: boolean;
}
