import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private appInsightsService: AppInsightsService) { }

  handleError(error: Error | HttpErrorResponse) {
    let message;
    let stackTrace;
    let isHttpErrorResponse = false;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = this.getServerMessage(error);
      stackTrace = 'no stack trace for server errors';
      isHttpErrorResponse = true;
    } else {
      // Client Error
      message = this.getClientMessage(error);
      stackTrace = this.getClientStack(error);
    }

    const errorObject = new Error(message);
    errorObject.stack = stackTrace;

    // log the error via app insights
    // passing in href from window.location because importing Router to ErrorHandler creates a cyclical dependency
    this.appInsightsService.logException(errorObject, isHttpErrorResponse, window.location.href);
  }

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }

    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message;
  }
}
