import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { GoogleDirectionsRouteData } from '../models/maps/google-directions-route-data.model';

@Injectable({
  providedIn: 'root'
})
export class GmapsDirectionsService {

  private origin = new ReplaySubject<google.maps.LatLng>(1);
  private destination = new ReplaySubject<google.maps.LatLng>(1);
  private showDirection = new ReplaySubject<boolean>(1);
  private travelTime = new ReplaySubject<Date>(1);
  private directionsRoute = new ReplaySubject<google.maps.DirectionsRoute>(1);
  private directionsRouteData = new ReplaySubject<GoogleDirectionsRouteData>(1);

  origin$ = this.origin.asObservable();
  destination$ = this.destination.asObservable();
  showDirection$ = this.showDirection.asObservable();
  travelTime$ = this.travelTime.asObservable();
  directionsRoute$ = this.directionsRoute.asObservable();
  directionsRouteData$ = this.directionsRouteData.asObservable();

  updateOrigin(origin: google.maps.LatLng) {
    this.origin.next(origin);
  }

  updateDestination(destination: google.maps.LatLng) {
    this.destination.next(destination);
  }

  updateShowDirection(showDirection: boolean) {
    this.showDirection.next(showDirection);
  }

  updateTravelTime(travelTime: Date) {
    this.travelTime.next(travelTime);
  }

  updateDirectionsRoute(directionsRoute: google.maps.DirectionsRoute) {
    this.directionsRoute.next(directionsRoute);
  }

  updateDirectionsRouteData(directionsRouteData: GoogleDirectionsRouteData) {
    this.directionsRouteData.next(directionsRouteData);
  }

  clearDirectionsData() {
    this.origin.next(null);
    this.destination.next(null);
    this.travelTime.next(null);
    this.directionsRoute.next(null);
    this.directionsRouteData.next(null);
  }
}
