import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractiveToolsModel } from 'src/app/models/interactive-tools-configuration.model';
import { InteractiveToolModalComponent } from '../interactive-tool-modal/interactive-tool-modal.component';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-plan-tools-modal',
  templateUrl: './plan-tools-modal.component.html',
  styleUrls: ['./plan-tools-modal.component.scss']
})
export class PlanToolsModalComponent extends BaseComponent implements OnInit {

  @Input() public interactiveTools: InteractiveToolsModel;
  private isFinshingOptions: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal) { super(); }

  ngOnInit() {
  }

  openInteractiveToolModal(url: string, title: string, isFinishingOptions?: boolean): void {
    const modalRef: NgbModalRef = this.modalService.open(InteractiveToolModalComponent,
        { windowClass: `${ModalClasses.Full}`, backdrop: false });

    modalRef.componentInstance.url = url;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isFinishingOptions = isFinishingOptions;
  }

}
