import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationItem } from '../models/navigation-item.model';
import { NavigationConfiguration } from '../models/navigation-configuration-model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  navigationItems = new Subject<NavigationItem[]>();
  navConfigItem = new Subject<NavigationConfiguration>();
  private defaultPageId = '';
  private modalNavigationItems: NavigationItem[] = [];
  private sleepStateNavigationItems: NavigationItem[] = [];
  private browserAccessNavigationItems: NavigationItem[] = [];

  updateMoreIcon(navigationItem: NavigationConfiguration): void {
    this.navConfigItem.next(navigationItem);
  }

  updateNavigationsItems(navigationItems: NavigationItem[]): void {
    // filter out nav items that have no Page_Url
    let filteredNavItems = navigationItems.filter(item => item.Page_Url);

    filteredNavItems = this.addAdditionalNavigationItems(this.modalNavigationItems, filteredNavItems);
    filteredNavItems = this.addAdditionalNavigationItems(this.sleepStateNavigationItems, filteredNavItems);
    filteredNavItems = this.addAdditionalNavigationItems(this.browserAccessNavigationItems, filteredNavItems);

    filteredNavItems.sort((a, b) => a.Order - b.Order);

    if (filteredNavItems.length) {
      // checks for first page navigation item to set default page id, non-page items will break navigation
      this.defaultPageId = filteredNavItems.find(navItem => navItem.Page_Url && navItem.Page_Url.trim().length).Page_Url;
    } else {
      this.defaultPageId = '';
    }

    this.navigationItems.next(filteredNavItems);
  }

  getDefaultPageId() {
    return this.defaultPageId;
  }

  addAdditionalNavigationItems(addtionalNavItems: NavigationItem[], pageNavigationItems: NavigationItem[]): NavigationItem[] {
    let combinedNavigationItems = [];
    if (addtionalNavItems && addtionalNavItems.length) {
      combinedNavigationItems = pageNavigationItems.concat(addtionalNavItems);
    } else {
      combinedNavigationItems = pageNavigationItems;
    }
    return combinedNavigationItems;
  }

  setModalNavigationItems(navigationItems: NavigationItem[]) {
    if (navigationItems && navigationItems.length) {
      this.modalNavigationItems = navigationItems.filter(navigationItem => navigationItem.Modal_Ref);
    }
  }

  setSleepStateNavigationItems(sleepStateNavigationItems: NavigationItem[]) {
    if (sleepStateNavigationItems && sleepStateNavigationItems.length) {
      sleepStateNavigationItems.map(sleepStateNavigationItem => {
        sleepStateNavigationItem.IsSleepStateNavItem = true;
        this.sleepStateNavigationItems.push(sleepStateNavigationItem);
      });
    }
  }

  setBrowserAccessNavigationItems(browserAccessNavigationItems: NavigationItem[]) {
    if (browserAccessNavigationItems && browserAccessNavigationItems.length) {
      this.browserAccessNavigationItems = browserAccessNavigationItems.filter(browserAccessNavigationItem =>
        browserAccessNavigationItem.External_URL &&
        browserAccessNavigationItem.External_URL.trim().length &&
        this.validateUrl(browserAccessNavigationItem.External_URL));

      browserAccessNavigationItems.map(browserAccessNavigationItem => {
        browserAccessNavigationItem.External_URL = this.prependExternalUrls(browserAccessNavigationItem.External_URL);
      });
    }
  }

  prependExternalUrls(externalUrl: string): string {
    if (!externalUrl.match(/^https?:\/\//i)) {
      externalUrl = 'https://' + externalUrl;
    }
    return externalUrl;
  }

  validateUrl(externalUrl: string): boolean {
    const urlRegex: RegExp = new RegExp('((https?|ftp|file):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]', 'i');
    return !!(urlRegex.test(externalUrl));
  }
}
