<div *ngIf="videoModalModel" class="PlanVirtualTourModal" appPauseSleepState>
  <div class="modal-header">
    <h4 class="modal-title">{{videoModalModel.ModalHeader}}</h4>
    <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
  </div>
  <div class="PlanVirtualTourModal__body d-flex flex-column modal-body">
    <div class="PlanVirtualTourModal__iframeWrapper">
      <iframe *ngIf="videoModalModel.VirtualTourUrl" [src]="videoModalModel.VirtualTourUrl | safeUrl" height="560" width="315"></iframe>
    </div>
  </div>
  <div class="back-wrapper modal-footer">
    <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
  </div>
</div>
