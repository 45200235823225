import { Image } from 'src/app/models/product/image.model';
import { LatLng } from '@agm/core';

export class GooglePlacesSearchParams {
  public Latitude: number;
  public Longitude: number;
  public Radius: number;
  public Query: string;

  constructor() {
    this.Latitude = null;
    this.Longitude = null;
    this.Radius = null;
    this.Query = null;
  }
}
