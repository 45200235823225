import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { QMI } from 'src/app/models/product/qmi.model';
import { QmiCardModel } from 'src/app/models/content/qmi/qmi-card.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { setTrimUrl } from 'src/helper-functions/sitecore-api-url-helper';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { QMIDiscountOption } from 'src/app/models/enums/qmi-discount-option-enum';

@Component({
  selector: 'app-qmi-card',
  templateUrl: './qmi-card.component.html',
  styleUrls: ['./qmi-card.component.scss']
})
export class QmiCardComponent implements OnInit, OnChanges {

  @Input() data: QmiCardModel;
  @Input() qmi: QMI;

  trimmedUrl: string = setTrimUrl(this.router);

  showSavingsFlag: boolean;
  showWasPrice: boolean;
  priceHasColor: boolean;
  price: number;

  constructor(private router: Router, private modalService: NgbModal, private adobeLaunchService: AdobeLaunchService) { }

  ngOnInit() {
    if (this.qmi) {
      this.showSavingsFlag = 
      this.showWasPrice = 
      this.priceHasColor = this.qmi.PriceDiscount && this.qmi.DisplayDiscountOption == QMIDiscountOption.ApplyDiscountAndShowDetails;
    }
  }

  ngOnChanges() {
    this.price = this.qmi.PriceDiscount && this.qmi.DisplayDiscountOption != QMIDiscountOption.DoNotApplyDiscount ? this.qmi.FinalPrice : this.qmi.Price;
  }

  closeModals(): void {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll();
    }
  }

  handleQmiClicked(qmi: QMI) {
    if (qmi) {
      this.closeModals();
      this.adobeLaunchService.pushQMIListingClickedEvent(qmi);
    }
  }
}
