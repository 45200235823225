import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AdobeLaunchCommunity, AdobeLaunchListing, AdobeLaunchPage, AdobeLaunchPageViewLoadedEvent,
         AdobeLaunchPlanListingDisplayedEvent, AdobeLaunchPlanListingClickedEvent,
         AdobeLaunchPlanListingViewedEvent,
         AdobeLaunchFormSubmittedEvent} from '../models/analytics/adobe-launch-event';
import { ProductFilterDataModel } from '../models/content/community/product-filter-data.model';
import { Community } from '../models/product/community.model';
import { Plan } from '../models/product/plan.model';
import { QMI } from '../models/product/qmi.model';
import { AppInsightsService } from './app-insights.service';
import { CommunityService } from './community.service';
import { ScriptInjectorService } from './script-injector.service';
import { SettingsService } from './settings.service';

declare global {
  interface Window { appEventData: any[]; } // extend global Window object to hold data layer property
}
declare const window: Window;
declare const _satellite: any;

@Injectable({
  providedIn: 'root'
})
export class AdobeLaunchService {

  currentCommunity: Community;
  currentFilterData: ProductFilterDataModel;
  unsubscribe$ = new Subject();

  renderer: Renderer2; // for analytics attribute purposes

  constructor(private scriptInjectorService: ScriptInjectorService, private appInsightsService: AppInsightsService,
              private communityService: CommunityService, private rendererFactory: RendererFactory2,
              private settingsService: SettingsService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initAdobeLaunchDataLayer() {
    window.appEventData = window.appEventData || [];
  }

  async injectAdobeLaunchScript() {
    if (this.settingsService.adobeLaunchScriptUrl) {
      try {
        await this.scriptInjectorService.load('adobeLaunch', this.settingsService.adobeLaunchScriptUrl);
        _satellite.pageBottom(); // Crucial call once the launch scripts are loaded, do not touch.
      } catch (e) {
        this.appInsightsService.logException(
          new Error(`An error occurred while loading Adobe Launch script: ${e}`), false);
      }
    }
  }

  triggerView(viewName: string) {
    // Trigger View for Target and Call Pre-hiding Snippet Code
    if (viewName) {
      if (typeof adobe !== 'undefined' && adobe.target) {
        // Sanitize viewName to get rid of any trailing symbols derived from URL
        if (viewName.startsWith('#') || viewName.startsWith('/')) {
          viewName = viewName.substr(1);
        }
        adobe.target.triggerView(viewName);
        this.prehideBody();
      }
    }
  }

  // tslint:disable
  prehideBody() {
    // Prehiding snippet as provided by Adobe, for Adobe Target with asynchronous Launch deployment.
    // Prempetively hides the app body in preparation for Target to finish loading the page.
    (function(g, b, d, f) {
      (function(a, c, d) {
        if (a) {
          const e = b.createElement('style');
          e.id = c;
          e.innerHTML = d;
          a.appendChild(e);
        }
      })
      (b.getElementsByTagName('head')[0], 'at-body-style', d); // This 'at-body-style' is the Adobe Target-added
                                                               // style class to designate when Target is done loading.
      setTimeout(function() {
        const a = b.getElementsByTagName('head')[0];
        if (a) {
          const c = b.getElementById('at-body-style');
          c && a.removeChild(c);
        }
      }, f);
    })
    (window, document, 'app-page {opacity: 0 !important}', 3E3);
  }
  // tslint:enable

  updateCurrentCommunity(community: Community) {
    this.currentCommunity = community;
  }

  updateCurrentFilterData(filterData: ProductFilterDataModel) {
    this.currentFilterData = filterData;
  }

  pushToDataLayer(event) {
    if (event) {
      this.initAdobeLaunchDataLayer();
      if (window && window.appEventData) {
        window.appEventData.push(event);
      }
    }
  }

  pushPageViewEvent(pageID, pageName, pageRoute) {
    if (pageID && pageName && pageRoute) {
      const pageURL = window.location.origin + pageRoute;

      // Analytics Page Object
      const adobeLaunchPage = new AdobeLaunchPage(pageID, pageName, pageURL);
      if (this.currentCommunity) {
        adobeLaunchPage.community = new AdobeLaunchCommunity(this.currentCommunity);
      }

      // Analytics Event
      const pageViewEvent = new AdobeLaunchPageViewLoadedEvent(adobeLaunchPage);
      this.pushToDataLayer(pageViewEvent);
    }
  }

  pushPlanListingViewedEvent(homeId: string) {
    if (homeId) {
      combineLatest([this.communityService.getPlanById(homeId), this.communityService.getQmiById(homeId)])
        .pipe(take(1))
        .subscribe(([plan, qmi]) => {
          let planListingViewedEvent = null;
          if (plan) {
            planListingViewedEvent = new AdobeLaunchPlanListingViewedEvent(this.currentCommunity, plan, plan.Price);
          }
          if (qmi) {
            const qmiId = qmi.InventoryHomeID && qmi.InventoryHomeID.toString();
            planListingViewedEvent = new AdobeLaunchPlanListingViewedEvent(this.currentCommunity, qmi.Plan, qmi.Price, qmiId);
          }
          if (planListingViewedEvent) {
            this.pushToDataLayer(planListingViewedEvent);
          }
        });
    }
  }

  pushQMIListingDisplayedEvent(qmis: QMI[]) {
    if (this.currentCommunity && qmis && qmis.length) {
      const qmiListings = [];

      qmis.map(qmi => {
        if (qmi) {
          const qmiId = qmi.InventoryHomeID && qmi.InventoryHomeID.toString();
          const qmiListing = new AdobeLaunchListing(this.currentCommunity, qmi.Plan, qmi.Price, qmiId);
          qmiListings.push(qmiListing);
        }
      });

      if (qmiListings.length) {
        const planListingDisplayedEvent = new AdobeLaunchPlanListingDisplayedEvent(qmiListings, this.currentFilterData);
        this.pushToDataLayer(planListingDisplayedEvent);
      }
    }
  }

  pushPlanListingDisplayedEvent(plans: Plan[]) {
    if (this.currentCommunity && plans && plans.length) {
      const planListings = [];

      plans.map(plan => {
        if (plan) {
          const planListing = new AdobeLaunchListing(this.currentCommunity, plan, plan.Price);
          planListings.push(planListing);
        }
      });

      if (planListings.length) {
        const planListingDisplayedEvent = new AdobeLaunchPlanListingDisplayedEvent(planListings, this.currentFilterData);
        this.pushToDataLayer(planListingDisplayedEvent);
      }
    }
  }

  pushQMIListingClickedEvent(qmi: QMI) {
    if (qmi) {
      this.pushPlanListingClickedEvent(qmi.Plan, qmi.Price, qmi.InventoryHomeID && qmi.InventoryHomeID.toString());
    }
  }

  pushPlanListingClickedEvent(plan: Plan, startingPrice: number, qmiID: string = null) {
    if (this.currentCommunity && plan) {
      const planListingClickedEvent = new AdobeLaunchPlanListingClickedEvent(this.currentCommunity, plan, startingPrice, qmiID);
      this.pushToDataLayer(planListingClickedEvent);
    }
  }

  pushFormSubmittedEvent(formName: string, formType: string, plans: Plan[] = null, qmis: QMI[] = null) {
    if (this.currentCommunity && formName && formType) {
      const formSubmittedEvent = new AdobeLaunchFormSubmittedEvent(this.currentCommunity, formName, formType, plans, qmis);
      this.pushToDataLayer(formSubmittedEvent);
    }
  }

  setAnalyticsCtaAttributeOnElem(elem: ElementRef, attrValue: string): void {
    if (elem && attrValue) {
      this.renderer.setAttribute(elem, 'data-analytics-cta', attrValue);
    }
  }

  setAnalyticsZoneAttributeOnElem(elem: ElementRef, attrValue: string): void {
    if (elem && attrValue) {
      this.renderer.setAttribute(elem, 'data-analytics-zone', attrValue);
    }
  }
}
