import { getLocalStyleVar, getLocalStyleVarUrl, convertSvgToUrl } from 'src/helper-functions/local-resource-helper';
import { GoogleMapsConfiguration } from './google-maps-configuration.model';
import { BrandName } from '../enums/brand-enum';

export class GMapsIconScaledSize {
  public height: number;
  public width: number;

  constructor(height: number, width: number) {
    this.height = height;
    this.width = width;
  }
}

export class GMapsIconLabelOrigin {
  public x: number;
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export class GMapsMarkerLabelOptions {
  public color: string;
  public fontSize: number;
  public fontWeight: string;

  private defaultLabelColor = getLocalStyleVar('--brand-map-marker-label-color') || '#000';
  private defaultFontSize = getLocalStyleVar('--brand-map-marker-label-font-size') || 1.5;
  private defaultFontWeight = 'normal';

  constructor(color?: string, fontSize?: number, fontWeight?: string) {
    this.color = color ? color : this.defaultLabelColor;
    this.fontSize = fontSize ? fontSize : this.defaultFontSize;
    this.fontWeight = fontWeight ? fontWeight : this.defaultFontWeight;
  }
}

export class GMapsMarkerIconOptions {
  public scaledSize: GMapsIconScaledSize;
  public labelOrigin: GMapsIconLabelOrigin;

  constructor() {
    this.scaledSize = new GMapsIconScaledSize(45, 45);
    this.labelOrigin = new GMapsIconLabelOrigin(37, 30);
  }
}

export class GMapsMarker {
  public MarkerID: string | number;
  public Latitude: number;
  public Longitude: number;
  public IsOrigin: boolean;
  public IsDestination: boolean;
  public IsSelected: boolean;
  public LabelText: string;
  public zIndex: number;

  public IconUrl: string;
  public IconSelectedUrl: string;
  public LabelOptions: GMapsMarkerLabelOptions;
  public IconOptions: GMapsMarkerIconOptions;

  constructor(markerID: string | number, lat: number, lng: number, googleMapsConfiguration?: GoogleMapsConfiguration, brandName?: string) {
    this.MarkerID = markerID;
    this.Latitude = lat;
    this.Longitude = lng;
    this.IsOrigin = false;
    this.IsDestination = false;
    this.IsSelected = false;
    this.LabelText = ' ';
    this.setIconUrlsByBrand(brandName, googleMapsConfiguration);
    this.zIndex = null;
    this.IconOptions = new GMapsMarkerIconOptions();
    this.LabelOptions = new GMapsMarkerLabelOptions();
  }

  setScale(scale: number) {
    if (this.IconOptions) {
      this.IconOptions.scaledSize = new GMapsIconScaledSize(75 * scale, 75 * scale);
      this.IconOptions.labelOrigin = new GMapsIconLabelOrigin(37 * scale, 30 * scale);
    }
    if (this.LabelOptions) {
      const defaultFontSize = getLocalStyleVar('--brand-map-marker-label-font-size') || 1.5;
      this.LabelOptions.fontSize = defaultFontSize * scale;
    }
  }

  setLabelOptions(fontColor: string, fontSize?: number) {
    if (!this.LabelOptions) {
      this.LabelOptions = new GMapsMarkerLabelOptions();
    }
    if (fontColor) {
      this.LabelOptions.color = fontColor;
    }
    if (fontSize) {
      this.LabelOptions.fontSize = fontSize;
    }
  }

  setIconUrl(iconUrl: string, iconSelectedUrl?: string) {
    if (iconUrl) {
      this.IconUrl = iconUrl;
    }
    if (iconSelectedUrl) {
      this.IconSelectedUrl = iconSelectedUrl;
    } else {
      this.IconSelectedUrl = iconUrl;
    }
  }

   // Sets pins based on SVGs set in Sitecore per brand
   setIconUrlsByBrand(brandName: string, mapConfiguration: GoogleMapsConfiguration) {
    // get brand specific icons from the config item passed in, convert to url for google maps
    if (brandName && mapConfiguration) {
      switch (brandName) {
        case BrandName.Pulte:
          this.setIconUrl(
                 convertSvgToUrl(mapConfiguration.Pulte_Default_Pin),
                 convertSvgToUrl(mapConfiguration.Pulte_Selected_Pin));
          break;
        case BrandName.DelWebb:
          this.setIconUrl(
                 convertSvgToUrl(mapConfiguration.Del_Webb_Default_Pin),
                 convertSvgToUrl(mapConfiguration.Del_Webb_Selected_Pin));
          break;
        case BrandName.Centex:
          this.setIconUrl(
                 convertSvgToUrl(mapConfiguration.Centex_Default_Pin),
                 convertSvgToUrl(mapConfiguration.Centex_Selected_Pin));
          break;
        case BrandName.DiVosta:
          this.setIconUrl(
                 convertSvgToUrl(mapConfiguration.Divosta_Default_Pin),
                 convertSvgToUrl(mapConfiguration.Divosta_Selected_Pin));
          break;
        case BrandName.JohnWieland:
          this.setIconUrl(
                 convertSvgToUrl(mapConfiguration.John_Wieland_Default_Pin),
                 convertSvgToUrl(mapConfiguration.John_Wieland_Selected_Pin));
          break;
        case BrandName.AmericanWest:
          this.setIconUrl(
                 convertSvgToUrl(mapConfiguration.American_West_Default_Pin),
                 convertSvgToUrl(mapConfiguration.American_West_Selected_Pin));
          break;
      }
    // get current brand's map pin SVGs from config passed in, convert to url for google maps
    } else if (mapConfiguration && mapConfiguration.Location_Default_Pin && mapConfiguration.Location_Selected_Pin) {
      this.setIconUrl(
        convertSvgToUrl(mapConfiguration.Location_Default_Pin),
        convertSvgToUrl(mapConfiguration.Location_Selected_Pin));
    // if the map config is not valid, pull the default icon values from the brand's .scss file
    } else {
      this.setIconUrl(
        getLocalStyleVarUrl('--brand-map-marker-icon'),
        getLocalStyleVarUrl('--brand-map-marker-selected-icon'));
    }
  }

  setLabelAdjacentMarkerOptions() {
    if (this.IconOptions) {
      this.IconOptions.labelOrigin = new GMapsIconLabelOrigin(45, -8);
    }
    this.LabelOptions = new GMapsMarkerLabelOptions('#000', 1, 'bold');
  }
}
