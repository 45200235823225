import { ImageType } from 'src/app/models/enums/image-enum';
import { Image } from 'src/app/models/product/image.model';

export function checkForImages(imageArray: Image[]): boolean {
  return imageArray && imageArray.length ? true : false;
}

export function checkForSingleImage(imageArray): boolean {
  return imageArray && imageArray.length === 1 && imageArray[0] && imageArray[0].Path ? true : false;
}

export function sortImagesByImageRank(imageArray: Image[]): Image[] {
  if (imageArray) {
    // If rank is 0 it is set to Do Not Show in CMS
    return imageArray.filter(x => x.ImageRank > 0).sort((a, b) => a.ImageRank - b.ImageRank);
  }
}

export function sortImagesByKioskRank(imageArray: Image[]): Image[] {
  let sortedImages: Image[] = [];

  if (imageArray) {
    // If rank is 0 it is set to Do Not Show in CMS
    const kioskImages = imageArray.filter(x => x.KioskRank > 0).sort((a, b) => a.KioskRank - b.KioskRank);

    if (kioskImages && kioskImages.length) {
      sortedImages = kioskImages;
    } else {
      // use Community Gallery images if Kiosk Rank isn't set
      imageArray = imageArray.filter(image => image.ImageType === ImageType.CommunityOverview);
      sortedImages = sortImagesByImageRank(imageArray);
    }
  }

  return sortedImages;
}

export function isImageUrl(url: string) {
  return (url.match(/\.(jpeg|jpg|gif|png|ashx)$/) != null);
}
