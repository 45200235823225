<form class="CommunityHomesFilter"
      [ngClass]="{'CommunityHomesFilter--many-checkboxes': checkboxes?.length > 3}"
      #homeFiltersForm="ngForm"
      id="homeFiltersForm"
      name="homeFiltersForm"
      [@fadeIn]='showFilters'>
  <div *ngIf="showFilters"
        class="CommunityHomesFilter__background-screen"
       (click)="toggleShowFilters()"
></div>
  <a class="CommunityHomesFilter__button rounded">
    <span class="CommunityHomesFilter__button-text px-4 pt-2 pb-4"
          [ngClass]="{ 'CommunityHomesFilter__button-text--show-aditional-filters': showFilters }"
          [@fadeInOnEnter]
          (click)="toggleShowFilters()">
        <span class="CommunityHomesFilter__filter-icon"></span>
        {{ showFilters ? "Hide" : "" }} {{data?.Filter_Name}}
        <span *ngIf="formFilterCount && !showFilters"
              class="CommunityHomesFilter__filter-count px-3 py-1">{{ formFilterCount }}
        </span>
    </span>
  </a>
  <div *ngIf="showFilters"
        id="filterContent"
        class="CommunityHomesFilter__filter-content rounded mb-2">
    <div class="CommunityHomesFilter__filter-row pl-4 pt-4">
      <div class="CommunityHomesFilter__filter-label-row d-flex flex-row align-items-center justify-content-center">
        <span class="CommunityHomesFilter__label">{{data?.Filter_Price}}</span>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="number"
              name="minPriceFilterInput"
              placeholder={{data?.Filter_Price_Range_Minimum}}
              #minPriceFilterInput="ngModel"
              [(ngModel)]="minPriceFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(minPriceFilterInput)"
            />
            <div *ngIf="minPriceFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(minPriceFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === minPriceFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of minPrice | rangeArray:(maxPriceFilterValue || maxPrice):true:false:50000'
                    class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="minPriceFilterValue"
                    [checked]="minPriceFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2" for="textInputRadio">
                    {{value | abbrevCurrency: 0:'USD':'symbol':'en-US'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span>to</span>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="number"
              name="maxPriceFilterInput"
              placeholder={{data?.Filter_Price_Range_Maximum}}
              #maxPriceFilterInput="ngModel"
              [(ngModel)]="maxPriceFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(maxPriceFilterInput)"
            />
            <div *ngIf="maxPriceFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(maxPriceFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === maxPriceFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of (minPriceFilterValue || minPrice) | rangeArray:maxPrice:false:true:50000'
                    class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="maxPriceFilterValue"
                    [checked]="maxPriceFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2 overflow-hidden text-nowrap" for="textInputRadio">
                    {{value | abbrevCurrency: 0:'USD':'symbol':'en-US'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="CommunityHomesFilter__filter-row pl-4 pt-4">
      <div class="CommunityHomesFilter__filter-label-row d-flex flex-row align-items-center justify-content-center">
        <span class="CommunityHomesFilter__label">{{data?.Filter_Livable_Space}}</span>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="search"
              name="minSquareFootFilterInput"
              #minSquareFootFilterInput="ngModel"
              placeholder={{data?.Filter_Living_Space_Minimum}}
              [(ngModel)]="minSquareFootFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(minSquareFootFilterInput)"
            />
            <div *ngIf="minSquareFootFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(minSquareFootFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === minSquareFootFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of minSquareFoot | rangeArray:(maxSquareFootFilterValue || maxSquareFoot):true:false:250'
                    class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="minSquareFootFilterValue"
                    [checked]="minSquareFootFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2 overflow-hidden text-nowrap" for="textInputRadio">
                    {{value}} sq.ft.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span>to</span>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="number"
              name="maxSquareFootFilterInput"
              #maxSquareFootFilterInput="ngModel"
              placeholder={{data?.Filter_Living_Space_Maximum}}
              [(ngModel)]="maxSquareFootFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(maxSquareFootFilterInput)"
            />
            <div *ngIf="maxSquareFootFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(maxSquareFootFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === maxSquareFootFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of (minSquareFootFilterValue || minSquareFoot) | rangeArray:maxSquareFoot:false:true:250'
                    class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="maxSquareFootFilterValue"
                    [checked]="maxSquareFootFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2 overflow-hidden text-nowrap" for="textInputRadio">
                    {{value}} sq.ft.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="CommunityHomesFilter__filter-row pl-4 pt-4">
      <div class="CommunityHomesFilter__filter-label-row d-flex flex-row align-items-center justify-content-center">
        <span class="CommunityHomesFilter__label">{{data?.Filter_Bedroom_Count}}</span>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="number"
              name="minBedroomsFilterInput"
              #minBedroomsFilterInput="ngModel"
              placeholder={{data?.Filter_Bedrooms_Minimum}}
              [(ngModel)]="minBedroomsFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(minBedroomsFilterInput)"
            />
            <div *ngIf="minBedroomsFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(minBedroomsFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === minBedroomsFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of minBedrooms | rangeArray:(maxBedroomsFilterValue || maxBedrooms):true:false'
                   class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="minBedroomsFilterValue"
                    [checked]="minBedroomsFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2 overflow-hidden text-nowrap" for="textInputRadio">
                    {{value}}BR
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span>to</span>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="number"
              name="maxBedroomsFilterInput"
              #maxBedroomsFilterInput="ngModel"
              placeholder={{data?.Filter_Bedrooms_Maximum}}
              [(ngModel)]="maxBedroomsFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(maxBedroomsFilterInput)"
            />
            <div *ngIf="maxBedroomsFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(maxBedroomsFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === maxBedroomsFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of (minBedroomsFilterValue || minBedrooms) | rangeArray:maxBedrooms:false:true'
                    class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="maxBedroomsFilterValue"
                    [checked]="maxBedroomsFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2 overflow-hidden text-nowrap" for="textInputRadio">
                    {{value}}BR
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="CommunityHomesFilter__filter-row pl-4 pt-4">
      <div class="CommunityHomesFilter__filter-label-row d-flex flex-row align-items-center justify-content-center">
        <span class="CommunityHomesFilter__label">{{data?.Filter_Bathroom_Count}}</span>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="number"
              name="minBathroomsFilterInput"
              #minBathroomsFilterInput="ngModel"
              placeholder={{data?.Filter_Bathrooms_Minimum}}
              [(ngModel)]="minBathroomsFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(minBathroomsFilterInput)"
            />
            <div *ngIf="minBathroomsFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(minBathroomsFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === minBathroomsFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of minBathrooms | rangeArray:(maxBathroomsFilterValue || maxBathrooms):true:false:0.5'
                    class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="minBathroomsFilterValue"
                    [checked]="minBathroomsFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2 overflow-hidden text-nowrap" for="textInputRadio">
                    {{value}}BA
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span>to</span>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex flow-row align-items-center position-relative">
            <input
              class="CommunityHomesFilter__filter-text-input pl-2 py-3 mx-3"
              type="number"
              name="maxBathroomsFilterInput"
              #maxBathroomsFilterInput="ngModel"
              placeholder={{data?.Filter_Bathrooms_Maximum}}
              [(ngModel)]="maxBathroomsFilterValue"
              readonly
              (focus)="setCurrentFocusedInput(maxBathroomsFilterInput)"
            />
            <div *ngIf="maxBathroomsFilterValue"
                class="CommunityHomesFilter__text-input-clear"
                (click)="clearInputValue(maxBathroomsFilterInput)">
              X
            </div>
          </div>
          <div *ngIf="focusedInput === maxBathroomsFilterInput"
               class="CommunityHomesFilter__radio-btn-dropdown overflow-visible d-flex flex-column align-items-center justify-content-start">
            <div class="CommunityHomesFilter__text-input-radio-container d-flex flex-column align-items-center justify-content-center rounded shadow mt-2 px-3 pb-3">
              <div *ngFor='let value of (minBathroomsFilterValue || minBathrooms) | rangeArray:maxBathrooms:false:true:0.5'
                    class="CommunityHomesFilter__radio-row d-flex flex-row align-items-center justify-content-center mt-3 mr-auto">
                <div class="CommunityHomesFilter__radio-button-input-wrapper d-flex align-items-center justify-content-center">
                  <input class="form-radio-button"
                    type="radio"
                    value={{value}}
                    name="textInputRadio"
                    [(ngModel)]="maxBathroomsFilterValue"
                    [checked]="maxBathroomsFilterValue === value"
                    (change)="updateFilterHomesComponent()">
                </div>
                <span class="pl-2 overflow-hidden text-nowrap" for="textInputRadio">
                    {{value}}BA
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-2 pb-1">
      <div *ngFor="let checkbox of checkboxes" class="CommunityHomesFilter__filter-row px-4 pt-4 w-100"
            [ngClass]="{'col-6 pl-4 pr-0 pt-3 w-50' : checkboxes?.length > 3,
                        'd-none' : checkbox.hideCheckbox}">
        <div class="CommunityHomesFilter__checkbox-input-wrapper">
          <input
            type="checkbox"
            class="form-checkbox"
            name="{{checkbox.name}}"
            [(ngModel)]="checkbox.checked.value"
            (change)="updateFilterHomesComponent()"
          />
        </div>
        <span class="CommunityHomesFilter__checkbox-label pl-3">{{checkbox.label}}</span>
      </div>
    </div>

    <div *ngIf="data?.Filter_Exact_Matches_Count && exactResultsCount > 0" class="CommunityHomesFilter__filter-row px-4 pt-4 pb-2">
      <span
        class="CommunityHomesFilter__results-label px-1 w-100">
          {{ data.Filter_Exact_Matches_Count | mergeField: '{count}': exactResultsCount }}
      </span>
    </div>

    <div *ngIf="data?.Filter_Close_Matches_Count && closeResultsCount > 0" class="CommunityHomesFilter__filter-row px-4 pt-3 pb-2">
      <span
        class="CommunityHomesFilter__results-label
                px-1 w-100">
          {{ data.Filter_Close_Matches_Count | mergeField: '{count}': closeResultsCount }}
      </span>
    </div>

    <div *ngIf="data?.Filter_View_Results" class="CommunityHomesFilter__filter-row px-4 pt-3 pb-2">
      <button
        class="CommunityHomesFilter__results-button btn btn-primary px-1 w-100"
        (click)="toggleShowFilters()">
          {{ data.Filter_View_Results }}
        </button>
    </div>

    <div *ngIf="data?.Clear_Filters_CTA" class="CommunityHomesFilter__filter-row px-4 pb-2">
      <button
        class="CommunityHomesFilter__clear-filters btn btn-link
               mx-1 w-100 d-flex align-items-center justify-content-center"
        (click)="clearAdditionalFilters()">
          {{data.Clear_Filters_CTA}}
      </button>
    </div>
  </div>
</form>
