<section class="ProductOverview container-fluid">
  <div *ngIf="data.Series_Card_Background_Image && showSeriesOverview"
       class="ProductOverview__series-background"
       [ngStyle]="{'background-image': 'url(' + data.Series_Card_Background_Image.Src + ')'}">
  </div>
  <div *ngIf="showSeriesOverview" class="ProductOverview__series-container row justify-content-center px-5 h-100 position-relative">
    <div *ngFor="let eachSeries of series; let i = index;"
          class="col-4 ProductOverview__series-card">
      <app-plan-card-series
        [@fadeInUpOnEnter]="{ value: '', params: { duration: 800, translate: '200px', delay: i * 150 } }"
        [@fadeOutDownOnLeave]
        [series]="eachSeries"
        [data]="data"
        (seriesSelected)="goToSeriesDetails($event)"></app-plan-card-series>
    </div>
  </div>

  <!-- Homes/QMI Toggle -->
  <div class="d-flex flex-column h-100" *ngIf="!showSeriesOverview">
    <div class="d-flex flex-column justify-content-center mb-3">
      <ul ngbNav class="ProductOverview__series-overview d-flex row align-items-center justify-content-center py-3 shadow"
          data-analytics-zone="Home Type Filter"
          [activeId]="selectedHomeType"
          (navChange)="toggleHomeTypeFromNav($event)">
        <li [ngbNavItem]="'Homes'">
          <a ngbNavLink class="ProductOverview__home-type"
             [ngClass]="{'ProductOverview__home-type-selected': isHomesTypeSelected()}">
            {{data.Plan_Toggle_Text}}
          </a>
        </li>
        <li class="ProductOverview__toggle-switch-container d-flex align-items-center mx-3"
            attr.data-analytics-cta="{{toggledHomeType}}"
            (click)="toggleHomeTypeFromSwitch()">
          <div class="ProductOverview__toggle-switch-circle"
               [ngClass]="{'ProductOverview__toggle-switch-qmi-selected' : !isHomesTypeSelected()}"></div>
        </li>
        <li [ngbNavItem]="'Qmis'">
          <a ngbNavLink class="ProductOverview__home-type"
             [ngClass]="{'ProductOverview__home-type-selected': !isHomesTypeSelected()}">
            {{data.QMI_Toggle_Text}}
          </a>
        </li>
        <app-community-homes-filter *ngIf="(plans?.length || qmis?.length)"
                                    [homes]="isHomesTypeSelected() ? plans : qmis"
                                    [series]="selectedSeries"
                                    [data]="data">
        </app-community-homes-filter>
      </ul>

      <!-- Series Nav Items -->
      <div class="ProductOverview__filter-section">
        <div class="ProductOverview__series-toggle" data-analytics-zone="Series Filter">
          <ul *ngIf="series.length" ngbNav #seriesNav="ngbNav"
              class="nav-tabs row d-flex justify-content-center border-bottom-0 ProductOverview__series-toggle-list"
              (navChange)="filterHomesBySeriesId($event.nextId)"
              [activeId]="activeSeriesNav">
            <!-- Create link/cta for each Series under this community -->
            <li *ngFor="let eachSeries of series" [ngbNavItem]="eachSeries?.SeriesID">
              <a ngbNavLink class="ProductOverview__series-toggle-item"
                 attr.data-analytics-cta="{{eachSeries.SeriesName}}"
                 [@fadeInDownOnEnter]>
                 {{data.Series_Label | mergeField: '{series}':eachSeries.SeriesName}}
              </a>
              <ng-template ngbNavContent>
                <div *ngIf="eachSeries.SeriesDescription" class="row justify-content-center mt-3">
                  {{eachSeries.SeriesDescription}}
                </div>
              </ng-template>
            </li>
            <!-- Create link/cta for All Homes nav option -->
            <li [ngbNavItem]="'All'">
              <a ngbNavLink class="ProductOverview__series-toggle-item"
                 data-analytics-cta="All Homes"
                 [@fadeInDownOnEnter]>
                 {{data.All_Homes_Label}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ProductOverview__card-container pt-1"
         [ngClass]="{'pt-5': !series.length}">

      <div *ngIf="(!plans?.length && isHomesTypeSelected())"
            class="ProductOverview__no-homes text-center font-weight-bold">
        <h1>{{data.No_Plans_Available}}</h1>
      </div>
      <div *ngIf="(!qmis?.length && !isHomesTypeSelected())"
        class="ProductOverview__no-homes text-center font-weight-bold">
        <h1>{{data.No_QMIs_Available}}</h1>
      </div>

      <div *ngIf="filteredPlans?.length > 0"
           class="ProductOverview__card row mb-3">
        <app-plan-card *ngFor="let plan of filteredPlans; trackBy: cardTrackBy; let i = index;"
                       class="col-3 card"
                       [@fadeInUpOnEnter]="{ value: '', params: { duration: 800, translate: '200px', delay: i * 150 } }"
                       [data]="planCardModel"
                       [plan]="plan"
                       [ngClass]="{'ProductOverview__less-cards': filteredPlans.length < 4, 'd-none': !isHomesTypeSelected()}">
        </app-plan-card>
      </div>

      <div *ngIf="closeFilteredPlans?.length > 0 && data.Filter_Close_Matches"
            class="ProductOverview__card row mb-3"
            [ngClass]="{'d-none': !isHomesTypeSelected()}">
        <div class="ProductOverview__additional-filter-header px-4 pb-4 d-flex flex-row align-items-center justify-content-center">
          <hr/>
          <span class="ProductOverview__additional-filter-label px-5 d-flex align-items-center">
            {{ data.Filter_Close_Matches }}
          </span>
          <hr/>
        </div>
        <app-plan-card *ngFor="let plan of closeFilteredPlans; trackBy: cardTrackBy; let i = index;"
                       class="col-3 card"
                       [@fadeInUpOnEnter]="{ value: '', params: { duration: 800, translate: '200px', delay: i * 150 } }"
                       [@fadeOutDownOnLeave]
                       [data]="planCardModel"
                       [plan]="plan"
                       [ngClass]="{'ProductOverview__less-cards': closeFilteredPlans.length < 4}">
        </app-plan-card>
      </div>

      <div *ngIf="filteredQmis?.length > 0"
           class="ProductOverview__card row mb-3">
        <app-qmi-card *ngFor="let qmi of filteredQmis; trackBy: cardTrackBy; let i = index;"
                      class="col-3 card"
                      [@fadeInUpOnEnter]="{ value: '', params: { duration: 800, translate: '200px', delay: i * 150 } }"
                      [@fadeOutDownOnLeave]
                      [data]="qmiCardModel"
                      [qmi]="qmi"
                      [ngClass]="{'ProductOverview__less-cards': filteredQmis.length < 4, 'd-none': isHomesTypeSelected()}">
        </app-qmi-card>
      </div>

      <div *ngIf="closeFilteredQmis?.length > 0 && data.Filter_Close_Matches"
            class="ProductOverview__card row mb-3"
            [ngClass]="{'d-none': isHomesTypeSelected()}">
        <div class="ProductOverview__additional-filter-header px-4 pb-4 d-flex flex-row align-items-center justify-content-center">
          <hr/>
          <span class="ProductOverview__additional-filter-label px-5 d-flex align-items-center">
            {{ data.Filter_Close_Matches }}
          </span>
          <hr/>
        </div>
        <app-qmi-card *ngFor="let qmi of closeFilteredQmis; trackBy: cardTrackBy; let i = index;"
                      class="col-3 card"
                      [@fadeInUpOnEnter]="{ value: '', params: { duration: 800, translate: '200px', delay: i * 150 } }"
                      [@fadeOutDownOnLeave]
                      [data]="qmiCardModel"
                      [qmi]="qmi"
                      [ngClass]="{'ProductOverview__less-cards': closeFilteredQmis.length < 4}">
        </app-qmi-card>
      </div>
    </div>
  </div>
</section>
