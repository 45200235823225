import { Component, OnInit, Input } from '@angular/core';
import { ImageModel } from 'src/app/models/content/editorial/image.model';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() data: ImageModel;

  constructor() { }

  ngOnInit() {}

}
