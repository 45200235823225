<div *ngIf="ctaData.Brochure_CTA_Toggle" class="RequestBrochure row align-items-center position-absolute h-50 mr-1">
    <button (click)="openBrochureModal()"
            class="RequestBrochure__button btn d-inline-flex justify-content-center align-items-center px-5 mx-3 h-100 {{ctaData.Brochure_CTA_Style | buttonStyle}}"
            data-analytics-cta="Get Brochure">
      <cl-image *ngIf="ctaData.Brochure_CTA_Icon"
        class="mr-1"
        public-id="{{ctaData.Brochure_CTA_Icon.Src}}"
        format="auto"
        quality="auto"
        type="fetch"
        crop="fill"
        height="30"
        width="30"
        [attr.aspect-ratio]="1">
      </cl-image>
      {{ctaData.Brochure_CTA_Label}}
    </button>
  </div>
