import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { Observable } from 'rxjs';
import { CommunityAmenitiesModel } from 'src/app/models/product/community-amenities.model';
import { BaseComponent } from '../../shared/base/base.component';

@Component({
  selector: 'app-community-amenity-details',
  templateUrl: './community-amenity-details.component.html',
  styleUrls: ['./community-amenity-details.component.scss']
})
export class CommunityAmenityDetailComponent extends BaseComponent implements OnInit {

  @Input() data: string;
  communityAmenity$: Observable<CommunityAmenitiesModel>;

  constructor(private communityService: CommunityService) { super(); }

  ngOnInit() {
    this.communityAmenity$ = this.communityService.amenities;
  }

}
