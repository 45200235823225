import { Component, OnInit, Input } from '@angular/core';
import { ColumnCardModel } from 'src/app/models/content/editorial/card.model';

@Component({
  selector: 'app-column-card',
  templateUrl: './column-card.component.html',
  styleUrls: ['./column-card.component.scss']
})
export class ColumnCardComponent implements OnInit {

  @Input() data: ColumnCardModel;

  constructor() { }

  ngOnInit() {}

}
