import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
  selector: '[appNameValid]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NameValidatorDirective, multi: true }
  ]
})
export class NameValidatorDirective implements Validator {

  constructor() { }

  validate(control: FormControl) {

    const value = control.value;
    let nameMatch: string;

    if (value) {
      // Name Regex enforces that only letters, apostrophes, hyphens, and spaces are allowed in the name fields
      // Lookaheads are used to prevent a non-letter character to end a string in these fields (i.e. 'John-' and 'John ' are not valid)
      // tslint:disable-next-line: max-line-length
      const namePattern = /^((?=[A-Za-zÀ-ÖØ-öø-ÿ]))(([A-Za-zÀ-ÖØ-öø-ÿ])|([A-Za-zÀ-ÖØ-öø-ÿ]['-](?=[A-Za-zÀ-ÖØ-öø-ÿ]))|( (?=[A-Za-zÀ-ÖØ-öø-ÿ])))*$/;

      nameMatch = value.match(namePattern);
    }
    return nameMatch || !value ? null : { invalidname: value };
  }
}
