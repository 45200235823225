<section *ngIf="plan" class="PlanTours" [@fadeInOnEnter]>
  <div *ngIf="(hasVideos || plan.VirtualTour)">
    <h3>{{data.Header_Text}}</h3>
    <div>
      <app-icon-cta *ngIf="plan.VirtualTour" [label]='data.Virtual_Tour_CTA_Text' [icon]='data.Virtual_Tour_CTA_Icon'
                     data-analytics-cta="Virtual Tour"
                    (click)='openVirtualTourModal()'>
      </app-icon-cta>
      <app-icon-cta *ngIf="hasVideos" [label]='data.Video_Tour_CTA_Text' [icon]='data.Video_Tour_CTA_Icon'
                    data-analytics-cta="Video Tour"
                    (click)='openVideoTourModal()'>
      </app-icon-cta>
    </div>
  </div>
</section>
