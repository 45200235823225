import { Pipe, PipeTransform } from '@angular/core';

// This pipe accepts 2 strings. First string is the merge key and second string is the merge value
// The merge value will replace the merge string
@Pipe({
  name: 'mergeField'
})
export class MergeFieldPipe implements PipeTransform {

  transform(value: string, mergeKey: string, mergeValue: any, boldValue?: boolean): string {
    let mergedString = value;

    if (value && mergeKey && (mergeValue || mergeValue >= 0)) {
      if (boldValue) {
        mergedString = mergedString.replace(mergeKey, '<strong>' + mergeValue + '</strong>');
      } else {
        mergedString = mergedString.replace(mergeKey, mergeValue);
      }
    }

    return mergedString;
  }

}
