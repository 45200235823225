<section *ngIf="nearbyCommunities && data" class="NearbyCommunities row h-100 mr-0">
  <div *ngIf="selectedCommunity"
        class="NearbyCommunities__sidebar col-3 pr-0 position-absolute"
        [@fadeInOnEnter]="{value: '', params: {duration: 1000, delay: 500 }}"> <!-- TODO: This part of the HTML aka the Sidebar should really be it's own component -->
    <app-carousel *ngIf="carouselOptions?.HasImages" [carouselOptions]="carouselOptions"></app-carousel>
    <div class="px-4 pt-2">
      <div class="NearbyCommunities__community-name font-weight-bold">{{selectedCommunity.Name}}</div>
      <div class="row my-1 px-2">
        <div class="NearbyCommunities__address-icon col-1 px-0"></div>
        <div *ngIf="selectedCommunity.Address" class="NearbyCommunities__address col-11 px-0">
          <div>{{selectedCommunity.Address.Street1}}</div>
          <div>{{selectedCommunity.Address.City}}, {{selectedCommunity.Address.State}} {{selectedCommunity.Address.ZipCode}}</div>
          <div>{{selectedCommunity.SalesPhoneNumber}}</div>
        </div>
      </div>
      <div *ngIf="selectedCommunity.PlanCount" class="my-3 px-2"><strong>{{selectedCommunity.PlanCount}}</strong> {{data.Home_Design_Availability}}</div>
      <div *ngIf="selectedCommunity.InventoryCount" class="my-3 px-2"><strong>{{selectedCommunity.InventoryCount}}</strong> {{data.QMI_Availability}}</div>
      <div class="my-3 px-2">{{selectedCommunity.MetaDescription}}</div>
      <div class="row my-2 d-flex text-center">
        <div *ngIf="selectedCommunity.StartingFromPrice" class="col-6 flex-column">
          <div class="font-weight-bold">{{selectedCommunity.StartingFromPrice | currency: 'USD':'symbol':'1.0-0'}}</div>
          <div>{{data.Price}}</div>
        </div>
        <div *ngIf="selectedCommunity.MinBedrooms || selectedCommunity.MaxBedrooms" class="col-6 flex-column">
          <div class="font-weight-bold">{{selectedCommunity.MinBedrooms | range: selectedCommunity.MaxBedrooms}}</div>
          <div>{{data.Bedrooms}}</div>
        </div>
      </div>
      <div class="row my-2 text-center">
        <div *ngIf="selectedCommunity.MinBathrooms || selectedCommunity.MaxBathrooms" class="col-6 flex-column">
          <div class="font-weight-bold">{{selectedCommunity.MinBathrooms | range: selectedCommunity.MaxBathrooms}}</div>
          <div>{{data.Bathrooms}}</div>
        </div>
        <div *ngIf="selectedCommunity.MinGarages || selectedCommunity.MaxGarages" class="col-6 flex-column">
          <div class="font-weight-bold">{{selectedCommunity.MinGarages | range: selectedCommunity.MaxGarages}}</div>
          <div>{{data.Garage}}</div>
        </div>
      </div>
    </div>
    <div class="NearbyCommunities__community-count position-absolute w-100 text-center font-weight-bold mt-auto pb-2 px-3">
      {{nearbyCommunities.length}} {{data.Nearby_Community_Count}}
    </div>
  </div>
  <div class="NearbyCommunities__map w-75 h-100 px-0">
    <div class="NearbyCommunities__map-legend px-0 position-absolute m-4 font-weight-bold shadow"
         [@fadeInOnEnter]> <!-- TODO: This map legend should really be it's component -->
      <div class="NearbyCommunities__legend-background px-2 cursor-pointer d-flex flex-row align-items-center justify-content-center"
           [ngClass]="{'mb-1': showLegend}"
           (click)="toggleLegend()">
        <div class="ml-3">{{data.Legend_Title}}</div>
        <div class="d-flex align-items-center mx-2"
             [ngClass]="showLegend ? 'pt-2' : 'pb-2'">
          <div class="NearbyCommunities__legend-arrow p-3"
               [ngClass]="{'NearbyCommunities__legend-arrow-up': showLegend}"></div>
        </div>
      </div>
      <div *ngIf="showLegend" class="NearbyCommunities__legend-background py-1" [@fadeInOnEnter] [@fadeOutOnLeave]>
        <div *ngFor="let marker of legendMarkers" class="d-flex flex-row align-items-center my-2">
          <div class="col-4">
            <img [src]="marker.IconUrl | safeUrl" />
          </div>
          <div class="col-8">
            {{marker.MarkerID}}
          </div>
        </div>
      </div>
    </div>
    <app-ng-google-map
      [mapOptions]="mapOptions"
      (markerClicked)="markerClicked($event)"
      (mapClicked)="mapClicked($event)">
    </app-ng-google-map>
  </div>
</section>

