import { Image } from 'src/app/models/product/image.model';
import { PlanFeature } from '../../product/feature.model';

export class PlanFeaturesModalModel {
  EmbraceYourSpaceImages: Image[];
  PlanGalleryImage: Image;
  Features: PlanFeature[];
  MetaDescription: string;
  hasSummary: boolean;
}
