<div class="FormControlError w-100" *ngIf="data && input?.errors && input.invalid && (input.touched || displayError)">

  <span class="FormControlError__error-text" *ngIf="input.errors.required">
    {{type ? (data.Field_Required_Error | mergeField: '{field}': type)
           :  data.Default_Required_Error}}
  </span>

  <span class="FormControlError__error-text" *ngIf="input.errors.minlength">
    {{data.Default_Min_Length_Error | mergeField: '{minLength}': input.errors.minlength.requiredLength}}
  </span>

  <span class="FormControlError__error-text" *ngIf="input.errors.invalidphone">
    {{data.Phone_Invalid_Error}}
  </span>

  <span class="FormControlError__error-text" *ngIf="input.errors.invalidname">
    {{data.Name_Invalid_Error}}
  </span>

  <span class="FormControlError__error-text" *ngIf="input.errors.invalidemail">
    {{data.Email_Invalid_Error}}
  </span>

  <span class="FormControlError__error-text" *ngIf="input.errors.pattern">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'Email'">
        {{data.Email_Invalid_Error}}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{data.Default_Invalid_Error}}
      </ng-container>
    </ng-container>
  </span>

</div>
