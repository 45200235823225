import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import { CommunityService } from 'src/app/core-services/community.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { CommunitySummaryModel } from 'src/app/models/content/community/community-summary.model';
import { Community } from 'src/app/models/product/community.model';
import { fadeInOnEnterAnimation, fadeInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-community-summary',
  templateUrl: './community-summary.component.html',
  styleUrls: ['./community-summary.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation(),
    fadeInOnEnterAnimation({ duration: 600, delay: 1000 })
  ]
})
export class CommunitySummaryComponent extends BaseComponent implements OnInit {

  @Input() data: CommunitySummaryModel;

  community$: Observable<Community>;

  constructor(private communityService: CommunityService,
              private modalService: NgbModal,
              private location: Location) { super(); }

  ngOnInit() {
    this.community$ = this.communityService.community;
  }

  backButton() {
    // Close any active modals on the screen and if there are none, go back.
    this.modalService.hasOpenModals() ? this.modalService.dismissAll() : this.location.back();
  }

}
