import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { } from 'googlemaps';
import { ReplaySubject, throwError, ObservableInput } from 'rxjs';
import { GooglePlacesSearchParams } from '../models/maps/google-places-search-params';
import { GooglePlacesResult } from '../models/maps/google-places-result.model';
import { tap, catchError } from 'rxjs/operators';
import { GooglePlacesDetails } from '../models/maps/google-places-details.model';
import { AppInsightsService } from './app-insights.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})

export class GooglePlacesService {

  places = new ReplaySubject<GooglePlacesResult>(1);
  details = new ReplaySubject<GooglePlacesDetails>(1);

  private apiUrl: string;

  constructor(private http: HttpClient, private appInsightsService: AppInsightsService,
      private settingsService: SettingsService) {
    this.apiUrl = this.settingsService.apiUrl + '/googleplaces';
  }

  googlePlacesTextSearch(params: GooglePlacesSearchParams) {
    if (params) {
      this.http.get<GooglePlacesResult>(
        `${this.apiUrl}/PlacesTextSearch`, { params: (params as any) })
        .pipe(
          tap((res: GooglePlacesResult) => {
            if (res && res.status === 'OK' && res.results) {
              this.places.next(res);
            }
          }),
          catchError((err: HttpErrorResponse) => {
            this.appInsightsService.logException(
              new Error(`An error occurred for Google Places Service request: ${err.message}`), false);
            return throwError(err.error);
          })
        )
        .subscribe();
    }
  }

  getGooglePlacesDetailsById(googlePlaceId: string) {
    this.http.get<GooglePlacesDetails>(
      `${this.apiUrl}/PlaceDetails`, { params: { googlePlaceId }})
      .pipe(
        tap((res: GooglePlacesDetails) => {
          this.details.next(res);
        }),
        catchError((err: HttpErrorResponse) => {
          this.appInsightsService.logException(
            new Error(`An error occurred for Google Places Service request: ${err.message}`), false);
          return throwError(err.error);
        })
      )
      .subscribe();
  }

  clearGooglePlacesData() {
    this.places.next(null);
    this.details.next(null);
  }
}
