
  <div *ngIf="videoModalModel" class="VideoModal" appPauseSleepState>
  <div class="modal-header">
    <h4 class="modal-title">{{videoModalModel.ModalHeader}}</h4>
    <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
  </div>

  <div class="d-flex flex-column justify-content-center modal-body">
    <div class="VideoModal__iframeWrapper position-relative d-flex justify-content-center w-100"
      [ngClass]="{'VideoModal__iframeWrapper--summary': videoModalModel.HasSummary}">

      <iframe *ngIf="videoUrl" class="position-absolute border-0 h-100" [src]="videoUrl | safeUrl"></iframe>
    </div>


    <div *ngIf="videoModalModel.ModalDescription"
      class="VideoModal__caption d-inline-flex py-2 {{captionPlacement}}"
      [ngClass]="{'VideoModal__caption--summary': videoModalModel.HasSummary}">
      {{videoModalModel.ModalDescription}}
    </div>
  </div>


  <div class="back-wrapper modal-footer">
    <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
  </div>


</div>
