export abstract class Environment {
  public envName: string;
  public production: boolean;

  public apiUrl: string;
  public previewApiUrl: string;
  public productApiUrl: string;

  public previewCloudinaryVideoRemoteFolder: string;
  public cloudinaryVideoRemoteFolder: string;

  public googleApiKey: string;
  public pulteAVID: string;
  public cloudinaryID: string;

  public applicationInsights: {
    instrumentationKey: string;
  };
  public adobeLaunchScriptUrl: string;
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
