<section *ngIf="date" class="DateTimePicker my-3">
  <div class="DateTimePicker__value d-inline-flex justify-content-between w-100">
    <div class="d-inline-flex justify-content-between align-items-center">
      <div class="d-inline-block" placement="top-left" container="body" ngbDropdown>
        <div id="timeSelectionDropdown" class="DateTimePicker__time cursor-pointer" ngbDropdownToggle>
          {{date | date: 'shortTime'}}
        </div>
        <div class="DateTimePicker__time-dropdown-menu mini-scrollbar overflow-y-auto" aria-labelledby="timeSelectionDropdown" ngbDropdownMenu>
          <button *ngFor="let time of timeList"
            class="DateTimePicker__time-dropdown-menu-item"
            (click)="setTimeFromDropdown(time)" ngbDropdownItem>
            {{time | date: 'shortTime'}}
          </button>
        </div>
      </div>
      <div class="d-inline-flex">
        <div class="d-inline-flex cursor-pointer mr-1" (click)="decrementTime()">
          <div class="DateTimePicker__left-arrow pr-2" [ngClass]="{'DateTimePicker__left-arrow--disabled': disablePreviousTime}"></div>
          <div class="DateTimePicker__arrow-space"></div>
        </div>
        <div class="d-inline-flex cursor-pointer ml-1" (click)="incrementTime()">
          <div class="DateTimePicker__arrow-space"></div>
          <div class="DateTimePicker__right-arrow pl-2"></div>
        </div>
      </div>
    </div>
    <div class="d-inline-flex justify-content-end align-items-center">
      <div>{{date | date: 'EEE, MMM d'}}</div>
      <div class="d-inline-flex">
        <div class="d-inline-flex cursor-pointer mr-1" (click)="decrementDate()">
          <div class="DateTimePicker__left-arrow pr-2" [ngClass]="{'DateTimePicker__left-arrow--disabled': disablePreviousDate}"></div>
          <div class="DateTimePicker__arrow-space"></div>
        </div>
        <div class="d-inline-flex cursor-pointer ml-1" (click)="incrementDate()">
          <div class="DateTimePicker__arrow-space"></div>
          <div class="DateTimePicker__right-arrow pl-2"></div>
        </div>
      </div>
    </div>
  </div>
</section>
