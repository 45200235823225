import { Directive, OnInit, OnDestroy } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';

@Directive({
  selector: '[appPauseSleepState]'
})
export class PauseSleepStateDirective implements OnInit, OnDestroy {

  constructor(private userIdleService: UserIdleService) {}

  ngOnInit(): void {
    this.userIdleService.stopWatching();
  }

  ngOnDestroy(): void {
    this.userIdleService.startWatching();
  }
}
