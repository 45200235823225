import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductOverviewService {

  seriesID = new ReplaySubject<string>(1);
  selectedHomeType = new ReplaySubject<string>(1);

  constructor() { }

  updateSeriesID(seriesID: string): void {
    this.seriesID.next(seriesID);
    this.seriesID.complete();
  }

  updateSelectedHomeType(selectedHomeType: string): void {
    this.selectedHomeType.next(selectedHomeType);
    this.selectedHomeType.complete();
  }
}
