import { CTAPageItemRef } from '../../cta-page-item-ref.model';
import { KioskImage } from '../media/kiosk-image.model';
import { ProductFilterModel } from '../community/product-filter.model';

export class ProductOverviewModel extends ProductFilterModel {
  Plan_Toggle_Text: string;
  Plan_Bathrooms: string;
  Plan_Bedrooms: string;
  Plan_CTA_Detail: string;
  Plan_Garage: string;
  Plan_Price: string;
  Plan_Series: string;
  Plan_Square_Feet: string;
  Plan_LMP_Starting_From: string;
  PlanPitiPriceLabel: string;
  QMILMPStartingFrom: string;
  QMIPitiPriceLabel: string;
  Plan_CTA_Page_Item_Ref: CTAPageItemRef;
  Plan_Almost_Sold_Out: string;
  Plan_Future_Release: string;
  Plan_Last_Chance: string;
  Plan_Last_Home_Design: string;
  Plan_Model_For_Sale: string;
  Plan_Model_Open: string;
  Plan_New_Model: string;
  Plan_New_Plan: string;
  Plan_Sold_Out: string;
  Plan_QMI_Only: string;
  No_Plans_Available: string;
  QMI_Availability: string;
  QMI_Availability_Date: string;
  QMI_Bathrooms: string;
  QMI_Bedrooms: string;
  QMI_Garage: string;
  QMI_Lot: string;
  QMI_Price: string;
  QMI_Series: string;
  QMI_Square_Feet: string;
  QMI_CTA_Detail: string;
  QMI_CTA_Page_Item_Ref: CTAPageItemRef;
  QMI_Price_Discount: string;
  QMI_Toggle_Text: string;
  QMI_Default_Toggle: boolean;
  No_QMIs_Available: string;
  Series_Name: string;
  Series_Bedrooms: string;
  Series_Bathrooms: string;
  Series_Square_Feet: string;
  Series_Garage: string;
  Series_CTA_Detail: string;
  Series_Price: string;
  Series_Label: string;
  All_Homes_Label: string;
  Hers_Score_CTA_Label: string;
  Hers_Score_CTA_ToolTip: string;
  Series_Card_Background_Image: KioskImage;
  Bathrooms_Maximum: string;
  Bathrooms_Minimum: string;
  Call_For_Pricing_Text: string;
}

export enum ProductOverviewHometype {
  Homes = 'Homes',
  Qmis = 'Qmis'
}

