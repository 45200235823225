import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from 'src/app/core-services/settings.service';
import { InlineVideoModel } from 'src/app/models/content/editorial/inline-video.model';
import { hostnameContainsPreviewPrefix } from 'src/helper-functions/sitecore-api-url-helper';

@Component({
  selector: 'app-inline-video',
  templateUrl: './inline-video.component.html',
  styleUrls: ['./inline-video.component.scss']
})
export class InlineVideoComponent implements OnInit {

  @Input() data: InlineVideoModel;
  videoUrl: string;
  remoteFolder: string;
  videoFilePath: string;

  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
    if (this.data) {
      if (this.data.YouTube_Video_Key || this.data.Vimeo_Video_Key) {
        this.setVideoUrl();
      } else if (this.data.Video) {
        this.setVideoFilePath();
      }
    }
  }

  setVideoUrl() {
    if (this.data.Vimeo_Video_Key) {
      this.videoUrl = `https://player.vimeo.com/video/${this.data.Vimeo_Video_Key}`;
    } else if (this.data.YouTube_Video_Key) {
      this.videoUrl = `https://youtube.com/embed/${this.data.YouTube_Video_Key}`;
    }

    if (this.data.Enable_Autoplay) {
      this.videoUrl += '?autoplay=1';
      if (this.data.Vimeo_Video_Key) {
        this.videoUrl += `&muted=1`;
      } else if (this.data.YouTube_Video_Key) {
        this.videoUrl += `&mute=1`;
      }
    }
  }

  setVideoFilePath() {
    if (hostnameContainsPreviewPrefix()) {
      this.remoteFolder = this.settingsService.previewCloudinaryVideoRemoteFolder;
    } else {
      this.remoteFolder = this.settingsService.cloudinaryVideoRemoteFolder;
    }
    this.videoFilePath = this.remoteFolder + this.data.Video.Src.replace(/([.][a][s][h][x])$/, '');
  }

}
