import { Image } from 'src/app/models/product/image.model';
import { KioskImage } from './content/media/kiosk-image.model';

export class CarouselOptionsModel {
  public RotationInterval: boolean | number;
  public ShowArrows: boolean;
  public ShowPips: boolean;
  public Width: string | number;
  public AspectRatio: number;
  public InitialSlideId: string;
  public ShowImageIndex: boolean;
  public ShowImageCaption: boolean;
  public ShowModalCta: boolean;
  public Images: Image[];
  public StatusOverlayText: string[];
  public HasImages: boolean;
  public IsFloorPlan: boolean;
  public HasSingleImage: boolean;
  public UseSmallArrows: boolean;
  public CarouselBanner: KioskImage;

  constructor(providedImages: Image[]) {
    this.RotationInterval = false;
    this.ShowArrows = true;
    this.ShowPips = false;
    this.Width = 'auto';
    this.AspectRatio = 1.5;
    this.InitialSlideId = '0';
    this.ShowImageIndex = true;
    this.ShowImageCaption = true;
    this.ShowModalCta = false;
    this.IsFloorPlan = false;
    this.Images = providedImages;
    this.HasSingleImage = this.Images.length === 1;
    this.HasImages = this.Images && this.Images.length ? true : false;
  }
}
