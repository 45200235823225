import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { setBrandSpecificConfiguration } from './helper-functions/brand-specific-configuration';

setBrandSpecificConfiguration();

fetch('./assets/environment/settings.json').then(resp => resp.json())
  .then(settings => {
  window["settings"] = settings;

  if (window["settings"].production){
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
