import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
  selector: '[appEmailValid]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }
  ]
})
export class EmailValidatorDirective implements Validator {

  constructor() { }

  validate(control: FormControl) {

    const value = control.value;
    let emailMatch: string;

    if (value) {
      // email Regex pattern enforces standard email format (@ and domain)
      // while only allowing hyphen, period, and underscore - . _ as special characters
      // tslint:disable-next-line: max-line-length
      const emailPattern = /^[^<>()[\]{}\\,;:\%#^*\s@\"$&+=|/?'`~!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

      emailMatch = value.match(emailPattern);
    }
    return emailMatch || !value ? null : { invalidemail: value };
  }
}
