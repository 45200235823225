<section class="AlphaVisionMap"
         [ngClass]="{'AlphaVisionMap--fullscreen position-fixed w-100 h-100': fullscreenEnabled}">

  <div class="position-absolute h-100 w-100" #divSitePlan>
  </div>

  <div *ngIf="mapInitialized && !loading && !hasError">

    <div *ngIf="mapLotsReady">
      <span *ngIf="(masterMapName && !fullscreenEnabled) || selectedPlan || selectedLot"
            class="AlphaVisionMap__close-button position-absolute py-1"
            [@fadeInOnEnter]
            (click)="resetMapData()">
            <app-close-button></app-close-button>
      </span>

      <app-lot-map-legend [legendData]="data"
                          [community]="community"
                          [selectedLot]="selectedLot"
                          [lotAttributes]="lotAttributes"
                          [selectedPlan]="selectedPlan"
                          [selectedAmenity]="selectedAmenity"
                          [showBackButton]="(masterMapName && !fullscreenEnabled) || selectedPlan || selectedLot || selectedAmenity"
                          [lotsCount]="lotsCount"
                          (backButtonClicked)="resetMapData()">
      </app-lot-map-legend>
    </div>
  </div>

  <div class="AlphaVisionMap__map-controls position-absolute d-flex justify-content-end align-items-end h-100 w-100 flex-column">
    <div *ngIf="!loading && mapInitialized"
          class="AlphaVisionMap__map-control AlphaVisionMap__zoom-in-button mr-3 mb-2"
          [@fadeInOnEnter]
          (click)="mapZoomIn()">
    </div>
    <div *ngIf="!loading && mapInitialized"
          class="AlphaVisionMap__map-control AlphaVisionMap__zoom-out-button mr-3 mb-2"
          (click)="mapZoomOut()">
    </div>
    <div  *ngIf="!hasError"
          class="AlphaVisionMap__map-control AlphaVisionMap__fullscreen-button mr-3 mb-4"
          [ngClass]="{'fullscreen-enabled': fullscreenEnabled}"
          (click)="mapFullscreen()"
          [@fadeInOnEnter]>
    </div>
  </div>

  <app-loading-spinner [loading]="loading" [scale]="3"></app-loading-spinner>

  <span *ngIf="!loading && hasError && data?.Homesites_Map_Error_Label"
         class="AlphaVisionMap__error-message w-100 position-absolute text-center">
         {{data.Homesites_Map_Error_Label}}
  </span>

</section>
