import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { RequestBrochureModel } from '../models/content/editorial/request-brochure.model';

@Injectable({
  providedIn: 'root'
})
export class RequestBrochureService {
  RequestBrochureItem = new ReplaySubject<RequestBrochureModel>(1);

  constructor() { }

  updateRequestBrochure(RequestBrochure: RequestBrochureModel): void {
    this.RequestBrochureItem.next(RequestBrochure);
    this.RequestBrochureItem.complete();
  }
}
