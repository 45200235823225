import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { combineLatest } from 'rxjs';
import { Community } from 'src/app/models/product/community.model';
import { CommunityTourModel } from 'src/app/models/content/community/community-tour.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VideoModalComponent } from '../../shared/modals/video-modal/video-modal.component';
import { VideoModalModel } from 'src/app/models/content/media/video-modal.model';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { PagesService } from 'src/app/core-services/pages.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../shared/base/base.component';
import { PlanVirtualTourModalComponent } from '../../shared/modals/plan-virtual-tour-modal/plan-virtual-tour-modal.component';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-community-tour',
  templateUrl: './community-tour.component.html',
  styleUrls: ['./community-tour.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800, delay: 400 })
  ]
})
export class CommunityTourComponent extends BaseComponent implements OnInit {

  @Input() data: CommunityTourModel;

  community: Community;
  modalClass: string;
  hasVideos: boolean;

  constructor(private communityService: CommunityService,
              private modalService: NgbModal,
              private pageService: PagesService) { super(); }

  ngOnInit() {
    combineLatest([this.communityService.community, this.pageService.hasSummary])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([community, hasSummary]) => {
        this.community = community;
        this.modalClass = hasSummary ? ModalClasses.Summary : '';

        this.hasVideos = this.setHasVideos(this.community);
      });
  }

  openVideoModal(videoId: string, caption: string, modalTitle: string): void {
    if (videoId) {
      const modalRef: NgbModalRef = this.modalService.open(VideoModalComponent, { backdrop: false,
        windowClass: `${ModalClasses.Full} ${this.modalClass}` });
      const videoModalModel: VideoModalModel = new VideoModalModel();
      videoModalModel.ModalHeader = modalTitle;
      videoModalModel.ModalDescription = caption;
      videoModalModel.VideoId = videoId;
      videoModalModel.EnableAutoplay = true;
      modalRef.componentInstance.videoModalModel = videoModalModel;
    }
  }

  openVirtualTourModal(modalTitle: string) {
    const modalRef: NgbModalRef = this.modalService.open(PlanVirtualTourModalComponent, { backdrop: false,
      windowClass: `${ModalClasses.Full} ${this.modalClass}` });
    const videoModalModel: VideoModalModel = new VideoModalModel();
    videoModalModel.ModalHeader = modalTitle;
    videoModalModel.VirtualTourUrl = this.community.VirtualTour;
    modalRef.componentInstance.videoModalModel = videoModalModel;
  }

  setHasVideos(community: Community): boolean {
    return !!(community && community.Videos && community.Videos.length && community.Videos[0] && community.Videos[0].VideoPath);
  }
}
