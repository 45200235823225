import { Component, OnInit, Input } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CommunityService } from 'src/app/core-services/community.service';
import { Observable } from 'rxjs';
import { QMI } from 'src/app/models/product/qmi.model';
import { QmiSummaryModel } from 'src/app/models/content/qmi/qmi-summary.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { BaseComponent } from '../../shared/base/base.component';
import { fadeInUpOnEnterAnimation, fadeInOnEnterAnimation } from 'angular-animations';
import { LmpService } from 'src/app/core-services/lmp.service';
import { Community } from 'src/app/models/product/community.model';
import { LmpConfigurationModel } from 'src/app/models/lmp-configuration.model';

@Component({
  selector: 'app-qmi-summary',
  templateUrl: './qmi-summary.component.html',
  styleUrls: ['./qmi-summary.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation(),
    fadeInOnEnterAnimation({ duration: 600, delay: 1000 })
  ]
})
export class QmiSummaryComponent extends BaseComponent implements OnInit {

  @Input() data: QmiSummaryModel;

  qmi$: Observable<QMI>;
  community: Community;
  lmpConfiguration: LmpConfigurationModel;

  constructor(private communityService: CommunityService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private location: Location,
    private lmpService: LmpService) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      takeUntil(this.unsubscribe$)
    ).subscribe((inventoryHomeId: string) => {
      this.qmi$ = this.communityService.getQmiById(inventoryHomeId);
    });

    var cmsPIOnlyFlag: boolean = false;
    var overrideBrandPIOnlyFlag: boolean = false;
    var sitecorePIOnlyFlag: boolean = false;

    this.communityService.community.subscribe((community: Community) => {
      overrideBrandPIOnlyFlag = community.OverrideBrandDisplayPIOnlyFlag;
      cmsPIOnlyFlag = community.DisplayPIOnlyFlag;
    });

    this.lmpService.LmpConfiguration.subscribe((lmp: LmpConfigurationModel) => {
      sitecorePIOnlyFlag = lmp.Display_PI_Only;
      this.lmpConfiguration = lmp;
    });

    this.qmi$.subscribe((qmi: QMI) => {
      qmi.DisplayPIOnly = overrideBrandPIOnlyFlag ? cmsPIOnlyFlag : sitecorePIOnlyFlag;
    })
  }

  backButton() {
    // Close any active modals on the screen and if there are none, go back.
    this.modalService.hasOpenModals() ? this.modalService.dismissAll() : this.location.back();
  }
}
