import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestBrochureModel } from 'src/app/models/content/editorial/request-brochure.model';
import { RequestBrochureService } from 'src/app/core-services/request-brochure.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { CommunityService } from 'src/app/core-services/community.service';
import { combineLatest } from 'rxjs';
import { Plan } from 'src/app/models/product/plan.model';
import { Series } from 'src/app/models/product/series.model';
import { setSuggestionText } from 'src/helper-functions/keyboard-helper';
import { PlanBrochureService } from 'src/app/core-services/plan-brochure.service';
import { Community } from 'src/app/models/product/community.model';
import { RequestBrochureResponse } from 'src/app/models/content/editorial/request-brochure-response.model';
import { FormControl, NgForm } from '@angular/forms';
import { QMI } from 'src/app/models/product/qmi.model';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { AdobeLaunchFormName, AdobeLaunchFormType } from 'src/app/models/analytics/adobe-launch-event-enum';

@Component({
  selector: 'app-request-brochure-modal',
  templateUrl: './request-brochure-modal.component.html',
  styleUrls: ['./request-brochure-modal.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class RequestBrochureModalComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() currentPlanData: Plan;
  @Input() currentQmiData: QMI;
  selectedPlans: Plan[] = [];
  selectedQmis: QMI[] = [];

  @ViewChild('requestBrochureForm') requestBrochureForm: NgForm;
  @ViewChild('brochureEmail') brochureEmailInput: ElementRef;

  requestBrochureModal: RequestBrochureModel;
  plans: Plan[] = [];
  plansWithoutSeries: Plan[] = [];
  qmis: QMI[] = [];
  qmisWithoutSeries: QMI[] = [];
  series: Series[] = [];
  community: Community;

  emailDomains: string[] = [];
  emailAddress: string = '';

  loading: boolean = false;
  formSubmitted: boolean;
  formSubmittedResponse: RequestBrochureResponse;

  constructor(public activeModal: NgbActiveModal,
              private requestBrochureService: RequestBrochureService,
              private communityService: CommunityService,
              private planBrochureService: PlanBrochureService,
              private adobeLaunchService: AdobeLaunchService) { super(); }

  ngOnInit() {
    const observables = [this.communityService.plans,
                         this.communityService.community,
                         this.communityService.series,
                         this.communityService.qmis,
                         this.requestBrochureService.RequestBrochureItem];

    combineLatest(observables)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([plans, community, series, qmis, requestBrochureItem]: any[]) => {
      this.requestBrochureModal = requestBrochureItem;
      if (plans && plans.length) {
        this.plans = plans;
        if (series && series.length) {
          this.series = series;
        }
        if (qmis && qmis.length) {
          this.qmis = qmis;
        }
        if (community) {
          this.community = community;
        }
        if (this.community && this.community.PlanRankType && this.community.PlanRankType.toLowerCase().includes('series')) {
          this.plansWithoutSeries = this.plans.filter(plan => !plan.SeriesName);
          this.qmisWithoutSeries = this.qmis.filter(qmi => !qmi.Plan.SeriesName);
        } else {
          this.plansWithoutSeries = this.plans;
          this.qmisWithoutSeries = this.qmis;
        }
      }
    });

    if (this.requestBrochureModal) {
      this.emailDomains = setSuggestionText(this.requestBrochureModal.Domain_1,
                                            this.requestBrochureModal.Domain_2,
                                            this.requestBrochureModal.Domain_3);
    }

    if (this.currentPlanData && this.selectedPlans) {
      this.selectedPlans.push(this.currentPlanData);
    }

    if (this.currentQmiData && this.selectedQmis) {
      this.selectedQmis.push(this.currentQmiData);
    }
  }

  ngOnDestroy() {
    this.formSubmitted = false;
    this.formSubmittedResponse = null;
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    this.focusEmailInput();
  }

  planChecked(event, plan: Plan) {
    if (event && event.target && plan) {
      if (event.target.checked) {
        this.selectedPlans.push(plan);
      } else {
        this.selectedPlans = this.selectedPlans.filter(p => p.Id !== plan.Id);
      }
    }
    this.focusEmailInput();
  }

  qmiChecked(event, qmi: QMI) {
    if (event && event.target && qmi) {
      if (event.target.checked) {
        this.selectedQmis.push(qmi);
      } else {
        this.selectedQmis = this.selectedQmis.filter(q => q.InventoryHomeID !== qmi.InventoryHomeID);
      }
    }
    this.focusEmailInput();
  }

  async submitForm() {
    if (this.formIsValid()) {
        this.formSubmitted = true;
        this.loading = true;
        const uniqueSelectedPlans = [...new Set(this.selectedPlans)];
        const uniqueSelectedQmis = [...new Set(this.selectedQmis)];
        const resp: RequestBrochureResponse = await this.planBrochureService.requestPlanBrochure(this.community,
                                                                                                uniqueSelectedPlans,
                                                                                                uniqueSelectedQmis,
                                                                                                this.emailAddress);
        this.formSubmittedResponse = resp;

        // on successful form submission, push analytics form event
        if (this.formSubmittedResponse.isSuccessful &&
          ((uniqueSelectedPlans && uniqueSelectedPlans.length) ||
          (uniqueSelectedQmis && uniqueSelectedQmis.length))) {
            this.adobeLaunchService.pushFormSubmittedEvent(AdobeLaunchFormName.RequestBrochure,
                                                           AdobeLaunchFormType.Modal,
                                                           uniqueSelectedPlans,
                                                           uniqueSelectedQmis);
        }
      }
    this.loading = false;
    this.defocusEmailInput();
  }

  focusEmailInput() {
    if (this.brochureEmailInput && this.brochureEmailInput.nativeElement) {
      this.brochureEmailInput.nativeElement.focus();
    }
  }

  defocusEmailInput() {
    if (this.brochureEmailInput && this.brochureEmailInput.nativeElement) {
      this.brochureEmailInput.nativeElement.blur();
    }
  }

  formIsValid(): boolean {
    let isValid: boolean;

    if (this.requestBrochureForm && this.requestBrochureForm.valid &&
        this.community && this.emailAddress &&
        ((this.selectedPlans && this.selectedPlans.length) || (this.selectedQmis && this.selectedQmis.length))
         ) {
          isValid = true;
         } else {
          isValid = false;
         }

    return isValid;
  }
}
