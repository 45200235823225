<div class="modal-header">
  <h4 class="modal-title">Gallery</h4>
  <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
</div>
<div class="modal-body row w-100">
  <div class="col-3"></div>
  <div class="col-6">
    <app-carousel *ngIf="modalCarouselOptions && modalCarouselOptions.HasImages"[carouselOptions]="modalCarouselOptions"></app-carousel>
  </div>
  <div class="col-3"></div>
</div>
<div class="back-wrapper modal-footer">
  <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
</div>
