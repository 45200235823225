import { Component, OnInit, Input } from '@angular/core';
import { BoxCardModel } from 'src/app/models/content/editorial/card.model';

@Component({
  selector: 'app-box-card',
  templateUrl: './box-card.component.html',
  styleUrls: ['./box-card.component.scss']
})

export class BoxCardComponent implements OnInit {

  @Input() data: BoxCardModel;

  constructor() { }

  ngOnInit() {
  }

}
