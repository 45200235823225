<section *ngIf="data && qmi" class="card__container d-flex flex-column flex-wrap">
  <div *ngIf="qmi.Images && qmi.Images[0]">
    <div *ngIf="showSavingsFlag" class="image__status-overlay position-absolute text-light bg-dark p-2">
      {{data.Price_Discount | mergeField: '{pricediscount}': [qmi.PriceDiscount | currency: 'USD':'symbol':'1.0-0']}}
    </div>
    <cl-image class="w-100" public-id="{{qmi.Images[0].Path}}" format="auto" quality="auto" width="auto" type="fetch"
      crop="fill" [attr.alt]="qmi.Images[0].AltText" [attr.aspect-ratio]="1.5"></cl-image>
  </div>

  <h1 class="card__header">{{qmi.Plan.PlanName}}</h1>
  <div class="container d-flex flex-wrap flex-column">
    <div *ngIf="qmi.Plan.SeriesName" class="col-12 card__grid-item-series">
      {{data.Series | mergeField: '{series}': qmi.Plan.SeriesName}}
    </div>
    <div class="col-12 card__grid-item-address pb-0">
      {{qmi.Address.Street1}}
      <div *ngIf="qmi.LotBlock" class="d-inline">
        , {{data.Lot | mergeField: '{lot}': qmi.LotBlock}}
      </div>
    </div>
    <div class="col-12 card__grid-item card__grid-item--price  d-flex flex-column justify-content-center"
      [ngClass]="{'d-flex justify-content-center align-items-center pb-0': qmi.CallForPricingFlag}">
      <div *ngIf="!qmi.CallForPricingFlag">
        <div *ngIf="showWasPrice" class="card__grid-item-value-price card__grid-item-value-price--final-line">
          {{qmi.Price | currency: 'USD':'symbol':'1.0-0'}}<br></div>
        <div class="card__grid-item-value-price"
          [ngClass]="{'card__grid-item-value-price--final-color': priceHasColor}">{{price | currency:
          'USD':'symbol':'1.0-0'}}
          <br>
        </div>
        <div class="card__grid-item-text-price">{{data.Price}}</div>
      </div>
      <div *ngIf="qmi.CallForPricingFlag" class="card__grid-item-text-price ">
        <h2 class=" font-weight-lighter">{{data.Call_For_Pricing_Text}}</h2>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-4 card__grid-item">
        <div class="card__grid-item-value">{{qmi.DateAvailable}}<br></div>
        <div class="card__grid-item-text">{{data.Availability_Date}}</div>
      </div>
      <div class="col-4 card__grid-item">
        <div class="card__grid-item-value">{{qmi.Bedrooms}}<br></div>
        <div class="card__grid-item-text">{{data.Bedrooms}}</div>
      </div>
      <div class="col-4 card__grid-item">
        <div class="card__grid-item-value">{{qmi.Bathrooms}}<br></div>
        <div class="card__grid-item-text">{{data.Bathrooms}}</div>
      </div>
    </div>
    <div class="row justify-content-center no-gutters">
      <div class="col-4 card__grid-item" *ngIf="qmi.Garages">
        <div class="card__grid-item-value">{{qmi.Garages}}<br></div>
        <div class="card__grid-item-text">{{data.Garage}}</div>
      </div>
      <div class="col-4 card__grid-item">
        <div class="card__grid-item-value">{{qmi.SquareFeet}}<br></div>
        <div class="card__grid-item-text">{{data.Square_Feet}}</div>
      </div>
      <div class="col-4 pl-0 card__grid-item"
        *ngIf="data?.LmpConfiguration.Enable_LMP && qmi.MortgagePayment?.TotalMonthlyPrincipalAndInterest && qmi.DisplayPIOnly && !qmi.CallForPricingFlag; else PITIContent">
        <div class="card__grid-item-value-lmp d-inline-flex">
          <span>{{qmi.MortgagePayment.TotalMonthlyPrincipalAndInterest | currency: 'USD':'symbol':'1.0-0'}} /mo</span>
          <app-lmp-tooltip [data]="data.LmpConfiguration" [mortgagePayment]="qmi.MortgagePayment"></app-lmp-tooltip>
          <br>
        </div>
        <div class="card__grid-item-text">{{data.LMPStartingFrom}}</div>
      </div>
      <ng-template #PITIContent>
        <div class="col-4 pl-0 card__grid-item"
          *ngIf="data?.LmpConfiguration.Enable_LMP && qmi.MortgagePayment?.TotalMonthlyPayment && !qmi.DisplayPIOnly && !qmi.CallForPricingFlag">
          <div class="card__grid-item-value-lmp d-inline-flex">
            <span>{{qmi.MortgagePayment.TotalMonthlyPayment | currency: 'USD':'symbol':'1.0-0'}} /mo</span>
            <app-lmp-tooltip [data]="data.LmpConfiguration" [mortgagePayment]="qmi.MortgagePayment"></app-lmp-tooltip>
            <br>
          </div>
          <div class="card__grid-item-text">{{data.PITIPriceLabel}}</div>
        </div>
      </ng-template>
    </div>
    <div class="card__button-container pt-0">
      <a *ngIf="data.QMI_CTA_Page_Item_Ref && trimmedUrl" class="btn-card btn btn-primary" data-analytics-cta="View QMI"
        (click)="handleQmiClicked(qmi)"
        [routerLink]="[trimmedUrl + data.QMI_CTA_Page_Item_Ref?.Page_Url + '/' + qmi.InventoryHomeID]">
        {{data.QMI_CTA_Text}}
      </a>
    </div>
  </div>
</section>