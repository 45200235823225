import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoModalModel } from 'src/app/models/content/media/video-modal.model';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {

  @Input() videoModalModel: VideoModalModel;
  videoUrl: string;
  captionPlacement: string;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.videoModalModel && this.videoModalModel.VideoId) {
      this.setVideoUrl();
      this.setCaptionPlacement();
    }
  }

  setVideoUrl() {
    this.videoUrl = `https://youtube.com/embed/${this.videoModalModel.VideoId}`;

    if (this.videoModalModel.EnableAutoplay) {
      this.videoUrl += '?autoplay=1&mute=1';
    }
  }

  setCaptionPlacement() {
    switch (this.videoModalModel.ModalDescriptionPlacement) {
      case 'Center':
        this.captionPlacement = 'justify-content-center';
        break;
      case 'Right':
        this.captionPlacement = 'justify-content-end';
        break;
      default:
        this.captionPlacement = 'justify-content-start';
    }
  }

}
