import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
  selector: '[appPhoneValid]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PhoneValidatorDirective, multi: true }
  ]
})
export class PhoneValidatorDirective implements Validator {

  constructor() { }

  validate(control: FormControl) {

    const value = control.value;
    let phoneNumberMatch: string;

    if (value) {
      // return as valid if the input starts with a '+'
      if (value.startsWith('+')) {
        // TODO: If ever needed, validate the international number
        return value.length >= 5 ? null : { minlength: { requiredLength: 5}};
      }

      // tslint:disable-next-line: max-line-length
      const phoneNumberPattern = /^[\\(]{0,1}([0-9]){3}[\\)]{0,1}[ ]?([^0-1]){1}([0-9]){2}[ ]?[-]?[ ]?([0-9]){4}[ ]*((#){0,1}([0-9]){1,5}){0,1}$/g;

      phoneNumberMatch = value.match(phoneNumberPattern);
    }
    return phoneNumberMatch || !value ? null : { invalidphone: value };
  }
}
