import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hers-score',
  templateUrl: './hers-score-graph.component.html',
  styleUrls: ['./hers-score-graph.component.scss']
})
export class HersScoreComponent implements OnInit {

  @Input() HersScore: number;
  pinText: number;
  pinPosition: number;


  constructor() { }

  ngOnInit() {
    if (this.HersScore > 0) {
      this.pinText = this.HersScore;
      this.pinPosition  = 300 - this.pinText * 2.163846153846154;
    }

  }

}
