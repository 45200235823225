<div class="HersModal">
    <div class="modal-header">
        <div class="col-3 HersModal__title">
            <h4 class="modal-title ">{{HersScore.Modal_Title}}</h4>
            <br/>
            <div>
                <div class="HersModal__title-plan">{{HersScore.Plan_Name | mergeField: '{plan}':plan.PlanName}}</div>
                <div class="HersModal__title-community" *ngIf="community && community?.Address">
                    {{community.Name}} | {{community.Address.City}}, {{community.Address.StateAbbreviation}}
                </div>
                <hr class="HersModal__title-hairLine">
            </div>
        </div>
        <div *ngIf="HersScore?.HasSavings" class="col-5 HersModal__header">
            <div class="HersModal__header-1">{{HersScore.Header_1}}</div>
            <div class="HersModal__header-asterisk">{{HersScore.Hers_Difference_5 | currency}}<div *ngIf="HersScore.Asterisk">*</div></div>
            <div [innerHTML]="HersScore.Header_Details" class="HersModal__header-detail"></div>
        </div>
        <span class="modal-action align-self-start pt-3 pr-2" (click)="activeModal.close()"><app-close-button></app-close-button></span>
    </div>
    <div class="modal-body">
        <div *ngIf="HersScore?.HasSavings" class="row">
            <div class="col-5">
                <app-hers-score [HersScore]="plan.HERSScore"></app-hers-score>
            </div>
            <div class="col-7">
                <table class="HersModal__savingsTable">
                    <tr>
                        <th></th>
                        <th class="HersModal__savingsTableHeaderColumn">{{HersScore.Column_1}}</th>
                        <th class="HersModal__savingsTableHeaderColumn">{{HersScore.Column_2}}</th>
                    </tr>
                    <tr>
                        <th class="HersModal__savingsTableHeader">
                            {{HersScore.Row_1}}
                        </th>
                        <td class="HersModal__savingsTableData font-weight-bold">{{HersScore.Hers_Savings_1  | currency}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th class="HersModal__savingsTableHeader">{{HersScore.Row_2}}</th>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Savings_2 | currency}}</td>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Difference_2 | currency}}</td>
                    </tr>
                    <tr>
                        <th class="HersModal__savingsTableHeader">{{HersScore.Row_3}}</th>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Savings_3 | currency}}</td>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Difference_3 | currency}}</td>
                    </tr>
                    <tr>
                        <th class="HersModal__savingsTableHeader">{{HersScore.Row_4}}</th>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Savings_4 | currency}}</td>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Difference_4 | currency}}</td>
                    </tr>
                    <tr>
                        <th class="HersModal__savingsTableHeader">{{HersScore.Row_5}}</th>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Savings_5 | currency}}</td>
                        <td class="HersModal__savingsTableData">{{HersScore.Hers_Difference_5 | currency}}</td>
                    </tr>
                </table>
                <div class="HersModal__tableDetails">{{HersScore.Table_Details}}</div>
                <div class="HersModal__header-2">
                    <div class="HersModal__header-2-text">{{HersScore.Header_2}}</div>
                    <div>{{HersScore.Header_2_Description}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="!HersScore?.HasSavings" class="row">
            <div class="col-6">
                <app-hers-score [HersScore]="plan.HERSScore"></app-hers-score>
            </div>
            <div class="col-5 HersModal__liteHeader">
                <br/>
                <div class="HersModal__liteHeader-text">{{HersScore.Lite_Header}}</div>
                <div>{{HersScore.Lite_Header_Description}}</div>
                <div class="HersModal__liteHeader-description-2">{{HersScore.Header_2_Description}}</div>
            </div>
        </div>
    </div>
    <div class="back-wrapper modal-footer">
        <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
    </div>
</div>
