import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CommunityService } from 'src/app/core-services/community.service';
import { LmpConfigurationModel } from 'src/app/models/lmp-configuration.model';
import { Community } from 'src/app/models/product/community.model';
import { MortgagePayment } from 'src/app/models/product/mortgage-payment.model';
import { BaseComponent } from '../../shared/base/base.component';

@Component({
  selector: 'app-lmp-tooltip',
  templateUrl: './lmp-tooltip.component.html',
  styleUrls: ['./lmp-tooltip.component.scss']
})
export class LmpTooltipComponent extends BaseComponent implements OnInit {

  @Input() data: LmpConfigurationModel;
  @Input() mortgagePayment: MortgagePayment;

  community: Community;
  displayPIOnly: boolean = false;

  constructor(private communityService: CommunityService ) { super(); }

  ngOnInit() {
    this.communityService.community
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((community) => {
        if (community) {
          this.community = community;
        }
    });

    if (this.data && this.mortgagePayment && this.community) {
      this.displayPIOnly =
        !!((this.data.Display_PI_Only && !this.community.OverrideBrandDisplayPIOnlyFlag) ||
          (this.community.OverrideBrandDisplayPIOnlyFlag && this.community.DisplayPIOnlyFlag));
    }
  }

}
