import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CommunityLotMapModel } from 'src/app/models/content/community/community-lot-map.model';
import { PlanCardModel } from 'src/app/models/content/plan/plan-card.model';
import { HersScoreService } from 'src/app/core-services/hers-score.service';
import { Plan } from 'src/app/models/product/plan.model';
import { Community } from 'src/app/models/product/community.model';
import { PagesService } from 'src/app/core-services/pages.service';
import { BaseComponent } from '../../shared/base/base.component';
import { checkForImages, sortImagesByImageRank, isImageUrl } from 'src/helper-functions/image-helper';
import { ActivatedRoute } from '@angular/router';
import { AlphaVisionMapService } from 'src/app/core-services/alphavision-map.service';
import { AlphaVisionLot, AlphaVisionAmenity, AlphaVisionLotCounts } from 'src/app/models/maps/alphavision-map-data.model';
import { Image } from 'src/app/models/product/image.model';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';
import { LmpService } from 'src/app/core-services/lmp.service';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { fadeInAnimation, fadeInOnEnterAnimation, fadeInRightOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-community-lot-map',
  templateUrl: './community-lot-map.component.html',
  styleUrls: ['./community-lot-map.component.scss'],
  animations: [
    fadeInAnimation(),
    fadeInOnEnterAnimation(),
    fadeInRightOnEnterAnimation({ duration: 800, translate: '300px' })
  ]
})
export class CommunityLotMapComponent extends BaseComponent implements OnInit {

  @Input() data: CommunityLotMapModel;

  public planCardModel: PlanCardModel;
  public hasFixedHeader: boolean;

  public community: Community;
  public plans: Plan[];
  public filteredPlans: Plan[];
  public closeFilteredPlans: Plan[];

  public mapLoaded: boolean = false;
  public selectedPlan: Plan;
  public selectedLot: AlphaVisionLot;
  public selectedLotPlanCount: number = 0;
  public selectedLotId: string;
  public numberOfAvailableLotsForSelectedPlan: number;

  public selectedAmenity: AlphaVisionAmenity;
  public selectedAmenityImages: Image[] = [];
  public amenityCarouselOptions: CarouselOptionsModel;

  private selectedPlanIsFromRoute: boolean = false;

  constructor(private communityService: CommunityService,
              private hersService: HersScoreService,
              private lmpService: LmpService,
              private pageService: PagesService,
              private route: ActivatedRoute,
              private alphaVisionMapService: AlphaVisionMapService,
              private adobeLaunchService: AdobeLaunchService) { super(); }

  ngOnInit() {
    combineLatest([this.communityService.community,
                   this.communityService.plans,
                   this.hersService.HersScoreitem,
                   this.pageService.hasFixedHeader,
                   this.route.queryParams,
                   this.lmpService.LmpConfiguration])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([community, plans, hers, hasFixedHeader, routeParams, lmp]: any) => {
        this.community = community;

        if (plans) {
          this.plans = plans;
          this.plans.map(plan => {
            if (checkForImages(plan.Images)) {
              plan.Images = sortImagesByImageRank(plan.Images);
            }
          });

          this.filteredPlans = this.plans;
          this.planCardModel = this.mapSitecoreLabelsToPlanCard(this.data);
          this.pushAdobeLaunchPlanListingEvent();

          if (routeParams) {
            if (routeParams.planId) {
              this.setSelectedPlan(+routeParams.planId, true);
            }
            if (routeParams.lotId) {
              // Sends lot ID to AV Map component for selecting specific Lot. If the API/Map can't
              // find that specific Lot for the SitePlan loaded, it defaults to just showing the Selected Plan.
              this.selectedLotId = routeParams.lotId;
            }
          }
        }

        if (hers) {
          this.planCardModel.HersScore = hers;
        }

        if (hasFixedHeader) {
          this.hasFixedHeader = hasFixedHeader;
        }

        if (lmp) {
          this.planCardModel.LmpConfiguration = lmp;
        }
      });

    this.alphaVisionMapService.mapPlanIds
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((planIds) => {
        // If we have plan IDs and no selected currently set, filter by them.
        if (planIds && planIds.length) {
          if (!this.selectedPlan) {
            this.filteredPlans = this.plans.filter(p => planIds.includes(p.Id.toString()));
            this.closeFilteredPlans = this.plans.filter(p => !planIds.includes(p.Id.toString()));
          } else {
            this.initPlanListsFromSelection(this.selectedPlan, planIds);
          }
        } else if (this.selectedPlanIsFromRoute) { // If we have a selected Plan from route, set flag false for next plan ID filter
          this.selectedPlanIsFromRoute = false;
        } else { // If no plan IDs return, filter cards back to default.
          this.selectedPlan = null;
          this.filteredPlans = this.plans;
          this.closeFilteredPlans = [];
        }
        this.resetCardColumnScroll();
      });

    this.alphaVisionMapService.selectedLot
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedLot: AlphaVisionLot) => {
        this.selectedLot = selectedLot;
        if (this.selectedLot) {
          this.resetCardColumnScroll();
          this.filterPlansForCount();
        } else if (this.selectedPlan) {
          this.initPlanListsFromSelection(this.selectedPlan);
        }
      });

    this.alphaVisionMapService.selectedAmenity
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedAmenity: AlphaVisionAmenity) => {
        this.selectedAmenity = selectedAmenity;

        if (this.selectedAmenity) {
          this.selectedAmenity.medias.map(media => {
            // AV can sometimes return back YouTube URLs which our carousel does not currently support.
            if (media.url && isImageUrl(media.url)) {
              const image: Image = new Image();
              image.Path = media.url;
              image.AltText = media.name;
              this.selectedAmenityImages.push(image);
            }
          });

          if (this.selectedAmenityImages && this.selectedAmenityImages.length) {
            this.amenityCarouselOptions = new CarouselOptionsModel(this.selectedAmenityImages);
            this.amenityCarouselOptions.ShowImageIndex = false;
            this.amenityCarouselOptions.ShowImageCaption = false;
          }

        } else {
          this.amenityCarouselOptions = null;
          this.selectedAmenityImages = [];
        }
      });
  }

  public filterPlansForCount() {
    if (this.selectedLot) {
      this.selectedLotPlanCount = this.plans.reduce((count, plan) => {
        return this.selectedLot.plans.some(lotPlan => lotPlan.id === plan.Id.toString()) ? ++count : count;
      }, 0);
    }
  }

  public setSelectedPlan(selectedPlanId: number, isFromRoute: boolean) {
    if (selectedPlanId) {
      this.selectedPlan = this.plans.find(plan => plan.Id === selectedPlanId);
      if (this.selectedPlan) {
        this.selectedPlanIsFromRoute = isFromRoute;
        this.initPlanListsFromSelection(this.selectedPlan);
        this.alphaVisionMapService.updateSelectedPlan(this.selectedPlan);
      }
    }
  }

  public setLotsCount(lotCounts: AlphaVisionLotCounts) {
    this.numberOfAvailableLotsForSelectedPlan = lotCounts.AvailableHomesiteLotsCount;
  }

  public setMapLoaded(mapLoaded: boolean) {
    this.mapLoaded = mapLoaded;
  }

  public initPlanListsFromSelection(selectedPlan: Plan, selectedPlanIds?: string[]) {
    if (selectedPlan && selectedPlanIds && selectedPlanIds.length) {
      this.filteredPlans = this.plans.filter(plan => plan.Id !== selectedPlan.Id && selectedPlanIds.includes(plan.Id.toString()));
      this.closeFilteredPlans = this.plans.filter(plan => plan.Id !== selectedPlan.Id && !selectedPlanIds.includes(plan.Id.toString()));
    } else {
      this.filteredPlans = [];
      this.closeFilteredPlans = this.plans.filter(plan => plan.Id !== selectedPlan.Id);
    }
    this.resetCardColumnScroll();
  }

  private resetCardColumnScroll() {
    // Scroll back to the top of list where the selected plan card is sitting.
    // TODO: Clean up using ViewChild instead in the future.
    const cardColumn = document.getElementById('lotMapCardColumn');
    if (cardColumn) {
      cardColumn.scrollTop = 0;
    }
  }

  private mapSitecoreLabelsToPlanCard(sitecoreLabels: CommunityLotMapModel): PlanCardModel {
    const planCardModel = new PlanCardModel();
    if (sitecoreLabels) {
      planCardModel.Series_Name = sitecoreLabels.Series_Label;
      planCardModel.Price = sitecoreLabels.Price;
      planCardModel.Bedrooms = sitecoreLabels.Bedrooms;
      planCardModel.Bathrooms = sitecoreLabels.Bathrooms;
      planCardModel.Garage = sitecoreLabels.Garage;
      planCardModel.Square_Feet = sitecoreLabels.Square_Feet;
      planCardModel.LMP_Starting_From = sitecoreLabels.LMP_Starting_From;
      planCardModel.PITI_Price_Label = sitecoreLabels.PitiPriceLabel;
      planCardModel.See_Lots_CTA_Detail = sitecoreLabels.See_Lots_CTA_Text;
      planCardModel.View_Home_CTA_Detail = sitecoreLabels.View_Home_CTA_Text;
      planCardModel.CTA_Page_Item_Ref = sitecoreLabels.CTA_Page_Item_Ref;
      planCardModel.QMI_Only = sitecoreLabels.Plan_QMI_Only;
      planCardModel.Almost_Sold_Out = sitecoreLabels.Plan_Almost_Sold_Out;
      planCardModel.Future_Release = sitecoreLabels.Plan_Future_Release;
      planCardModel.Last_Chance = sitecoreLabels.Plan_Last_Chance;
      planCardModel.Last_Home_Design = sitecoreLabels.Plan_Last_Home_Design;
      planCardModel.Model_For_Sale = sitecoreLabels.Plan_Model_For_Sale;
      planCardModel.Model_Open = sitecoreLabels.Plan_Model_Open;
      planCardModel.New_Model = sitecoreLabels.Plan_New_Model;
      planCardModel.New_Plan = sitecoreLabels.Plan_New_Plan;
      planCardModel.Sold_Out = sitecoreLabels.Plan_Sold_Out;
      planCardModel.Homesites_Label = sitecoreLabels.Homesites_Label;
      planCardModel.Quick_Move_Ins_Label = sitecoreLabels.Quick_Move_Ins_Label;
    }
    return planCardModel;
  }

  // Adobe Launch Listing Analytics
  private pushAdobeLaunchPlanListingEvent() {
    const plans = [this.selectedPlan].concat(this.filteredPlans).concat(this.closeFilteredPlans);
    if (plans && plans.length) {
      this.adobeLaunchService.pushPlanListingDisplayedEvent(plans);
    }
  }
}
