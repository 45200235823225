import { Type } from '@angular/core';
import { ArticleCardComponent } from '../community/editorials/article-card/article-card.component';
import { BoxCardComponent } from '../community/editorials/box-card/box-card.component';
import { CommunityHeroComponent } from '../community/products/community-hero/community-hero.component';
import { ProductOverviewComponent } from '../community/products/product-overview/product-overview.component';
import { PlanSummaryComponent } from '../community/products/plan-summary/plan-summary.component';
import { PlanGalleryComponent } from '../community/products/plan-gallery/plan-gallery.component';
import { PlanFeaturesComponent } from '../community/products/plan-features/plan-features.component';
import { QmiGalleryComponent } from '../community/products/qmi-gallery/qmi-gallery.component';
import { QmiSummaryComponent } from '../community/products/qmi-summary/qmi-summary.component';
import { CommunityLotMapComponent } from '../community/products/community-lot-map/community-lot-map.component';
import { CommunityDescriptionComponent } from '../community/products/community-description/community-description.component';
import { CommunityTourComponent } from '../community/products/community-tour/community-tour.component';
import { CommunityGalleryComponent } from '../community/products/community-gallery/community-gallery.component';
import { CtaComponent } from '../community/editorials/cta/cta.component';
import { ImageComponent } from '../community/editorials/image/image.component';
import { PageHeaderComponent } from '../community/editorials/page-header/page-header.component';
import { PlanCtasComponent } from '../community/products/plan-ctas/plan-ctas.component';
import { PlanFloorPlansComponent } from '../community/products/plan-floor-plans/plan-floor-plans.component';
import { CommunityPoiCategoriesComponent } from '../community/products/community-poi-categories/community-poi-categories.component';
import { PlanToursComponent } from '../community/products/plan-tours/plan-tours.component';
import { QmiLocationComponent } from '../community/products/qmi-location/qmi-location.component';
import { QmiDescriptionComponent } from '../community/products/qmi-description/qmi-description.component';
import { QmiTourComponent } from '../community/products/qmi-tour/qmi-tour.component';
import { VRTourPlanComponent } from '../community/products/vr-tour-plan/vr-tour-plan.component';
import { NearbyCommunitiesComponent } from '../community/products/nearby-communities/nearby-communities.component';
import { InlineVideoComponent } from '../community/editorials/inline-video/inline-video.component';
import { TileCardComponent } from '../community/editorials/tile-card/tile-card.component';
import { ContentCardComponent } from '../community/editorials/content-card/content-card.component';
import { CommunitySummaryComponent } from '../community/products/community-summary/community-summary.component';
import { SpecialOffersCardComponent } from '../community/editorials/special-offers-card/special-offers-card.component';
import { ColumnCardComponent } from '../community/editorials/column-card/column-card.component';
import { GuestRegistrationModalComponent } from '../community/shared/modals/guest-registration-modal/guest-registration-modal.component';
import { CommunityAmenityDetailComponent } from '../community/products/community-amenity-details/community-amenity-details.component';

// Key Value pair that stores Module Type strings and their associated Component Types
// Add any new Sitecore Module Templates to this list if they are intended to be used as dynamically rendered Kiosk Modules
// The Key string must match the name of the concrete class generated by Glass Mapper
export const DynamicComponentTypes: Record<string, Type<any>> = {
    Article_Card: ArticleCardComponent,
    Box_Card: BoxCardComponent,
    Content_Card: ContentCardComponent,
    Special_Offers_Card: SpecialOffersCardComponent,
    Community_Hero: CommunityHeroComponent,
    Product_Overview: ProductOverviewComponent,
    Plan_Gallery: PlanGalleryComponent,
    Plan_Summary: PlanSummaryComponent,
    Plan_Features: PlanFeaturesComponent,
    Plan_CTAs: PlanCtasComponent,
    Plan_Floor_Plans: PlanFloorPlansComponent,
    QMI_Gallery: QmiGalleryComponent,
    QMI_Summary: QmiSummaryComponent,
    Community_Lot_Map: CommunityLotMapComponent,
    CTA: CtaComponent,
    Community_Description: CommunityDescriptionComponent,
    Community_Tour: CommunityTourComponent,
    Community_Gallery: CommunityGalleryComponent,
    Page_Header: PageHeaderComponent,
    Community_Poi_Categories: CommunityPoiCategoriesComponent,
    Plan_Tours: PlanToursComponent,
    QMI_Location: QmiLocationComponent,
    QMI_Description: QmiDescriptionComponent,
    QMI_Tour: QmiTourComponent,
    VR_Tour_Plan: VRTourPlanComponent,
    Nearby_Communities: NearbyCommunitiesComponent,
    Inline_Video: InlineVideoComponent,
    Tile_Card: TileCardComponent,
    Community_Summary: CommunitySummaryComponent,
    Column_Card: ColumnCardComponent,
    Single_Image: ImageComponent,
    Guest_Registration_Modal: GuestRegistrationModalComponent,
    Amenity_Detail: CommunityAmenityDetailComponent
};

export const DynamicComponents = [
  ArticleCardComponent,
  BoxCardComponent,
  ContentCardComponent,
  SpecialOffersCardComponent,
  CommunityHeroComponent,
  ProductOverviewComponent,
  PlanGalleryComponent,
  PlanSummaryComponent,
  PlanFeaturesComponent,
  PlanCtasComponent,
  PlanFloorPlansComponent,
  QmiGalleryComponent,
  QmiSummaryComponent,
  CommunityLotMapComponent,
  CtaComponent,
  CommunityDescriptionComponent,
  CommunityTourComponent,
  CommunityGalleryComponent,
  PageHeaderComponent,
  CommunityPoiCategoriesComponent,
  PlanToursComponent,
  QmiLocationComponent,
  QmiDescriptionComponent,
  QmiTourComponent,
  VRTourPlanComponent,
  NearbyCommunitiesComponent,
  InlineVideoComponent,
  TileCardComponent,
  CommunitySummaryComponent,
  ColumnCardComponent,
  ImageComponent,
  GuestRegistrationModalComponent,
  CommunityAmenityDetailComponent
];
