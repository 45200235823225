import { Component, OnInit, Input } from '@angular/core';
import { CtaModel } from 'src/app/models/content/editorial/cta.model';
import { Observable } from 'rxjs';
import { Community } from 'src/app/models/product/community.model';
import { CommunityService } from 'src/app/core-services/community.service';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800, delay: 750 })
  ]
})
export class CtaComponent implements OnInit {

  @Input() data: CtaModel;

  community$: Observable<Community>;
  planCountClass: string;

  constructor(private communityService: CommunityService) { }

  ngOnInit() {
    this.community$ = this.communityService.community;
    this.planCountClass = this.setPlanCountClass(this.data.CTA_Style);
  }

  setPlanCountClass(buttonType: string): string {
    let planCountClass: string = '';

    switch (buttonType) {
      case 'Default':
        planCountClass = 'Cta__plan-count--default';
        break;
      case 'Link':
        planCountClass = 'Cta__plan-count--link';
        break;
      default:
        planCountClass = 'Cta__plan-count--primary';
    }

    return planCountClass;
  }

}
