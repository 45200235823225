import { Directive } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
  selector: '[ngModel][appPhoneMask]',
  providers: [NgModel],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '(ngModelChange)': 'onInputChange($event)'
  }
})
export class PhoneMaskDirective {

  constructor(public model: NgControl) { }

  onInputChange(value) {
    let formattedNumber: string = '';
    let rawValue: string;
    let areaCode: string;
    let phoneNumber: string;
    let extension: string;

    if (value) {
      // remove special characters added by pipe formatting and invalid inputs
      rawValue = value.replace(/[^0-9\+]/g, '');

      // only apply the pipe to domestic numbers. a '+' in the beginning is how we are detecting the number is international
      if (!rawValue.startsWith('+')) {
        switch (rawValue.length) {
          case 1:
          case 2:
          case 3:
            areaCode = rawValue;
            break;
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
            areaCode = rawValue.slice(0, 3);
            phoneNumber = rawValue.slice(3);
            break;
          default:
            areaCode = rawValue.slice(0, 3);
            phoneNumber = rawValue.slice(3, 10);
            extension = rawValue.slice(10);
        }

        if (phoneNumber) {
          if (phoneNumber.length > 3) {
            phoneNumber = phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 7);
          } else {
            phoneNumber = phoneNumber;
          }

          formattedNumber = '(' + areaCode + ') ' + phoneNumber;

          if (extension) {
            formattedNumber += ' #' + extension;
          }
        } else {
          formattedNumber = '(' + areaCode;
        }
      } else {
        // return the number, as is, but removing anything except, + - ( ) and numbers.
        formattedNumber = value.replace(/[^0-9\+\-() ]/g, '');
      }
    }

    this.model.valueAccessor.writeValue(formattedNumber);
  }
}
