<div class="InteractiveToolModal" appPauseSleepState>
  <div>
    <span class="modal-action position-absolute d-flex InteractiveToolModal__close-button justify-content-end"
          [ngClass]="{'InteractiveToolModal__close-button--is-finishing-options': isFinishingOptions}"
          (click)="activeModal.close()">
      <app-close-button [isWhiteIcon]="!isFinishingOptions"></app-close-button>
    </span>
    <div class="InteractiveToolModal__iframeWrapper pb-3">
      <iframe [src]="url | safeUrl" height="560" width="315"></iframe>
    </div>
  </div>
</div>
