<div *ngIf="floorPlanImages && floorPlanImages.length && floorPlanConfigs && floorPlanConfigs.length"
      class="PlanFloorPlans"
      [@fadeInOnEnter]>
  <h3>{{data.Header_Text}}</h3>
  <div *ngIf="floorPlanConfigs.length > 1" class="row d-flex justify-content-start">
    <div *ngFor="let floorPlanConfig of floorPlanConfigs">
      <div *ngIf="floorPlanConfig"
        class="PlanFloorPlans__planConfiguration col-auto"
        [ngClass]="{'PlanFloorPlans__selectedConfig': floorPlanConfig.IsSelected}"
        (click)="selectConfiguration(floorPlanConfig.Index)">
        {{floorPlanConfig.ConfigurationName}}
      </div>
    </div>
  </div>
  <div *ngFor="let floorPlanConfig of floorPlanConfigs">
    <div *ngIf="floorPlanConfig && floorPlanConfig.CarouselOptions
      && floorPlanConfig.CarouselOptions.Images"
      [ngClass]="{'d-none': !floorPlanConfig.IsSelected}">
      <app-carousel [carouselOptions]="floorPlanConfig.CarouselOptions"
        (slideClickEvent)="openFloorPlanModal($event)"
        (activeSlideChange)="setActiveImageSelection($event)"></app-carousel>
      <div *ngFor="let floorPlanImage of floorPlanConfig.CarouselOptions.Images; index as i">
        <div class="PlanFloorPlans__floorType px-2"
          [ngClass]="{'PlanFloorPlans__floorTypeSelected': floorPlanConfig.ActiveImageIndex === i, 'd-none': floorPlanConfig.ValidSingleImage}"
          (click)="selectFloorPlanImage(floorPlanConfig.Index, floorPlanConfig.CarouselIndex, i)">
          {{floorPlanImage.FloorPlanType | uppercase}}
        </div>
      </div>
    </div>
  </div>
</div>
