import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, concatMap, takeUntil, takeWhile } from 'rxjs/operators';
import { PlanFloorPlansModel, FloorPlansConfigurationModel } from 'src/app/models/content/plan/plan-floor-plans.model';
import { FloorPlanImage } from 'src/app/models/product/floor-plan-image.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FloorPlanModalComponent } from 'src/app/community/shared/modals/floor-plan-modal/floor-plan-modal.component';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';
import { PagesService } from 'src/app/core-services/pages.service';
import { ModalClasses } from '../../../shared/modal-classes';
import { GroupByPipe } from 'src/app/community/shared/pipes/group-by.pipe';
import { CarouselComponent } from 'src/app/community/shared/media/carousel/carousel.component';
import { BaseComponent } from '../../shared/base/base.component';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-plan-floor-plans',
  templateUrl: './plan-floor-plans.component.html',
  styleUrls: ['./plan-floor-plans.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800, delay: 600 })
  ]
})
export class PlanFloorPlansComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() data: PlanFloorPlansModel;
  @ViewChildren(CarouselComponent) carousels !: QueryList<CarouselComponent>;

  floorPlanImages: FloorPlanImage[] = [];
  floorPlanConfigs: FloorPlansConfigurationModel[] = [];
  floorPlanCarousels: CarouselComponent[] = [];
  totalCarousels: number = 0;
  hasSummary: boolean;
  planId: string;

  constructor(private communityService: CommunityService, private route: ActivatedRoute, private modalService: NgbModal,
              private groupByPipe: GroupByPipe, private pageService: PagesService) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      concatMap((planId: string) => {
        this.planId = planId;
        const observables = [ this.communityService.getFloorPlanImagesByPlanId(planId),
          this.pageService.hasSummary];
        return forkJoin(observables);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((response: any[]) => {
      this.floorPlanImages = response[0];
      if (this.floorPlanImages && this.floorPlanImages.length) {
        this.floorPlanImages = this.sortFloorPlanImages(this.floorPlanImages);

        const groupedFloorPlanImages = this.groupByPipe.transform(this.floorPlanImages, 'PlanConfiguration');

        this.floorPlanConfigs = this.setFloorPlanConfigurationData(groupedFloorPlanImages);

        if (this.floorPlanConfigs && this.floorPlanConfigs.length) {
          this.floorPlanConfigs[0].IsSelected = true;
        }
      }
      this.hasSummary = response[1];
    });
  }

  ngAfterViewInit() {
    this.carousels.changes
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((carousels: QueryList<CarouselComponent>) => {
      this.floorPlanCarousels = carousels.toArray();
    });
  }

  sortFloorPlanImages(floorPlanImages: FloorPlanImage[]): FloorPlanImage[] {
    const sortedFloorPlanImages: FloorPlanImage[] =
      floorPlanImages.sort((a, b) => a.PlanConfiguration.localeCompare(b.PlanConfiguration));

    return sortedFloorPlanImages;
  }

  setFloorPlanConfigurationData(groupedFloorPlanImages: any[]): FloorPlansConfigurationModel[] {
    const floorPlanConfigs = groupedFloorPlanImages
    .filter(imageGroup => !imageGroup.key.includes('Options'))
    .map((floorPlanImageGroup, index) => {
      const floorPlanConfig = new FloorPlansConfigurationModel();

      floorPlanConfig.ConfigurationName = `CONFIGURATION ${index + 1}`;

      if (floorPlanImageGroup.value) {
        floorPlanConfig.CarouselOptions = this.setCarouselOptionsForConfig(floorPlanImageGroup.value);
      }

      if (floorPlanConfig.CarouselOptions && floorPlanConfig.CarouselOptions.Images
        && floorPlanConfig.CarouselOptions.Images.length === 1) {
        this.validateSingleImage(floorPlanConfig);
      }

      floorPlanConfig.Index = index;

      return floorPlanConfig;
    });

    const totalFloorPlanConfigs = floorPlanConfigs.length;

    const optionConfig = this.setFloorPlanOptionsData(groupedFloorPlanImages, totalFloorPlanConfigs);
    if (optionConfig) {
      floorPlanConfigs.push(optionConfig);
    }

    floorPlanConfigs.map(config => this.setCarouselIndex(config));

    return floorPlanConfigs;
  }

  setFloorPlanOptionsData(groupedFloorPlanImages: any[], optionConfigIndex: number): FloorPlansConfigurationModel {
    let optionImages: FloorPlanImage[] = [];
    const floorPlanOptions = groupedFloorPlanImages.filter(imageGroup => imageGroup.key.includes('Options'));

    if (floorPlanOptions && floorPlanOptions.length) {
      optionImages = floorPlanOptions.map(optionGroup => {
        if (optionGroup && optionGroup.value && optionGroup.value[0] && optionGroup.value[0].PlanConfiguration) {
          if (optionGroup.value[0].PlanConfiguration === 'Options2') {
            optionGroup.value[0].FloorPlanType = 'Options 2';
          } else {
            optionGroup.value[0].FloorPlanType = optionGroup.value[0].PlanConfiguration;
          }
          return optionGroup.value[0];
        }
      });

      if (optionImages && optionImages.length) {
        const optionConfig = new FloorPlansConfigurationModel();

        optionConfig.ConfigurationName = 'OPTIONS';

        optionConfig.CarouselOptions = this.setCarouselOptionsForConfig(optionImages);

        if (optionConfig.CarouselOptions.Images && optionConfig.CarouselOptions.Images.length === 1) {
         this.validateSingleImage(optionConfig);
        }

        optionConfig.Index = optionConfigIndex;

        return optionConfig;
      }
    }
  }

  selectConfiguration(index: number): void {
    if (this.floorPlanConfigs && this.floorPlanConfigs[index]) {
      this.floorPlanConfigs.map(config => config.IsSelected = false);
      this.floorPlanConfigs[index].IsSelected = true;
    }
  }

  setCarouselOptionsForConfig(floorPlanImages: FloorPlanImage[]): CarouselOptionsModel {
    const carouselOptions = new CarouselOptionsModel(floorPlanImages);
    carouselOptions.ShowImageIndex = false;
    carouselOptions.ShowModalCta = true;
    carouselOptions.ShowImageCaption = false;
    carouselOptions.IsFloorPlan = true;
    carouselOptions.AspectRatio = 1;
    return carouselOptions;
  }

  validateSingleImage(floorPlanConfig: FloorPlansConfigurationModel): void {
    if (floorPlanConfig.CarouselOptions && floorPlanConfig.CarouselOptions.Images
      && floorPlanConfig.CarouselOptions.Images[0] && floorPlanConfig.CarouselOptions.Images[0].Path) {
        floorPlanConfig.ValidSingleImage = true;
      }
  }

  selectFloorPlanImage(activeConfigIndex: number, carouselIndex: number, imageIndex: number): void {
    if (this.floorPlanConfigs && this.floorPlanConfigs[activeConfigIndex]
      && this.floorPlanCarousels && this.floorPlanCarousels[carouselIndex]) {
      this.floorPlanConfigs[activeConfigIndex].ActiveImageIndex = imageIndex;
      this.floorPlanCarousels[carouselIndex].selectSlide(imageIndex);
    }
  }

  setActiveImageSelection(activeSlideId: number): void {
    const activeConfig = this.getActiveConfig();
    if (activeConfig) {
      activeConfig.ActiveImageIndex = activeSlideId;
    }
  }

  setCarouselIndex(floorPlanConfig: FloorPlansConfigurationModel): void {
    floorPlanConfig.CarouselIndex = this.totalCarousels;
    this.totalCarousels ++;
  }

  getActiveConfig(): FloorPlansConfigurationModel {
    const activeConfig: FloorPlansConfigurationModel = this.floorPlanConfigs.find(config => config.IsSelected === true);
    return activeConfig;
  }

  openFloorPlanModal(activeImageIndex: number): void {
    const modalClass = this.hasSummary ? ModalClasses.Summary : '';
    const modalRef: NgbModalRef = this.modalService.open(FloorPlanModalComponent,
      { windowClass: `${ModalClasses.Full} ${modalClass}`, backdrop: false });
    const activeConfig = this.getActiveConfig();
    if (activeConfig && activeConfig.CarouselOptions && activeConfig.CarouselOptions.Images[activeImageIndex]) {
      modalRef.componentInstance.data = this.data;
      modalRef.componentInstance.planId = this.planId;
      modalRef.componentInstance.floorPlanConfigs = this.floorPlanConfigs;
      modalRef.componentInstance.floorPlanCarousels = this.floorPlanCarousels;
    }
  }
}
