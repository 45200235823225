export enum AdobeLaunchEvent {
  PageViewLoaded = 'Page View Loaded',
  PlanListingDisplayed = 'Plan Listing Displayed',
  PlanListingViewed = 'Plan Listing Viewed',
  PlanListingClicked = 'Plan Listing Clicked',
  FormSubmitted = 'Form Submitted'
}

export enum AdobeLaunchFilterKey {
  MinPrice = 'minPrice',
  MaxPrice = 'minPrice',
  MinSqFt = 'minSquareFoot',
  MaxSqft = 'maxSquareFoot',
  MinBeds = 'minBedrooms',
  MaxBeds = 'maxBedrooms',
  MinBaths = 'minBathrooms',
  MaxBaths = 'maxBathrooms',
  ModelAvailable = 'modelAvailable',
  QmiAvailable = 'qmiAvailable',
  Available = 'available',
  Sold = 'sold',
  NotReleased = 'notReleased'
}

export enum AdobeLaunchFormName {
  RequestBrochure = 'Brochure Request',
  GuestRegistration = 'Guest Registration',
  MayMyCommute = 'Map My Commute'
}

export enum AdobeLaunchFormType {
  Modal = 'Modal',
  Inline = 'Inline'
}
