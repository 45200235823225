import { Router } from '@angular/router';

// check hostname for the preview prefix and return true if it exists
export function hostnameContainsPreviewPrefix(): boolean {
  let hostnameContainsPreview: boolean = false;
  const hostname: string = window.location.hostname.toLowerCase();

  if (hostname.includes('preview.')) {
    hostnameContainsPreview = true;
  }

  return hostnameContainsPreview;
}

// set a trimmed url for components that can't retrieve the current URL
export function setTrimUrl(router: Router): string {
  return `${router.url.split('/page/')[0]}/page/`;
}
