<div class="modal-header">
    <h1 class="modal-title featureGallery__header">All Features</h1>
    <span class="modal-action" (click)="activeModal.close()"><app-close-button></app-close-button></span>
</div>
<div *ngIf="planFeaturesModal" class="modal-body row">
  <div *ngIf="planFeaturesModal.EmbraceYourSpaceImages" class="d-flex">
    <div class="d-flex flex-wrap col-6">
      <div class="col-6">
        <cl-image class="w-100 rounded"
          public-id="{{planFeaturesModal.EmbraceYourSpaceImages[2].Path}}"
          format="auto"
          quality="auto"
          type="fetch"
          crop="fill"
          width="auto"
          [attr.alt]="planFeaturesModal.EmbraceYourSpaceImages[2].AltText"
          [attr.aspect-ratio]="1.5"></cl-image>
        <i>{{planFeaturesModal.EmbraceYourSpaceImages[2].Caption}}</i>
        <div class="featureGallery__bottomImage">
          <cl-image class="w-100 rounded"
            public-id="{{planFeaturesModal.EmbraceYourSpaceImages[0].Path}}"
            format="auto"
            quality="auto"
            type="fetch"
            crop="fill"
            width="auto"
            [attr.alt]="planFeaturesModal.EmbraceYourSpaceImages[0].AltText"  
            [attr.aspect-ratio]="1.5"></cl-image>
          <i>{{planFeaturesModal.EmbraceYourSpaceImages[0].Caption}}</i>
        </div>
      </div>
      <div class="d-flex col-6 flex-column p-0">
          <cl-image class="w-100 rounded"
            public-id="{{planFeaturesModal.EmbraceYourSpaceImages[1].Path}}"
            format="auto"
            quality="auto"
            type="fetch"
            crop="fill"
            width="auto"
            [attr.alt]="planFeaturesModal.EmbraceYourSpaceImages[1].AltText"
            [attr.aspect-ratio]="0.75"></cl-image>
          <i>{{planFeaturesModal.EmbraceYourSpaceImages[1].Caption}}</i>
      </div>
    </div>
    <div *ngIf="planFeaturesModal.PlanGalleryImage" class="col-6">
      <cl-image class="w-100 rounded"
        public-id="{{planFeaturesModal.PlanGalleryImage.Path}}"
        format="auto"
        quality="auto"
        type="fetch"
        crop="fill"
        width="auto"
        [attr.alt]="planFeaturesModal.PlanGalleryImage.AltText"
        [attr.aspect-ratio]="1.5"></cl-image>
      <i>{{planFeaturesModal.PlanGalleryImage.Caption}}</i>
    </div>
    <div class="featureGallery__description pb-3" [ngClass]="{'featureGallery__description--has-summary': planFeaturesModal.hasSummary}">
      <div *ngIf="planFeaturesModal.MetaDescription" class="featureGallery__description-meta">
        {{planFeaturesModal.MetaDescription}}
      </div>
      <div *ngIf="planFeaturesModal.Features">
        <div *ngFor="let groupedFeature of planFeaturesModal.Features | groupBy:'Category'">
          <div class="featureGallery__description-key">{{groupedFeature?.key}}</div>
          <ul class="m-0" *ngFor="let feature of groupedFeature?.value | slice:0:5;">
              <li>{{feature.FeatureName}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="back-wrapper modal-footer">
  <span class="back-wrapper-btn" (click)="activeModal.close()"><app-back-button></app-back-button></span>
</div>
