<section class="d-flex justify-content-center">
    <cl-image *ngIf="data?.Image?.Src"
        public-id="{{data.Image.Src}}"
        class="mr-1"
        format="auto"
        quality="auto"
        type="fetch"
        crop="fill"
        [attr.alt]="data.Image.Alt">
    </cl-image>
</section>