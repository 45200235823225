import { GuestRegistrationContactModel } from './guest-registration-contact.model';

export class GuestRegistrationModel {
  Contacts: GuestRegistrationContactModel[] = [];
  HasAgent: boolean;
  AgentFirstName?: string;
  AgentLastName?: string;
  AgentEmail?: string;
  AgentPhone?: string;
  AgentZip?: string;
  CommunityId: number;
  IsKioskRegistration: boolean;
  AdditionalComments: string;
}
