import { Component, OnInit, Input } from '@angular/core';
import { VideoModalModel } from 'src/app/models/content/media/video-modal.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plan-virtual-tour-modal',
  templateUrl: './plan-virtual-tour-modal.component.html',
  styleUrls: ['./plan-virtual-tour-modal.component.scss']
})
export class PlanVirtualTourModalComponent implements OnInit {

  @Input() videoModalModel: VideoModalModel;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

}
