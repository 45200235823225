import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommunityService } from 'src/app/core-services/community.service';
import { map, takeUntil } from 'rxjs/operators';
import { Plan } from 'src/app/models/product/plan.model';
import { Observable, Subject } from 'rxjs';
import { PlanCtasModel } from 'src/app/models/content/plan/plan-ctas.model';
import { BaseComponent } from '../../shared/base/base.component';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanQmiListingModalComponent } from '../../shared/modals/plan-qmi-listing-modal/plan-qmi-listing-modal.component';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-plan-ctas',
  templateUrl: './plan-ctas.component.html',
  styleUrls: ['./plan-ctas.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 800, delay: 750 })
  ]
})
export class PlanCtasComponent extends BaseComponent implements OnInit {

  @Input() data: PlanCtasModel;

  plan$: Observable<Plan>;
  qmiCountClass: string;

  constructor(private communityService: CommunityService,
              private route: ActivatedRoute,
              private modalService: NgbModal) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      takeUntil(this.unsubscribe$)
    ).subscribe((planId: string) => {
      this.plan$ = this.communityService.getPlanById(planId);
      this.qmiCountClass = this.setQmiCountClass(this.data.QMI_CTA_Style);
    });
  }

  setQmiCountClass(buttonType: string): string {
    let qmiCountClass: string = '';

    switch (buttonType) {
      case 'Default':
        qmiCountClass = 'planCtas__qmiCountDefault';
        break;
      default:
        qmiCountClass = 'planCtas__qmiCountPrimary';
    }

    return qmiCountClass;
  }

  openQmiListModal(plan: Plan): void {
    const modalRef: NgbModalRef = this.modalService.open(PlanQmiListingModalComponent, { backdrop: false,
      windowClass: `${ModalClasses.Full}`});
    if (this.data) {
      modalRef.componentInstance.data = this.data;
      modalRef.componentInstance.plan = plan;
    }
  }

}

