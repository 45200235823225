import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { tap, catchError, concatMap, takeUntil, timeout } from 'rxjs/operators';
import { ObservableInput, of, Subject, throwError } from 'rxjs';
import { Community } from '../models/product/community.model';
import { Plan } from '../models/product/plan.model';
import { RequestBrochureParams } from '../models/content/editorial/request-brochure-params.model';
import { AppInsightsService } from './app-insights.service';
import { RequestBrochureResponse } from '../models/content/editorial/request-brochure-response.model';
import { QMI } from '../models/product/qmi.model';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class PlanBrochureService {

  private apiUrl: string;

  constructor(private http: HttpClient, private appInsightsService: AppInsightsService,
    private settingsService: SettingsService) { 
      this.apiUrl = this.settingsService.apiUrl + '/planbrochure';
  }

  async requestPlanBrochure(community: Community, plans: Plan[], qmis: QMI[], emailAddress: string) {
    if (community && plans && emailAddress) {
      const requestBrochureParams = new RequestBrochureParams();
      requestBrochureParams.CommunityId = community.Id.toString();
      requestBrochureParams.EmailAddress = emailAddress;
      requestBrochureParams.PlanIds = plans.map(plan => plan.Id.toString());
      requestBrochureParams.QmiIds = qmis.map(qmi => qmi.InventoryHomeID.toString());

      return await this.http.get<RequestBrochureResponse>(
        `${this.apiUrl}/SendBrochureEmail`, { params: requestBrochureParams as any })
        .pipe(
          timeout(30000),
          catchError((err: HttpErrorResponse) => {
            this.appInsightsService.logException(new Error(`An error occurred for Plan Brochure service: ${err.message}`), false);
            return of(new RequestBrochureResponse()); // default isSuccessful = false
          })
        ).toPromise();
    }
  }
}
