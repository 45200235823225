import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol, CurrencyPipe } from '@angular/common';
import { pipeFromArray } from 'rxjs/internal/util/pipe';

// Provides currnecy piping functionality while also abbreviating number value inputs of 1000 or more.
@Pipe({ name: 'abbrevCurrency' })
export class AbbrevCurrencyPipe implements PipeTransform {
  transform(num, fixed, currency, format, locale) {
    if (num === null) { 
      return null; 
    }

    if (num === 0) { 
      return '0'; 
    }

    // number of decimal places to show
    fixed = (!fixed || fixed < 0) ? 0 : fixed;

    // get power
    const b = parseFloat(num).toPrecision(2).split('e'); 

    // floor at decimals, ceiling at trillions
    const k = b.length === 1 ? 0 : Math.floor(Math.min(parseFloat(b[1].slice(1)), 14) / 3);

    // divide by power
    const c = k < 1 ? num.toFixed(+ fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed);
    const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
    const e = new CurrencyPipe(locale, currency).transform(d, currency, format, `1.${fixed}-${fixed}`, locale);

    return e + ['', 'K', 'M', 'B', 'T'][k]; // append power
  }
}
