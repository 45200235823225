import { Component, OnInit, Input } from '@angular/core';
import { RequestBrochureCTAModel } from 'src/app/models/content/editorial/request-brochure.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { RequestBrochureModalComponent } from '../../shared/modals/request-brochure-modal/request-brochure-modal.component';
import { Plan } from 'src/app/models/product/plan.model';
import { QMI } from 'src/app/models/product/qmi.model';

@Component({
  selector: 'app-request-brochure-cta',
  templateUrl: './request-brochure-cta.component.html',
  styleUrls: ['./request-brochure-cta.component.scss']
})
export class RequestBrochureCTAComponent implements OnInit {

  @Input() ctaData: RequestBrochureCTAModel;
  @Input() currentPlanData: Plan;
  @Input() currentQmiData: QMI;

  modalClass: string;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  openBrochureModal() {
    const modalRef: NgbModalRef = this.modalService.open(RequestBrochureModalComponent,
      { windowClass: `${ModalClasses.Full} ${ModalClasses.Right} `, backdrop: true, backdropClass: ` ${ModalClasses.BackDrop}` });
    modalRef.componentInstance.currentPlanData = this.currentPlanData;
    modalRef.componentInstance.currentQmiData = this.currentQmiData;
  }
}
